import { DispatchAction } from '@iolabs/redux-utils';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import { Box } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';

import { ModeTypes } from '../../redux/editing/editingSlice';
import { useMode } from '../../redux/editing/hooks';
import { setSelectedAsset, setSelectedAssetProps, useElementsStatus } from '../../redux/project';
import {
    IFileElementMove,
    IFileElementRotate,
    IFileElementsStatus,
    IFileElementStatus,
} from '../../redux/project/types';
import TwoWayMap from '../../utils/TwoWayMap';
import BoxOperationsInfo from '../Scanning/BoxOperationsInfo';
import { getSensorSvgPath } from '../Scanning/utils';
import ToolbarExtensionName, { type ToolbarExtensionType } from './extensions/ToolbarTransform';
import SensorHighlighter from './SensorHighlighter';
import useStyles from './styles';

interface ISensorsWrapperProps {
    viewer: Autodesk.Viewing.Viewer3D;
    externalIDMap: TwoWayMap<string, number>;
    adjustingActiveTool?: string;
}
interface IPoint {
    x: number;
    y: number;
}

interface IBoxCoords {
    min: IPoint;
    max: IPoint;
    // world: any;
}

export interface IBox extends IBoxCoords {
    elementStatus: IFileElementStatus;
    dbID: number | undefined;
    externalID: string;
    assetImage?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    /**
     * @deprecated
     */
    elementCenter?: THREE.Vector2;
    transform?: IFileElementMove;
    adjustingActive?: boolean;
    scheduledDelete?: boolean;
    scheduledAdjust?: boolean;
    moved?: boolean;
    rotated?: boolean;
    deleted?: boolean;
    copied?: boolean;
    created?: boolean;
    rotate?: IFileElementRotate;
}

/**
 * Calculate bounding box based on center point and size copied from object defined by dbID
 * @param viewer
 * @param centerPoint
 * @param status
 * @param dbID
 */
const getOutlineFromTemporary = (
    viewer,
    centerPoint: THREE.Vector3,
    status: IFileElementStatus,
    dbID?: number,
): IBoxCoords | null => {
    if (!centerPoint) {
        return null;
    }

    const center = _.cloneDeep(viewer.worldToClient(centerPoint));

    //TODO: detect real sensor size if no matching dbID sent
    const boundingBox: THREE.Box3 = dbID
        ? getObjectBound2D(viewer.model, dbID)
        : new THREE.Box3(new THREE.Vector3(0, 0, 0), new THREE.Vector3(4, 4, 0));

    // bounding box returned in world coordinates
    const bbSizeMin = viewer.worldToClient(boundingBox.min);
    const bbSizeMax = viewer.worldToClient(boundingBox.max);
    const sensorSize: THREE.Vector2 = new THREE.Vector2(
        Math.abs(bbSizeMax.x - bbSizeMin.x),
        Math.abs(bbSizeMax.y - bbSizeMin.y),
    );

    // console.log('AEC | getOutlineFromTemporary | dbID ', dbID);

    // calculate bounding box based on center point and size
    const minClient = {
        x: center.x - sensorSize.x / 2,
        y: center.y - sensorSize.y / 2,
    };
    const maxClient = {
        x: center.x + sensorSize.x / 2,
        y: center.y + sensorSize.y / 2,
    };

    return {
        min: minClient,
        max: maxClient,
    };
};

/**
 * Get client (screen) coordinates from mouse and drag event
 * @param event
 * @return Vector2
 */
const getClientCoordsFromEvent = (
    event: React.MouseEvent<HTMLElement, MouseEvent> | React.DragEvent<HTMLElement>,
): THREE.Vector2 => {
    const clientRect = event?.currentTarget?.getBoundingClientRect();
    return {
        x: event?.clientX - clientRect?.left,
        y: event?.clientY - clientRect?.top,
    } as THREE.Vector2;
};

const getOutline = (viewer, id, status: IFileElementStatus): IBoxCoords | null => {
    const bondingBox = getObjectBound2D(viewer.model, id);

    if (bondingBox?.min && bondingBox?.max) {
        //Move element
        if (status.move) {
            bondingBox.min.x = bondingBox.min.x + status.move.deltaWorld.x;
            bondingBox.max.x = bondingBox.max.x + status.move.deltaWorld.x;
            bondingBox.min.y = bondingBox.min.y + status.move.deltaWorld.y;
            bondingBox.max.y = bondingBox.max.y + status.move.deltaWorld.y;
        }

        const min = viewer.worldToClient(bondingBox.min);
        const max = viewer.worldToClient(bondingBox.max);

        return {
            min: {
                x: min.x,
                y: min.y,
            },
            max: {
                x: max.x,
                y: max.y,
            },
        };
    }
    return null;
};

const find2DBounds = (model, fragId, dbId, bc) => {
    const mesh = model.getFragmentList().getVizmesh(fragId);
    const vbr = new Autodesk.Viewing.Private.VertexBufferReader(mesh.geometry);
    vbr.enumGeomsForObject(dbId, bc);
};

export const getObjectBound2D = (model, objectId) => {
    // This doesn't guarantee that an object tree will be created but it will be pretty likely
    let bounds, bc, i;
    if (model?.is2d()) {
        bounds = new THREE.Box3();
        // move this next one up into the calling method
        bc = new Autodesk.Viewing.Private.BoundsCallback(bounds);

        const dbId2fragId = model.getData().fragments.dbId2fragId;

        const fragIds = dbId2fragId[objectId];

        // fragId is either a single vertex buffer or an array of vertex buffers
        if (Array.isArray(fragIds)) {
            for (let j = 0; j < fragIds.length; j++) {
                // go through each vertex buffer, looking for the object id
                find2DBounds(model, fragIds[j], objectId, bc);
            }
        } else if (typeof fragIds === 'number') {
            // go through the specific vertex buffer, looking for the object id
            find2DBounds(model, fragIds, objectId, bc);
        }

        // should have some real box at this point; check
        if (!bounds.isEmpty()) {
            return bounds;
        }
    }
};

const boxSymbolOverlay = (box: IBox) => {
    // if (!box.moved && !box.rotated) {
    //     return {
    //         display: 'none',
    //     };
    // } else {
    if (box.moved) {
        return {
            border: '3px dashed #FFD500',
        };
    } else {
        return {};
    }
    // }
};

const boxStyle = (box: IBox) => {
    const width = Math.abs(box.max.x - box.min.x);
    const height = Math.abs(box.max.y - box.min.y);
    const bg = box.moved || box.created ? 'rgba(255,0,0,.5)' : 'transparent';
    const opacity = box.moved || box.created ? 0.7 : 1;
    const style = {
        top: `${Math.min(box.min.y, box.max.y)}px`,
        left: `${box.min.x}px`,
        width: `${width}px`,
        height: `${height}px`,
        backgroundColor: bg,
        opacity: opacity,
        pointerEvents: (box.moved || box.created || box.copied
            ? 'all'
            : 'none') as React.CSSProperties['pointerEvents'],
    };

    return style;
};

const SensorsWrapper: React.FC<ISensorsWrapperProps> = ({
    viewer,
    externalIDMap,
    adjustingActiveTool,
}) => {
    const classes = useStyles();

    const dispatch = useDispatch<DispatchAction>();

    const elementsStatuses = useElementsStatus();

    const mode = useMode();

    const [sensorOverlayStyleOverride, setSensorOverlayStyleOverride] = useState({});

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const [statuses, _setStatuses] = useState<IFileElementsStatus>();
    const statusesRef = React.useRef(statuses);

    const [statusesLocal, setStatusesLocal] = useState<IFileElementsStatus>();
    const [boxesHidden, setBoxesHidden] = useState(false);

    const setStatuses = data => {
        statusesRef.current = data;
        _setStatuses(data);
    };

    useEffect(() => {
        setStatusesLocal(elementsStatuses);
        setStatuses(elementsStatuses);
    }, [elementsStatuses]);

    const [boxes, setBoxes] = useState<IBox[]>([]);
    const boxesRef = React.useRef(boxes);
    useEffect(() => {
        boxesRef.current = boxes;
    }, [boxes]);

    const handleViewerClick = event => {
        const clientCoords = getClientCoordsFromEvent(event);
        return boxes.filter(box => {
            return (
                clientCoords.x >= box.min.x &&
                clientCoords.x <= box.max.x &&
                clientCoords.y >= box.min.y &&
                clientCoords.y <= box.max.y
            );
        });
    };

    useEffect(() => {
        let timeoutId: ReturnType<typeof setTimeout> | undefined;

        const handleViewerChange = () => {
            // if more than 500 sensors, use debounce
            if (boxesRef.current.length < 100) {
                calculateBoxes();
                return;
            }

            setBoxesHidden(true); // Call hideBoxes immediately when the event listener is triggered

            // If there's a previous timeout, clear it to avoid calling calculateBoxes multiple times
            if (timeoutId) {
                clearTimeout(timeoutId);
            }

            // Set a new timeout to debounce calculateBoxes
            timeoutId = setTimeout(() => {
                setBoxesHidden(false);
                calculateBoxes();
            }, 1000);
        };

        if (viewer && viewer.container) {
            viewer.addEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, handleViewerChange);
            viewer.container.addEventListener('click', handleViewerClick);
        }

        return () => {
            if (viewer && viewer.container) {
                viewer.removeEventListener(
                    Autodesk.Viewing.CAMERA_CHANGE_EVENT,
                    handleViewerChange,
                );
                viewer.container.removeEventListener('click', handleViewerClick);
            }

            // Clear the timeout when the component is unmounted to avoid memory leaks
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [externalIDMap, statusesRef]);

    useEffect(() => {
        if (elementsStatuses) {
            calculateBoxes();
        }
    }, [statuses]);

    useEffect(() => {
        const stylesOverride = adjustingActiveTool
            ? { pointerEvents: 'auto', cursor: 'default' }
            : {};
        const tTool = getTransformExtension();
        const crossPointerTools = ['create', 'move', 'copy'];
        let setCrossPointer = false;
        crossPointerTools.forEach(tool => {
            if (tTool && tTool.isToolActive(tool)) {
                setCrossPointer = true;
            }
        });
        if (setCrossPointer) {
            stylesOverride.cursor = 'crosshair';
        }

        setSensorOverlayStyleOverride(stylesOverride);
    }, [adjustingActiveTool]);

    const canRotate = (externalId: string) => {
        const tTool = getTransformExtension();
        const activeElement = tTool?.getActiveElement();
        const activeRotation = tTool?.isToolActive('rotate');
        return activeRotation && activeElement && activeElement?.externalId === externalId;
    };

    const calculateBoxes = () => {
        const bxs: IBox[] = [];
        const statusesList = statusesRef.current;

        if (statusesList) {
            //Append local statuses - from geometry status
            Object.keys(statusesList)?.forEach(externalID => {
                const status = statusesList[externalID];
                const dbID = externalIDMap.get(externalID);

                if (dbID || status.copy || status.create) {
                    // if (status.isTemporary) {
                    //     console.log('temporary box', externalID, status);
                    // }

                    // todo use correct image based on sensor type
                    const assetImageFromStatus =
                        status.create || status.move || status.copy
                            ? getSensorSvgPath(
                                  (status.create &&
                                      status.create.sourceElementTemplate &&
                                      status.create.sourceElementTemplate.code) ||
                                      'FDO221',
                              )
                            : undefined;

                    //`data:image/svg+xml,%3Csvg width='100%25' height='100%25' viewBox='0 0 222 222' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;'%3E%3Cpath d='M0,221.497L0,0L221.268,0L221.268,221.497L0,221.497ZM206.253,206.25L206.253,15L15.753,15L15.753,206.25L206.253,206.25ZM148.399,179.352C147.345,179.354 146.367,178.803 145.823,177.901C143.613,174.234 137.833,164.645 135.599,160.938C135.043,160.015 135.025,158.865 135.551,157.925C137.754,153.987 143.628,143.489 145.841,139.535C146.371,138.587 147.372,138 148.459,138L150.718,138C150.718,138 151.045,63.004 151.115,46.987C151.122,45.335 152.463,44 154.115,44L162.753,44C164.41,44 165.753,45.343 165.753,47L165.753,138L168.043,138C169.128,138 170.128,138.585 170.659,139.531C172.871,143.471 178.74,153.925 180.946,157.854C181.474,158.794 181.456,159.946 180.9,160.87C178.664,164.583 172.878,174.191 170.672,177.855C170.13,178.754 169.158,179.305 168.109,179.308C163.842,179.317 152.674,179.342 148.399,179.352ZM42.286,172.172C39.276,170.862 37.503,168.298 37.503,165.256C37.503,162.405 39.428,160.102 58.011,140.722C64.959,133.476 70.527,127.274 70.386,126.941C70.244,126.607 62.703,119.28 53.628,110.659C35.932,93.848 34.202,91.592 36.073,87.76C37.09,85.677 69.444,51.152 74.718,46.521C78.215,43.451 81.791,43.423 84.81,46.442C86.511,48.143 87.003,49.315 87.003,51.668C87.003,55.095 85.966,56.35 64.999,78.295L53.871,89.943C62.532,98.219 71.19,106.499 79.876,114.75C88.402,122.848 89.189,123.91 89.226,127.365C89.249,129.582 87.755,131.426 75.565,144.22C68.037,152.122 59.178,161.427 55.878,164.899C52.578,168.371 48.792,171.671 47.464,172.231C44.627,173.429 45.188,173.435 42.286,172.172Z' style='fill-rule:nonzero;'/%3E%3C/svg%3E`;

                    // if (externalID == 'temp-354fc3dc-b2cf-4436-a313-56b6189cdc41-00119f9f') {
                    //     debugger;
                    // }
                    // if (externalID == '354fc3dc-b2cf-4436-a313-56b6189cdc41-00119f9f') {
                    //     debugger;
                    // }
                    // if (status.create) {
                    //     debugger;
                    // }

                    const outline =
                        status.copy || status.create
                            ? getOutlineFromTemporary(
                                  viewer,
                                  status.copy
                                      ? status.copy.targetWorldPoint
                                      : status.create!.targetWorldPoint, // todo get rid of this chain, not needed
                                  status,
                                  status.copy?.sourceElementId
                                      ? externalIDMap.get(status.copy.sourceElementId)
                                      : undefined /*externalIDMap.get(status.create!.sourceElementId)*/, // taking first sensor as a sample, we will probably need to get any sensor of the same type
                              )
                            : // move or original position
                              getOutline(viewer, dbID, status);

                    if (outline) {
                        bxs.push({
                            ...outline,
                            dbID,
                            externalID,
                            elementStatus: status,
                            assetImage: assetImageFromStatus,
                            moved: !!status.move,
                            rotated: !!status.rotate,
                            rotate: status.rotate,
                            deleted: !!status.delete?.delete,
                            copied: !!status.copy,
                            created: !!status.create,
                        });
                    }
                }
            });
        }
        setBoxes(bxs);
    };

    const getTransformExtension = (): ToolbarExtensionType => {
        const tTool: ToolbarExtensionType = viewer?.getExtension(
            ToolbarExtensionName,
        ) as unknown as ToolbarExtensionType;

        return tTool;
    };

    const handleRotateLeft = event => {
        getTransformExtension()?.rotateActiveElementLeft();
        event.stopPropagation();
    };

    const handleRotateRight = event => {
        getTransformExtension()?.rotateActiveElementRight();
        event.stopPropagation();
    };

    const handleOverlayClicked = event => {
        if (getTransformExtension()?.isToolActive('move')) {
            getTransformExtension()?.moveActiveElement(event);
        }

        if (getTransformExtension()?.isToolActive('copy')) {
            getTransformExtension()?.copyActiveElement(event);
        }

        if (getTransformExtension()?.isToolActive('create')) {
            getTransformExtension()?.createNewElement(event);
        }

        event.stopPropagation();
    };

    const [draggingElement, setDraggingElement] = useState(false);

    const handleOverlayMouseDown = e => {
        setDraggingElement(true);
    };

    const handleOverlayMouseMove = event => {
        if (draggingElement) {
            // console.log('MMD-m | ', event);
            if (getTransformExtension()?.isToolActive('move')) {
                getTransformExtension()?.moveActiveElement(event, true);
            }
        }
    };

    const handleOverlayMouseUp = event => {
        setDraggingElement(false);
        if (getTransformExtension()?.isToolActive('move')) {
            getTransformExtension()?.moveActiveElement(event);
        }
    };

    // Save selected asset to redux store
    // Save selected asset properties to redux store
    const handleClickSymbolOverlay = (box: IBox, event) => {
        // console.log('OVF | handleClickSymbolOverlay', box, event);

        // Allow only if not in edit or scanning mode
        if (mode != ModeTypes.default) {
            return;
        }

        dispatch(setSelectedAsset(box.externalID));

        // If dbId is set - ge properties from viewer
        const dbId = box.dbID ?? -1;
        if (dbId > 0) {
            viewer?.model.getProperties(dbId, propsResult => {
                dispatch(
                    setSelectedAssetProps({
                        externalId: box.externalID,
                        dbId: dbId,
                        name: propsResult.name,
                        properties: propsResult.properties,
                    }),
                );
            });
        }

        // If dbId is not set
        else {
            dispatch(
                setSelectedAssetProps({
                    externalId: box.externalID,
                    dbId: dbId,
                    name: box.elementStatus.elementName ?? undefined,
                    properties: [],
                }),
            );
        }
    };
    const overlayRef = useRef(HTMLElement);

    useEffect(() => {
        // console.log('OVF | ', overlayRef);
        if (overlayRef && overlayRef.current) {
            const overlayRefElem = overlayRef.current as unknown as HTMLElement;
            overlayRefElem.addEventListener(
                'wheel',
                function (e) {
                    e.preventDefault();
                    console.log('OVF | No wheel', e);
                },
                { passive: false },
            );
        }
    }, [overlayRef]);

    useEffect(() => {
        if (viewer && viewer.model) {
            viewer.clearThemingColors(viewer.model);
            boxes.forEach(box => {
                if (box.dbID && (box.moved || box.deleted)) {
                    viewer.setThemingColor(
                        box.dbID,
                        new THREE.Vector4(1, 1, 1, 1),
                        viewer.model,
                        true,
                    );
                }
            });
        }
    }, [boxes, viewer, viewer.model]);

    return createPortal(
        <Box
            className={classes.sensorsOverlay}
            style={sensorOverlayStyleOverride}
            onClick={handleOverlayClicked}
            onMouseDown={handleOverlayMouseDown}
            onMouseMove={handleOverlayMouseMove}
            onMouseUp={handleOverlayMouseUp}
            ref={overlayRef}
        >
            {!boxesHidden &&
                boxes.map((box, index) => (
                    <Box
                        className={classes.boxOverlay}
                        style={boxStyle(box)}
                        key={index}
                        onClick={event => handleClickSymbolOverlay(box, event)}
                    >
                        <div className={classes.sensorSymbolOverlay} style={boxSymbolOverlay(box)}>
                            {box.assetImage && (
                                <box.assetImage className={classes.sensorSymbolOverlayImage} />
                            )}
                            {/*<img*/}
                            {/*    className={classes.sensorSymbolOverlayImage}*/}
                            {/*    src={box.assetImage}*/}
                            {/*    alt={box.dbID?.toString()}*/}
                            {/*    onClick={event => handleClickSymbolOverlay(box, event)}*/}
                            {/*/>*/}
                        </div>
                        <SensorHighlighter
                            viewer={viewer}
                            externalID={box.externalID}
                            dbID={box.dbID}
                            elementStatus={box.elementStatus}
                        />
                        <BoxOperationsInfo box={box} />
                        {canRotate(box.externalID) && (
                            <div className={classes.rotatingControls}>
                                <button onClick={handleRotateLeft}>
                                    <RotateLeftIcon />
                                </button>
                                <button onClick={handleRotateRight}>
                                    <RotateRightIcon />
                                </button>
                            </div>
                        )}
                    </Box>
                ))}
        </Box>,
        viewer.container,
    );
};

export default SensorsWrapper;

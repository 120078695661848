import { createListenerMiddleware } from '@reduxjs/toolkit';

import { AddRecentFileRequest } from '../../generate/api';
import { filesApi } from '../../packages/Api/data/files/client';
import { setCurrentVersion } from './projectSlice';
import { IProject } from './types';

export const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
    actionCreator: setCurrentVersion,
    effect: async (action, listenerApi) => {
        const projectData = action.payload;
        console.log(projectData.data.urn);
        if (
            projectData &&
            projectData.data &&
            projectData.data.urn &&
            projectData.data.urn.length > 0
        ) {
            const request: AddRecentFileRequest = {
                forgeVersionURN: projectData.data.urn,
            };

            filesApi
                .fileFileHistoryAddPost(request)
                .then(response => {
                    console.log(response ? 'Recent file added' : 'Recent file NOT added');
                })
                .catch(error => {
                    console.error('Unable to add recent file to user history', error);
                });
        }
    },
});

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IMethodName } from '../../components/Scanning/utils';
import { RootState } from '../store';

export interface ISignal {
    methodName?: IMethodName;
    data?: any;
}

export interface ISignalRRootState {
    current: {
        methodName?: IMethodName;
        data?: any;
    };
    // history: ISignal[];
}

const initialState: Readonly<ISignalRRootState> = {
    current: {},
    // history: [],
};

const exportSlice = createSlice({
    name: 'signalR',
    initialState,
    reducers: {
        onSignalRCatch: (state, action: PayloadAction<ISignal>) => {
            // store previous signal in history
            state.current.methodName = action.payload.methodName;
            state.current.data = action.payload.data;
        },
    },
});

export const { onSignalRCatch } = exportSlice.actions;

export const selectSignalRState = (state: RootState): ISignal | undefined => state.signalR.current;

export default exportSlice.reducer;

import { isPlatform } from '@ionic/react';
import { useKeycloak } from '@react-keycloak/web';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { getForgeLogoutUrl } from '../../redux/keyclock/forge';

interface ILogoutProps {
    children?: React.ReactNode;
}

const Logout: React.FC<ILogoutProps> = ({ children }) => {
    const { keycloak } = useKeycloak();
    const history = useHistory();
    const homepageUrl = `${window.location.origin}`;
    const logoutUrl = getForgeLogoutUrl(homepageUrl);
    const handleLogoutCallback = () => {
        // if ios or android
        if (isPlatform('capacitor')) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const inAppBrowserRef = cordova.InAppBrowser.open(logoutUrl, '_blank', 'location=no');

            const logout = () => {
                keycloak?.logout();
                history.push('/');
                window.location.reload();
            };

            inAppBrowserRef.addEventListener('exit', () => {
                logout();
            });

            inAppBrowserRef.addEventListener('loadstop', () => {
                logout();
            });
        }
        // if web
        else {
            keycloak?.logout({
                redirectUri: logoutUrl,
            });
        }
    };

    return <span onClick={handleLogoutCallback}>{children}</span>;
};

export default Logout;

import { IonContent, IonPage } from '@ionic/react';
import { CssBaseline } from '@mui/material';
import React from 'react';

import style from './singleLayout.module.css';

interface ISingleLayoutProps {
    children?: React.ReactNode;
}

const SingleLayout: React.FC<ISingleLayoutProps> = ({ children }) => {
    return (
        <IonPage>
            <CssBaseline />
            <IonContent>
                <div className={style.root}>{children}</div>
            </IonContent>
        </IonPage>
    );
};

export default SingleLayout;

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        dialogContent: {
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center',
        },
    }),
);

export default useStyles;

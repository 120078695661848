import CloseIcon from '@mui/icons-material/Close';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import { Box } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import { SearchResultFile } from '../../generate/api';
import { filesApi } from '../../packages/Api/data/files/client';
import { useTranslation } from '../../redux/translations/hook';
import messages from './messages';
import useStyles from './styles';

interface IModalProps {
    onSearchResult: (files: SearchResultFile[]) => void;
    onSearchClear: () => void;
}

const FileSearchBar: React.FC<IModalProps> = ({ onSearchResult, onSearchClear }) => {
    const classes = useStyles();

    const inputRef = useRef<HTMLInputElement | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [clearInputVisible, setClearInputVisible] = useState<boolean>(false);

    let typingTimer; //timer
    const doneTypingInterval = 1000; //grace period for typing another letter

    // ---------------------------------------------------------------------------------------------------------
    // Translations
    // ---------------------------------------------------------------------------------------------------------
    const searchPlaceholder = useTranslation({ ...messages.searchPlaceholder });

    function clearSearch() {
        setSearchQuery('');
        setClearInputVisible(false);
        if (inputRef.current) {
            inputRef.current.value = '';
        }
        onSearchClear();
    }

    function handleChange(event) {
        const query = event.target.value;
        if (!clearInputVisible) {
            setClearInputVisible(query.length > 0);
        }

        if (query.length >= 3) {
            clearTimeout(typingTimer);
            typingTimer = setTimeout(() => setSearchQuery(query), doneTypingInterval);
        } else {
            clearTimeout(typingTimer);
        }
    }

    useEffect(() => {
        if (searchQuery && searchQuery.length >= 3) {
            setIsLoading(true);
            filesApi
                .fileFileSearchGet(searchQuery)
                .then(response => {
                    setSearchQuery('');
                    if (response && response.data && response.data.files) {
                        onSearchResult(response.data.files);
                    } else {
                        onSearchResult([]);
                    }
                })
                .catch(error => {
                    console.error('Unable to search project files', error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [searchQuery]);

    return (
        <Box className={classes.searchWrapper}>
            <input
                ref={inputRef}
                type="text"
                className={classes.searchInput}
                placeholder={searchPlaceholder}
                onChange={handleChange}
            ></input>
            {clearInputVisible && (
                <Box className={classes.closeIcon} onClick={clearSearch}>
                    {isLoading ? (
                        <HourglassTopIcon fontSize="small" />
                    ) : (
                        <CloseIcon fontSize="small" />
                    )}
                </Box>
            )}
        </Box>
    );
};

export default FileSearchBar;

import { IPushpinsItem, ObjectLayer } from '@iolabs/forge-viewer-object-layer';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { Issue } from '../../generate/api';
import { useAppDispatch } from '../../hooks';
import {
    setCurrenIssuePushpin,
    setCurrentIssue,
    setCurrentIssuePushpinExternalId,
    setPushpinInsertMode,
    useIssuesState,
} from '../../redux/issues';
import { sanitizeExternalId } from '../../utils/sanitizeExternalId';
import useStyles from './styles';

interface IIssuesLayerProps {
    viewer: Autodesk.Viewing.Viewer3D;
}

const IssuesLayer: React.FC<IIssuesLayerProps> = ({ viewer }) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();

    const reduxIssues = useIssuesState();
    const issues = reduxIssues.list?.issues;
    const highlightedIssue = reduxIssues.currentIssue?.forgeIssueID;
    const mode = reduxIssues?.mode;
    const isInsertMode = reduxIssues?.pushpin?.insertMode;

    const handlePushpinPlaced = (pushpin: IPushpinsItem<Issue>) => {
        console.log('Pushpin placed', pushpin);
        dispatch(
            setCurrenIssuePushpin({
                objectId: pushpin.objectId,
                coordinates: {
                    x: pushpin.coordinates.x,
                    y: pushpin.coordinates.y,
                    z: pushpin.coordinates.z,
                },
            }),
        );

        // externalId from pushpin
        const dbId = viewer?.getSelection()[0];
        if (!dbId) {
            return;
        }
        viewer.getProperties(dbId, function (result) {
            if (result.externalId) {
                const externalId = sanitizeExternalId(result.externalId);
                dispatch(setCurrentIssuePushpinExternalId(externalId));
            } else {
                dispatch(setCurrentIssuePushpinExternalId(null));
            }
        });

        console.log('Pushpin placed', pushpin);
    };

    const handleClickIssue = (issue: Issue) => () => {
        if (mode === 'edit') return;
        dispatch(setCurrentIssue(issue));
    };

    const key = isInsertMode ? 'insert' : 'noinsert';

    if (!viewer || !issues) {
        return <></>;
    }

    return (
        <ObjectLayer<Issue>
            key={key}
            viewer={viewer}
            items={
                issues
                    ? issues
                          .filter(i => i.linkedDocument)
                          .map(i => {
                              return {
                                  coordinates: i.linkedDocument?.position,
                                  objectId: i.linkedDocument?.objectID
                                      ? i.linkedDocument?.objectID
                                      : undefined,
                                  data: i,
                                  classNames:
                                      highlightedIssue === (i.forgeIssueID as string)
                                          ? classes.pushpinHighlighted
                                          : '',
                              } as IPushpinsItem<Issue>;
                          })
                    : []
            }
            renderPushpinContent={(item, anchorElement, handleClose) => (
                <Box className={`pushpin-label ${classes.pushpinLabel}`}>{item.title}</Box>
            )}
            placeOnClick={isInsertMode}
            onPushpinChanged={handlePushpinPlaced}
            onPushpinClicked={item => handleClickIssue(item as Issue)()}
        />
    );
};

export default IssuesLayer;

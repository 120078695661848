import { defineMessages } from 'react-intl';

export default defineMessages({
    signOut: {
        id: 'components.header.sign-out',
        defaultMessage: 'Sign out',
    },
    language: {
        id: 'components.header.language',
        defaultMessage: 'Language',
    },
    messageLanguageChanged: {
        id: 'components.languageSwitcher.messageLanguageChanged',
        defaultMessage: 'Language changed',
    },
    messageViewerReloadRequired: {
        id: 'components.languageSwitcher.messageViewerReload',
        defaultMessage: 'All translations will be applied after viewer is reloaded.',
    },
});

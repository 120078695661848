import { defineMessages } from 'react-intl';

export default defineMessages({
    labelProject: {
        id: 'components.viewSelector.label.project',
        defaultMessage: 'Project',
    },
    labelFile: {
        id: 'components.viewSelector.label.file',
        defaultMessage: 'File',
    },
    labelVersion: {
        id: 'components.viewSelector.label.version',
        defaultMessage: 'Version',
    },
});

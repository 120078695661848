import { useSelector } from 'react-redux';

import { IssueDetailsResponse } from '../../generate/api';
import {
    IIssuesSettings,
    selectCurrentIssueDetails,
    selectFilterIsActive,
    selectFilters,
    selectForceRefresh,
    selectIssuesSelectedCount,
    selectIssuesSettings,
    selectIssuesState,
    selectSearchText,
} from './issuesSlice';

export const useIssuesState = () => useSelector(selectIssuesState);

export const useIssuesSearchText = () => useSelector(selectSearchText);
export const useIssuesFilters = () => useSelector(selectFilters);
// export const useIssuesSelected = () => useSelector(selectIssuesSelected);
export const useIssuesSelectedCount = () => useSelector(selectIssuesSelectedCount);

export const useIssuesFilterIsActive = () => useSelector(selectFilterIsActive);
export const useIssuesForceRefresh = () => useSelector(selectForceRefresh);

export const useIssuesSettings = (): IIssuesSettings => useSelector(selectIssuesSettings);
export const useCurrentIssuesDetails = (): IssueDetailsResponse | undefined =>
    useSelector(selectCurrentIssueDetails);

import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    AppBar,
    Box,
    Button,
    CircularProgress,
    List,
    ListItem,
    ListItemText,
    Popover,
    Tab,
    Tabs,
} from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';

import { useProjectState } from '../../redux/project';
import LockedModelInfo from '../LockedModelInfo/LockedModelInfo';
import FormattedMessage from '../Translation/FormattedMessage';
import { ViewableRole, ViewerRole } from '../Viewer/types';
import messages from './messages';
import useStyles from './styles';

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </Box>
    );
}

export interface IViewSelectorPosition {
    left?: string;
    top?: string;
    right?: string;
    bottom?: string;
}

interface IViewSelector extends React.HTMLAttributes<HTMLElement> {
    viewables: any[];
    viewable: any;
    onSelectViewable: (viewable: Autodesk.Viewing.BubbleNode) => void;
    getViewableName: (viewable: Autodesk.Viewing.BubbleNode) => string;
    role?: ViewerRole;
    viewSelectorPosition?: IViewSelectorPosition;
    disabled?: boolean;
}

const ViewSelector: React.FC<IViewSelector> = ({
    viewable,
    viewables,
    onSelectViewable,
    getViewableName,
    role,
    viewSelectorPosition,
    disabled,
}) => {
    const classes = useStyles();
    const project = useProjectState();
    const [activeRole, setActiveRole] = useState<any>(undefined);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [tabIndex, setTabIndex] = useState<number>(0);
    const [roles, setRoles] = useState<any>();
    const [viewablesByRole, setViewablesByRole] = useState<any>({});
    const [enteredMenu, setEnteredMenu] = useState<boolean>(false);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveRole(newValue);
    };

    const handleChangeIndex = (index: number) => {
        setActiveRole(index);
    };

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const handleSelectViewable = (viewable: any) => {
        setAnchorEl(null);
        onSelectViewable(viewable);
        handleClose();
    };

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setEnteredMenu(false);
    };

    const handleEnterMenu = () => {
        setEnteredMenu(true);
    };

    const handleExitMenu = () => {
        setEnteredMenu(false);
    };

    const tabAriaProps = index => {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    };

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const getViewableRole = viewable => {
        return viewable.data ? viewable.data.role : viewable.role;
    };

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const getViewableGuid = viewable => {
        return viewable.data ? viewable.data.guid : viewable.guid;
    };

    useEffect(() => {
        const tempViewablesByRole = {};

        // eslint-disable-next-line @typescript-eslint/no-shadow
        viewables?.forEach(viewable => {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            const role = getViewableRole(viewable);
            if (!tempViewablesByRole[role]) {
                tempViewablesByRole[role] = [];
            }
            tempViewablesByRole[role].push(viewable);
        });

        const roleKeys = role ? [role] : Object?.keys(tempViewablesByRole)?.sort();

        // Sort viewables
        // eslint-disable-next-line @typescript-eslint/no-shadow
        roleKeys.forEach(role => {
            tempViewablesByRole[role] = tempViewablesByRole[role]?.sort(
                (v1: Autodesk.Viewing.BubbleNode, v2: Autodesk.Viewing.BubbleNode) => {
                    return getViewableName(v1) < getViewableName(v2) ? -1 : 1;
                },
            );
        });

        setViewablesByRole(tempViewablesByRole);
        setRoles(roleKeys);
    }, [viewables, activeRole, role]);

    useEffect(() => {
        if (viewable && roles) {
            setTabIndex(
                activeRole !== null ? activeRole : roles?.indexOf(getViewableRole(viewable)),
            );
        }
    }, [viewable, roles]);

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const getDefaultViewable = role => {
        const defaultViewable = viewables[0].doc.docRoot.getDefaultGeometry();
        if (!role || getViewableRole(defaultViewable) === role) {
            return defaultViewable;
        } else if (role === ViewableRole['2D']) {
            return viewables.find(
                v =>
                    v?.data?.role === ViewableRole['2D'] && v?.data?.name?.includes('Grundriss EG'),
            );
        } else {
            return null;
        }
    };

    // reload initial viewable after split view change
    useEffect(() => {
        if ((!viewable || getViewableRole(viewable) != role) && role && viewablesByRole) {
            if (viewablesByRole[role]) {
                let defaultViewable = getDefaultViewable(role);
                // sorting here again - because it is not sorted and I dont know why wtf
                defaultViewable = defaultViewable
                    ? defaultViewable
                    : viewablesByRole[role]?.sort(
                          (v1: Autodesk.Viewing.BubbleNode, v2: Autodesk.Viewing.BubbleNode) => {
                              return getViewableName(v1) < getViewableName(v2) ? -1 : 1;
                          },
                      )[0];

                onSelectViewable(defaultViewable);
            }
        } else if (viewable && getViewableRole(viewable) == role) {
            onSelectViewable(viewable as unknown as Autodesk.Viewing.BubbleNode);
        }
    }, [role, viewablesByRole]);

    const getCurrentTabIndex = () => {
        return activeRole !== undefined ? activeRole : roles?.indexOf(getViewableRole(viewable));
    };

    // Switch next / prev view
    const getCurrentViewableIndex = () => {
        if (viewablesByRole && roles) {
            return viewablesByRole[roles[getCurrentTabIndex()]]?.indexOf(viewable);
        }
        return null;
    };
    const handlePrevViewable = () => {
        const prevIndex = getCurrentViewableIndex() - 1;
        const prev = viewablesByRole[roles[getCurrentTabIndex()]][prevIndex];
        if (prev) {
            onSelectViewable(prev);
        }
    };
    const handleNextViewable = () => {
        const nextIndex = getCurrentViewableIndex() + 1;
        const next = viewablesByRole[roles[getCurrentTabIndex()]][nextIndex];
        if (next) {
            onSelectViewable(next);
        }
    };

    function getViewablesMenu() {
        return (
            <div className={classes.root}>
                <AppBar position="static" color="default">
                    <Tabs
                        value={getCurrentTabIndex()}
                        // value={enteredMenu ? tabIndex : false}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="viewer tabs"
                        centered
                        className={classes.tabs}
                    >
                        {roles?.map((singleRole: string, index: number) => {
                            return (
                                <Tab
                                    key={`role-${singleRole}`}
                                    label={singleRole}
                                    {...tabAriaProps(index)}
                                    className={classes.tab}
                                />
                            );
                        })}
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    axis="x"
                    index={getCurrentTabIndex()}
                    onChangeIndex={handleChangeIndex}
                    className={classes.swipeableViews}
                >
                    {roles?.map((singleRole: string, index?: number) => {
                        return (
                            <TabPanel
                                key={`role-${singleRole}`}
                                value={getCurrentTabIndex()}
                                index={index}
                                dir="x"
                            >
                                <List component="nav">
                                    {viewablesByRole &&
                                        viewablesByRole?.[singleRole]?.map(view => {
                                            return (
                                                <ListItem
                                                    key={`role-${getViewableGuid(view)}`}
                                                    button
                                                    selected={view === viewable}
                                                    onClick={() => handleSelectViewable(view)}
                                                    className={classes.listItem}
                                                >
                                                    <ListItemText
                                                        primary={getViewableName(view)}
                                                        className={classes.listItemText}
                                                    />
                                                </ListItem>
                                            );
                                        })}
                                </List>
                            </TabPanel>
                        );
                    }) || (
                        <TabPanel value={getCurrentTabIndex()} index={0} dir="x">
                            <List component="nav"></List>
                        </TabPanel>
                    )}
                </SwipeableViews>
            </div>
        );
    }
    const getCustomStyle = () => {
        const style: any = { ...viewSelectorPosition };

        return style;
    };

    const version = project.file.currentVersion?.data.version;
    const fileName = project.file.currentVersion?.text;

    const [mainAnchorEl, setMainAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const mainPopoverOpen = Boolean(mainAnchorEl);
    const mainPopoverId = open ? 'popover' : undefined;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMainAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setMainAnchorEl(null);
    };

    const layerSvgIcon = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14.019"
            height="14.852"
            viewBox="0 0 14.019 14.852"
        >
            <path
                id="Icon_material-layers"
                data-name="Icon material-layers"
                d="M11.5,15.881l-5.74-4.463L4.5,12.4l7.009,5.452L18.519,12.4l-1.269-.989Zm.008-1.978,5.732-4.463,1.277-.989L11.509,3,4.5,8.452l1.269.989Z"
                transform="translate(-4.5 -3)"
                fill="#d3d3d3"
            />
        </svg>
    );

    return (
        <>
            <div className="flex items-center">
                <button
                    className="bg-gray-100 hover:bg-gray-200 text-black rounded-md px-2 h-[34px] max-w-[150px] md:max-w-[250px] overflow-hidden whitespace-nowrap overflow-ellipsis text-sm"
                    onClick={handleClick}
                >
                    <span>
                        <span className="mr-2 relative top-[3px]">{layerSvgIcon}</span>
                        {viewable ? (
                            getViewableName(viewable as unknown as Autodesk.Viewing.BubbleNode)
                        ) : (
                            <CircularProgress size={16} />
                        )}
                    </span>
                </button>
            </div>
            <Popover
                id={mainPopoverId}
                open={mainPopoverOpen}
                anchorEl={mainAnchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div className="p-5">
                    <Box className={classes.buttons}>
                        <Box
                            className={clsx(classes.arrows, disabled && classes.disabled)}
                            position={'relative'}
                        >
                            <Button
                                variant="text"
                                size="small"
                                className={clsx(classes.arrow, classes.arrowPrev)}
                                onClick={handlePrevViewable}
                                startIcon={<ArrowLeftIcon />}
                                disabled={disabled}
                            />
                            <Button
                                variant="text"
                                size="small"
                                className={clsx(classes.arrow, classes.arrowNext)}
                                onClick={handleNextViewable}
                                endIcon={<ArrowRightIcon />}
                                disabled={disabled}
                            />
                        </Box>
                        <Box
                            className={clsx(disabled && classes.disabled, 'flex-1')}
                            position={'relative'}
                        >
                            <Button
                                variant="text"
                                size="small"
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                fullWidth
                                onClick={handleOpenMenu}
                                className={classes.layerButton}
                                endIcon={<ExpandMoreIcon className={classes.layerButtonIcon} />}
                                style={getCustomStyle()}
                                disabled={disabled}
                            >
                                <span className={classes.layerButtonInner}>
                                    {viewable ? (
                                        getViewableName(
                                            viewable as unknown as Autodesk.Viewing.BubbleNode,
                                        )
                                    ) : (
                                        <CircularProgress
                                            size={16}
                                            className={classes.circularProgress}
                                        />
                                    )}
                                </span>
                            </Button>
                        </Box>
                    </Box>
                    <div className="mt-3 text-sm">
                        <div className="mb-2">
                            <strong>
                                <FormattedMessage {...messages.labelProject} />:
                            </strong>{' '}
                            <span>{project.project?.name}</span>
                        </div>
                        <div className="mb-2">
                            <strong>
                                <FormattedMessage {...messages.labelFile} />:
                            </strong>{' '}
                            <span>{fileName}</span>
                        </div>
                        <div>
                            <strong>
                                <FormattedMessage {...messages.labelVersion} />:
                            </strong>{' '}
                            <span>{version}</span>
                        </div>
                    </div>
                </div>
            </Popover>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleCloseMenu}
                TransitionProps={{
                    onEntered: handleEnterMenu,
                    onExit: handleExitMenu,
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {viewables?.length > 0 && getViewablesMenu()}
            </Popover>
        </>
    );
};

export default ViewSelector;

import { DispatchAction } from '@iolabs/redux-utils';
import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { DialogContext } from '../../../dialogs/DialogProvider/DialogProvider';
import { hideGlobalOverlay } from '../../../redux/project';
import { useSignalRState } from '../../../redux/signalR/hooks';
import { useTranslation } from '../../../redux/translations/hook';
import { DF } from '../../DialogFactory/DialogFactory';
import { INotificationDialogProps } from '../../DialogFactory/NotificationDialog/NotificationDialog';
import messages from '../../Scanning/messages';
import { IMethodName } from '../../Scanning/utils';
import { checkResponseAccess } from '../SignalRProvider';

const SignalOnDesignAutomationComplete: React.FC = () => {
    const signalRState = useSignalRState();
    const dispatch = useDispatch<DispatchAction>();

    const { openDialog } = useContext(DialogContext);

    const transMessageModelWasSuccessfullyUnlockedDueToForceRelease = useTranslation({
        ...messages.messageModelWasSuccessfullyUnlockedDueToForceRelease,
    });

    useEffect(() => {
        // Common code
        // ====================================================================
        const responseData = signalRState?.data;

        // Check if we have access to this response
        if (!checkResponseAccess(responseData)) return;
        // ====================================================================

        // Check if this is the correct signal
        if (signalRState?.methodName != IMethodName.onDesignAutomationComplete) return;

        // ====================================================================
        if (responseData?.ActivityType === 'ReleaseLock') {
            dispatch(hideGlobalOverlay());
            if (responseData?.isForceRelease) {
                openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Success', {
                    content: transMessageModelWasSuccessfullyUnlockedDueToForceRelease,
                });
            }
        }
    }, [signalRState]);

    return <div></div>;
};

export default SignalOnDesignAutomationComplete;

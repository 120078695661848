import React from 'react';

import { IPushpinsItem } from './ObjectLayer';

export enum ItemState {
  EXISTING = 'existing',
  ADDED = 'added',
}
export interface IItemsListBoxInfo<TItem> {
  itemsBoxInfo: IItemExtended<TItem>[];
  minDepth: number;
  maxDepth: number;
}

export interface IItemExtended<TItem> extends IPushpinsItem<TItem> {
  clientCoords: THREE.Vector3;
  depth: number;
  state: ItemState;
  /**
   * Z-index of the pushpin
   */
  zIndex?: number;
}

/**
 * Get client (screen) coordinates from mouse and drag event
 * @param event
 * @return Vector2
 */
export const getClientCoordsFromEvent = (
  event: React.MouseEvent<HTMLElement, MouseEvent> | React.DragEvent<HTMLElement>,
): THREE.Vector2 => {
  const clientRect = event?.currentTarget?.getBoundingClientRect();
  return {
    x: event?.clientX - clientRect?.left,
    y: event?.clientY - clientRect?.top,
  } as THREE.Vector2;
};

/**
 * Counting correct coordinates from mouse and drag event
 * @param viewer
 * @param viewerCoords
 */
export const getWorldCoordsFromViewerCoords = (
  viewer: Autodesk.Viewing.Viewer3D,
  viewerCoords: THREE.Vector2,
): THREE.Vector3 => {
  let worldCoords = viewer.clientToWorld(viewerCoords.x, viewerCoords.y);
  if (worldCoords) {
    worldCoords = worldCoords.point;
  } else {
    worldCoords = viewer.impl.intersectGround(viewerCoords.x, viewerCoords.y);
  }
  return worldCoords;
};

/**
 * Get intersected object by client coords
 * @param viewer
 * @param viewerCoords
 */
export const getIntersectedObject = (
  viewer: Autodesk.Viewing.Viewer3D,
  viewerCoords: THREE.Vector2,
): number => {
  const intersection = viewer.hitTest(viewerCoords.x, viewerCoords.y, false);
  return intersection?.dbId;
};

/**
 * Select intersected objects
 * @param viewer
 * @param viewerCoords
 */
export const selectIntersectedObject = (
  viewer: Autodesk.Viewing.Viewer3D,
  viewerCoords: THREE.Vector2,
): number => {
  const dbId = getIntersectedObject(viewer, viewerCoords);
  if (dbId) {
    viewer.select(dbId);
  }
  return dbId;
};

export const isInViewport = (point: THREE.Vector3, dim: Autodesk.Viewing.Private.Dimensions) => {
  return (
    0 <= point.x && point.x <= (dim.width ?? 0) && 0 <= point.y && point.y <= (dim.height ?? 0)
  );
};

export const getItemsPositions = <TItem>(
  viewer: Autodesk.Viewing.Viewer3D,
  items: IPushpinsItem<TItem>[],
  state: ItemState,
) => {
  const dims = viewer.getDimensions();
  const itemsBoxesList: IItemsListBoxInfo<TItem> = {
    maxDepth: 0,
    minDepth: 10000,
    itemsBoxInfo: [],
  };

  items?.forEach(item => {
    try {
      const itemsBoxInfoLocal = { ...item };

      //
      // const positionVector = new THREE.Vector3(
      //     itemsBoxInfoLocal.worldCoords.x,
      //     itemsBoxInfoLocal.worldCoords.y,
      //     itemsBoxInfoLocal.worldCoords.z
      // );

      const positionVector = item.coordinates as THREE.Vector3;

      const clientCoords = viewer.worldToClient(positionVector);
      if (isInViewport(clientCoords, dims)) {
        const distance = viewer.getCamera().position.distanceTo(positionVector);

        // max/min depths
        itemsBoxesList.maxDepth = Math.max(itemsBoxesList.maxDepth, distance);
        itemsBoxesList.minDepth = Math.min(itemsBoxesList.minDepth, distance);

        itemsBoxesList.itemsBoxInfo.push({
          ...itemsBoxInfoLocal,
          clientCoords: clientCoords,
          depth: distance,
          zIndex: Math.round((100000 - distance) * 100),
          state: state,
        });
      }
    } catch (e) {
      console.error('Error detecting sensor vector', e);
    }
  });

  return itemsBoxesList.itemsBoxInfo;
};

import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { DialogContext } from '../../dialogs/DialogProvider/DialogProvider';
import { useTranslation } from '../../redux/translations/hook';
import feedbackMessages from '../DialogFactory/Feedback/messages';
import notificationMessages from '../DialogFactory/NotificationDialog/messages';
import wipMessages from '../DialogFactory/WIP/messages';
import Feedback from './Feedback/Feedback';
import NotificationDialog from './NotificationDialog/NotificationDialog';
import WIP from './WIP/WIP';

/**
 * Dialog factory
 * - define all dialogs name here
 */
export enum DF {
    CONFIRM_DIALOG = 'CONFIRM_DIALOG',
    NOTIFICATION_DIALOG = 'NOTIFICATION_DIALOG',
    CREATE_PRESET = 'CREATE_PRESET',
    PRESET_MANAGER = 'PRESET_MANAGER',
    RELOAD_CACHE = 'RELOAD_CACHE',
    BATCH_UPDATE = 'BATCH_UPDATE',
    FEEDBACK = 'FEEDBACK',
    EXPORT_ISSUES = 'EXPORT_ISSUES',
    NEW_ATTRIBUTE = 'NEW_ATTRIBUTE',
    UPDATE_ATTRIBUTE = 'UPDATE_ATTRIBUTE',
    ATTRIBUTE_MAPPING = 'ATTRIBUTE_MAPPING',
    // eslint-disable-next-line @typescript-eslint/no-shadow
    WIP = 'WIP', // Work In Progress
}

const DialogFactory: React.FC = () => {
    const location = useLocation();
    const { createDialog } = useContext(DialogContext);

    // translations
    const transFeedbackTitle = useTranslation({ ...feedbackMessages.title });
    const transWipTitle = useTranslation({ ...wipMessages.title });
    const transNotificationTitle = useTranslation({ ...notificationMessages.title });

    useEffect(() => {
        createDialog({
            id: DF.WIP,
            title: transWipTitle,
            maxWidth: 'sm',
            fullWidth: true,
            dialogContent: <WIP />,
        });
    }, []);

    useEffect(() => {
        createDialog({
            id: DF.NOTIFICATION_DIALOG,
            title: transNotificationTitle,
            maxWidth: 'sm',
            fullWidth: true,
            dialogContent: data => {
                return <NotificationDialog {...data} />;
            },
        });
    }, []);

    useEffect(() => {
        createDialog({
            id: DF.FEEDBACK,
            title: transFeedbackTitle,
            maxWidth: 'sm',
            fullWidth: true,
            dialogContent: <Feedback location={location} />,
        });
    }, [location]);

    return <></>;
};

export default DialogFactory;

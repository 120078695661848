import { useSelector } from 'react-redux';

import {
    selectAssetsSettings,
    selectElementsStatus,
    selectGlobalOverlay,
    selectLockedModelInfo,
    selectMarkups,
    selectMenu,
    selectSidebar,
    selectProjectState,
    selectSelectedAsset,
    selectSelectedBluetoothDevice,
    selectUser,
    selectViewableElements,
} from './projectSlice';
import {
    IAssetSettings,
    IBluetoothDevice,
    IFileElementsStatus,
    IGlobalOverlay,
    IMarkupsState,
    IMenu,
    IProjectState,
    ISelectedAsset,
    IViewableElement,
} from './types';

export const useProjectState = (): IProjectState => useSelector(selectProjectState);
export const useViewableElements = (): IViewableElement[] => useSelector(selectViewableElements);
export const useSelectedAsset = (): ISelectedAsset => useSelector(selectSelectedAsset);
export const useGlobalOverlay = (): IGlobalOverlay => useSelector(selectGlobalOverlay);
export const useAssetsSettings = (): IAssetSettings => useSelector(selectAssetsSettings);
export const useElementsStatus = (): IFileElementsStatus => useSelector(selectElementsStatus);
export const useLockedModelInfo = () => useSelector(selectLockedModelInfo);
export const useModelAssetStatuses = () =>
    useSelector(selectProjectState)?.modelAssetStatuses || [];
export const useESelectedBluetoothDevice = (): IBluetoothDevice =>
    useSelector(selectSelectedBluetoothDevice);
export const useMarkups = (): IMarkupsState => useSelector(selectMarkups);
export const useMenu = (): IMenu => useSelector(selectMenu);
export const useSidebar = (): boolean => useSelector(selectSidebar);
export const useUser = () => useSelector(selectUser);

import { AxiosResponse } from 'axios';

import config from '../../../../config/config';
import { AssetsModuleApi, Configuration } from '../../../../generate/api';
import { getAuthorizationToken } from '../../../../redux/keyclock/middleware';
import { store } from '../../../../redux/store';
import ApiClient from '../../ApiClient';

const assetsOldApi = new ApiClient('/Asset', config.api.baseUrl);

// todo - use new api
//  problem with new api - it is not working correctly because of using headers
export const filtersData = (token: string, forgeFileVersionUrn): Promise<any> => {
    ApiClient.setAuthorizationToken(token);

    return assetsOldApi
        .get(`/Filters`, {
            headers: {
                forgeFileVersionUrn: forgeFileVersionUrn,
            },
        })
        .then((response: AxiosResponse<any>) => {
            // todo ts
            return Promise.resolve(response.data);
        })
        .catch(error => {
            return Promise.reject(error);
        });
};

export const assetsApi = new AssetsModuleApi(
    new Configuration({
        basePath: config.api.baseUrl,
        accessToken: () => {
            return store.getState().keycloak.token as string;
        },
    }),
);

import { defineMessages } from 'react-intl';

export default defineMessages({
    // Titles
    titleActiveLocks: {
        id: 'components.lockedModelInfo.title.activeLocks',
        defaultMessage: 'Active locks',
    },

    // Buttons
    buttonForceRelease: {
        id: 'components.lockedModelInfo.button.forceRelease',
        defaultMessage: 'Force release',
    },
    buttonCancel: {
        id: 'components.lockedModelInfo.button.cancel',
        defaultMessage: 'Cancel',
    },
    buttonOk: {
        id: 'components.lockedModelInfo.button.ok',
        defaultMessage: 'Ok',
    },

    // Labels
    labelUser: {
        id: 'components.lockedModelInfo.label.user',
        defaultMessage: 'User',
    },
    labelTimeLocked: {
        id: 'components.lockedModelInfo.label.timeLocked',
        defaultMessage: 'Time locked',
    },

    // Messages
    messageForceReleaseError: {
        id: 'components.lockedModelInfo.message.forceReleaseError',
        defaultMessage: 'Force release error',
    },
    messageForceReleaseConfirmation: {
        id: 'components.lockedModelInfo.message.forceReleaseConfirmation',
        defaultMessage: 'Do you really want force release actual model?',
    },
    messageNoUsers: {
        id: 'components.lockedModelInfo.message.noUsers',
        defaultMessage: 'No users',
    },
    messageForcefullyUnlockingElements: {
        id: 'components.editSession.message.forcefullyUnlockingElements',
        defaultMessage: 'Forcefully unlocking elements...',
    },
});

import { defineMessages } from 'react-intl';

export default defineMessages({
    searchPlaceholder: {
        id: 'components.filesExplorer.message.searchPlaceholder',
        defaultMessage: 'Search...',
    },
    noFilesFound: {
        id: 'components.filesExplorer.message.noFilesFound',
        defaultMessage: 'No files found',
    },
    noFilesAvailable: {
        id: 'components.filesExplorer.message.noFilesAvailable',
        defaultMessage: 'No files available',
    },
    project: {
        id: 'components.filesExplorer.message.project',
        defaultMessage: 'Project',
    },
    lastOpened: {
        id: 'components.filesExplorer.message.lastOpened',
        defaultMessage: 'Last opened',
    },
    testTranslationWithParameters: {
        id: 'components.filesExplorer.message.testTranslationWithParameters',
        defaultMessage:
            'First parameter: {firstParameter}, Second parameter: {secondParameter} and Third parameter: {thirdParameter}',
    },
});

import * as signalR from '@microsoft/signalr';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import config from '../../config/config';
import { addNotification } from '../../redux/notifier';
import { useProjectState } from '../../redux/project';
import { onSignalRCatch } from '../../redux/signalR/signalrSlice';
import { store } from '../../redux/store';
import { IMethodName } from '../Scanning/utils';
import SignalOnAssetSyncComplete from './signals/onAssetSyncComplete';
import SignalOnDesignAutomationComplete from './signals/onDesignAutomationComplete';
import SignalOnForceReleaseRequested from './signals/onForceReleaseRequested';

export interface ISignalRProviderProps {
    children: React.ReactNode;
}

export const checkResponseAccess = (responseData: any) => {
    const project = store.getState().project;
    return (
        responseData?.ProjectID === project?.project?.id &&
        responseData?.VersionUrn === project?.file?.currentVersion?.data?.urn &&
        responseData?.ViewableID === project.file.currentViewable?.viewableID
    );
};

const SignalRProvider: React.FC<ISignalRProviderProps> = ({ children }) => {
    const dispatch = useDispatch();
    const project = useProjectState();
    const [connection, setConnection] = useState<signalR.HubConnection | null>(null);

    const createConnection = () => {
        const baseUrl = config.api.baseUrl;
        const newConnection = new signalR.HubConnectionBuilder()
            .withUrl(baseUrl + '/SignalR/Notification')
            .withAutomaticReconnect()
            .build();
        setConnection(newConnection);
    };

    useEffect(() => {
        createConnection();
        return () => {
            connection?.stop();
            dispatch(
                onSignalRCatch({
                    methodName: undefined,
                    data: {},
                }),
            );
        };
    }, []);

    const handleSignalRMethod = (methodName: IMethodName) => (data: string) => {
        const responseData = JSON.parse(data);
        log(methodName, responseData);
        dispatch(
            onSignalRCatch({
                methodName,
                data: responseData,
            }),
        );

        // Dispatch empty signal after 50ms
        setTimeout(() => {
            dispatch(
                onSignalRCatch({
                    methodName: undefined,
                    data: {},
                }),
            );
        }, 200);
    };

    // Log only if data is targeted to current file
    const log = (method: string, data) => {
        let allowLog = false;
        if (checkResponseAccess(data)) allowLog = true;
        if (!data?.VersionUrn) allowLog = true;
        if (allowLog) {
            const logStyle = 'background: #0031E3; color: #ffffff';
            console.log('%c## SignalR: ' + method, logStyle, data);
        }
    };

    useEffect(() => {
        if (connection) {
            connection
                .start()
                .then(() => {
                    console.log('SignalR Connected!');
                    Object.values(IMethodName).forEach(methodName => {
                        connection.on(methodName, handleSignalRMethod(methodName));
                    });
                })
                .catch(e => {
                    console.debug('## SignalR Connection failed: ', e);
                    dispatch(
                        addNotification({
                            variant: 'error',
                            message: 'Connection to server failed',
                        }),
                    );
                });
        }
    }, [connection]);

    // testing
    // ====================================================================
    const h01 = () => {
        const responseData = {
            ProjectID: project?.project?.id,
            VersionUrn: project?.file?.currentVersion?.data?.urn,
            ViewableID: project.file.currentViewable?.viewableID,
            // --------------------------------------------
            isForceRelease: true,
            NewVersionUrn: 'urn:adsk.wipprod:fs.file:vf.Qz75rPMHSsKu_dpTkNLZUA?version=10',
        };
        log(IMethodName.onAssetSyncComplete, responseData);

        dispatch(
            onSignalRCatch({
                methodName: IMethodName.onAssetSyncComplete,
                data: responseData,
            }),
        );
    };

    return (
        <>
            <SignalOnAssetSyncComplete />
            <SignalOnDesignAutomationComplete />
            <SignalOnForceReleaseRequested />
            {/*<div className="w-full text-[10px] bg-gray-100 fixed bottom-0 left-0 right-0 z-10 border-t border-black">*/}
            {/*    <span className="cursor-pointer p-1 hover:underline" onClick={h01}>*/}
            {/*        onAssetSyncComplete*/}
            {/*    </span>*/}
            {/*</div>*/}
            {children}
        </>
    );
};

export default SignalRProvider;

import { AxiosError } from 'axios';

import { createError } from './errorFactory';
import ForbiddenHttpError from './errors/ForbiddenHttpError';
import NetworkError from './errors/NetworkError';
import UnauthorizedHttpError from './errors/UnauthorizedHttpError';

export const handleHttpErrors = (error: AxiosError) => {
    if (error.response) {
        if (error.response.status === 401) {
            throw new UnauthorizedHttpError();
        } else if (error.response.status === 403) {
            throw new ForbiddenHttpError();
        } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (error.response.data.errorId) {
                // eslint-disable-next-line @typescript-eslint/no-throw-literal,@typescript-eslint/ban-ts-comment
                // @ts-ignore
                // eslint-disable-next-line @typescript-eslint/no-throw-literal
                throw createError(error.response.data.errorId, error.response.data);
            } else {
                throw error;
            }
        }
    } else if (error.request) {
        throw new NetworkError();
    } else {
        throw error;
    }
};

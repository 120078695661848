import { DispatchAction } from '@iolabs/redux-utils';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setGlobalOverlay } from '../../../redux/project';
import { useSignalRState } from '../../../redux/signalR/hooks';
import { useTranslation } from '../../../redux/translations/hook';
import messages from '../../Scanning/messages';
import { IMethodName } from '../../Scanning/utils';
import { checkResponseAccess } from '../SignalRProvider';

const SignalOnForceReleaseRequested: React.FC = () => {
    const signalRState = useSignalRState();
    const dispatch = useDispatch<DispatchAction>();

    const transMessageForcefullyUnlockingElements = useTranslation({
        ...messages.messageForcefullyUnlockingElements,
    });

    useEffect(() => {
        // Common code
        // ====================================================================
        const responseData = signalRState?.data;

        // Check if we have access to this response
        if (!checkResponseAccess(responseData)) return;
        // ====================================================================

        // Check if this is the correct signal
        if (signalRState?.methodName != IMethodName.onForceReleaseRequested) return;

        // ====================================================================
        dispatch(
            setGlobalOverlay({
                show: true,
                text: transMessageForcefullyUnlockingElements,
            }),
        );
    }, [signalRState]);

    return <div></div>;
};

export default SignalOnForceReleaseRequested;

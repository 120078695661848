import { createListenerMiddleware } from '@reduxjs/toolkit';

import { assetsApi, filtersData } from '../../packages/Api/data/assets/client';
import assetReducer, {
    onAssetsList,
    onAssetsListDone,
    onAssetsListFail,
    onFilters,
    onFiltersDone as onAssetsFiltersDone,
    onFiltersFail as onAssetsFiltersFail,
} from './assetsSlice';

export const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
    actionCreator: onFilters,
    effect: async (action, listenerApi) => {
        const { token, forgeFileVersionUrn } = action.payload;

        filtersData(token, forgeFileVersionUrn)
            .then(response => {
                listenerApi.dispatch(
                    onAssetsFiltersDone({
                        filters: response,
                    }),
                );
            })
            .catch(error => {
                listenerApi.dispatch(
                    onAssetsFiltersFail({
                        error,
                    }),
                );
            });
    },
});

listenerMiddleware.startListening({
    actionCreator: onAssetsList,
    effect: async (action, listenerApi) => {
        const data = action.payload;
        assetsApi
            .assetListPost(data)
            .then(response => {
                listenerApi.dispatch(
                    onAssetsListDone({
                        attributeList: response.data,
                    }),
                );
            })
            .catch(error => {
                listenerApi.dispatch(
                    onAssetsListFail({
                        error,
                    }),
                );
            });
    },
});

import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect, useState } from 'react';

import { IssueMarkup } from '../../../generate/api';
import { markupThumbnail } from '../../../packages/Api/data/markups/client';

interface IMarkupThumbnailProps {
    markup: IssueMarkup;
}

const MarkupThumbnail: React.FC<IMarkupThumbnailProps> = ({ markup }) => {
    const { keycloak } = useKeycloak();
    const [thumbnail, setThumbnail] = useState<string>();
    useEffect(() => {
        if (keycloak?.token && markup.objectMarkupID) {
            markupThumbnail(keycloak.token, markup.objectMarkupID, { thumbnailWidth: 300 }).then(
                response => {
                    setThumbnail(URL.createObjectURL(response));
                },
            );
        }
    }, [markup]);

    return (
        <img
            src={thumbnail}
            alt={markup.name as string}
            className="object-cover aspect-video border rounded border-gray-300 block"
        />
    );
};

export default MarkupThumbnail;

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LanguageIcon from '@mui/icons-material/Language';
import MenuIcon from '@mui/icons-material/Menu';
import {
    Avatar,
    Box,
    Button,
    ClickAwayListener,
    Divider,
    ListItemIcon,
    MenuItem,
    MenuList,
    Paper,
    Popover,
    Theme,
    Toolbar,
    Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useKeycloak } from '@react-keycloak/web';
import clsx from 'clsx';
import { KeycloakProfile } from 'keycloak-js';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Path } from '../../pages/PageRouter';
import { useTranslation } from '../../redux/translations/hook';
import { template } from '../../theme/Theme';
import useHeaderContent from '../HeaderProvider/useHeaderContent';
import Icon from '../Icon/Icon';
import { defaultHeaderHeight } from '../Layout/Layout';
import Logout from '../Logout/Logout';
import LanguageSwitcher from './LanguageSwitcher';
import messages from './messages';

type StylesProps = {
    headerHeight?: string;
    drawerDisabled?: boolean;
};

export const useHeaderStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: (props: StylesProps | undefined) => ({
            minHeight: props?.headerHeight ? props?.headerHeight : defaultHeaderHeight,
            [theme.breakpoints.up('sm')]: {
                flexWrap: 'nowrap',
                minHeight: props?.headerHeight ? props?.headerHeight : defaultHeaderHeight,
                height: props?.headerHeight ? props?.headerHeight : defaultHeaderHeight,
            },
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            [theme.breakpoints.up('md')]: {
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
            },
            zIndex: 200,
            boxShadow: '0 0 5px #00000038',
        }),
        close: (props: StylesProps | undefined) => ({
            paddingLeft: props?.drawerDisabled ? '!inherit' : `${theme.spacing(7)} !important`,
        }),
        burgerButton: {
            color: theme.palette.common.white,
            marginLeft: '-12px',
        },
        burgerIcon: {
            width: '20px',
            height: '20px',
        },
        backButton: {
            color: theme.palette.common.white,
            padding: '8px',
            marginRight: theme.spacing(1),
            [theme.breakpoints.up('sm')]: {
                height: '40px',
            },
        },
        iconButton: {
            color: theme.palette.grey['100'],
            padding: '8px',
            marginRight: theme.spacing(1),
            [theme.breakpoints.up('sm')]: {
                height: '40px',
            },
        },
        menu: {
            flex: '1 1 0%',
            textAlign: 'right',
            display: 'flex',
            flexFlow: 'row nowrap',
            justifyContent: 'flex-end',
            alignItems: 'center',
            order: 1,
            [theme.breakpoints.up('sm')]: {
                order: 2,
                flexBasis: '30%',
            },
            [theme.breakpoints.up('lg')]: {
                flexBasis: '50%',
            },
        },
        menuButton: {
            minWidth: 'auto',
            paddingLeft: 0,
            paddingRight: 0,
            color: theme.palette.grey['600'],
            '&:hover': {
                backgroundColor: 'inherit',
            },
        },
        logoBox: {
            display: 'flex',
            alignItems: 'center',
            minHeight: defaultHeaderHeight,
            marginRight: '20px',
            '& img': {
                height: '100%',
                width: '100%',
                maxWidth: '100px',
                maxHeight: defaultHeaderHeight,
                cursor: 'pointer',
            },
            [theme.breakpoints.up('sm')]: {
                marginRight: '0',
                '& img': {
                    maxWidth: '140px',
                },
            },
        },
        headingBox: {
            display: 'flex',
            flexGrow: 1,
            flexBasis: '80%',
            alignSelf: 'flex-start',
            alignItems: 'center',
            textAlign: 'left',
            color: theme.palette.grey['200'],
            order: 1,
            minHeight: defaultHeaderHeight,
            [theme.breakpoints.up('sm')]: {
                flexBasis: '70%',
                minHeight: defaultHeaderHeight,
                marginLeft: theme.spacing(4),
                order: 1,
            },
            [theme.breakpoints.up('lg')]: {
                flexBasis: '30%',
            },
        },
        heading: {
            color: template.headerBarColor,
            fontSize: theme.typography.pxToRem(12),
            fontWeight: 400,
            // maxWidth: 200,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            [theme.breakpoints.up('sm')]: {
                fontSize: theme.typography.pxToRem(17),
            },
        },
        popper: {
            zIndex: 10,
        },
        avatar: {
            color: template.headerCircleTextColor,
            backgroundColor: template.headerCircleBgColor,
            textTransform: 'uppercase',
            width: '38px',
            height: '38px',
            fontSize: theme.typography.pxToRem(14),
            [theme.breakpoints.up('sm')]: {
                width: '50px',
                height: '50px',
                fontSize: theme.typography.pxToRem(16),
            },
        },
        menuList: {
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.common.white,
            minWidth: theme.spacing(24),
            [theme.breakpoints.up('sm')]: {
                '& li': {
                    justifyContent: 'flex-start',
                },
            },
        },
        userBox: {
            display: 'none',
            [theme.breakpoints.up('lg')]: {
                display: 'block',
                marginRight: theme.spacing(2),
                '& h4': {
                    color: template.headerBarColor,
                    fontSize: theme.typography.pxToRem(16),
                },
            },
        },
        menuItemIcon: {
            color: theme.palette.text.secondary,
            minWidth: '35px',
            verticalAlign: 'middle',
        },
        headerUserName: {
            color: theme.palette.grey['600'],
            fontSize: theme.typography.pxToRem(15),
            padding: '8px 18px',
            fontWeight: 500,
        },
        languageBox: {
            padding: '8px',
            alignItems: 'center',
            display: 'flex',
        },
        menuItemLanguage: {
            display: 'flex',
            padding: '5px',
        },
        languageBoxTitle: {
            alignSelf: 'flex-start',
            flex: 1,
            '& svg': {
                marginRight: theme.spacing(0.4),
            },
        },
        menuItemRow: {
            width: '100%',
            color: '#333',
            background: theme.palette.common.white,
            alignItems: 'center',
            justifyContent: 'start',
            '& svg path': {
                fill: theme.palette.primary.main,
            },
        },
        languageBoxWrap: {
            alignSelf: 'flex-end',
            verticalAlign: 'middle',
            marginBottom: theme.spacing(0.2),
        },
        divider: {
            backgroundColor: theme.palette.text.secondary,
            opacity: 0.2,
            height: '1px',
            border: 0,
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5),
        },
        hide: {
            display: 'none',
        },
    }),
);

interface IHeaderProps {
    drawerOpen: boolean;
    handleDrawerOpen: () => void;
    logo?: string;
    rightMenu?: React.ReactNode;
    drawerDisabled?: boolean;
    stylesProps?: StylesProps;
}

const Header: React.FC<IHeaderProps> = (props: IHeaderProps) => {
    const { drawerOpen, logo, drawerDisabled, stylesProps } = props;

    if (stylesProps) {
        stylesProps.drawerDisabled = !!drawerDisabled;
    }

    const classes = useHeaderStyles(stylesProps);
    const { keycloak } = useKeycloak();
    const history = useHistory();
    const { headerContent } = useHeaderContent();

    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [userData, setUserData] = useState<KeycloakProfile | null>(null);
    const anchorRef = useRef<HTMLButtonElement | null>(null);

    const handleMenuToggle = () => {
        setMenuOpen(prevMenuOpen => !prevMenuOpen);
    };

    const handleMenuClose = (event: MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setMenuOpen(false);
    };

    const handleRootClick = () => {
        history.push(Path.ROOT);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setMenuOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevMenuOpen = React.useRef(menuOpen);

    useEffect(() => {
        Promise.resolve(keycloak?.loadUserProfile()).then(data => {
            setUserData(data as KeycloakProfile);
        });

        if (prevMenuOpen.current && !menuOpen) {
            anchorRef.current!.focus();
        }

        prevMenuOpen.current = menuOpen;
    }, [keycloak, menuOpen]);

    // translations
    const transSignOut = useTranslation({ ...messages.signOut });

    if (window.location.pathname != Path.PROJECT_SETUP) {
        return <></>;
    }

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.close]: !drawerOpen,
            })}
        >
            {logo && (
                <Box className={classes.logoBox} onClick={handleRootClick}>
                    <img alt="" src={logo} />
                </Box>
            )}
            <Box className={classes.headingBox}>{headerContent}</Box>
            <Box className={classes.menu}>
                {userData && (
                    <>
                        <Button
                            ref={anchorRef}
                            aria-controls={menuOpen ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            disableRipple
                            disableFocusRipple
                            disableTouchRipple
                            variant="text"
                            onClick={handleMenuToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </Button>

                        <Popover
                            open={menuOpen}
                            anchorEl={anchorRef.current}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            className={classes.popper}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleMenuClose}>
                                    <MenuList
                                        autoFocusItem={menuOpen}
                                        id="menu-list-grow"
                                        onKeyDown={handleListKeyDown}
                                        className={classes.menuList}
                                    >
                                        <Box className={classes.headerUserName}>
                                            {`${userData!.firstName} ${userData!.lastName}`}
                                        </Box>

                                        <Divider className={classes.divider} />

                                        <div
                                            className={clsx(
                                                classes.menuItemLanguage,
                                                classes.menuItemRow,
                                            )}
                                        >
                                            <Icon name={'menu-language'} size={40} />
                                            <LanguageSwitcher />
                                        </div>

                                        <Divider className={classes.divider} />

                                        <Logout>
                                            <MenuItem>
                                                <ListItemIcon className={classes.menuItemIcon}>
                                                    <ExitToAppIcon fontSize="small" />
                                                </ListItemIcon>
                                                <Typography variant="inherit">
                                                    {transSignOut}
                                                </Typography>
                                            </MenuItem>
                                        </Logout>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Popover>
                    </>
                )}
            </Box>
        </Toolbar>
    );
};

export default Header;

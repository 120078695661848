/* tslint:disable */
/* eslint-disable */
/**
 * FireBIM API
 * API definitions for FireBim
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CheckProjectResponse } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { ProjectAddRequest } from '../models';
// @ts-ignore
import { ProjectListResponse } from '../models';
// @ts-ignore
import { UserInfo } from '../models';
/**
 * ProjectsModuleApi - axios parameter creator
 * @export
 */
export const ProjectsModuleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Checks project synchronization status.
         * @param {ProjectAddRequest} [request] The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectForceCheckGet: async (request?: ProjectAddRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Project/Force/Check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (request != null) {
                localVarHeaderParameter['request'] = typeof request === 'string' 
                    ? request 
                    : JSON.stringify(request);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets project list.
         * @param {string} hubId Forge hub ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectListGet: async (hubId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hubId' is not null or undefined
            assertParamExists('projectListGet', 'hubId', hubId)
            const localVarPath = `/Project/List`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (hubId !== undefined) {
                localVarQueryParameter['HubId'] = hubId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets current user info.
         * @param {string} forgeProjectId Project ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUserGet: async (forgeProjectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forgeProjectId' is not null or undefined
            assertParamExists('projectUserGet', 'forgeProjectId', forgeProjectId)
            const localVarPath = `/Project/User`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (forgeProjectId !== undefined) {
                localVarQueryParameter['ForgeProjectId'] = forgeProjectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectsModuleApi - functional programming interface
 * @export
 */
export const ProjectsModuleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectsModuleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Checks project synchronization status.
         * @param {ProjectAddRequest} [request] The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectForceCheckGet(request?: ProjectAddRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectForceCheckGet(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets project list.
         * @param {string} hubId Forge hub ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectListGet(hubId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectListGet(hubId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets current user info.
         * @param {string} forgeProjectId Project ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUserGet(forgeProjectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUserGet(forgeProjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectsModuleApi - factory interface
 * @export
 */
export const ProjectsModuleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectsModuleApiFp(configuration)
    return {
        /**
         * 
         * @summary Checks project synchronization status.
         * @param {ProjectAddRequest} [request] The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectForceCheckGet(request?: ProjectAddRequest, options?: any): AxiosPromise<CheckProjectResponse> {
            return localVarFp.projectForceCheckGet(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets project list.
         * @param {string} hubId Forge hub ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectListGet(hubId: string, options?: any): AxiosPromise<ProjectListResponse> {
            return localVarFp.projectListGet(hubId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets current user info.
         * @param {string} forgeProjectId Project ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUserGet(forgeProjectId: string, options?: any): AxiosPromise<UserInfo> {
            return localVarFp.projectUserGet(forgeProjectId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectsModuleApi - object-oriented interface
 * @export
 * @class ProjectsModuleApi
 * @extends {BaseAPI}
 */
export class ProjectsModuleApi extends BaseAPI {
    /**
     * 
     * @summary Checks project synchronization status.
     * @param {ProjectAddRequest} [request] The request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsModuleApi
     */
    public projectForceCheckGet(request?: ProjectAddRequest, options?: AxiosRequestConfig) {
        return ProjectsModuleApiFp(this.configuration).projectForceCheckGet(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets project list.
     * @param {string} hubId Forge hub ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsModuleApi
     */
    public projectListGet(hubId: string, options?: AxiosRequestConfig) {
        return ProjectsModuleApiFp(this.configuration).projectListGet(hubId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets current user info.
     * @param {string} forgeProjectId Project ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsModuleApi
     */
    public projectUserGet(forgeProjectId: string, options?: AxiosRequestConfig) {
        return ProjectsModuleApiFp(this.configuration).projectUserGet(forgeProjectId, options).then((request) => request(this.axios, this.basePath));
    }
}

import React, { ReactElement } from 'react';

import { IPushpinsItem } from './ObjectLayer';

export interface IObjectWrapperProps<TItem> {
  /**
   * Item for which the wrapper is rendered
   */
  item?: TItem;

  /**z
   * Item Content rendeing function
   * @param item
   * @param anchorElement
   * @param handleClose
   */
  renderContent?: (
    item: TItem,
    anchorElement: HTMLElement | null,
    handleClose?: () => void,
  ) => ReactElement;

  /**
   * Event called when new pusphin is clicked
   * @param pushpin
   */
  onPushpinClicked?: (item?: TItem) => void;
}

const ObjectWrapper = <TItem,>({
  item,
  renderContent,
  onPushpinClicked,
}: IObjectWrapperProps<TItem>) => {
  const [buttonEl, setButtonEl] = React.useState<HTMLElement | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'pushpin-popover' : undefined;

  const handleClickButton = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(buttonEl);
    }
    if (onPushpinClicked) {
      onPushpinClicked(item);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div
        className={'object-layer-object-pushpin'}
        aria-describedby={id}
        ref={c => setButtonEl(c)}
        onClick={handleClickButton}
      />
      {renderContent && item && renderContent(item, anchorEl, handleClose)}
    </div>
  );
};

export default ObjectWrapper;

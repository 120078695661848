import { DispatchAction } from '@iolabs/redux-utils';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import ConfirmModal from '../../components/Modals/ConfirmModal';
import InfoModal from '../../components/Modals/InfoModal';
import { DialogContext } from '../../dialogs/DialogProvider/DialogProvider';
import {
    CreateModelLockRequest,
    PerformModelOperationsRequest,
    ReleaseModelLockRequest,
    ScheduleModelOperationRequest,
    ScheduleModelOperationRequestData,
    Sensor,
    ValidateSerialNumberRequest,
    ValidateSerialNumberResponse,
} from '../../generate/api';
import { filesApi } from '../../packages/Api/data/files/client';
import { fsmApi } from '../../packages/Api/data/fsm/client';
import {
    clearAllScheduleOperations,
    IElementScheduleOperations,
    ModeTypes,
    removeScheduleForElement,
    setElementScheduleOperations,
    setLockState,
    setMode as useSetMode,
    setOnForceRewriteAttributesByCopy,
    setRunningLockingState,
    setSelectedElementAttributesToCache,
    setSelectElementAttributes,
} from '../../redux/editing/editingSlice';
import { useEditingState, useMode } from '../../redux/editing/hooks';
import { IExportRunningState, onExportState } from '../../redux/export/exportSlice';
import { useExportState } from '../../redux/export/hooks';
import { useProfile } from '../../redux/keyclock';
import { addNotification } from '../../redux/notifier';
import {
    hideGlobalOverlay,
    setAssetsSettings,
    setElementsStatus,
    setGlobalOverlay,
    setMenu,
    setSelectedAsset,
    useElementsStatus,
    useLockedModelInfo,
    useProjectState,
    useUser,
} from '../../redux/project';
import {
    IFileElementRotate,
    IFileElementsStatus,
    IFileElementStatus,
    roleElectrician,
} from '../../redux/project/types';
import { useSignalRState } from '../../redux/signalR/hooks';
import { useTranslation } from '../../redux/translations/hook';
import { DF } from '../DialogFactory/DialogFactory';
import { INotificationDialogProps } from '../DialogFactory/NotificationDialog/NotificationDialog';
import FsmExport from '../FsmExport/FsmExport';
import Sidebar from '../Sidebar/Sidebar';
import FormattedMessage from '../Translation/FormattedMessage';
import ToolbarExtensionName, {
    type ToolbarExtensionType,
    CopyEventDetails,
    CreateEventDetails,
    getRealWorldCoordinates,
    MoveEventDetails,
    RotateEventDetails,
} from '../Viewer/extensions/ToolbarTransform';
import { getObjectBound2D } from '../Viewer/SensorsWrapper';
import { ISelection } from '../Viewer/Viewer';
import { AttributesTable } from './AttributesTable';
import LockingStateInfo from './LockingStateInfo';
import messages from './messages';
import OperationsDetail from './OperationsList';
import MlKitBarcodeScanner, { isValidPlatform, MlKitErrors } from './Scanner/MlKitBarcodeScanner';
import useStyles from './styles';
import {
    IAttributesMap,
    IAttributesNames,
    IRunningLockingState,
    IScanningProps,
    ISerialNumberValidationProps,
} from './types';
import {
    updateElementsStatus,
    updateLockInfo,
    updateLockInfoAndElementsStatus,
} from './updateElementsStatus';
import {
    elementAttributesData,
    extractCodeFromDataMatrix,
    getSensorSvg,
    getTemporaryUUID,
    IMethodName,
    singleElementDiscardCall,
} from './utils';

const Scanning: React.FC<IScanningProps> = ({
    viewer,
    modelLoaded,
    viewable,
    viewableElements,
    singleSelection,
    externalIdMap,
    adjustGeometryEnabled,
}) => {
    const dispatch = useDispatch<DispatchAction>();
    const classes = useStyles();
    const { keycloak } = useKeycloak();
    const user = useUser();
    const signalRState = useSignalRState();

    const [editedAttributes, setEditedAttributes] = useState<IAttributesMap>();
    const [elementToCreate, setElementToCreate] = useState<any>();

    const mode = useMode();
    const setMode = newMode => dispatch(useSetMode(newMode));
    const modeRef = useRef(mode);
    useEffect(() => {
        modeRef.current = mode;
    }, [mode]);

    const project = useProjectState();
    const lockInfo = useLockedModelInfo();
    const [unlockedOperationsNeedsConfirmation, setUnlockedOperationsNeedsConfirmation] =
        useState<boolean>(false);
    let unlockedOperationsConfirmed = false;

    const [daFailureElements, setDaFailureElements] = useState<string[]>([]);

    const serialNumberValidationStatusInitialState: ISerialNumberValidationProps = {
        isUnique: false,
        isValid: false,
        isValidating: false,
        serialNumber: undefined,
        duplicateElementName: undefined,
        overwriteConfirmed: false,
        validationMessage: undefined,
        scheduleModelOperationRequest: undefined,
    };

    const [serialNumberValidationStatus, setSerialNumberValidationStatus] =
        useState<ISerialNumberValidationProps>(serialNumberValidationStatusInitialState);

    const [manualModeOnly, setManualModeOnly] = useState<boolean>(false);

    const forgeProjectId = project?.project?.id;
    const fileVersionUrn = project?.file?.currentVersion?.data?.urn;

    const editingState = useEditingState();
    const editingStateRef = useRef(editingState);
    useEffect(() => {
        editingStateRef.current = editingState;
    }, [editingState]);

    const lockState = editingState.lockState;
    const [enableSubmit, setEnableSubmit] = useState<boolean>(false);

    const [runningLocking, setRunningLocking] = useState<IRunningLockingState>(
        IRunningLockingState.none,
    );
    useEffect(() => {
        dispatch(setRunningLockingState(runningLocking));
        return () => {
            dispatch(setRunningLockingState(IRunningLockingState.none));
        };
    }, [runningLocking]);

    const elementsStatus = useElementsStatus();
    const elementsStatusRef = useRef<IFileElementsStatus>();
    const [supportedSensorsList, setSupportedSensorsList] = useState<Sensor[]>();

    const [singleDiscardAllowed, setSingleDiscardAllowed] = useState<boolean>(false);
    const [singleDiscardConfirmationShow, setSingleDiscardConfirmationShow] =
        useState<boolean>(false);

    const keycloakProfile = useProfile();

    const logStyle = 'background: #00CCCC; color: #000000';

    const { openDialog } = useContext(DialogContext);

    const [confirmDiscardOperationsVisible, setConfirmDiscardOperationsVisible] =
        useState<boolean>(false);
    const [confirmPerformOperationsVisible, setConfirmPerformOperationsVisible] =
        useState<boolean>(false);

    // ---------------------------------------------------------------------------------------------------------
    // Translations
    // ---------------------------------------------------------------------------------------------------------
    const transMessageLockingElements = useTranslation({ ...messages.messageLockingElements });
    const transMessageModelBeingProcessed = useTranslation({
        ...messages.messageModelBeingProcessed,
    });
    const transMessageVersionOfModelIsNotLatest = useTranslation({
        ...messages.messageVersionOfModelIsNotLatest,
    });
    const transMessageErrorDuringLockingModel = useTranslation({
        ...messages.messageErrorDuringLockingModel,
    });
    const transMessageModelWasSuccessfullyLocked = useTranslation({
        ...messages.messageModelWasSuccessfullyLocked,
    });

    // const transMessageUserSubmittedModel = useTranslation({
    //     ...messages.messageUserSubmittedModel,
    // });
    const transMessageYourChangesHaveBeenSuccessfullySubmitted = useTranslation({
        ...messages.messageYourChangesHaveBeenSuccessfullySubmitted,
    });
    const transMessageModelWasSuccessfullyUnlocked = useTranslation({
        ...messages.messageModelWasSuccessfullyUnlocked,
    });
    const transMessageUnlockingModelElements = useTranslation({
        ...messages.messageUnlockingModelElements,
    });
    const transMessageProcessingModelChanges = useTranslation({
        ...messages.messageProcessingModelChanges,
    });
    const transMessageSynchronizingAssets = useTranslation({
        ...messages.messageSynchronizingAssets,
    });
    const transMessageErrorOccurredDuringSavingElementChanges = useTranslation({
        ...messages.messageErrorOccurredDuringSavingElementChanges,
    });
    const transMessageNoOperationsToPerform = useTranslation({
        ...messages.messageNoOperationsToPerform,
    });
    const transMessagePerformOperationsError = useTranslation({
        ...messages.messagePerformOperationsError,
    });
    const messageModelTransformationInProgressError = useTranslation({
        ...messages.messageModelTransformationInProgressError,
    });
    // const transMessageErrorDuringLockingView = userName => // TODO fix to this
    //     useTranslation({
    //         ...messages.messageErrorDuringLockingView,
    //         vars: {
    //             userName: userName,
    //         },
    //     });
    const transMessageErrorDuringLockingView = useTranslation({
        ...messages.messageErrorDuringLockingView,
    });
    const transMessagePublishingModel = useTranslation({
        ...messages.messagePublishingModel,
    });
    const transMessagePublishingModelDueToForceLockRelease = useTranslation({
        ...messages.messagePublishingModelDueToForceLockRelease,
    });
    const transMessageModelPublishingFailed = useTranslation({
        ...messages.messageModelPublishingFailed,
    });
    const transMessageModelPublishingDueToForceLockReleaseFailed = useTranslation({
        ...messages.messageModelPublishingDueToForceLockReleaseFailed,
    });
    const transMessagePublishingModelHasFailed = useTranslation({
        ...messages.messagePublishingModelHasFailed,
    });
    const transMessagePublishingModelDueToForceLockReleaseHasFailed = useTranslation({
        ...messages.messagePublishingModelDueToForceLockReleaseHasFailed,
    });
    const transMessageModelIsBeingProcessedPleaseTryAgainLater = useTranslation({
        ...messages.messageModelIsBeingProcessedPleaseTryAgainLater,
    });
    const transMessageAnErrorOccurredDuringUnlockingModel = useTranslation({
        ...messages.messageAnErrorOccurredDuringUnlockingModel,
    });
    const transMessageMissingSerialNumber = useTranslation({
        ...messages.messageMissingSerialNumber,
    });
    const transMessageElementChangeSuccessfullyScheduled = useTranslation({
        ...messages.messageElementChangeSuccessfullyScheduled,
    });
    const transMessageModelUnlockingDueToForceLockReleaseFailed = useTranslation({
        ...messages.messageModelUnlockingDueToForceLockReleaseFailed,
    });
    const transMessageModelUnlockingFailed = useTranslation({
        ...messages.messageModelUnlockingFailed,
    });
    const transMessageModelProcessingDueToForceLockReleaseFailed = useTranslation({
        ...messages.messageModelProcessingDueToForceLockReleaseFailed,
    });
    const transMessageModelProcessingFailed = useTranslation({
        ...messages.messageModelProcessingFailed,
    });
    const serialNumberValidationInvalid = useTranslation({
        ...messages.serialNumberValidationInvalid,
    });
    const singleElementSuccess = useTranslation({
        ...messages.singleElementSuccess,
    });
    const singleElementError = useTranslation({
        ...messages.singleElementError,
    });
    const singleElementDiscardInProgress = useTranslation({
        ...messages.singleElementDiscardInProgress,
    });
    const transMessageDeleteElementPermissionError = useTranslation({
        ...messages.messageDeleteElementPermissionError,
    });
    const transMessageCantCopyInEditMode = useTranslation({
        ...messages.messageCantCopyInEditMode,
    });
    const transMessageScheduleOperation = useTranslation({
        ...messages.messageScheduleOperation,
    });
    const transMessageCannotCopyElementMissingCoordinates = useTranslation({
        ...messages.messageCannotCopyElementMissingCoordinates,
    });
    const transMessageCannotCopyNewScheduledElement = useTranslation({
        ...messages.messageCannotCopyNewScheduledElement,
    });
    const transMessageCannotCopyNewCopiedScheduledElement = useTranslation({
        ...messages.messageCannotCopyNewCopiedScheduledElement,
    });
    const transMessageOpeningFSMExport = useTranslation({
        ...messages.messageOpeningFSMExport,
    });

    // ---------------------------------------------------------------------------------------------------------
    // INIT
    // ---------------------------------------------------------------------------------------------------------
    useEffect(() => {
        initLockElements({}); // Lock elements
        return () => {
            dispatch(setMode(ModeTypes.default));
            dispatch(clearAllScheduleOperations());
        };
    }, []);

    // ---------------------------------------------------------------------------------------------------------
    // FSM Export
    // ---------------------------------------------------------------------------------------------------------
    const fsmExportState = useExportState();
    useEffect(() => {
        if (
            fsmExportState.runningState === IExportRunningState.initializing ||
            fsmExportState.runningState === IExportRunningState.initializingKeepOverlay
        ) {
            initLockElements({ variant: 'fsmExport' });
        }
    }, [fsmExportState]);

    useEffect(() => {
        return () => {
            dispatch(onExportState(undefined));
            dispatch(setLockState(undefined));
        };
    }, []);

    // ---------------------------------------------------------------------------------------------------------
    // Update elements status
    // Fetch users in model
    // ---------------------------------------------------------------------------------------------------------
    const updateElementsAndFetchUsers = () => {
        updateLockInfo().then(() => {
            updateElementsStatus({
                externalIdMap,
                viewer,
                viewableElements,
            });
        });
    };

    // load AEC extension
    useEffect(() => {
        viewer.loadExtension('Autodesk.AEC.ViewportsExtension', {});
        return () => {
            viewer.unloadExtension('Autodesk.AEC.ViewportsExtension');
        };
    }, [viewer]);

    useEffect(() => {
        elementsStatusRef.current = elementsStatus;
    }, [elementsStatus]);

    // ---------------------------------------------------------------------------------------------------------
    // Close scanning
    // ---------------------------------------------------------------------------------------------------------
    useEffect(() => {
        return () => {
            updateElementsAndFetchUsers();
        };
    }, []);

    // ---------------------------------------------------------------------------------------------------------
    // Adjust geometry
    // ---------------------------------------------------------------------------------------------------------
    useEffect(() => {
        // load supported sensors
        filesApi.fileSensorsGet().then(response => {
            setSupportedSensorsList(response.data?.sensors ?? undefined);
        });
        return () => {
            clearAfterCopyElement();
        };
    }, []);

    const clearAfterCopyElement = () => {
        dispatch(setOnForceRewriteAttributesByCopy(false));
        dispatch(setSelectedElementAttributesToCache());
    };

    /*
     * Merge elements status and scheduled operations
     * save to redux
     */
    const mergeElementsStatusAndScheduled = () => {
        const elementsStatusToUpdate = _.cloneDeep(elementsStatus);
        editingState.elementsScheduleOperations.forEach(element => {
            if (element.elementID in elementsStatusToUpdate) {
                elementsStatusToUpdate[element.elementID] = {
                    ...elementsStatusToUpdate[element.elementID],
                    ...element,
                };
            } else {
                elementsStatusToUpdate[element.elementID] = {
                    ...element,
                };
                console.log(element.elementID, 'not in elementsStatusToUpdate', element);
            }
        });

        if (!_.isEqual(elementsStatus, elementsStatusToUpdate)) {
            dispatch(setElementsStatus(elementsStatusToUpdate));
        }
    };
    useEffect(() => {
        mergeElementsStatusAndScheduled();
    }, [editingState.elementsScheduleOperations]);

    useEffect(() => {
        getToolbarExtension()?.setCreateSubmenuItems(
            supportedSensorsList?.map(s => {
                return {
                    id: s.code as string,
                    name: s.nameEN as string, // todo localization
                    code: s.code as string,
                    symbol: getSensorSvg(s.code as string),
                    placementZ: s.placementZ,
                };
            }),
        );
    }, [supportedSensorsList]);

    const getToolbarExtension = useCallback(() => {
        return viewer?.getExtension(ToolbarExtensionName) as unknown as ToolbarExtensionType;
    }, [viewer]);

    const eventHandlerMove = event => {
        dispatch(setMode(ModeTypes.edit));
        const detail: MoveEventDetails = event.detail;
        const sourceElement = { ...detail.src };

        console.log('MMX ADJUST GEOMETRY | ', detail);

        //Element isn't valid or doesn't have external id
        if (!sourceElement || !sourceElement.externalId) {
            return;
        }

        const elementsStatusWithMoved = _.cloneDeep(
            elementsStatusRef.current,
        ) as IFileElementsStatus;
        const selectionExternalId = sourceElement?.externalId;
        const newElementStatus = _.cloneDeep(elementsStatusWithMoved[selectionExternalId]);

        if (newElementStatus.create) {
            // element is new, change the creation position
            newElementStatus.create = {
                ...newElementStatus.create,
                targetWorldPoint: detail.worldCoordsTarget,
                targetRealWorldCoords: detail.worldCoordsTargetReal,
            };
        } else if (newElementStatus.copy) {
            // element is copied, change the creation position
            newElementStatus.copy = {
                ...newElementStatus.copy,
                targetWorldPoint: detail.worldCoordsTarget,
                targetRealWorldCoords: detail.worldCoordsTargetReal,
            };
        } else {
            // debugger;
            const boundingBox: THREE.Box3 = getObjectBound2D(viewer.model, sourceElement.dbId);
            const originVectorWorld = boundingBox.center();

            const originVectorReal = getRealWorldCoordinates(viewer, originVectorWorld);

            const originVector = viewer.worldToClient(originVectorWorld);

            const originX = originVector?.x || 0;
            const originY = originVector?.y || 0;

            // todo remove
            const targetVector = detail.worldCoordsTarget as THREE.Vector3;

            if (!originVector || !targetVector) {
                console.log('No origin or target vector');
                return;
            }

            // TODO delta calculation here is probably not required, since it is used only for sending to backend
            // but we can calculate the size of the outline here from bounding box

            // element is not new, set the move position
            newElementStatus.move = {
                deltaClient: {
                    x: detail.clientCoordsTarget.x - originX,
                    y: detail.clientCoordsTarget.y - originY,
                    z: 0,
                },
                deltaWorld: {
                    x: detail.worldCoordsTarget.x - (originVectorWorld.x ?? 0),
                    y: detail.worldCoordsTarget.y - (originVectorWorld.y ?? 0),
                    z: 0,
                },
                deltaWorldReal: {
                    x: detail.worldCoordsTargetReal.imperial.x - (originVectorReal.imperial.x ?? 0),
                    y: detail.worldCoordsTargetReal.imperial.y - (originVectorReal.imperial.y ?? 0),
                    z: 0,
                },
                targetRealWorldCoords: detail.worldCoordsTargetReal,
                targetWorldPoint: detail.worldCoordsTarget,
                scheduled: false,
            };
        }

        //Update element statuses
        // elementsStatusWithMoved[selectionExternalId] = newElementStatus;

        // dispatch(setElementsStatus(elementsStatusWithMoved));
        dispatch(
            setElementScheduleOperations({
                create: newElementStatus.create || undefined,
                copy: newElementStatus.copy || undefined,
                move: newElementStatus.move || undefined,
                elementID: selectionExternalId,
            }),
        );
        console.log('setElementScheduleOperations');

        console.log('TBL | Move - element location', newElementStatus, selectionExternalId);
    };

    const getAdjustExtension = () => {
        return viewer?.getExtension(ToolbarExtensionName) as unknown as ToolbarExtensionType;
    };

    const eventHandlerDeactivate = event => {
        const activeElement = getAdjustExtension()?.getActiveElement();

        // console.log('SCN | Geometry tool deactivated', activeElement);

        const elementsStatusWithAdjusted = _.cloneDeep(
            elementsStatusRef.current,
        ) as IFileElementsStatus;
        const selectionExternalId = activeElement?.externalId;
        if (!selectionExternalId) {
            return;
        }

        const currentElementStatus = _.cloneDeep(elementsStatusWithAdjusted[selectionExternalId]);

        console.log('SCN | Geometry tool deactivated - status adjusted', currentElementStatus);

        //TODO: Loop through adjustable methods - validate / remove unscheduled
        let dispatchStatusesUpdate = false;
        if (currentElementStatus?.move && !currentElementStatus.move.scheduled) {
            delete currentElementStatus.move;

            elementsStatusWithAdjusted[selectionExternalId] = currentElementStatus;

            dispatchStatusesUpdate = true;

            console.log(
                'SCN | Adjust - move - not scheduled',
                currentElementStatus,
                elementsStatusWithAdjusted[selectionExternalId],
            );
        }

        if (dispatchStatusesUpdate) {
            _.debounce(() => {
                // dispatch(setElementsStatus(elementsStatusWithAdjusted));
                dispatch(
                    setElementScheduleOperations({
                        elementID: selectionExternalId,
                        move: currentElementStatus.move || undefined,
                    }),
                );
                console.log('setElementScheduleOperations');
            }, 250);
        }
    };

    const eventHandlerCopy = event => {
        if (modeRef.current != ModeTypes.default) {
            openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Error', {
                content: transMessageCantCopyInEditMode,
            });
            return false;
        }

        // Disable copy if copied element is scheduled as create
        if (
            editingStateRef.current.elementsScheduleOperations.some(
                element => element.create && element.elementID === event.detail.src.externalId,
            )
        ) {
            openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Error', {
                content: transMessageCannotCopyNewScheduledElement,
            });
            return false;
        }
        // Disable copy if copied element is scheduled as copy
        if (
            editingStateRef.current.elementsScheduleOperations.some(
                element => element.copy && element.elementID === event.detail.src.externalId,
            )
        ) {
            openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Error', {
                content: transMessageCannotCopyNewCopiedScheduledElement,
            });
            return false;
        }

        const detail: CopyEventDetails = event.detail;

        const sourceActiveElement: any = { ...detail.src };

        if (!sourceActiveElement || !sourceActiveElement.externalId) {
            return;
        }

        dispatch(setMode(ModeTypes.edit));

        // Force rewrite attributes by copy in AttributesTable.tsx
        dispatch(
            setSelectedElementAttributesToCache(editingStateRef.current.selectedElementAttributes),
        );
        dispatch(setOnForceRewriteAttributesByCopy(true));

        const selectionExternalId = sourceActiveElement.externalId;
        const temporaryElementID = getTemporaryUUID();

        const newElementStatus: IFileElementStatus = {
            copy: {
                targetWorldPoint: detail.worldCoordsTarget,
                sourceElementId: selectionExternalId,
                targetRealWorldCoords: detail.worldCoordsTargetReal,
            },
            elementName: `COPY ${sourceActiveElement.name}`,
            isLocked: true,
        };

        console.log('Copy - element status', newElementStatus);

        //Update element statuses
        const elementsStatusWithCopied = _.cloneDeep(
            elementsStatusRef.current,
        ) as IFileElementsStatus;
        elementsStatusWithCopied[temporaryElementID] = newElementStatus;
        dispatch(setElementsStatus(elementsStatusWithCopied));

        // Dispatch schedule operation
        dispatch(
            setElementScheduleOperations({
                elementID: temporaryElementID.toString(),
                copy: newElementStatus.copy,
                elementName: newElementStatus.elementName || undefined,
            }),
        );
        console.log('setElementScheduleOperations');

        const precreatedSelection: ISelection = {
            name: newElementStatus.elementName!,
            externalId: temporaryElementID,
            dbId: -1,
            properties: [],
        };

        setSingleSelectionCache(precreatedSelection);
        setMode(ModeTypes.edit);
        setElementToCreate(newElementStatus);
        dispatch(setSelectedAsset(temporaryElementID));

        // deactivate tools to prevent multiple copy
        getToolbarExtension()?.deactivateTools();
    };

    const eventHandlerCreate = event => {
        dispatch(setMode(ModeTypes.edit));
        const detail: CreateEventDetails = event.detail;
        const templateSensor = detail.geometry;

        const selectionExternalId = getTemporaryUUID();

        const newElementStatus: IFileElementStatus = {
            create: {
                sourceElementTemplate: templateSensor,
                targetWorldPoint: detail.worldCoords,
                targetRealWorldCoords: detail.worldCoordsTargetReal,
            },
            // externalId: selectionExternalId,
            elementName: `NEW ${templateSensor.name} - ${templateSensor.code}`,
            isLocked: true,
            // lockState: LockState.LOCKED,
        };

        console.log('Create - element status', newElementStatus);

        // Update element statuses
        const elementsStatusWithMoved = _.cloneDeep(
            elementsStatusRef.current,
        ) as IFileElementsStatus;
        elementsStatusWithMoved[selectionExternalId] = newElementStatus;
        dispatch(setElementsStatus(elementsStatusWithMoved));

        // Dispatch schedule operation
        dispatch(
            setElementScheduleOperations({
                elementID: String(selectionExternalId),
                create: newElementStatus.create,
            }),
        );
        console.log('setElementScheduleOperations');

        const precreatedSelection: ISelection = {
            name: newElementStatus.elementName!,
            externalId: selectionExternalId,
            dbId: -1,
            properties: [],
        };

        // todo check if this works in copy
        setSingleSelectionCache(precreatedSelection);
        setMode(ModeTypes.edit);
        setElementToCreate(newElementStatus);
        dispatch(setSelectedAsset(selectionExternalId));

        // deactivate tool to prevent multiple creations
        getToolbarExtension()?.deactivateTools();
    };

    const eventHandlerRotate = event => {
        dispatch(setMode(ModeTypes.edit));
        const detail: RotateEventDetails = event.detail;

        const sourceActiveElement: any = { ...detail.src };

        const selectionExternalId = sourceActiveElement.externalId;

        let rotateAngle = detail.angle;

        // get current scheduled rotate operation from redux
        const currentScheduledElement: IFileElementRotate | undefined =
            editingStateRef.current.elementsScheduleOperations.find(
                element => element.elementID === selectionExternalId,
            )?.rotate || undefined;

        if (currentScheduledElement) {
            rotateAngle += (currentScheduledElement.angle || 0) as number;
        }

        rotateAngle = rotateAngle % 360;
        rotateAngle = Math.round(rotateAngle);

        const rotateAngleRad = rotateAngle * (Math.PI / 180);

        const rotateOperation: IFileElementRotate = {
            angle: rotateAngle,
            angleRad: rotateAngleRad,
            rotateAxis: detail.rotateAxis,
        };

        dispatch(
            setElementScheduleOperations({
                elementID: selectionExternalId,
                rotate: rotateOperation,
            }),
        );
        console.log('setElementScheduleOperations');

        //                handleScheduleOperation(newElementStatus);
    };

    const eventHandlerDelete = event => {
        // Roles
        if (user?.forgeRoles?.some(role => roleElectrician.includes(role))) {
            openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Error', {
                content: transMessageDeleteElementPermissionError,
            });
            return false;
        }

        const detail = event.detail || {};

        const sourceActiveElement: any = { ...detail.src };

        if (!sourceActiveElement || !sourceActiveElement.externalId) {
            return;
        }

        const selectionExternalId = sourceActiveElement.externalId;

        const currentScheduledElement: IElementScheduleOperations | undefined =
            editingStateRef.current.elementsScheduleOperations.find(
                element => element.elementID === selectionExternalId,
            ) || undefined;

        if (
            (currentScheduledElement?.create && !currentScheduledElement?.create?.scheduled) ||
            (currentScheduledElement?.copy && !currentScheduledElement?.copy?.scheduled)
        ) {
            // if this element not scheduled - prevent delete and show error
            if (!currentScheduledElement?.delete?.scheduled) {
                openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Error', {
                    content: "You can't delete this element. Please use Cancel.",
                });
                return;
            }
        }

        dispatch(setMode(ModeTypes.edit));

        dispatch(
            setElementScheduleOperations({
                elementID: selectionExternalId,
                delete: {
                    delete: true,
                    scheduled: false,
                },
            }),
        );
        console.log('setElementScheduleOperations');
    };

    const listenersAdded = useRef(false);
    useEffect(() => {
        if (!listenersAdded.current) {
            viewer.addEventListener('ADJUST_GEOMETRY_MOVED', eventHandlerMove);
            viewer.addEventListener('ADJUST_GEOMETRY_TOOL_DEACTIVATED', eventHandlerDeactivate);
            viewer.addEventListener('ADJUST_GEOMETRY_COPIED', eventHandlerCopy);
            viewer.addEventListener('ADJUST_GEOMETRY_CREATED', eventHandlerCreate);
            viewer.addEventListener('ADJUST_GEOMETRY_ROTATED', eventHandlerRotate);
            viewer.addEventListener('ADJUST_GEOMETRY_DELETED', eventHandlerDelete);
            listenersAdded.current = true;
        }
        return () => {
            viewer.removeEventListener('ADJUST_GEOMETRY_MOVED', eventHandlerMove);
            viewer.removeEventListener('ADJUST_GEOMETRY_TOOL_DEACTIVATED', eventHandlerDeactivate);
            viewer.removeEventListener('ADJUST_GEOMETRY_COPIED', eventHandlerCopy);
            viewer.removeEventListener('ADJUST_GEOMETRY_CREATED', eventHandlerCreate);
            viewer.removeEventListener('ADJUST_GEOMETRY_ROTATED', eventHandlerRotate);
            viewer.removeEventListener('ADJUST_GEOMETRY_DELETED', eventHandlerDelete);
        };
    }, []);

    // Apply scheduled operations to elements
    useEffect(() => {
        mergeElementsStatusAndScheduled();
    }, [editingState.elementsScheduleOperations, elementsStatus]);

    // ---------------------------------------------------------------------------------------------------------
    // Init function - Lock elements
    // ---------------------------------------------------------------------------------------------------------
    interface IInitLockElementsProps {
        variant?: 'fsmExport';
    }
    const initLockElements = ({ variant }: IInitLockElementsProps) => {
        return new Promise(async (resolve, reject) => {
            try {
                if (viewer && modelLoaded) {
                    if (
                        keycloak &&
                        keycloak.token &&
                        forgeProjectId &&
                        fileVersionUrn &&
                        viewable
                    ) {
                        dispatch(
                            setGlobalOverlay({
                                show: true,
                                text: transMessageLockingElements,
                            }),
                        );

                        if (variant === 'fsmExport') {
                            console.log('## scanning - FSM Export - show overlay');
                            dispatch(
                                setGlobalOverlay({
                                    show: true,
                                    text: transMessageOpeningFSMExport,
                                }),
                            );
                        }

                        setRunningLocking(IRunningLockingState.running);

                        // Get all elements from viewable
                        const getViewableElements = async () => {
                            const elementsArr = {};
                            viewableElements.map(element => {
                                elementsArr[element.externalId] = element.name;
                            });
                            return elementsArr;
                        };

                        const getElementsFromAPI = async () => {
                            try {
                                const response = await filesApi.fileVersionElementsListPost({
                                    fileVersionUrn: fileVersionUrn,
                                    projectID: forgeProjectId,
                                });
                                return response.data.elements;
                            } catch (error) {
                                console.error('Error fetching elements from API:', error);
                                throw error;
                            }
                        };

                        const fetchData = async () => {
                            let elements;
                            if (variant === 'fsmExport') {
                                elements = await getElementsFromAPI();
                            } else {
                                elements = await getViewableElements();
                            }

                            // Request lock for elements
                            const data: CreateModelLockRequest = {
                                projectID: forgeProjectId,
                                fileVersionUrn: fileVersionUrn,
                                viewableID: viewable?.data?.viewableID,
                                elements: elements,
                                isFsmLock: variant === 'fsmExport',
                            };

                            console.log('%c## Start lock elements', logStyle, data);

                            filesApi
                                .fileVersionLockElementsPost(data)
                                .then(lockElementsResponseData => {
                                    dispatch(
                                        setLockState(lockElementsResponseData.data?.lockState),
                                    );

                                    if (variant === 'fsmExport') {
                                        dispatch(
                                            onExportState(IExportRunningState.initializingDone),
                                        );
                                    }

                                    // lockState - LockRequested
                                    if (
                                        lockElementsResponseData.data?.lockState === 'LockRequested'
                                    ) {
                                    }

                                    // lockState - Locked
                                    if (lockElementsResponseData.data?.lockState === 'Locked') {
                                        const elementsCount = Object.keys(elements)?.length;
                                        console.log(
                                            `## View "${viewable?.data?.name}" already successfully locked (${elementsCount} elements)`,
                                        );
                                        setRunningLocking(IRunningLockingState.none);
                                    }

                                    // lockState - Busy
                                    if (lockElementsResponseData.data?.lockState === 'Busy') {
                                        openDialog<INotificationDialogProps>(
                                            DF.NOTIFICATION_DIALOG,
                                            'Error',
                                            {
                                                content: transMessageModelBeingProcessed,
                                            },
                                        );
                                        console.log(
                                            `## View "${viewable?.data?.name}" cannot be locked due to running operations`,
                                        );
                                        closeScanner();
                                    }

                                    // Hide overlay process
                                    let hideOverlay = true;
                                    if (
                                        lockElementsResponseData.data?.lockState ===
                                            'LockRequested' &&
                                        variant === 'fsmExport'
                                    ) {
                                        console.log('## scanning - FSM Export - keep overlay');
                                        hideOverlay = false;
                                    }

                                    if (
                                        fsmExportState.runningState ===
                                        IExportRunningState.initializingKeepOverlay
                                    ) {
                                        console.log('## scanning - FSM Export - hide overlay');
                                        hideOverlay = false;
                                    }

                                    if (hideOverlay) {
                                        console.log('## scanning - hide overlay');
                                        dispatch(hideGlobalOverlay());
                                    }
                                })
                                .catch(error => {
                                    console.error('## Locking elements error', error);

                                    if (
                                        error.response?.data?.ErrorCode === 'FileVersionNotLatest'
                                    ) {
                                        openDialog<INotificationDialogProps>(
                                            DF.NOTIFICATION_DIALOG,
                                            'Error',
                                            {
                                                content: transMessageVersionOfModelIsNotLatest,
                                            },
                                        );
                                        console.log('## Version of model is not latest');
                                        closeScanner();
                                        return;
                                    }

                                    openDialog<INotificationDialogProps>(
                                        DF.NOTIFICATION_DIALOG,
                                        'Error',
                                        {
                                            content: transMessageErrorDuringLockingModel,
                                        },
                                    );
                                    console.error(
                                        `## Unable to lock view '${viewable?.data?.name}'`,
                                        error,
                                    );
                                    closeScanner();
                                    dispatch(hideGlobalOverlay());
                                })
                                .finally(() => {
                                    updateElements();
                                });
                        };

                        fetchData();
                    } else {
                        console.log('## forgeProjectId, fileVersionUrn or viewable is not set');
                        reject('forgeProjectId, fileVersionUrn or viewable is not set');
                    }
                } else {
                    console.log('## Viewer not loaded');
                    reject('Viewer not loaded');
                }
            } catch (error) {
                // Handle any synchronous errors that might occur during the execution of the function
                console.error('## Error during lock elements:', error);
                reject(error);
            }
        });
    };

    // ---------------------------------------------------------------------------------------------------------
    // Selection
    // ---------------------------------------------------------------------------------------------------------
    /**
     * after select item and go to "edit" or "scan" - duplicates selection into "selectionCache"
     * than work only with selection cache.
     * empty selection cache after discard or "close session and save"
     */
    const [singleSelectionCache, setSingleSelectionCacheInternal] = useState<ISelection | null>();
    const singleSelectionCacheRef = useRef<ISelection | null>();
    const setSingleSelectionCache = (selection: ISelection | null) => {
        singleSelectionCacheRef.current = selection;
        setSingleSelectionCacheInternal(selection);
    };

    // Element selection
    useEffect(() => {
        // check if not in scanMode or editMode or adjustGeometryNode
        if (mode != ModeTypes.default) {
            return;
        }

        // check if is selected some element
        if (!singleSelection?.dbId) {
            console.log('## Deselected element');
            setSingleSelectionCache(null);
            return;
        }

        // check if selected item is in elementsStatus
        if (!(singleSelection?.externalId && singleSelection?.externalId in elementsStatus)) {
            return;
        }

        // set selection into cache
        setSingleSelectionCache(singleSelection);
        console.log('## Selected element', singleSelection);

        // update elements status
        updateElements();
    }, [singleSelection]);

    // Highlight selected item
    useEffect(() => {
        const tTool = getToolbarExtension();
        if (tTool && singleSelectionCache) {
            tTool.setActiveElement(singleSelectionCache);
        }
    }, [singleSelectionCache]);

    // Disable change selection in asset table if is active scanMode or editMode
    useEffect(() => {
        if (mode === ModeTypes.scan || mode === ModeTypes.edit) {
            dispatch(setAssetsSettings({ disableChangeSelection: true }));
        } else {
            dispatch(setAssetsSettings({ disableChangeSelection: false }));
        }
    }, [mode]);

    useEffect(() => {
        // component did unmount, deactivate adjust geometry tools
        return () => {
            getToolbarExtension()?.deactivateTools();
            viewer.dispatchEvent(new Event('ADJUST_GEOMETRY_TOOL_DEACTIVATED'));
            dispatch(setSelectedAsset(undefined));
        };
    }, []);

    // Check if enable Save button
    const checkIfEnableSaveButton = (): boolean => {
        // Check if some operations exists on elements
        const isSomeOperations = Object.keys(elementsStatus).some(key => {
            return (elementsStatus[key]?.operationCount as number) > 0;
        });
        if (isSomeOperations) {
            setEnableSubmit(true);
            return true;
        } else {
            setEnableSubmit(false);
            return false;
        }
    };

    const [elementsStatusLocal, setElementsStatusLocal] = useState<IFileElementsStatus>();
    useEffect(() => {
        checkIfEnableSaveButton();
        setElementsStatusLocal(elementsStatus);
    }, [elementsStatus]);

    // ---------------------------------------------------------------------------------------------------------
    // Serial number
    // ---------------------------------------------------------------------------------------------------------
    const [serialNumber, setSerialNumber] = useState<string>('');
    const handleChangeSerialNumberValue = event => {
        setSerialNumber(event.target.value);
    };

    // Serial number input
    useEffect(() => {
        const seNum = singleSelectionCache?.properties.find(
            x => x.attributeName === IAttributesNames.serialNumber,
        )?.displayValue;
        if (seNum) {
            setSerialNumber(seNum as string);
        } else {
            setSerialNumber('');
        }
    }, [singleSelectionCache]);

    // fetch and save to redux Selected element attributes
    useEffect(() => {
        if (singleSelectionCache) {
            console.log('call POST: elementData');
            dispatch(setSelectElementAttributes());
            elementAttributesData({
                fileVersionUrn: fileVersionUrn,
                singleSelection: singleSelectionCache,
                projectID: forgeProjectId,
                viewableID: viewable?.data?.viewableID,
                keycloak: keycloak,
            })
                .then(async data => {
                    dispatch(setSelectElementAttributes(data));
                })
                .catch(error => {
                    console.error(error);
                });
        } else {
            dispatch(setSelectElementAttributes());
        }
    }, [singleSelectionCache, mode]);

    // Serial number
    useEffect(() => {
        const data = editingState.selectedElementAttributes;
        if (data) {
            const serialNo = data.find(x => x.attributeName === IAttributesNames.serialNumber);
            if (serialNo?.value || serialNo?.newValue) {
                if (serialNo?.newValue) {
                    setSerialNumber(serialNo.newValue);
                } else if (serialNo?.value) {
                    setSerialNumber(serialNo.value);
                }
            }
            if (singleSelectionCache && singleSelectionCache.externalId) {
                handleSingleElementDiscard(singleSelectionCache.externalId, data);
            }
        }
    }, [editingState.selectedElementAttributes]);

    // ---------------------------------------------------------------------------------------------------------
    // Single element discard
    // ---------------------------------------------------------------------------------------------------------
    // Single element discard handler
    const handleSingleElementDiscard = (elementId: string, attributeMap: IAttributesMap) => {
        setSingleDiscardAllowed(false);
        const selectedElement = lockInfo?.elements?.find(x => x.elementID == elementId);
        if (selectedElement && selectedElement?.operationCount) {
            const someBatched = attributeMap.some(x => x.isBatched);
            setSingleDiscardAllowed(!someBatched && selectedElement?.operationCount > 0);
        }
    };

    // Shows confirmation dialog for single element discard
    const handleSingleElementDiscardClick = () => {
        setSingleDiscardConfirmationShow(true);
    };

    // Single element discard endpoint API call
    const singleElementDiscard = async () => {
        setSingleDiscardConfirmationShow(false);
        if (singleSelectionCache) {
            dispatch(
                setGlobalOverlay({
                    show: true,
                    text: singleElementDiscardInProgress,
                }),
            );
            await singleElementDiscardCall({
                fileVersionUrn: fileVersionUrn,
                singleSelection: singleSelectionCache,
                projectID: forgeProjectId,
                viewableID: viewable?.data?.viewableID,
                keycloak: keycloak,
            })
                .then(async data => {
                    if (data) {
                        if (data.operations) {
                            if (data.operations.length == 0) {
                                dispatch(
                                    addNotification({
                                        variant: 'success',
                                        message: singleElementSuccess,
                                    }),
                                );

                                // Remove scheduled oprations for discarded element from Redux
                                if (data.elementID) {
                                    dispatch(removeScheduleForElement(data.elementID));
                                }

                                updateElements();
                            } else {
                                dispatch(
                                    addNotification({
                                        variant: 'error',
                                        message: singleElementError,
                                    }),
                                );
                            }
                        }
                    }
                })
                .catch(error => {
                    console.error(error);
                    dispatch(
                        addNotification({
                            variant: 'error',
                            message: singleElementError,
                        }),
                    );
                })
                .finally(() => {
                    viewer.clearSelection();
                    dispatch(hideGlobalOverlay());
                    dispatch(setSelectedAsset(undefined));
                    setSingleDiscardAllowed(false);
                });
        }
    };

    // ---------------------------------------------------------------------------------------------------------
    // Get lockInfo - elements
    // ---------------------------------------------------------------------------------------------------------
    const updateElements = () => {
        if (!forgeProjectId || !fileVersionUrn) return;
        updateElementsAndFetchUsers();
    };

    // ---------------------------------------------------------------------------------------------------------
    // Close scanner
    // ---------------------------------------------------------------------------------------------------------
    // Disable overlay
    const closeScanner = () => {
        console.log('## Hide overlay');
        dispatch(hideGlobalOverlay());
        console.log('## Close scanner');
        dispatch(setMenu({ scanningOpen: false }));
    };

    // ---------------------------------------------------------------------------------------------------------
    // SignalR
    // ---------------------------------------------------------------------------------------------------------

    // Check if data from SignalR are relatable for selected viewable
    const checkResponseAccess = responseData => {
        return (
            responseData?.ProjectID === forgeProjectId &&
            responseData?.VersionUrn === fileVersionUrn &&
            responseData?.ViewableID === viewable?.data?.viewableID
        );
    };

    const [onModelPublishComplete, setOnModelPublishComplete] = useState<boolean>(false);

    // New SignalR catching
    useEffect(() => {
        const responseData = signalRState?.data;

        // SignalR - onDesignAutomationComplete
        if (signalRState?.methodName === IMethodName.onDesignAutomationComplete) {
            updateElementsAndFetchUsers();
            if (!checkResponseAccess(signalRState?.data)) return;

            if (responseData?.ActivityType === 'SetLock') {
                updateElements();
                dispatch(setLockState('Locked'));
                setRunningLocking(IRunningLockingState.none);

                if (keycloakProfile?.id === responseData?.UserID) {
                    dispatch(hideGlobalOverlay());

                    dispatch(
                        addNotification({
                            variant: 'success',
                            message: transMessageModelWasSuccessfullyLocked,
                        }),
                    );

                    console.log(
                        `## View ${viewable?.data?.name} successfully locked (${responseData.SuccessElementIds.length} elements)`,
                    );
                }
            }

            if (responseData?.ActivityType === 'ProcessModelAndReleaseLock') {
            }

            if (responseData?.ActivityType === 'ProcessModelAndKeepLock') {
                if (keycloakProfile?.id === responseData?.UserID) {
                    openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Success', {
                        content: transMessageYourChangesHaveBeenSuccessfullySubmitted,
                    });
                    console.log(
                        `## onDesignAutomationComplete: User ${responseData?.UserName} has successfully submitted to central model (${responseData?.ActivityType})`,
                    );

                    // if any failure elements exists info modal is shown with listed failured elements
                    if (responseData?.FailureElementIds.length > 0) {
                        if (lockInfo) {
                            const failuredLockedElements = lockInfo.elements
                                ?.filter(
                                    x =>
                                        x.elementID &&
                                        (responseData?.FailureElementIds as string[]).includes(
                                            x.elementID,
                                        ),
                                )
                                .map(x => (x.elementName ? x.elementName : ''));
                            if (failuredLockedElements) {
                                setDaFailureElements(failuredLockedElements);
                            } else {
                                setDaFailureElements(responseData?.FailureElementIds);
                            }
                        } else {
                            setDaFailureElements(responseData?.FailureElementIds);
                        }
                    } else {
                        closeScanner();
                    }

                    return;
                }
            }

            if (responseData?.ActivityType === 'ReleaseLock') {
                if (keycloakProfile?.id === responseData?.UserID && !responseData?.isForceRelease) {
                    // todo - better check -> userID

                    openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Success', {
                        content: transMessageModelWasSuccessfullyUnlocked,
                    });
                }
                closeScanner();
            }

            if (responseData?.ActivityType === 'Export') {
                if (keycloakProfile?.id === responseData?.UserID) {
                    dispatch(onExportState(IExportRunningState.exportStart));
                }
            }
        }

        // SignalR - onDesignAutomationFailure
        if (signalRState?.methodName === IMethodName.onDesignAutomationFailure) {
            if (!checkResponseAccess(signalRState?.data)) return;
            // todo restrict to user and add CTA on retry which will refresh locking

            if (responseData.ActivityType === 'SetLock') {
                console.log(
                    'onDesignAutomationFailure - ActivityType: SetLock',
                    keycloakProfile,
                    keycloakProfile?.id,
                    responseData?.UserID,
                );
                setRunningLocking(IRunningLockingState.failed);
                openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Error', {
                    content: transMessageErrorDuringLockingView,
                });
                console.log(`## DA failure: failed to lock view "${viewable?.data?.name}"`);
                dispatch(hideGlobalOverlay());
                return;
            }

            if (responseData.ActivityType === 'ProcessModelAndReleaseLock') {
                let message = transMessageModelProcessingFailed;

                if (responseData?.isForceRelease) {
                    message = transMessageModelProcessingDueToForceLockReleaseFailed;
                }

                openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Error', {
                    content: message,
                });
                console.log('## Model processing has failed', responseData);
                dispatch(hideGlobalOverlay());
                closeScanner();
                return;
            }

            if (responseData.ActivityType === 'ReleaseLock') {
                let message = transMessageModelUnlockingFailed;

                if (responseData?.isForceRelease) {
                    message = transMessageModelUnlockingDueToForceLockReleaseFailed;
                }

                openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Error', {
                    content: message,
                });
            }

            if (responseData?.ActivityType === 'Export') {
                /*
                todo
                 - error message
                 - hide overlay
                 */
                openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Error', {
                    content: 'Error during export',
                });
            }

            dispatch(hideGlobalOverlay());
        }

        // SignalR - onModelPublishStarted
        if (signalRState?.methodName === IMethodName.onModelPublishStarted) {
            if (!checkResponseAccess(signalRState?.data)) return;

            let message = transMessagePublishingModel;

            if (responseData?.isForceRelease) {
                message = transMessagePublishingModelDueToForceLockRelease;
            }

            dispatch(
                setGlobalOverlay({
                    show: true,
                    text: message,
                }),
            );
            console.log('## Publishing model..');
        }

        // SignalR - onModelPublishComplete
        if (signalRState?.methodName === IMethodName.onModelPublishComplete) {
            if (!checkResponseAccess(signalRState?.data)) return;
            // show only once - can be received twice
            if (!onModelPublishComplete) {
                console.log(
                    '## Model publish successfully finished, continuing with asset synchronization',
                );

                setOnModelPublishComplete(true);
            }
        }

        // SignalR - onModelPublishFailure
        if (signalRState?.methodName === IMethodName.onModelPublishFailure) {
            if (!checkResponseAccess(signalRState?.data)) return;
            if (responseData.ActivityType === 'ProcessModelAndReleaseLock') {
                let message = transMessageModelPublishingFailed;

                if (responseData?.isForceRelease) {
                    message = transMessageModelPublishingDueToForceLockReleaseFailed;
                }

                openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Error', {
                    content: message,
                });
                console.log('## Model publish has failed', responseData);

                dispatch(hideGlobalOverlay());
            }
        }

        // SignalR - onAssetSyncStarted
        if (signalRState?.methodName === IMethodName.onAssetSyncStarted) {
            if (!checkResponseAccess(signalRState?.data)) return;
            dispatch(
                setGlobalOverlay({
                    show: true,
                    text: transMessageSynchronizingAssets,
                }),
            );
            console.log('## Asset synchronization has started');
        }

        // SignalR - onAssetSyncComplete
        if (signalRState?.methodName === IMethodName.onAssetSyncComplete) {
            if (!checkResponseAccess(signalRState?.data)) return;
            console.log('## Close scanner');
            closeScanner();
        }

        // SignalR - onAssetSyncFailure
        if (signalRState?.methodName === IMethodName.onAssetSyncFailure) {
            if (!checkResponseAccess(signalRState?.data)) return;
            let message = transMessagePublishingModelHasFailed;

            if (responseData?.isForceRelease) {
                message = transMessagePublishingModelDueToForceLockReleaseHasFailed;
            }

            openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Error', {
                content: message,
            });
            console.log('## Asset synchronization has failed', responseData);

            dispatch(hideGlobalOverlay());
        }

        // SignalR - onForceReleaseRequested
        if (signalRState?.methodName === IMethodName.onForceReleaseRequested) {
            if (!checkResponseAccess(signalRState?.data)) return;
        }
    }, [signalRState]);

    // ---------------------------------------------------------------------------------------------------------
    // DA Actions
    // ---------------------------------------------------------------------------------------------------------

    // closes info modal if any failure elements exists in design automation result
    const failuredDaElementsAcknowledged = () => {
        setDaFailureElements([]);
        closeScanner();
    };

    // ---------------------------------------------------------------------------------------------------------
    // CTA Actions
    // ---------------------------------------------------------------------------------------------------------

    // CTA Discard - Unlock elements
    const handleDiscardClick = () => {
        setConfirmDiscardOperationsVisible(false);
        if (
            keycloak &&
            keycloak.token &&
            forgeProjectId &&
            fileVersionUrn &&
            viewable?.data?.viewableID
        ) {
            console.log('## Discard button click');

            const data: ReleaseModelLockRequest = {
                projectID: forgeProjectId,
                fileVersionUrn: fileVersionUrn,
                viewableID: viewable.data.viewableID,
            };

            console.log('## Unlocking model', data);

            dispatch(
                setGlobalOverlay({
                    show: true,
                    text: transMessageUnlockingModelElements,
                }),
            );

            filesApi
                .fileVersionUnlockElementsPost(data)
                .then(unlockElementsResponseData => {
                    console.debug(unlockElementsResponseData);
                    dispatch(setLockState(unlockElementsResponseData.data?.lockState));
                    if (unlockElementsResponseData.data?.lockState === 'ReleaseRequested') {
                        // just continue
                    }

                    if (unlockElementsResponseData.data?.lockState === 'Released') {
                        closeScanner();
                    }

                    if (unlockElementsResponseData.data?.lockState === 'Busy') {
                        openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Error', {
                            content: transMessageModelIsBeingProcessedPleaseTryAgainLater,
                        });
                        console.log(
                            `## View "${viewable?.data?.name}" cannot be unlocked due to running operations`,
                        );
                    }
                })
                .catch(error => {
                    dispatch(hideGlobalOverlay());

                    if (error.response?.data?.ErrorCode === 'FileVersionNotLatest') {
                        openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Error', {
                            content: transMessageVersionOfModelIsNotLatest,
                        });
                        console.log('## Version of model is not latest');
                        closeScanner();
                        return;
                    }

                    console.error('## Unlocking model has failed', error);
                    openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Error', {
                        content: transMessageAnErrorOccurredDuringUnlockingModel,
                    });
                })
                .finally(() => {});
        }
    };

    const handleCreateGeometryFinished = (adjustGeometryStatus: any) => {
        const tTool = viewer?.getExtension(ToolbarExtensionName) as unknown as ToolbarExtensionType;
        if (tTool) {
            tTool.deactivateTools();
        }
        viewer.clearSelection();

        if (adjustGeometryStatus?.create) {
            setMode(ModeTypes.default);
            console.log('## Deselected element');
            setSingleSelectionCache(null);
            setElementToCreate(null);
        }
    };

    const handleRejectOperations = () => {
        if (!project.file.selectedAsset) {
            return;
        }

        dispatch(removeScheduleForElement(project.file.selectedAsset));
        clearAfterCopyElement();
        dispatch(setSelectedAsset(undefined));
        setSingleSelectionCache(null);
        viewer.clearSelection();

        viewer.dispatchEvent(new Event('ADJUST_GEOMETRY_TOOL_DEACTIVATED'));
        getToolbarExtension()?.setActiveElement(undefined);
    };

    // CTA Confirm and next
    const [scheduleOperationDone, setScheduleOperationDone] = useState<boolean>(true);
    const handleScheduleOperation = async () => {
        // todo pass externalID only and load operations by externalID
        // todo - disable cancel and confirm button until "scheduleOperation success"
        const selectionExternalId = singleSelectionCacheRef.current?.externalId;
        const selectionElementName = singleSelectionCacheRef.current?.name;
        let serialNumberIsValidating = false;

        clearAfterCopyElement();

        if (!selectionExternalId) {
            return;
        }

        // let adjustGeometryStatus = elementsStatusRef.current?.[selectionExternalId as string]; // todo - check if elementsStatusRef is needed over whole component
        const adjustGeometryStatus = editingStateRef.current.elementsScheduleOperations.find(
            item => item.elementID === selectionExternalId,
        );

        // if (adjustGeometryStatus && !adjustGeometryStatus.target) {
        //     selectionExternalId = adjustGeometryStatus?.externalId;
        //     selectionElementName = adjustGeometryStatus?.name;
        // }

        const elementStatusFetched = selectionExternalId
            ? elementsStatus[selectionExternalId]
            : null;
        console.log(
            'EEE | Sched',
            adjustGeometryEnabled,
            elementsStatus,
            selectionExternalId,
            adjustGeometryStatus,
            elementStatusFetched,
        );

        if (forgeProjectId && fileVersionUrn && keycloak?.token && selectionExternalId) {
            dispatch(
                setGlobalOverlay({
                    show: true,
                    text: transMessageScheduleOperation,
                }),
            );

            console.log('## Handle confirm button (schedule operation)');

            let operations: Array<ScheduleModelOperationRequestData> = [];

            if (adjustGeometryStatus) {
                if (adjustGeometryStatus?.move) {
                    console.log('TBL | Scheduling transform - move');
                    const adjustGeometryOperation = {
                        // ...adjustGeometryStatus?.move.moveOperationTranslated,
                        x: adjustGeometryStatus?.move?.deltaWorldReal.x,
                        y: adjustGeometryStatus?.move?.deltaWorldReal.y,
                        z: adjustGeometryStatus?.move?.deltaWorldReal.z,
                        temporaryElementID:
                            adjustGeometryStatus.create || adjustGeometryStatus.copy
                                ? selectionExternalId
                                : undefined,
                    } as unknown as ScheduleModelOperationRequestData;
                    adjustGeometryOperation.operationType = 'MoveElement';

                    operations.push(adjustGeometryOperation);
                }

                if (adjustGeometryStatus?.copy) {
                    console.log('TBL | Scheduling copy');

                    const x = adjustGeometryStatus?.copy.targetRealWorldCoords?.imperial?.x;
                    const y = adjustGeometryStatus?.copy.targetRealWorldCoords?.imperial?.y;
                    const z = adjustGeometryStatus?.copy.targetRealWorldCoords?.imperial?.z;

                    if (x === undefined || y === undefined || z === undefined) {
                        console.error('## Cannot copy element - missing coordinates');
                        dispatch(hideGlobalOverlay());
                        openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Error', {
                            content: transMessageCannotCopyElementMissingCoordinates,
                        });
                        return;
                    }

                    const adjustGeometryOperation: ScheduleModelOperationRequestData = {
                        // ...adjustGeometryStatus?.create,
                        operationType: 'CopyElement',
                        temporaryElementID: selectionExternalId,
                        sourceElementID: adjustGeometryStatus?.copy.sourceElementId,
                        x: x,
                        y: y,
                        z: z,
                    };
                    operations.push(adjustGeometryOperation);
                }

                if (adjustGeometryStatus?.rotate) {
                    console.log('TBL | Scheduling rotate');
                    // const adjustGeometryOperation = {
                    //     ...adjustGeometryStatus?.rotate,
                    // } as unknown as ScheduleModelOperationRequestData;

                    const adjustGeometryOperation: ScheduleModelOperationRequestData = {
                        operationType: 'RotateElement',
                        angle: adjustGeometryStatus?.rotate.angle,
                        x: adjustGeometryStatus?.rotate.rotateAxis.x,
                        y: adjustGeometryStatus?.rotate.rotateAxis.y,
                        z: adjustGeometryStatus?.rotate.rotateAxis.z,
                        temporaryElementID:
                            adjustGeometryStatus.create || adjustGeometryStatus.copy
                                ? selectionExternalId
                                : undefined,
                    };

                    // adjustGeometryOperation.angle = adjustGeometryStatus?.rotate.angleRad;
                    // adjustGeometryOperation.operationType = 'RotateElement';

                    operations.push(adjustGeometryOperation);
                }

                if (adjustGeometryStatus?.delete) {
                    console.log('TBL | Scheduling delete');
                    const adjustGeometryOperation = {
                        operationType: 'DeleteElement',
                        temporaryElementID:
                            adjustGeometryStatus.create || adjustGeometryStatus.copy
                                ? selectionExternalId
                                : undefined,
                    } as unknown as ScheduleModelOperationRequestData;

                    operations.push(adjustGeometryOperation);
                }
            }

            let setSensorCode = '';
            let editedSerialNumber = '';
            let serialNumberChanged = false;
            if (mode === ModeTypes.edit) {
                if (editedAttributes) {
                    editedAttributes.map(async editedAttribute => {
                        if (editedAttribute.attributeName === IAttributesNames.sensorCode) {
                            setSensorCode = editedAttribute.newValue || '';
                            return;
                        }

                        // serial number was edited
                        if (editedAttribute.attributeName === IAttributesNames.serialNumber) {
                            editedSerialNumber = editedAttribute.newValue || '';
                            serialNumberChanged = editedAttribute.newValue != undefined;
                        }

                        if (editedAttribute.hasOwnProperty('newValue')) {
                            const editedAttributeData: ScheduleModelOperationRequestData = {
                                operationType: 'SetAttribute',
                                attributeName: editedAttribute.attributeName,
                                attributeValue: editedAttribute.newValue,
                                temporaryElementID:
                                    adjustGeometryStatus?.create || adjustGeometryStatus?.copy
                                        ? selectionExternalId
                                        : undefined,
                            };

                            operations.push(editedAttributeData);
                        }
                    });

                    if (editedSerialNumber.length > 0 || serialNumberChanged) {
                        // serial number validation in edit mode
                        const snValidationResult = await handleSerialNumberValidation(
                            selectionExternalId,
                            editedSerialNumber,
                        );

                        if (snValidationResult.isValid) {
                            if (snValidationResult.isUnique) {
                                serialNumberIsValidating = false;
                                setSerialNumberValidationStatus(prevState => ({
                                    ...prevState,
                                    isValidating: serialNumberIsValidating,
                                }));
                            } else {
                                serialNumberIsValidating = true;
                            }
                        } else {
                            dispatch(hideGlobalOverlay());
                            return false;
                        }
                    }
                }
            }

            if (mode === ModeTypes.scan) {
                if (!serialNumber) {
                    dispatch(
                        addNotification({
                            variant: 'error',
                            message: transMessageMissingSerialNumber,
                        }),
                    );
                    return;
                } else {
                    if (selectionExternalId) {
                        // serial number validation in scan mode
                        const snValidationResult = await handleSerialNumberValidation(
                            selectionExternalId,
                            serialNumber,
                        );

                        // if serial number is valid and unique continue handleScheduleOperation flow
                        if (snValidationResult.isValid) {
                            if (snValidationResult.isUnique) {
                                serialNumberIsValidating = false;
                                setSerialNumberValidationStatus(prevState => ({
                                    ...prevState,
                                    isValidating: serialNumberIsValidating,
                                }));
                            } else {
                                serialNumberIsValidating = true;
                            }
                        } else {
                            // if serial number is not valid handleScheduleOperation flow is canceled
                            dispatch(hideGlobalOverlay());
                            return false;
                        }
                    }
                }

                const editedAttributeData: ScheduleModelOperationRequestData = {
                    operationType: 'SetAttribute',
                    attributeName: IAttributesNames.serialNumber,
                    attributeValue: serialNumber,
                };

                operations = [editedAttributeData];
            }

            if (adjustGeometryStatus && adjustGeometryStatus.create) {
                console.log('EEE | Scheduling create', adjustGeometryStatus);

                const createData = adjustGeometryStatus.create;

                const x = createData.targetRealWorldCoords?.imperial?.x;
                const y = createData.targetRealWorldCoords?.imperial?.y;
                const z = createData.targetRealWorldCoords?.imperial?.z;

                if (x === undefined || y === undefined || z === undefined) {
                    console.error('## Cannot create element - missing coordinates');
                    dispatch(hideGlobalOverlay());
                    openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Error', {
                        content: transMessageCannotCopyElementMissingCoordinates,
                    });
                    return;
                }

                const adjustGeometryOperation: ScheduleModelOperationRequestData = {
                    operationType: 'CreateElement',
                    temporaryElementID: selectionExternalId,
                    sensorCode: createData.sourceElementTemplate.code,
                    x,
                    y,
                    z,
                };

                operations.push(adjustGeometryOperation);
            }

            setMode(ModeTypes.default);

            if (operations?.length === 0) {
                dispatch(hideGlobalOverlay());
                return;
            }

            const data: ScheduleModelOperationRequest = {
                projectID: forgeProjectId,
                fileVersionUrn: fileVersionUrn,
                viewableID: viewable?.data?.viewableID,
                elementID: selectionExternalId,
                elementName: selectionElementName || 'n/a',
                operations: operations,
            };

            // if serial number is not unique handleScheduleOperation flow is canceled and original ScheduleModelOperationRequest is saved
            // SaveScheduleModelOperation is performed with saved data after serial number overwrite confirm modal is confirmed
            if (serialNumberIsValidating) {
                setSerialNumberValidationStatus(prevState => ({
                    ...prevState,
                    scheduleModelOperationRequest: data,
                }));
                return false;
            }

            console.log(
                `## Saving element '${singleSelectionCacheRef.current?.name}' [${selectionExternalId}] changes`,
                data,
            );

            // ScheduleModelOperation API call
            saveScheduleOperations(data, adjustGeometryStatus);

            setScheduleOperationDone(false);
        }
    };

    // ScheduleModelOperation API call
    const saveScheduleOperations = (
        data: ScheduleModelOperationRequest,
        adjustGeometryStatus: any = null,
    ) => {
        console.log('ScheduleOperation called...');

        setMode(ModeTypes.default);
        setScheduleOperationDone(false);

        filesApi
            .fileVersionScheduleOperationPost(data)
            .then(scheduleOperationResponseData => {
                setMode(ModeTypes.default);
                dispatch(setSelectedAsset(undefined));
                getToolbarExtension()?.deactivateTools();
                dispatch(
                    addNotification({
                        variant: 'success',
                        message: transMessageElementChangeSuccessfullyScheduled,
                    }),
                );
                console.log(
                    '## Element change successfully scheduled',
                    scheduleOperationResponseData,
                );

                // Mark element as "inProgress"
                const elementIdKey = scheduleOperationResponseData.data.elementID;
                const elements = _.cloneDeep(elementsStatus);
                if (elementIdKey && elements[elementIdKey]) {
                    elements[elementIdKey].serialNumberStatus = 'inProgress';
                    dispatch(setElementsStatus(elements));
                }
            })
            .catch(error => {
                openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Error', {
                    content: transMessageErrorOccurredDuringSavingElementChanges,
                });
                console.error('## Error occurred during saving element changes', error);
            })
            .finally(() => {
                setSerialNumberValidationStatus(serialNumberValidationStatusInitialState);
                dispatch(clearAllScheduleOperations());
                dispatch(hideGlobalOverlay());
                setScheduleOperationDone(true);
                updateElements();
                setEnableSubmit(true);
                if (adjustGeometryStatus /*&& !adjustGeometryStatus.target*/) {
                    // todo check this condition
                    handleCreateGeometryFinished(adjustGeometryStatus);
                }
            });
    };

    // serial number overwrite confirm modal is confirmed
    const confirmSerialNumberOverwrite = () => {
        console.log('OVERWRITE Serial Number CONFIRMED');
        setSerialNumberValidationStatus(prevState => ({
            ...prevState,
            overwriteConfirmed: true,
        }));
        if (
            serialNumberValidationStatus.isValidating == true &&
            serialNumberValidationStatus.scheduleModelOperationRequest
        ) {
            // if serial number overwrite confirm modal is confirmed SaveScheduleModelOperation is performed with saved data from handleScheduleOperation method
            saveScheduleOperations(serialNumberValidationStatus.scheduleModelOperationRequest);
        }
    };

    // serial number overwrite confirm modal is declined and SaveScheduleModelOperation is not performed
    const declineSerialNumberOverwrite = () => {
        setSerialNumber('');
        dispatch(hideGlobalOverlay());
        setSerialNumberValidationStatus(serialNumberValidationStatusInitialState);
    };

    const confirmPerformOperations = () => {
        console.log('Handle perform operations clicked');
        setConfirmPerformOperationsVisible(true);
    };

    const confirmDiscardOperations = () => {
        console.log('Handle discard operations clicked');
        setConfirmDiscardOperationsVisible(true);
    };

    // CTA Save & close session
    const handlePerformOperations = async () => {
        setConfirmPerformOperationsVisible(false);

        if (!checkIfEnableSaveButton()) {
            return;
        }

        try {
            // todo before load lock info
            const { isSomeOperations } = await updateLockInfoAndElementsStatus({
                externalIdMap,
                viewer,
                viewableElements,
            });

            if (!isSomeOperations) {
                console.log('## No operations to perform');
                openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Error', {
                    content: transMessageNoOperationsToPerform,
                });
                setEnableSubmit(false);
                return;
            }

            if (isSomeOperations) {
                console.log('## Some operations to perform');

                const unlockedOperations = getUnlockedElementsOperations();
                if (
                    unlockedOperations &&
                    Object.entries(unlockedOperations).length > 0 &&
                    !unlockedOperationsConfirmed
                ) {
                    setUnlockedOperationsNeedsConfirmation(true);
                    return;
                } else {
                    setEnableSubmit(true);
                    unlockedOperationsConfirmed = false;
                }
            }

            if (forgeProjectId && fileVersionUrn && viewable?.data?.viewableID && keycloak?.token) {
                console.log('## Save & close session button click');

                dispatch(
                    setGlobalOverlay({
                        show: true,
                        text: transMessageProcessingModelChanges,
                    }),
                );

                const data: PerformModelOperationsRequest = {
                    projectID: forgeProjectId,
                    fileVersionUrn: fileVersionUrn,
                    viewableID: viewable?.data?.viewableID,
                };

                filesApi
                    .fileVersionPerformOperationsPost(data)
                    .then(performOperationsResponseData => {
                        console.debug(
                            '## performOperations success: ',
                            performOperationsResponseData,
                        );
                    })
                    .catch(error => {
                        console.error('## An error occurred during saving model changes', error);
                        if (error.response?.data?.ErrorCode === 'ModelTransformationInProgress') {
                            openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Error', {
                                content: messageModelTransformationInProgressError,
                            });
                        } else {
                            openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Error', {
                                content: transMessagePerformOperationsError,
                            });
                        }
                        dispatch(hideGlobalOverlay());
                    });
            }
        } catch (error) {
            console.error('## An error occurred', error);
        }
    };

    // validates serial number against API endpoint and hadle its result
    const handleSerialNumberValidation = async (
        elementId: string,
        serialNumberValue: string,
    ): Promise<ValidateSerialNumberResponse> => {
        setSerialNumberValidationStatus(prevState => ({
            ...prevState,
            isValidating: true,
        }));

        // request for validate serial number API
        const request: ValidateSerialNumberRequest = {
            elementID: elementId,
            versionUrn: fileVersionUrn ? fileVersionUrn : '',
            serialNumber: serialNumberValue,
        };

        let response: ValidateSerialNumberResponse = {
            isValid: false,
        };

        // call validate serial number API
        await fsmApi
            .fsmValidateSerialNumberPost(request)
            .then(validateSerialNumberData => {
                response = validateSerialNumberData.data as ValidateSerialNumberResponse;
            })
            .catch(error => {
                if (error.response.data.SerialNumber) {
                    // if fluent validation error is thrown display dialog
                    openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Error', {
                        content: serialNumberValidationInvalid,
                    });
                }
                setSerialNumber('');
            });

        // get element name from lock info based on duplicate element id from validation response
        let elementName = response.duplicateElementIds
            ? response.duplicateElementIds[0]
            : undefined;
        if (lockInfo && lockInfo.elements && elementName) {
            const lockedElement = lockInfo.elements.find(x => x.elementID == elementName);
            elementName =
                lockedElement && lockedElement.elementName
                    ? lockedElement?.elementName
                    : elementName[0];
        }

        // get element properties values for duplicate element (not selected one) for display in serial number overwrite confirm modal
        if (response.duplicateElementIds) {
            const duplicateExternalId = externalIdMap?.get(response.duplicateElementIds[0]);
            await viewer.getProperties(duplicateExternalId as unknown as number, function (e) {
                const zoneCustomerText = e.properties.find(
                    x => x.attributeName == 'SI_BMA_Zone customer text',
                )?.displayValue;
                const zoneAddress = e.properties.find(
                    x => x.attributeName == 'SI_BMA_Zone address',
                )?.displayValue;

                elementName = `${zoneCustomerText} ${zoneAddress} (${elementName})`;

                setSerialNumberValidationStatus(prevState => ({
                    ...prevState,
                    duplicateElementName: elementName,
                }));
            });
        }

        // set state for serial number overwrite confirm modal
        setSerialNumberValidationStatus(prevState => ({
            ...prevState,
            isValid: response.isValid,
            isUnique: response.isUnique,
            serialNumber: serialNumberValue,
            validationMessage: response.validationMessage ? response.validationMessage : undefined,
            duplicateElementName: elementName,
        }));

        return response;
    };

    // get unlocked elements with existing operations for confirmation modal before handlePerformOperations
    const getUnlockedElementsOperations = () => {
        const unlockedElements = lockInfo?.elements?.filter(
            x => x.lockState != 'Locked' && x.operationCount && x.operationCount > 0,
        );
        return _.groupBy(unlockedElements, x => x.viewableID);
    };

    // unlocked elements with existing operations confirmation modal is confirmed = handlePerformOperations is performed
    const unlockedElementOpetaionsConfirmed = () => {
        setUnlockedOperationsNeedsConfirmation(false);
        unlockedOperationsConfirmed = true;
        handlePerformOperations();
    };

    // unlocked elements with existing operations confirmation modal is declined = no action is performed
    const unlockedElementOpetaionsDeclined = () => {
        setUnlockedOperationsNeedsConfirmation(false);
        unlockedOperationsConfirmed = false;
    };

    const handleSetEditMode = () => {
        setMode(ModeTypes.edit);
    };
    const handleCloseEditMode = () => {
        setMode(ModeTypes.default);
        handleRejectOperations();
    };
    const handleSetScanMode = () => {
        setMode(ModeTypes.scan);
        handleOpenScanner();
    };
    const handleCloseScanMode = () => {
        setMode(ModeTypes.default);
        handleRejectOperations();
    };

    // ---------------------------------------------------------------------------------------------------------
    // Scanner
    // ---------------------------------------------------------------------------------------------------------
    const [scannerOpen, setScannerOpen] = useState<boolean>(true);
    const handleScanResult = result => {
        console.log('## MlKit | Scan result - handle: ', JSON.stringify(result));
        if (result === MlKitErrors.SCANNING_CANCELLED) {
            console.log('## MlKit | Viewer scan canceled by user');
            handleCloseScanner();
            return;
        }

        const newSerialNumber = extractCodeFromDataMatrix(result);

        console.log('## MlKit | Viewer scan result', newSerialNumber);
        setSerialNumber(newSerialNumber);
        handleCloseScanner();
    };

    const handleOpenScanner = () => {
        if (!manualModeOnly) {
            setScannerOpen(true);
        }
    };

    const handleCloseScanner = () => {
        console.log('## MlKit | Viewer - closing scanner');
        setScannerOpen(false);
    };

    const handleSupportFailed = (error: string): void => {
        console.log('## MlKit | Support Error | ', error);
    };

    useEffect(() => {
        if (mode === ModeTypes.scan) {
            handleOpenScanner();
        }
    }, [mode]);

    //Validate manual mode only
    useEffect(() => {
        setManualModeOnly(!isValidPlatform());
    }, []);

    // ---------------------------------------------------------------------------------------------------------

    return (
        <Box className={classes.root}>
            <Sidebar open={true}>
                <div className="flex flex-col h-full p-2">
                    <LockingStateInfo
                        lockingState={runningLocking}
                        onRetry={() => initLockElements({})}
                        onClose={closeScanner}
                    />
                    <div className="flex-1 flex flex-col">
                        <div>
                            <Typography variant="h1" className={classes.title}>
                                <FormattedMessage {...messages.titleEditingSession} />
                            </Typography>

                            {singleSelectionCache?.dbId && (
                                <>
                                    {mode === ModeTypes.default && scheduleOperationDone && (
                                        <>
                                            <AttributesTable
                                                setEditedAttributes={setEditedAttributes}
                                                externalIdMap={externalIdMap}
                                                viewer={viewer}
                                            />

                                            {singleSelectionCache?.externalId &&
                                                elementsStatus[
                                                    singleSelectionCache?.externalId
                                                ] && (
                                                    <OperationsDetail
                                                        status={
                                                            elementsStatus[
                                                                singleSelectionCache?.externalId
                                                            ]
                                                        }
                                                        variant="info"
                                                    />
                                                )}

                                            <Box mt={2}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth
                                                    onClick={handleSetEditMode}
                                                    className="py-5"
                                                >
                                                    <FormattedMessage {...messages.buttonEdit} />
                                                </Button>
                                            </Box>

                                            <Box mt={2}>
                                                {!manualModeOnly && (
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        fullWidth
                                                        onClick={handleSetScanMode}
                                                        className="h-[120px] w-[120px] flex items-center justify-center text-xl mx-auto"
                                                    >
                                                        <span>
                                                            <FormattedMessage
                                                                {...messages.buttonScan}
                                                            />
                                                        </span>
                                                    </Button>
                                                )}
                                            </Box>
                                            {singleDiscardAllowed && (
                                                <Box mt={2}>
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        fullWidth
                                                        className="py-5"
                                                        onClick={handleSingleElementDiscardClick}
                                                    >
                                                        <FormattedMessage
                                                            {...messages.buttonElementDiscard}
                                                        />
                                                    </Button>
                                                </Box>
                                            )}
                                        </>
                                    )}

                                    {mode === ModeTypes.edit && (
                                        <>
                                            <AttributesTable
                                                setEditedAttributes={setEditedAttributes}
                                                externalIdMap={externalIdMap}
                                                viewer={viewer}
                                                editMode
                                            />

                                            {singleSelectionCache?.externalId &&
                                                elementsStatus[
                                                    singleSelectionCache?.externalId
                                                ] && (
                                                    <OperationsDetail
                                                        status={
                                                            elementsStatus[
                                                                singleSelectionCache?.externalId
                                                            ]
                                                        }
                                                        variant="info"
                                                    />
                                                )}

                                            <Box mt={3}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth
                                                    onClick={handleScheduleOperation}
                                                    className="py-5"
                                                >
                                                    <FormattedMessage {...messages.buttonConfirm} />
                                                </Button>
                                            </Box>

                                            <Box mt={2}>
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    fullWidth
                                                    onClick={handleCloseEditMode}
                                                    className="py-2"
                                                >
                                                    <FormattedMessage {...messages.buttonCancel} />
                                                </Button>
                                            </Box>
                                        </>
                                    )}

                                    {mode === ModeTypes.scan && (
                                        <>
                                            <Box className={classes.serialBox}>
                                                {scannerOpen && !manualModeOnly && (
                                                    <MlKitBarcodeScanner
                                                        onScanSuccess={handleScanResult}
                                                        onSupportFailed={handleSupportFailed}
                                                    />
                                                )}

                                                <Box textAlign={'center'} mb={1}>
                                                    <FormattedMessage
                                                        {...messages.titleSerialNumber}
                                                    />
                                                    :
                                                </Box>
                                                <TextField
                                                    variant="outlined"
                                                    value={serialNumber}
                                                    fullWidth
                                                    onChange={event =>
                                                        handleChangeSerialNumberValue(event)
                                                    }
                                                    className={classes.serialInput}
                                                />

                                                {!manualModeOnly && (
                                                    <Box mt={2}>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            fullWidth
                                                            onClick={handleOpenScanner}
                                                        >
                                                            <FormattedMessage
                                                                {...messages.buttonScanAgain}
                                                            />
                                                        </Button>
                                                    </Box>
                                                )}

                                                {singleSelectionCache?.properties.find(
                                                    x =>
                                                        x.attributeName ===
                                                        IAttributesNames.serialNumber,
                                                )?.displayValue !== serialNumber && (
                                                    <Box mt={1} textAlign={'center'}>
                                                        <FormattedMessage
                                                            {...messages.messageSerialNumberWasChanged}
                                                        />
                                                    </Box>
                                                )}
                                            </Box>

                                            <Box mt={3} className={classes.buttonsGroup}>
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    fullWidth
                                                    onClick={handleCloseScanMode}
                                                >
                                                    <FormattedMessage {...messages.buttonCancel} />
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth
                                                    onClick={handleScheduleOperation}
                                                >
                                                    <FormattedMessage {...messages.buttonConfirm} />
                                                </Button>
                                            </Box>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                        <div className="flex-1 flex justify-center items-center">
                            {!singleSelectionCache?.dbId && (
                                <Box className={classes.introTitle}>
                                    <FormattedMessage {...messages.titleSelectItem} />
                                </Box>
                            )}
                        </div>

                        <div>
                            <Box mt={10} className={classes.buttonsGroup}>
                                {mode === ModeTypes.default && (
                                    <>
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            size="medium"
                                            onClick={confirmDiscardOperations}
                                            className="py-3"
                                        >
                                            <FormattedMessage {...messages.buttonDiscard} />
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="medium"
                                            onClick={confirmPerformOperations}
                                            fullWidth
                                            disabled={lockState !== 'Locked' || !enableSubmit}
                                            className="py-3"
                                        >
                                            <FormattedMessage
                                                {...messages.buttonCloseSessionAndSave}
                                            />
                                        </Button>
                                    </>
                                )}
                            </Box>
                        </div>
                    </div>
                </div>
            </Sidebar>
            <FsmExport viewer={viewer} />

            {/* confirm modal that is shown if any unlocked elements with scheduled operations exists in handlePerformOperations */}
            <ConfirmModal
                title={useTranslation({ ...messages.unlockedOperationsModalHeadline })}
                opened={unlockedOperationsNeedsConfirmation}
                onConfirmClick={() => unlockedElementOpetaionsConfirmed()}
                onCloseClick={() => unlockedElementOpetaionsDeclined()}
            >
                <div>
                    <h2>
                        <FormattedMessage {...messages.modelContainsNotProcessedElements} />
                    </h2>
                    <h4>
                        <FormattedMessage {...messages.unlockedOperationsDiscarded} />
                    </h4>
                    {Object.entries(getUnlockedElementsOperations())?.map(([key, value]) => (
                        <div className="pt-2">
                            <h5>Viewable {key}</h5>
                            <ul key={key}>
                                {value.map(el => (
                                    <li key={el.elementID}>{el.elementName}</li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </ConfirmModal>

            {/* info modal that is shown any failure elements exists in design automation response */}
            <InfoModal
                opened={daFailureElements.length > 0}
                title={useTranslation({ ...messages.daFailuredDaElementsModalHeadline })}
                onCloseClick={failuredDaElementsAcknowledged}
            >
                <div>
                    <ul className="pt-2">
                        {daFailureElements && daFailureElements.map(el => <li key={el}>{el}</li>)}
                    </ul>
                </div>
            </InfoModal>

            {/* serial number overwrite confirmation modal that is shown if entered serial number already exists on another element */}
            <ConfirmModal
                title={useTranslation({ ...messages.serialNumberValidationModalTitle })}
                confirmButtonText={useTranslation({
                    ...messages.serialNumberValidationModalAccept,
                })}
                cancelButtonText={useTranslation({
                    ...messages.serialNumberValidationModalDiscard,
                })}
                opened={
                    serialNumberValidationStatus.isValid == true &&
                    serialNumberValidationStatus.isUnique == false &&
                    serialNumberValidationStatus.isValidating == true &&
                    serialNumberValidationStatus.overwriteConfirmed != true
                }
                onConfirmClick={confirmSerialNumberOverwrite}
                onCloseClick={declineSerialNumberOverwrite}
            >
                <div>
                    <p>
                        {useTranslation({ ...messages.serialNumberValidationExists }).replace(
                            /%\w+%/g,
                            serialNumberValidationStatus.duplicateElementName
                                ? serialNumberValidationStatus.duplicateElementName
                                : '',
                        )}
                    </p>
                </div>
            </ConfirmModal>

            {/* confirm modal for single element discard operation */}
            <ConfirmModal
                title={useTranslation({ ...messages.buttonElementDiscard })}
                opened={singleDiscardConfirmationShow}
                onConfirmClick={() => singleElementDiscard()}
                onCloseClick={() => setSingleDiscardConfirmationShow(false)}
            >
                <div>
                    <FormattedMessage {...messages.confirmSingleElementDiscardModalText} />
                </div>
            </ConfirmModal>

            {/* confirm modal for discard all changes */}
            <ConfirmModal
                title={useTranslation({ ...messages.buttonDiscard })}
                opened={confirmDiscardOperationsVisible}
                onConfirmClick={() => handleDiscardClick()}
                onCloseClick={() => setConfirmDiscardOperationsVisible(false)}
            >
                <div>
                    <FormattedMessage {...messages.confirmDiscardAllChangesModalText} />
                </div>
            </ConfirmModal>

            {/* confirm modal for perform oprations */}
            <ConfirmModal
                title={useTranslation({ ...messages.buttonCloseSessionAndSave })}
                opened={confirmPerformOperationsVisible}
                onConfirmClick={() => handlePerformOperations()}
                onCloseClick={() => setConfirmPerformOperationsVisible(false)}
            >
                <div>
                    <FormattedMessage {...messages.confirmPerformAllChangesModalText} />
                </div>
            </ConfirmModal>
        </Box>
    );
};

export default Scanning;

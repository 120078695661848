/* tslint:disable */
/* eslint-disable */
/**
 * FireBIM API
 * API definitions for FireBim
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FilterSchemaResult } from '../models';
// @ts-ignore
import { IssueAttachmentDownloadRequest } from '../models';
// @ts-ignore
import { IssueCacheRequest } from '../models';
// @ts-ignore
import { IssueCreateRequest } from '../models';
// @ts-ignore
import { IssueDetailsRequest } from '../models';
// @ts-ignore
import { IssueDetailsResponse } from '../models';
// @ts-ignore
import { IssueFilterRequest } from '../models';
// @ts-ignore
import { IssuePrepareRequest } from '../models';
// @ts-ignore
import { IssuePrepareResponse } from '../models';
// @ts-ignore
import { IssueRelevantMarkupListRequest } from '../models';
// @ts-ignore
import { IssueRelevantMarkupListResponse } from '../models';
// @ts-ignore
import { IssueSearchRequest } from '../models';
// @ts-ignore
import { IssueSearchResponse } from '../models';
// @ts-ignore
import { IssueUpdateRequest } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * IssuesModuleApi - axios parameter creator
 * @export
 */
export const IssuesModuleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download attachment.
         * @param {IssueAttachmentDownloadRequest} [issueAttachmentDownloadRequest] Download request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuesAttachmentsDownloadPost: async (issueAttachmentDownloadRequest?: IssueAttachmentDownloadRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Issues/Attachments/Download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(issueAttachmentDownloadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resets the issue cache.
         * @param {IssueCacheRequest} [issueCacheRequest] The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuesCacheClearPost: async (issueCacheRequest?: IssueCacheRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Issues/Cache/Clear`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(issueCacheRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Caches the issues tied to file URN for faster access to issues.
         * @param {IssueCacheRequest} [issueCacheRequest] The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuesCachePost: async (issueCacheRequest?: IssueCacheRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Issues/Cache`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(issueCacheRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates the issue through Autodesk Forge.
         * @param {IssueCreateRequest} [issueCreateRequest] The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuesCreatePost: async (issueCreateRequest?: IssueCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Issues/Create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(issueCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets issue details.
         * @param {IssueDetailsRequest} [issueDetailsRequest] Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuesDetailsPost: async (issueDetailsRequest?: IssueDetailsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Issues/Details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(issueDetailsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets list of filters for issues.
         * @param {IssueFilterRequest} [issueFilterRequest] Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuesFiltersPost: async (issueFilterRequest?: IssueFilterRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Issues/Filters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(issueFilterRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search issue with a filter.
         * @param {IssueSearchRequest} [issueSearchRequest] The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuesListPost: async (issueSearchRequest?: IssueSearchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Issues/List`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(issueSearchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets form data for creating when project is selected.
         * @param {IssuePrepareRequest} [issuePrepareRequest] Prepare issue request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuesPrepareIssuePost: async (issuePrepareRequest?: IssuePrepareRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Issues/PrepareIssue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(issuePrepareRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets markup list which can be attached to an issue.
         * @param {IssueRelevantMarkupListRequest} [issueRelevantMarkupListRequest] Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuesRelevantMarkupsPost: async (issueRelevantMarkupListRequest?: IssueRelevantMarkupListRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Issues/RelevantMarkups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(issueRelevantMarkupListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the issue through Autodesk Forge.
         * @param {IssueUpdateRequest} [issueUpdateRequest] The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuesUpdatePost: async (issueUpdateRequest?: IssueUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Issues/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(issueUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IssuesModuleApi - functional programming interface
 * @export
 */
export const IssuesModuleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IssuesModuleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Download attachment.
         * @param {IssueAttachmentDownloadRequest} [issueAttachmentDownloadRequest] Download request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issuesAttachmentsDownloadPost(issueAttachmentDownloadRequest?: IssueAttachmentDownloadRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issuesAttachmentsDownloadPost(issueAttachmentDownloadRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resets the issue cache.
         * @param {IssueCacheRequest} [issueCacheRequest] The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issuesCacheClearPost(issueCacheRequest?: IssueCacheRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issuesCacheClearPost(issueCacheRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Caches the issues tied to file URN for faster access to issues.
         * @param {IssueCacheRequest} [issueCacheRequest] The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issuesCachePost(issueCacheRequest?: IssueCacheRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issuesCachePost(issueCacheRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates the issue through Autodesk Forge.
         * @param {IssueCreateRequest} [issueCreateRequest] The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issuesCreatePost(issueCreateRequest?: IssueCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IssueDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issuesCreatePost(issueCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets issue details.
         * @param {IssueDetailsRequest} [issueDetailsRequest] Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issuesDetailsPost(issueDetailsRequest?: IssueDetailsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IssueDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issuesDetailsPost(issueDetailsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets list of filters for issues.
         * @param {IssueFilterRequest} [issueFilterRequest] Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issuesFiltersPost(issueFilterRequest?: IssueFilterRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilterSchemaResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issuesFiltersPost(issueFilterRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search issue with a filter.
         * @param {IssueSearchRequest} [issueSearchRequest] The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issuesListPost(issueSearchRequest?: IssueSearchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IssueSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issuesListPost(issueSearchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets form data for creating when project is selected.
         * @param {IssuePrepareRequest} [issuePrepareRequest] Prepare issue request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issuesPrepareIssuePost(issuePrepareRequest?: IssuePrepareRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IssuePrepareResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issuesPrepareIssuePost(issuePrepareRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets markup list which can be attached to an issue.
         * @param {IssueRelevantMarkupListRequest} [issueRelevantMarkupListRequest] Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issuesRelevantMarkupsPost(issueRelevantMarkupListRequest?: IssueRelevantMarkupListRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IssueRelevantMarkupListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issuesRelevantMarkupsPost(issueRelevantMarkupListRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the issue through Autodesk Forge.
         * @param {IssueUpdateRequest} [issueUpdateRequest] The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issuesUpdatePost(issueUpdateRequest?: IssueUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IssueDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issuesUpdatePost(issueUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IssuesModuleApi - factory interface
 * @export
 */
export const IssuesModuleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IssuesModuleApiFp(configuration)
    return {
        /**
         * 
         * @summary Download attachment.
         * @param {IssueAttachmentDownloadRequest} [issueAttachmentDownloadRequest] Download request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuesAttachmentsDownloadPost(issueAttachmentDownloadRequest?: IssueAttachmentDownloadRequest, options?: any): AxiosPromise<void> {
            return localVarFp.issuesAttachmentsDownloadPost(issueAttachmentDownloadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resets the issue cache.
         * @param {IssueCacheRequest} [issueCacheRequest] The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuesCacheClearPost(issueCacheRequest?: IssueCacheRequest, options?: any): AxiosPromise<void> {
            return localVarFp.issuesCacheClearPost(issueCacheRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Caches the issues tied to file URN for faster access to issues.
         * @param {IssueCacheRequest} [issueCacheRequest] The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuesCachePost(issueCacheRequest?: IssueCacheRequest, options?: any): AxiosPromise<void> {
            return localVarFp.issuesCachePost(issueCacheRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates the issue through Autodesk Forge.
         * @param {IssueCreateRequest} [issueCreateRequest] The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuesCreatePost(issueCreateRequest?: IssueCreateRequest, options?: any): AxiosPromise<IssueDetailsResponse> {
            return localVarFp.issuesCreatePost(issueCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets issue details.
         * @param {IssueDetailsRequest} [issueDetailsRequest] Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuesDetailsPost(issueDetailsRequest?: IssueDetailsRequest, options?: any): AxiosPromise<IssueDetailsResponse> {
            return localVarFp.issuesDetailsPost(issueDetailsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets list of filters for issues.
         * @param {IssueFilterRequest} [issueFilterRequest] Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuesFiltersPost(issueFilterRequest?: IssueFilterRequest, options?: any): AxiosPromise<FilterSchemaResult> {
            return localVarFp.issuesFiltersPost(issueFilterRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search issue with a filter.
         * @param {IssueSearchRequest} [issueSearchRequest] The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuesListPost(issueSearchRequest?: IssueSearchRequest, options?: any): AxiosPromise<IssueSearchResponse> {
            return localVarFp.issuesListPost(issueSearchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets form data for creating when project is selected.
         * @param {IssuePrepareRequest} [issuePrepareRequest] Prepare issue request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuesPrepareIssuePost(issuePrepareRequest?: IssuePrepareRequest, options?: any): AxiosPromise<IssuePrepareResponse> {
            return localVarFp.issuesPrepareIssuePost(issuePrepareRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets markup list which can be attached to an issue.
         * @param {IssueRelevantMarkupListRequest} [issueRelevantMarkupListRequest] Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuesRelevantMarkupsPost(issueRelevantMarkupListRequest?: IssueRelevantMarkupListRequest, options?: any): AxiosPromise<IssueRelevantMarkupListResponse> {
            return localVarFp.issuesRelevantMarkupsPost(issueRelevantMarkupListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the issue through Autodesk Forge.
         * @param {IssueUpdateRequest} [issueUpdateRequest] The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuesUpdatePost(issueUpdateRequest?: IssueUpdateRequest, options?: any): AxiosPromise<IssueDetailsResponse> {
            return localVarFp.issuesUpdatePost(issueUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IssuesModuleApi - object-oriented interface
 * @export
 * @class IssuesModuleApi
 * @extends {BaseAPI}
 */
export class IssuesModuleApi extends BaseAPI {
    /**
     * 
     * @summary Download attachment.
     * @param {IssueAttachmentDownloadRequest} [issueAttachmentDownloadRequest] Download request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssuesModuleApi
     */
    public issuesAttachmentsDownloadPost(issueAttachmentDownloadRequest?: IssueAttachmentDownloadRequest, options?: AxiosRequestConfig) {
        return IssuesModuleApiFp(this.configuration).issuesAttachmentsDownloadPost(issueAttachmentDownloadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resets the issue cache.
     * @param {IssueCacheRequest} [issueCacheRequest] The request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssuesModuleApi
     */
    public issuesCacheClearPost(issueCacheRequest?: IssueCacheRequest, options?: AxiosRequestConfig) {
        return IssuesModuleApiFp(this.configuration).issuesCacheClearPost(issueCacheRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Caches the issues tied to file URN for faster access to issues.
     * @param {IssueCacheRequest} [issueCacheRequest] The request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssuesModuleApi
     */
    public issuesCachePost(issueCacheRequest?: IssueCacheRequest, options?: AxiosRequestConfig) {
        return IssuesModuleApiFp(this.configuration).issuesCachePost(issueCacheRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates the issue through Autodesk Forge.
     * @param {IssueCreateRequest} [issueCreateRequest] The request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssuesModuleApi
     */
    public issuesCreatePost(issueCreateRequest?: IssueCreateRequest, options?: AxiosRequestConfig) {
        return IssuesModuleApiFp(this.configuration).issuesCreatePost(issueCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets issue details.
     * @param {IssueDetailsRequest} [issueDetailsRequest] Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssuesModuleApi
     */
    public issuesDetailsPost(issueDetailsRequest?: IssueDetailsRequest, options?: AxiosRequestConfig) {
        return IssuesModuleApiFp(this.configuration).issuesDetailsPost(issueDetailsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets list of filters for issues.
     * @param {IssueFilterRequest} [issueFilterRequest] Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssuesModuleApi
     */
    public issuesFiltersPost(issueFilterRequest?: IssueFilterRequest, options?: AxiosRequestConfig) {
        return IssuesModuleApiFp(this.configuration).issuesFiltersPost(issueFilterRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search issue with a filter.
     * @param {IssueSearchRequest} [issueSearchRequest] The request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssuesModuleApi
     */
    public issuesListPost(issueSearchRequest?: IssueSearchRequest, options?: AxiosRequestConfig) {
        return IssuesModuleApiFp(this.configuration).issuesListPost(issueSearchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets form data for creating when project is selected.
     * @param {IssuePrepareRequest} [issuePrepareRequest] Prepare issue request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssuesModuleApi
     */
    public issuesPrepareIssuePost(issuePrepareRequest?: IssuePrepareRequest, options?: AxiosRequestConfig) {
        return IssuesModuleApiFp(this.configuration).issuesPrepareIssuePost(issuePrepareRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets markup list which can be attached to an issue.
     * @param {IssueRelevantMarkupListRequest} [issueRelevantMarkupListRequest] Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssuesModuleApi
     */
    public issuesRelevantMarkupsPost(issueRelevantMarkupListRequest?: IssueRelevantMarkupListRequest, options?: AxiosRequestConfig) {
        return IssuesModuleApiFp(this.configuration).issuesRelevantMarkupsPost(issueRelevantMarkupListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the issue through Autodesk Forge.
     * @param {IssueUpdateRequest} [issueUpdateRequest] The request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssuesModuleApi
     */
    public issuesUpdatePost(issueUpdateRequest?: IssueUpdateRequest, options?: AxiosRequestConfig) {
        return IssuesModuleApiFp(this.configuration).issuesUpdatePost(issueUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

import { isPlatform } from '@ionic/react';
import { Button, CircularProgress, Tooltip } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { IssueAttachmentItem, IssueDetailsRequest, IssueMarkup } from '../../generate/api';
import { issuesApi } from '../../packages/Api/data/issues/client';
import {
    setCurrentIssueDetails,
    setIssuesOnRefresh,
    setMode,
    useCurrentIssuesDetails,
    useIssuesState,
} from '../../redux/issues';
import { addNotification } from '../../redux/notifier';
import { setSidebar, useProjectState } from '../../redux/project';
import theme from '../../theme/Theme';
import Icon from '../Icon/Icon';
import FormattedMessage from '../Translation/FormattedMessage';
import { handleAttachmentDownload } from './attachmentDownload';
import CurrentIssueMarkup from './Editor/CurrentMarkup';
import Editor from './Editor/Editor';
import messages from './messagesSidebar';

interface IIssueSidebarProps {
    viewer?: Autodesk.Viewing.Viewer3D;
    viewable: any;
}

const AttachmentRow: React.FC<{ attachment: IssueAttachmentItem }> = ({ attachment }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const isPlatformCapacitor = isPlatform('capacitor');

    const handleClickDownload = () => {
        setLoading(true);
        handleAttachmentDownload({
            attachment: attachment,
        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        <div className="flex justify-between border-b border-gray-200 py-1 gap-2">
            <Tooltip title={attachment.name} arrow>
                <div className="overflow-hidden whitespace-nowrap overflow-ellipsis text-sm">
                    {attachment.name}
                </div>
            </Tooltip>
            {!isPlatformCapacitor && (
                <div className="">
                    <div onClick={handleClickDownload}>
                        {loading ? (
                            <CircularProgress size={15} />
                        ) : (
                            <Icon
                                name="cloud-download"
                                size={20}
                                fill={theme.palette.primary.main}
                                className="cursor-pointer"
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

const IssueSidebar: React.FC<IIssueSidebarProps> = ({ viewer, viewable }) => {
    const project = useProjectState();
    const reduxIssues = useIssuesState();
    const currentIssueDetails = useCurrentIssuesDetails();
    const currentIssue = reduxIssues.currentIssue;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const mode = reduxIssues.mode;

    useEffect(() => {
        fetchDetails();
    }, [currentIssue]);

    const fetchDetails = () => {
        if (!project.project?.id || !currentIssue?.forgeIssueID) {
            console.log('Missing data', project.project?.id, currentIssue?.forgeIssueID);
            return;
        }

        const requestData: IssueDetailsRequest = {
            forgeProjectId: project.project?.id,
            forgeIssueID: currentIssue?.forgeIssueID,
        };

        setLoading(true);
        dispatch(setCurrentIssueDetails(undefined));
        dispatch(setSidebar(true));

        issuesApi
            .issuesDetailsPost(requestData)
            .then(response => {
                console.log('## Issues - Details', response);
                dispatch(setCurrentIssueDetails(response.data));
            })
            .catch(error => {
                console.error('## Issues - Details', error);
                dispatch(
                    addNotification({
                        variant: 'error',
                        message: `Error fetching issue detail`,
                    }),
                );
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleCLickEdit = () => {
        dispatch(setMode('edit'));
    };

    const handleClickCancel = () => {
        dispatch(setMode('view'));
    };

    const handleClickSave = () => {
        fetchDetails();
        dispatch(setIssuesOnRefresh(true));
        dispatch(setMode('view'));
    };

    // Due date
    let dueDate: any = '';
    if (currentIssueDetails?.issue?.dueDate) {
        dueDate = new Date(currentIssueDetails?.issue?.dueDate);
        dueDate = moment(dueDate).format('DD.MM.YYYY');
    }

    if (mode === 'edit' || mode === 'create') {
        return (
            <div className="p-4">
                <Editor onCancel={handleClickCancel} onSave={handleClickSave} viewable={viewable} />
            </div>
        );
    }

    if (!currentIssueDetails?.issue)
        return (
            <div className="p-4">
                {loading ? (
                    <div className="flex justify-center">
                        <CircularProgress size={30} />
                    </div>
                ) : (
                    <div className="text-center text-gray-400 text-lg py-5">
                        <FormattedMessage {...messages.messageNoIssueSelected} />
                    </div>
                )}
            </div>
        );

    return (
        <div className="p-4">
            {loading ? (
                <div className="flex justify-center">
                    <CircularProgress size={30} />
                </div>
            ) : (
                <div className="">
                    <div className="flex justify-between">
                        <div className="text-lg font-bold">
                            <FormattedMessage {...messages.titleIssue} />
                        </div>
                        <div className="">
                            <Button variant="outlined" color="primary" onClick={handleCLickEdit}>
                                <FormattedMessage {...messages.buttonEdit} />
                            </Button>
                        </div>
                    </div>
                    <div className="border-t border-gray-200 my-5"></div>
                    <div className="mb-5">
                        <div className="font-bold mb-1">
                            <FormattedMessage {...messages.labelTitle} />
                        </div>
                        <div className="">{currentIssueDetails?.issue?.title}</div>
                    </div>
                    <div className="mb-5">
                        <div className="font-bold mb-1">
                            <FormattedMessage {...messages.labelAssignTo} />
                        </div>
                        <div className="">{currentIssueDetails?.issue?.assignedToFullName}</div>
                    </div>
                    <div className="mb-5">
                        <div className="font-bold mb-1">
                            <FormattedMessage {...messages.labelDueDate} />
                        </div>
                        <div className="">{dueDate}</div>
                    </div>
                    <div className="mb-5">
                        <div className="font-bold mb-1">
                            <FormattedMessage {...messages.labelDescription} />
                        </div>
                        <div className="">{currentIssueDetails?.issue?.description}</div>
                    </div>
                    <div className="mb-5">
                        <div className="font-bold mb-1">
                            <FormattedMessage {...messages.labelMarkup} />
                        </div>
                        <div className="">
                            <CurrentIssueMarkup />
                        </div>
                    </div>

                    <div className="mb-5">
                        <div className="font-bold mb-1">
                            <FormattedMessage {...messages.labelAttachments} />
                        </div>
                        <div className="">
                            {currentIssueDetails?.attachments?.map((attachment, index) => {
                                return <AttachmentRow key={index} attachment={attachment} />;
                            })}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default IssueSidebar;

import { Button } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import FormattedMessage from '../Translation/FormattedMessage';
import messages from './messages';
import styles from './styles.module.css';
import { IRunningLockingState } from './types';

export interface ILockingStateInfoProps {
    lockingState: IRunningLockingState;
    onRetry: () => void;
    onClose: () => void;
}

const LockingStateInfo: React.FC<ILockingStateInfoProps> = ({ lockingState, onRetry, onClose }) => {
    return (
        <>
            {lockingState === IRunningLockingState.running && (
                <div className={clsx(styles.lockInfo, styles['lockInfo--running'])}>
                    <FormattedMessage {...messages.messageLockingOperationIsRunning} />
                </div>
            )}
            {lockingState === IRunningLockingState.failed && (
                <div className={clsx(styles.lockInfo, styles['lockInfo--failed'])}>
                    <div className="mb-3">
                        <FormattedMessage {...messages.messageLockingOperationHasFailed} />
                    </div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onRetry}
                        className="bg-red-600 text-white hover:bg-red-700"
                    >
                        <FormattedMessage {...messages.buttonRetry} />
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={onClose}
                        className="ml-2 border-red-600 text-red-600 hover:bg-red-700 hover:text-white hover:border-red-700"
                    >
                        <FormattedMessage {...messages.buttonClose} />
                    </Button>
                </div>
            )}
        </>
    );
};

export default LockingStateInfo;

import { useSelector } from 'react-redux';

import {
    selectActiveFilters,
    selectAssetsState,
    selectAttributeList,
    selectAttributeListLoading,
    selectFilters,
    selectFiltersLoading,
    selectFilterView,
    selectIsActiveFilter,
    selectReloadCache,
    selectReloadCacheLoading,
    selectSearchText,
} from './assetsSlice';

export const useAssetsState = () => useSelector(selectAssetsState);

// custom
export const useAssetsSearchText = () => useSelector(selectSearchText);
export const useAssetsActiveFilters = () => useSelector(selectActiveFilters);
export const useAssetsFilterIsActive = () => useSelector(selectIsActiveFilter);
export const useAssetsFilterView = () => useSelector(selectFilterView);

// filters
export const useAssetsFiltersLoading = () => useSelector(selectFiltersLoading);
export const useAssetsFilters = () => useSelector(selectFilters);

// attribute list
export const useAssetsAttributeListLoading = () => useSelector(selectAttributeListLoading);
export const useAssetsAttributeList = () => useSelector(selectAttributeList);

// reload cache
export const useAssetsReloadCacheLoading = () => useSelector(selectReloadCacheLoading);
export const useAssetsReloadCache = () => useSelector(selectReloadCache);

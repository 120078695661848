import { Button } from '@mui/material';
import React from 'react';

import Icon from '../Icon/Icon';
import FormattedMessage from '../Translation/FormattedMessage';
import messages from './messagesTable';
import useStyles from './styles';

interface ICreateProps {
    reloadCacheAndLoadIssues: any;
}

const Reset: React.FC<ICreateProps> = ({ reloadCacheAndLoadIssues }) => {
    const handleClick = () => {
        reloadCacheAndLoadIssues();
    };

    return (
        <Button variant="contained" className="w-[40px] h-[40px] min-w-0 p-0" onClick={handleClick}>
            <Icon name={'refresh'} size={20} />
        </Button>
    );
};

export default Reset;

import { ITree } from '../../packages/Api/data/tree/types';

/**
 * Merge new loaded data from server to tree state
 * @param nodeId
 * @param nodesToMerge
 * @param object
 */
export const mergeNodesToTreeState = (nodeId: string, nodesToMerge: any, object: any): any => {
    if (Array.isArray(object)) {
        if (object.length === 0 && nodesToMerge.length > 0) {
            // first push
            return nodesToMerge;
        }
        return object.map(node => {
            if (node.id === nodeId) {
                // set parent node as loaded
                const updatedNode = { ...node, loaded: true };
                if (node.children === undefined) {
                    updatedNode.children = [];
                    updatedNode.children.push(...nodesToMerge);
                } else {
                    updatedNode.children = nodesToMerge; //mergeByProperty(node.children, nodesToMerge, "id");
                }
                return updatedNode;
            } else {
                if (node.children) {
                    const updatedChildren = node.children.map(child =>
                        mergeNodesToTreeState(nodeId, nodesToMerge, child),
                    );
                    return { ...node, children: updatedChildren };
                } else {
                    return node;
                }
            }
        });
    } else {
        if (object.id === nodeId) {
            // set parent node as loaded
            const updatedObject = { ...object, loaded: true };
            if (object.children === undefined) {
                updatedObject.children = [];
                updatedObject.children.push(...nodesToMerge);
            } else {
                updatedObject.children = nodesToMerge; // mergeByProperty(updatedObject.children, nodesToMerge, "id");
            }
            return updatedObject;
        } else {
            if (object.children) {
                const updatedChildren = object.children.map(child =>
                    mergeNodesToTreeState(nodeId, nodesToMerge, child),
                );
                return { ...object, children: updatedChildren };
            } else {
                return object;
            }
        }
    }
};

/**
 * Set new object property loaded to true after loading new node tree data
 * @param data
 */
export const setDataAsLoaded = (data: any): any => {
    if (Array.isArray(data)) {
        data.forEach(node => {
            if (node.children && node.children.length > 0) {
                node.children.map(setDataAsLoaded);
            }
        });
    } else {
        if (data.children && data.children.length > 0) {
            data.children.map(setDataAsLoaded);
        }
    }

    return data;
};

/**
 * Set new object property loaded to true after loading new node tree data
 * @param data
 * @param parentNode
 */
export const enrichFolderIds = (data: ITree | ITree[], parentNode?: ITree): any => {
    if (Array.isArray(data)) {
        data.forEach(node => {
            node.pathIds = parentNode?.pathIds ? [...parentNode.pathIds] : [];
            node.pathIds.push(node.id);
            if (node.children && node.children.length > 0) {
                node.children.map(item => enrichFolderIds(item, node));
            }
        });
    } else {
        data.pathIds = parentNode?.pathIds ? [...parentNode.pathIds] : [];
        data.pathIds.push(data.id);
        if (data.children && data.children.length > 0) {
            data.children.map(item => enrichFolderIds(item, data));
        }
    }

    return data;
};

/**
 * Merge two arrays of objects by property field without duplicities
 * @param array1
 * @param array2
 * @param property
 */
// const mergeByProperty = (array1: any, array2: any, property: string) =>
//     array1.filter(aa => !array2.find(bb => aa[property] === bb[property])).concat(array2);

/* tslint:disable */
/* eslint-disable */
/**
 * FireBIM API
 * API definitions for FireBim
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FsmExportRequest } from '../models';
// @ts-ignore
import { FsmScheduleRequest } from '../models';
// @ts-ignore
import { FsmScheduleResponse } from '../models';
// @ts-ignore
import { FsmTreeRequest } from '../models';
// @ts-ignore
import { FsmTreeResponse } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { ValidateSerialNumberRequest } from '../models';
// @ts-ignore
import { ValidateSerialNumberResponse } from '../models';
/**
 * FSMModuleApi - axios parameter creator
 * @export
 */
export const FSMModuleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Exports a FSM file.
         * @param {FsmExportRequest} [fsmExportRequest] Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fsmExportPost: async (fsmExportRequest?: FsmExportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Fsm/Export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fsmExportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Schedules FSM tree update and triggers design automation.
         * @param {FsmScheduleRequest} [fsmScheduleRequest] FSM schedule request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fsmSchedulePost: async (fsmScheduleRequest?: FsmScheduleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Fsm/Schedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fsmScheduleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets FSM tree data.
         * @param {FsmTreeRequest} [fsmTreeRequest] Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fsmTreePost: async (fsmTreeRequest?: FsmTreeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Fsm/Tree`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fsmTreeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validates serial number.
         * @param {ValidateSerialNumberRequest} [validateSerialNumberRequest] Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fsmValidateSerialNumberPost: async (validateSerialNumberRequest?: ValidateSerialNumberRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Fsm/ValidateSerialNumber`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validateSerialNumberRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FSMModuleApi - functional programming interface
 * @export
 */
export const FSMModuleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FSMModuleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Exports a FSM file.
         * @param {FsmExportRequest} [fsmExportRequest] Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fsmExportPost(fsmExportRequest?: FsmExportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fsmExportPost(fsmExportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Schedules FSM tree update and triggers design automation.
         * @param {FsmScheduleRequest} [fsmScheduleRequest] FSM schedule request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fsmSchedulePost(fsmScheduleRequest?: FsmScheduleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FsmScheduleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fsmSchedulePost(fsmScheduleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets FSM tree data.
         * @param {FsmTreeRequest} [fsmTreeRequest] Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fsmTreePost(fsmTreeRequest?: FsmTreeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FsmTreeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fsmTreePost(fsmTreeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Validates serial number.
         * @param {ValidateSerialNumberRequest} [validateSerialNumberRequest] Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fsmValidateSerialNumberPost(validateSerialNumberRequest?: ValidateSerialNumberRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateSerialNumberResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fsmValidateSerialNumberPost(validateSerialNumberRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FSMModuleApi - factory interface
 * @export
 */
export const FSMModuleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FSMModuleApiFp(configuration)
    return {
        /**
         * 
         * @summary Exports a FSM file.
         * @param {FsmExportRequest} [fsmExportRequest] Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fsmExportPost(fsmExportRequest?: FsmExportRequest, options?: any): AxiosPromise<File> {
            return localVarFp.fsmExportPost(fsmExportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Schedules FSM tree update and triggers design automation.
         * @param {FsmScheduleRequest} [fsmScheduleRequest] FSM schedule request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fsmSchedulePost(fsmScheduleRequest?: FsmScheduleRequest, options?: any): AxiosPromise<FsmScheduleResponse> {
            return localVarFp.fsmSchedulePost(fsmScheduleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets FSM tree data.
         * @param {FsmTreeRequest} [fsmTreeRequest] Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fsmTreePost(fsmTreeRequest?: FsmTreeRequest, options?: any): AxiosPromise<FsmTreeResponse> {
            return localVarFp.fsmTreePost(fsmTreeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validates serial number.
         * @param {ValidateSerialNumberRequest} [validateSerialNumberRequest] Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fsmValidateSerialNumberPost(validateSerialNumberRequest?: ValidateSerialNumberRequest, options?: any): AxiosPromise<ValidateSerialNumberResponse> {
            return localVarFp.fsmValidateSerialNumberPost(validateSerialNumberRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FSMModuleApi - object-oriented interface
 * @export
 * @class FSMModuleApi
 * @extends {BaseAPI}
 */
export class FSMModuleApi extends BaseAPI {
    /**
     * 
     * @summary Exports a FSM file.
     * @param {FsmExportRequest} [fsmExportRequest] Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FSMModuleApi
     */
    public fsmExportPost(fsmExportRequest?: FsmExportRequest, options?: AxiosRequestConfig) {
        return FSMModuleApiFp(this.configuration).fsmExportPost(fsmExportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Schedules FSM tree update and triggers design automation.
     * @param {FsmScheduleRequest} [fsmScheduleRequest] FSM schedule request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FSMModuleApi
     */
    public fsmSchedulePost(fsmScheduleRequest?: FsmScheduleRequest, options?: AxiosRequestConfig) {
        return FSMModuleApiFp(this.configuration).fsmSchedulePost(fsmScheduleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets FSM tree data.
     * @param {FsmTreeRequest} [fsmTreeRequest] Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FSMModuleApi
     */
    public fsmTreePost(fsmTreeRequest?: FsmTreeRequest, options?: AxiosRequestConfig) {
        return FSMModuleApiFp(this.configuration).fsmTreePost(fsmTreeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Validates serial number.
     * @param {ValidateSerialNumberRequest} [validateSerialNumberRequest] Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FSMModuleApi
     */
    public fsmValidateSerialNumberPost(validateSerialNumberRequest?: ValidateSerialNumberRequest, options?: AxiosRequestConfig) {
        return FSMModuleApiFp(this.configuration).fsmValidateSerialNumberPost(validateSerialNumberRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

import { ReactKeycloakProvider } from '@react-keycloak/web';
import Keycloak, { KeycloakProfile } from 'keycloak-js';
import React, { PropsWithChildren, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import config from '../../config/config';
import { createClient } from '../../packages/Api/clientFactory';
import { registerPuppetterTokenInfo } from '../../packages/Puppeteer';
import { setEvent, setToken } from './keycloakSlice';

const keycloakDefaultInitConfig: Keycloak.KeycloakInitOptions = {
    checkLoginIframe: false,
    // adapter: "cordova",
};

interface ISecurityProvider {
    keycloakInitConfig?: Keycloak.KeycloakInitOptions;
}

export interface KeycloakProfileConfig extends KeycloakProfile {
    attributes: {
        forgeID: string[];
    };
}

const SecurityProvider: React.FC<PropsWithChildren<ISecurityProvider>> = (
    props: PropsWithChildren<ISecurityProvider>,
) => {
    const { keycloakInitConfig, children } = props;
    const dispatch = useDispatch();

    const keycloak: Keycloak.KeycloakInstance = Keycloak({
        clientId: config.security.clientId,
        realm: config.security.realm,
        url: config.security.realmUrl,
    });

    useEffect(() => {
        registerPuppetterTokenInfo(
            createClient({
                baseURL: `${config.api.baseUrl}`,
            }),
            keycloak,
        );
    }, [keycloak]);

    return (
        <ReactKeycloakProvider
            authClient={keycloak}
            initOptions={keycloakInitConfig ? keycloakInitConfig : keycloakDefaultInitConfig}
            onEvent={event => dispatch(setEvent(event))}
            onTokens={tokens => {
                dispatch(setToken({ ...tokens }));
            }}
        >
            <>{children}</>
        </ReactKeycloakProvider>
    );
};

export default SecurityProvider;

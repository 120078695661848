import { DispatchAction } from '@iolabs/redux-utils';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import {
    Badge,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Popover,
    Tooltip,
} from '@mui/material';
import clsx from 'clsx';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DialogContext } from '../../dialogs/DialogProvider/DialogProvider';
import { ModelLockUser } from '../../generate/api';
import { filesApi } from '../../packages/Api/data/files/client';
import { useProfile } from '../../redux/keyclock';
import {
    hideGlobalOverlay,
    setGlobalOverlay,
    useLockedModelInfo,
    useProjectState,
} from '../../redux/project';
import { useTranslation } from '../../redux/translations/hook';
import { DF } from '../DialogFactory/DialogFactory';
import { INotificationDialogProps } from '../DialogFactory/NotificationDialog/NotificationDialog';
import { fetchUsersLockedInModelAndSaveToRedux } from '../Scanning/apiUtils';
import FormattedMessage from '../Translation/FormattedMessage';
import messages from './messages';
import useStyles from './styles';

const ConfirmRelease: React.FC<{
    setModalClose: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setModalClose }) => {
    const project = useProjectState();
    const forgeProjectId = project?.project?.id;
    const fileVersionUrn = project?.file?.currentVersion?.data?.urn;
    const viewableId = project?.file?.currentViewable?.viewableID;
    const dispatch = useDispatch<DispatchAction>();

    const { openDialog } = useContext(DialogContext);

    const [open, setOpen] = React.useState(false);

    // Translations
    const transMessageForceReleaseError = useTranslation({
        ...messages.messageForceReleaseError,
    });

    const transMessageForcefullyUnlockingElements = useTranslation({
        ...messages.messageForcefullyUnlockingElements,
    });

    const handleForceRelease = () => {
        if (!forgeProjectId || !fileVersionUrn || !viewableId) {
            console.log('Missing data', forgeProjectId, fileVersionUrn, viewableId);
            return;
        }

        setOpen(false);
        setModalClose(false);

        dispatch(
            setGlobalOverlay({
                show: true,
                text: transMessageForcefullyUnlockingElements,
            }),
        );

        filesApi
            .fileVersionUnlockElementsForcePost({
                projectID: forgeProjectId,
                fileVersionUrn: fileVersionUrn,
                viewableID: viewableId,
            })
            .then(() => {
                console.log('Force release success');
            })
            .catch(err => {
                console.log('Force release error', err);
                openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Error', {
                    content: transMessageForceReleaseError,
                });
                dispatch(hideGlobalOverlay());
            });
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button onClick={handleClickOpen} variant="outlined" color="primary">
                <FormattedMessage {...messages.buttonForceRelease} />
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{'Confirm force release'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <FormattedMessage {...messages.messageForceReleaseConfirmation} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="primary">
                        <FormattedMessage {...messages.buttonCancel} />
                    </Button>
                    <Button onClick={handleForceRelease} variant="outlined" color="secondary">
                        <FormattedMessage {...messages.buttonOk} />
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

const LockedModelInfo: React.FC = () => {
    const [locked, setLocked] = useState<boolean>(false);
    const [usersCount, setUsersCount] = useState<number>(0);
    const profile = useProfile();
    const project = useProjectState();

    const classes = useStyles();

    const lockedModelInfo = useLockedModelInfo();

    const currentViewableId = project?.file?.currentViewable?.viewableID;
    const currentUserId = profile?.id;
    const usersInModel = lockedModelInfo?.users;

    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

    const open = Boolean(anchorEl);
    const id = open ? 'locked-model-info' : undefined;

    const handleClickOpen = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        fetchUsersLockedInModelAndSaveToRedux();
    }, [open]);

    // is in all users any viewable match currentViewableId
    const isCurrentViewableLocked = () => {
        return !!usersInModel?.some(user => {
            return user.viewables?.some(viewable => {
                return viewable === currentViewableId;
            });
        });
    };

    const isCurrentViewableLockedByCurrentUser = () => {
        return !!usersInModel?.some(user => {
            return user.viewables?.some(viewable => {
                return viewable === currentViewableId && user.userID === currentUserId;
            });
        });
    };

    useEffect(() => {
        const isViewableLocked = isCurrentViewableLocked();
        setLocked(isViewableLocked);
        setUsersCount(usersInModel?.length ?? 0);
    }, [usersInModel]);

    return (
        <>
            <div className="flex items-center mx-2">
                <div
                    className="bg-gray-100 hover:bg-gray-200 rounded-md h-[34px] w-[34px] cursor-pointer"
                    onClick={handleClickOpen}
                >
                    <div className={classes.icon}>
                        {locked ? (
                            <Tooltip title={'Model locked'} arrow>
                                <Badge badgeContent={usersCount} color="primary">
                                    <LockIcon color="action" />
                                </Badge>
                            </Tooltip>
                        ) : (
                            <Tooltip title={'Model unlocked'} arrow>
                                <LockOpenIcon color="primary" />
                            </Tooltip>
                        )}
                    </div>
                </div>
            </div>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div className="p-5 min-w-[300px]">
                    <div className="text-lg font-bold mb-3">
                        <FormattedMessage {...messages.titleActiveLocks} />
                    </div>
                    <div>
                        {usersCount > 0 ? (
                            <table className={classes.table}>
                                <thead>
                                    <tr>
                                        <th className={classes.tableThCol} align={'left'}>
                                            <FormattedMessage {...messages.labelUser} />
                                        </th>
                                        <th className={classes.tableThCol} align={'right'}>
                                            <FormattedMessage {...messages.labelTimeLocked} />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {usersInModel?.map((user, index) => {
                                        const lockDate: Date | string = new Date(
                                            user.lockDate as string,
                                        );
                                        const date = moment(lockDate).format('YYYY-MM-DD HH:mm:ss');
                                        const utcLockDate = moment.utc(date).toDate();
                                        const localLockDate = moment(utcLockDate)
                                            .local()
                                            .format('hh:mm DD.MM.YYYY');

                                        return (
                                            <tr key={index}>
                                                <td className={clsx(classes.tableCol, 'text-sm')}>
                                                    <LockUserInfo
                                                        key={user.userID}
                                                        userFullName={user.userFullName}
                                                        userEmail={user.userEmail}
                                                    />
                                                </td>
                                                <td
                                                    className={clsx(
                                                        classes.tableCol,
                                                        classes.tableColDate,
                                                        'text-sm',
                                                    )}
                                                >
                                                    {localLockDate}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        ) : (
                            <DialogContentText id="alert-dialog-description">
                                <FormattedMessage {...messages.messageNoUsers} />
                            </DialogContentText>
                        )}
                    </div>
                    <div className="flex">
                        {usersCount > 0 && <ConfirmRelease setModalClose={handleClose} />}
                    </div>
                </div>
            </Popover>
        </>
    );
};

const LockUserInfo: React.FC<ModelLockUser> = ({ userFullName, userEmail }) => {
    const classes = useStyles();

    const [userNameHovering, setUserNameHovering] = React.useState(false);

    return (
        <div
            className="pr-2"
            onMouseOver={() => setUserNameHovering(true)}
            onMouseOut={() => setUserNameHovering(false)}
        >
            {userFullName}
            {userNameHovering && <div className={classes.hoverInfo}>{userEmail}</div>}
        </div>
    );
};

export default LockedModelInfo;

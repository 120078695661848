import { createStyles, makeStyles } from '@mui/styles';

export const minWidth = 200;
export const maxHeight = 270;

const useStyles = makeStyles(() =>
    createStyles({
        contextMenu: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 'auto',
            bottom: 'auto',
            minWidth: minWidth + 'px',
            maxHeight: maxHeight + 'px',
            overflowY: 'auto',
            zIndex: 100,
            background: '#fff',
            border: '1px solid #ddd',
            borderRadius: '5px',
            padding: '9px 12px',
            lineHeight: '21px',
        },
        inputCheck: {
            marginRight: '4px',
        },
    }),
);

export default useStyles;

import fileDownload from 'js-file-download';

import { IssueAttachmentItem } from '../../generate/api';
import { attachmentDownload } from '../../packages/Api/data/issues/client';
import { addNotification } from '../../redux/notifier';
import { store } from '../../redux/store';

export const handleAttachmentDownload = async ({
    attachment,
}: {
    attachment: IssueAttachmentItem;
}) => {
    return new Promise<void>(async (resolve, reject) => {
        const dispatch = store.dispatch;
        const project = store.getState().project;
        const issues = store.getState().issues;
        const keycloak = store.getState().keycloak;
        const token = keycloak?.token;
        const forgeProjectId = project.project?.id;
        const forgeIssueID = issues.currentIssue?.forgeIssueID;
        const forgeAttachmentID = attachment.issueAttachmentID;

        // const requestData: IssueAttachmentDownloadRequest = {
        //     forgeAttachmentID: attachment.issueAttachmentID,
        //     forgeIssueID: currentIssue?.forgeIssueID,
        //     forgeProjectId: project.project?.id,
        // };
        //
        // if (
        //     !requestData.forgeAttachmentID ||
        //     !requestData.forgeIssueID ||
        //     !requestData.forgeProjectId
        // ) {
        //     console.log(
        //         'Missing data',
        //         requestData.forgeAttachmentID,
        //         requestData.forgeIssueID,
        //         requestData.forgeProjectId,
        //     );
        //     return;
        // }
        //
        // issuesApi.issuesAttachmentsDownloadPost(requestData).then(response => {
        //     console.log('## Issues - Attachment download', response);
        //     fileDownload(response.data as unknown as File, attachment.name as string);
        // });

        if (!token || !forgeAttachmentID || !forgeIssueID || !forgeProjectId) {
            console.log('Missing data');
            reject('Missing data');
            return;
        }

        try {
            const response = await attachmentDownload({
                token: token,
                forgeProjectId: forgeProjectId,
                forgeIssueID: forgeIssueID,
                forgeAttachmentID: forgeAttachmentID,
            });

            console.log('## Issues - Attachment download', response);
            fileDownload(response as unknown as Blob, attachment.name as string);
            resolve();
        } catch (error) {
            dispatch(
                addNotification({
                    variant: 'error',
                    message: `Problem with downloading attachment: ${attachment.name}` /* todo translate */,
                }),
            );
            console.error('## Issues - Attachment download', error);
            reject(error);
        }
    });
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProjectData } from '../../components/ProjectSwitcher/types';
import { ITree } from '../../packages/Api/data/tree/types';
import { RootState } from '../store';
import {
    OnLoadDataDoneOptions,
    OnLoadDataFailOptions,
    OnLoadDataLoadingDoneOptions,
    OnLoadDataNodeDoneOptions,
    OnLoadDataNodeFailOptions,
    OnLoadDataOptions,
    OnLoadNodeOptions,
    SetActiveNodeIdOptions,
    SetActiveProjectOptions,
    SetActiveVersionOptions,
    SetExpandedNodesOptions,
    SetProjectsOptions,
    SetSelectedNodesOptions,
} from './types';

export interface ITreeState {
    plot: {
        data: ITree[];
        contentData?: ITree[] | undefined;
        depth: number;
        urn?: string;
        loading: boolean;
        activeNodeId: string;
        activeVersion?: ITree;
        expandedNodes: string[];
        selectedNodes: string[];
    };
    activeProject?: ProjectData | undefined;
    projects: ProjectData[];
}

export const initialState: Readonly<ITreeState> = {
    plot: {
        data: [],
        contentData: [],
        depth: 0,
        loading: false,
        activeNodeId: '',
        expandedNodes: [],
        selectedNodes: [],
    },
    projects: [],
};

export const treeSlice = createSlice({
    name: 'tree',
    initialState,
    reducers: {
        onLoadData: (state, action: PayloadAction<OnLoadDataOptions>) => {
            state.plot.loading = true;
        },

        onLoadDataDone: (state, action: PayloadAction<OnLoadDataDoneOptions>) => {
            state.plot.data = action.payload.tree;
            state.plot.loading = false;
        },

        onLoadDataFail: (state, action: PayloadAction<OnLoadDataFailOptions>) => {
            state.plot.loading = false;
        },

        onLoadNode: (state, action: PayloadAction<OnLoadNodeOptions>) => {
            state.plot.loading = true;
        },

        onLoadDataLoadingDone: (state, action: PayloadAction<OnLoadDataLoadingDoneOptions>) => {
            state.plot.loading = false;
        },

        onLoadNodeDone: (state, action: PayloadAction<OnLoadDataNodeDoneOptions>) => {
            state.plot.data = action.payload.tree;
            state.plot.loading = false;
        },

        onLoadNodeFail: (state, action: PayloadAction<OnLoadDataNodeFailOptions>) => {
            state.plot.loading = false;
        },

        onResetData: (state, action: PayloadAction) => {
            state.plot = initialState.plot;
        },

        setActiveNodeId: (state, action: PayloadAction<SetActiveNodeIdOptions>) => {
            state.plot.activeNodeId = action.payload.activeNodeId;
        },

        setActiveVersion: (state, action: PayloadAction<SetActiveVersionOptions>) => {
            state.plot.activeVersion = action.payload.version;
        },

        setExpandedNodes: (state, action: PayloadAction<SetExpandedNodesOptions>) => {
            state.plot.expandedNodes = action.payload.expandedNodes;
        },

        setSelectedNodes: (state, action: PayloadAction<SetSelectedNodesOptions>) => {
            state.plot.selectedNodes = action.payload.selectedNodes;
        },

        setActiveProject: (state, action: PayloadAction<SetActiveProjectOptions>) => {
            state.activeProject = action.payload.activeProject;
        },

        setProjects: (state, action: PayloadAction<SetProjectsOptions>) => {
            state.projects = action.payload.projects;
        },
    },
});

export const {
    onLoadData,
    onLoadDataDone,
    onLoadDataFail,
    onLoadNode,
    onLoadDataLoadingDone,
    onLoadNodeDone,
    onLoadNodeFail,
    onResetData,
    setActiveNodeId,
    setActiveVersion,
    setExpandedNodes,
    setSelectedNodes,
    setActiveProject,
    setProjects,
} = treeSlice.actions;

export default treeSlice.reducer;

// Selectors
// ----------------------------

// Plot
export const selectPlotData = (state: RootState): ITree[] => state.tree.plot?.data;
export const selectPlotLoading = (state: RootState): boolean => state.tree.plot?.loading;
export const selectPlotActiveNodeId = (state: RootState): string => state.tree.plot?.activeNodeId;
export const selectPlotActiveVersion = (state: RootState): ITree =>
    state.tree.plot?.activeVersion as ITree;
export const selectPlotExpandedNodes = (state: RootState): string[] =>
    state.tree.plot.expandedNodes;
export const selectPlotSelectedNodes = (state: RootState): string[] =>
    state.tree.plot.selectedNodes;

// Project
export const selectActiveProject = (state: RootState): ProjectData | undefined =>
    state.tree.activeProject;
export const selectProjects = (state: RootState): ProjectData[] => state.tree.projects;

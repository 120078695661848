import { AxiosResponse } from 'axios';

import config from '../../../../config/config';
import { Configuration, FSMModuleApi } from '../../../../generate/api';
import { store } from '../../../../redux/store';
import ApiClient from '../../ApiClient';

const fsmOldApi = new ApiClient('/Fsm', config.api.baseUrl);

export const fsmApi = new FSMModuleApi(
    new Configuration({
        basePath: config.api.baseUrl,
        accessToken: () => {
            return store.getState().keycloak.token as string;
        },
    }),
);

export const fsmExport = (data): Promise<any> => {
    ApiClient.setAuthorizationToken(store.getState().keycloak.token);

    return fsmOldApi
        .post(`/Export`, data, {
            responseType: 'blob',
        })
        .then((response: AxiosResponse<any>) => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            return Promise.reject(error);
        });
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck

import { flushSync } from 'react-dom';
import { createRoot } from 'react-dom/client';

export interface GeometryType {
    id: string;
    name: string;
    code: string;
    symbol: string;
    placementZ?: number;
}

export interface RealWorldCoordinates {
    imperial: THREE.Vector3;
    metric: THREE.Vector3;
}

export interface CreateEventDetails {
    geometry: GeometryType;
    worldCoords: THREE.Vector3;
    clientCoords: THREE.Vector2;
    realWorldCoords: THREE.Vector3;
    worldCoordsTargetReal: RealWorldCoordinates;
}

export interface RotateEventDetails {
    src: Autodesk.Viewing.PropertyResult;
    angle: number;
    rotateAxis: THREE.Vector3;
}

export interface DeleteEventDetails {
    src: Autodesk.Viewing.PropertyResult;
}

export interface MoveEventDetails {
    src: Autodesk.Viewing.PropertyResult;
    worldCoordsTarget: THREE.Vector3; // todo normalize with other events
    clientCoordsTarget: THREE.Vector2;
    isDragging: boolean;
    worldCoordsTargetReal: RealWorldCoordinates;
}

export interface CopyEventDetails {
    src: Autodesk.Viewing.PropertyResult;
    worldCoordsTarget: THREE.Vector3; // todo normalize with other events
    clientCoords: THREE.Vector2;
    worldCoordsTargetReal: RealWorldCoordinates;
}

/**
 * Get client (screen) coordinates from mouse and drag event
 * @param event
 * @return Vector2
 */
const getClientCoordsFromEvent = (
    event: React.MouseEvent<HTMLElement, MouseEvent> | React.DragEvent<HTMLElement>,
): THREE.Vector2 => {
    const clientRect = event?.currentTarget?.getBoundingClientRect();
    return {
        x: event?.clientX - clientRect?.left,
        y: event?.clientY - clientRect?.top,
    } as THREE.Vector2;
};

/**
 * Get metric coordinates from imperial coordinates
 * TODO: dynamic unit detection, not only ft to mm
 * @param imperialCoords
 */
export const toMetricCoords = (imperialCoords: THREE.Vector3): THREE.Vector3 => {
    return imperialCoords.clone().multiplyScalar(304.8); // 1ft = 304.8mm
};

/**
 * Get viewport from coordinates
 * @param viewer
 * @param x
 * @param y
 */
const getViewport: any = (viewer: Autodesk.Viewing.Viewer3D, x: number, y: number) => {
    const viewportExt = viewer.getExtension(
        'Autodesk.AEC.ViewportsExtension',
    ) as unknown as ViewportExtension;
    const viewport = viewportExt?.findViewportAtPoint(viewer.model, new THREE.Vector2(x, y));
    return viewport;
};

/**
 * Get viewport from model
 * @param viewer
 */
const getViewportFromModel: any = (viewer: Autodesk.Viewing.Viewer3D) => {
    const viewportExt = viewer.getExtension(
        'Autodesk.AEC.ViewportsExtension',
    ) as unknown as ViewportExtension;

    const viewports = Autodesk.AEC.AecModelData.findViewportsOnSheet(viewer.model);
    if (!viewports || viewports.length <= 0) {
        return null;
    }
    const viewport = Array.isArray(viewports) ? viewports[0] : viewports;
    return viewport;
};

/**
 * Get forge viewer world coordinates from real world coordinates
 * @param viewer
 * @param realWorldCoords
 * @param placementZ
 * @return RealWorldCoordinates
 */
export const getWorldCoordinatesFromReal = (
    viewer: Autodesk.Viewing.Viewer3D,
    realWorldCoords: THREE.Vector3,
    placementZ?: number,
): THREE.Vector3 => {
    const viewport = getViewportFromModel(viewer);

    if (!viewport) {
        return false;
    }

    const sheetUnitScale = viewer.model.getUnitScale();

    const sheetMatrix = Autodesk.AEC.AecModelData.get3DTo2DMatrix(viewport, sheetUnitScale).clone();
    // sheetMatrix.multiply(viewer3D.model.getInverseModelToViewerTransform());

    const sheetPos = realWorldCoords.clone().applyMatrix4(sheetMatrix);
    return sheetPos;
};

/**
 * Get real world coordinates from forge viewer world coordinates
 * @param viewer
 * @param worldCoords
 * @param placementZ
 * @return RealWorldCoordinates
 */
export const getRealWorldCoordinates = (
    viewer: Autodesk.Viewing.Viewer3D,
    worldCoords: THREE.Vector3,
    placementZ?: number,
): RealWorldCoordinates => {
    const viewport = getViewport(viewer, worldCoords?.x, worldCoords?.y);
    if (!viewport) {
        return false;
    }
    const sheetUnitScale = viewer.model.getUnitScale();
    const matrix = viewport?.get2DTo3DMatrix(sheetUnitScale);
    const worldPos = worldCoords.clone().applyMatrix4(matrix);

    // apply z index from placementZ
    worldPos.z = worldPos.z + (placementZ || 0) / sheetUnitScale / 304.8;
    return {
        imperial: worldPos,
        metric: toMetricCoords(worldPos),
    };
};

/**
 * Counting correct coordinates from mouse and drag event
 * @param viewerCoords
 */
const getWorldCoordsFromViewerCoords = (
    viewer: Autodesk.Viewing.Viewer3D,
    viewerCoords: THREE.Vector2,
): THREE.Vector3 => {
    let worldCoords = viewer.clientToWorld(viewerCoords.x, viewerCoords.y);
    if (worldCoords) {
        worldCoords = worldCoords.point;
    } else {
        worldCoords = viewer.impl.intersectGround(viewerCoords.x, viewerCoords.y);
    }
    return worldCoords;
};

function ToolbarExtension(viewer, options) {
    Autodesk.Viewing.Extension.call(this, viewer, options);
}

ToolbarExtension.prototype = Object.create(Autodesk.Viewing.Extension.prototype);
ToolbarExtension.prototype.constructor = ToolbarExtension;

ToolbarExtension.prototype.load = function () {
    // Ensure the model is centered
    //    this.viewer.fitToView();
    this.toolName = 'AdjustGeometryTool';
    this.activeTool = null;
    this.adjustTools = ['move', 'copy', 'rotate', 'delete'];
    return true;
};

ToolbarExtension.prototype.iconToString = function (iconPath: string) {
    const div = document.createElement('div');
    const root = createRoot(div);
    const promise = new Promise();

    flushSync(() => {
        root.render(`<Icon />`);
    });
    console.log(div.innerHTML);
};

ToolbarExtension.prototype.unload = function () {
    if (this.subToolbar) {
        this.viewer.toolbar.removeControl(this.subToolbar);
        this.subToolbar = null;
    }
};

ToolbarExtension.prototype.setCurrentBounds = function (bounds) {
    this.bounds = bounds;
};

ToolbarExtension.prototype.getCurrentBounds = function () {
    return this.bounds;
};

ToolbarExtension.prototype.setActiveElement = function (activeElement) {
    console.log('TE setting active element', activeElement);
    this.activeElement = activeElement;
    this.viewer.activeElement = activeElement;

    // switch controls based on active element
    if (activeElement) {
        this.attachElementControlButtons();
        this.detachElementCreateButton();
    } else {
        this.detachElementControlButtons();
        this.attachElementCreateButton();
        this.deactivateTools();
    }
};

//TODO: Refactor setting element incl. name
ToolbarExtension.prototype.setActiveElementName = function (activeElementName) {
    if (this.activeElement && this.viewer.activeElement) {
        this.activeElement.name = activeElementName;
        this.viewer.activeElement.name = activeElementName;
    }
};

ToolbarExtension.prototype.getActiveElement = function () {
    return this.activeElement;
};

ToolbarExtension.prototype.activateTool = function (activeTool) {
    this.activeTool = activeTool;
};

ToolbarExtension.prototype.deactiveTool = function () {
    this.activeTool = null;
};

let selectCirclesClicked = {};
const setSelectCirclesClicked = value => {
    selectCirclesClicked = value;
};

ToolbarExtension.prototype.fetchBounds = function () {
    const viewer = this.viewer;
    const selected = viewer.getSelection() || [];
    const dbId2fragId = viewer.model.getData().fragments.dbId2fragId;

    let fragIds = dbId2fragId[selected[0]];
    const dbId = selected[0];

    if (fragIds !== null && !Array.isArray(fragIds)) {
        fragIds = [fragIds];
    }
    if (fragIds) {
        fragIds?.map(fragId => {
            const mesh = viewer.model.getFragmentList().getVizmesh(fragId);
            const vbr = new Autodesk.Viewing.Private.VertexBufferReader(mesh.geometry);

            const bounds = new THREE.Box3();
            // move this next one up into the calling method
            const bc = new Autodesk.Viewing.Private.BoundsCallback(bounds);
            vbr.enumGeomsForObject(dbId, bc);

            if (selectCirclesClicked[dbId]) {
                return;
            }

            selectCirclesClicked[dbId] = true;
            setSelectCirclesClicked(selectCirclesClicked);
            ToolbarExtension.bounds = bounds;
        });
    }
};

ToolbarExtension.prototype.getTool = function (subToolName) {
    const tool = this.toolbar.getControl(this.toolName);
    return tool.getControl(subToolName);
};

ToolbarExtension.prototype.isToolActive = function (subToolName) {
    const tool = this.getTool(subToolName);
    if (!tool) {
        return false;
    }

    return tool.getState() === 0;
};

ToolbarExtension.prototype.isAdjustToolActive = function () {
    let hasActivatedTool = false;
    this.adjustTools.forEach(tool => {
        if (this.isToolActive(tool)) {
            hasActivatedTool = true;
        }
    });

    return hasActivatedTool;
};

ToolbarExtension.prototype.deactivateTools = function (skipTool: string = null) {
    const viewer = this.viewer;
    const ext = viewer.getExtension(ToolbarExtensionName);
    const allTools = ext.adjustTools;
    allTools.push('create');
    allTools.forEach(subToolName => {
        if (skipTool && subToolName === skipTool) {
            return;
        }
        const tool = ext.getTool(subToolName);
        if (tool && tool.getState() === 0) {
            tool.setState(1);
            tool.removeClass('ext-button--activated');
            if (viewer && !skipTool) {
                viewer.dispatchEvent(new Event('ADJUST_GEOMETRY_TOOL_DEACTIVATED'));
            }
        }
    });
};

ToolbarExtension.prototype.deleteActiveElement = function () {
    const viewer = this.viewer;
    const activeElementSelected = this.activeElement;

    //TODO: Add confirm dialog

    if (activeElementSelected) {
        viewer.dispatchEvent(
            new CustomEvent<DeleteEventDetails>('ADJUST_GEOMETRY_DELETED', {
                detail: {
                    src: activeElementSelected,
                },
            }),
        );
    }
};

ToolbarExtension.prototype.rotateActiveElement = function (angle) {
    const viewer = this.viewer;
    viewer.dispatchEvent(
        new CustomEvent<RotateEventDetails>('ADJUST_GEOMETRY_ROTATED', {
            detail: {
                angle: angle || 0,
                src: this.activeElement,
                rotateAxis: new THREE.Vector3(0, 0, 1),
            },
        }),
    );
};

ToolbarExtension.prototype.angleDegToRad = function (angle) {
    return angle * (Math.PI / 180);
};

ToolbarExtension.prototype.rotateActiveElementLeft = function () {
    this.rotateActiveElement(-90);
};

ToolbarExtension.prototype.rotateActiveElementRight = function () {
    this.rotateActiveElement(90);
};

ToolbarExtension.prototype.moveActiveElement = function (event: any, isDragging = false) {
    const viewer = this.viewer;
    const activeElementSelected = this.activeElement;
    const clientCoords = getClientCoordsFromEvent(event);
    const worldCoords = getWorldCoordsFromViewerCoords(viewer, clientCoords);

    const worldCoordsTargetReal = getRealWorldCoordinates(viewer, worldCoords);

    if (!worldCoordsTargetReal) {
        return;
    }

    viewer.dispatchEvent(
        new CustomEvent<MoveEventDetails>('ADJUST_GEOMETRY_MOVED', {
            detail: {
                worldCoordsTarget: worldCoords,
                clientCoordsTarget: clientCoords,
                src: activeElementSelected,
                isDragging: isDragging,
                worldCoordsTargetReal: worldCoordsTargetReal,
            },
        }),
    );
};

ToolbarExtension.prototype.copyActiveElement = function (event: any) {
    const viewer = this.viewer;
    const activeElementSelected = this.activeElement;
    const clientCoords = getClientCoordsFromEvent(event);
    const worldCoords = getWorldCoordsFromViewerCoords(viewer, clientCoords);
    const elevation =
        activeElementSelected?.properties?.find(
            p => p.displayName == 'Elevation from Level' && p.displayCategory == 'Constraints',
        )?.displayValue ?? 0;

    const realWorlCoords = getRealWorldCoordinates(viewer, worldCoords, elevation / 1000);

    viewer.dispatchEvent(
        new CustomEvent<CopyEventDetails>('ADJUST_GEOMETRY_COPIED', {
            detail: {
                worldCoordsTarget: worldCoords,
                clientCoords: clientCoords,
                src: activeElementSelected,
                worldCoordsTargetReal: realWorlCoords,
            },
        }),
    );
};

ToolbarExtension.prototype.createNewElement = function (event: any) {
    const viewer = this.viewer;
    const clientCoords = getClientCoordsFromEvent(event);
    const targetClientCoords = {
        x: clientCoords.x,
        y: clientCoords.y,
    };
    const targetWorldCoords = getWorldCoordsFromViewerCoords(viewer, targetClientCoords);

    viewer.dispatchEvent(
        new CustomEvent<CreateEventDetails>('ADJUST_GEOMETRY_CREATED', {
            detail: {
                clientCoords: targetClientCoords,
                worldCoords: targetWorldCoords,
                geometry: this.activeCreateTool,
                worldCoordsTargetReal: getRealWorldCoordinates(
                    viewer,
                    targetWorldCoords,
                    this.activeCreateTool.placementZ,
                ),
                sourceElement: this.activeElement,
            },
        }),
    );
};

ToolbarExtension.prototype.attachElementControlButtons = function () {
    const viewer = this.viewer;
    const moveButton = new Autodesk.Viewing.UI.Button('move');
    moveButton.onClick = function (e) {
        const ext = viewer.getExtension(ToolbarExtensionName);
        const moveTool = ext.getTool('move');
        ext.deactivateTools('move');
        if (moveTool.getState() !== 0) {
            moveTool.setState(0);
            moveTool.addClass('ext-button--activated');
            viewer?.dispatchEvent(
                new CustomEvent('ADJUST_GEOMETRY_TOOL_ACTIVATED', {
                    detail: {
                        toolCode: 'move',
                    },
                }),
            );
        } else {
            moveTool.setState(1);
            moveTool.removeClass('ext-button--activated');
            viewer?.dispatchEvent(new Event('ADJUST_GEOMETRY_TOOL_DEACTIVATED'));
        }
    };
    moveButton.addClass('ext-button--move');
    moveButton.setToolTip('Move');

    const rotateButton = new Autodesk.Viewing.UI.Button('rotate');
    rotateButton.onClick = function (e) {
        const ext = viewer.getExtension(ToolbarExtensionName);
        const rotateTool = ext.getTool('rotate');
        ext.deactivateTools('rotate');
        if (rotateTool.getState() !== 0) {
            rotateTool.setState(0);
            rotateTool.addClass('ext-button--activated');
            viewer?.dispatchEvent(
                new CustomEvent('ADJUST_GEOMETRY_TOOL_ACTIVATED', {
                    detail: {
                        toolCode: 'rotate',
                    },
                }),
            );
        } else {
            rotateTool.setState(1);
            rotateTool.removeClass('ext-button--activated');
            viewer?.dispatchEvent(new Event('ADJUST_GEOMETRY_TOOL_DEACTIVATED'));
        }
    };
    rotateButton.addClass('ext-button--rotate');
    rotateButton.setToolTip('Rotate');

    const copyButton = new Autodesk.Viewing.UI.Button('copy');
    copyButton.onClick = function (e) {
        const ext = viewer.getExtension(ToolbarExtensionName);
        const copyTool = ext.getTool('copy');
        ext.deactivateTools('copy');
        if (copyTool.getState() !== 0) {
            copyTool.setState(0);
            copyTool.addClass('ext-button--activated');
            viewer.dispatchEvent(
                new CustomEvent('ADJUST_GEOMETRY_TOOL_ACTIVATED', {
                    detail: {
                        toolCode: 'copy',
                    },
                }),
            );
        } else {
            copyTool.setState(1);
            copyTool.removeClass('ext-button--activated');
            if (viewer) {
                viewer.dispatchEvent(new Event('ADJUST_GEOMETRY_TOOL_DEACTIVATED'));
            }
        }
    };
    copyButton.addClass('ext-button--copy');
    copyButton.setToolTip('Copy');

    // todo - qrScanButton is not used
    const qrScanButton = new Autodesk.Viewing.UI.Button('qrscan');
    qrScanButton.onClick = function (e) {
        const activeElementSelected = this.activeElement;
        if (activeElementSelected) {
            viewer.dispatchEvent(
                new CustomEvent('ADJUST_GEOMETRY_SCAN_TRIGGERED', {
                    element: activeElementSelected,
                }),
            );
        }
    };
    qrScanButton.addClass('ext-button--qrscan');
    qrScanButton.setToolTip('Scan');

    const deleteButton = new Autodesk.Viewing.UI.Button('delete');
    deleteButton.onClick = function () {
        const ext = viewer.getExtension(ToolbarExtensionName);
        ext.deleteActiveElement();
    };
    deleteButton.addClass('ext-button--delete');
    deleteButton.setToolTip('Delete');

    this.subToolbar.addControl(deleteButton);
    this.subToolbar.addControl(moveButton);
    this.subToolbar.addControl(rotateButton);
    this.subToolbar.addControl(copyButton);
    // this.subToolbar.addControl(qrScanButton);
};

ToolbarExtension.prototype.detachElementControlButtons = function () {
    const subtoolbar = this.subToolbar;
    this.adjustTools.forEach(tool => {
        subtoolbar.removeControl(tool);
    });
};

ToolbarExtension.prototype.attachElementCreateButton = function () {
    if (this.supportedGeometriesToCreate?.length > 0) {
        const createButton = new Autodesk.Viewing.UI.ComboButton('create');
        const viewer = this.viewer;
        createButton.addClass('ext-button--create');
        createButton.setToolTip('Create');

        this.createButton = createButton;

        this.subToolbar.addControl(createButton);

        this.supportedGeometriesToCreate?.forEach(geometry => {
            const geometryButton = new Autodesk.Viewing.UI.Button(
                `ext-button--create--${geometry.code}`,
            );
            geometryButton.addClass(`ext-button--create--${geometry.code}`);
            geometryButton.setToolTip(geometry.name);
            geometryButton.icon.innerHTML = geometry.symbol;

            geometryButton.onClick = function (e) {
                const ext = viewer.getExtension(ToolbarExtensionName);
                const createTool = ext.getTool('create');
                const subCreateTool = createTool?.subMenu?.getControl(
                    `ext-button--create--${geometry.code}`,
                );
                if (subCreateTool.getState() !== 0) {
                    ext.activeCreateTool = geometry;
                    subCreateTool.setState(0);
                    subCreateTool.addClass('ext-button--activated');
                    viewer.dispatchEvent(
                        new CustomEvent('ADJUST_GEOMETRY_TOOL_ACTIVATED', {
                            detail: { toolCode: 'create', subtoolCode: geometry.code },
                        }),
                    );
                } else {
                    ext.activeCreateTool = undefined;
                    subCreateTool.setState(1);
                    subCreateTool.removeClass('ext-button--activated');
                    if (viewer) {
                        viewer.dispatchEvent(new Event('ADJUST_GEOMETRY_TOOL_DEACTIVATED'));
                    }
                }
            };

            createButton.addControl(geometryButton);

            this.createButtons?.push(geometryButton);
        });
    }
};

ToolbarExtension.prototype.detachElementCreateButton = function () {
    this.subToolbar.removeControl('create');
};

ToolbarExtension.prototype.atttachTestButton = function () {
    const testButton = new Autodesk.Viewing.UI.Button('test');
    const viewer = this.viewer;
    testButton.onClick = function (e) {
        const ext = viewer.getExtension(ToolbarExtensionName);
        ext.deactivateTools();
    };
    testButton.addClass('ext-button--deactivate-tools');
    testButton.setToolTip('Deactivate all tools');

    this.subToolbar.addControl(testButton);
};

// ToolbarExtension.prototype.supportedGeometriesToCreate : GeometryType[] | undefined = undefined;

ToolbarExtension.prototype.syncCreateMenuItems = function () {
    const createButton = this.createButton as Autodesk.Viewing.UI.ComboButton;
    this.createButtons?.forEach(cb => {
        createButton.removeControl(cb);
    });
    this.createButtons = [];

    this.supportedGeometriesToCreate?.forEach(geometry => {
        const geometryButton = new Autodesk.Viewing.UI.Button(
            `ext-button--create--${geometry.code}`,
        );
        geometryButton.addClass(`ext-button--create--${geometry.code}`);
        geometryButton.setToolTip(geometry.name);
        createButton.addControl(geometryButton);

        this.createButtons?.push(geometryButton);
    });
    // todo remove
    console.log('this.supportedGeometriesToCreate: ', this.supportedGeometriesToCreate);
    console.log('createButtons: ', this.createButtons);
    console.log('createButton: ', this.createButton);
};

ToolbarExtension.prototype.setCreateSubmenuItems = function (
    supportedSensors: GeometryType[] | undefined,
) {
    this.supportedGeometriesToCreate = supportedSensors;
    this.detachElementCreateButton();
    this.attachElementCreateButton();
};

ToolbarExtension.prototype.onToolbarCreated = function (toolbar) {
    this.toolbar = toolbar;

    // SubToolbar
    this.subToolbar = new Autodesk.Viewing.UI.ControlGroup(this.toolName);
    toolbar.addControl(this.subToolbar);

    if (!this.activeElement) {
        this.attachElementCreateButton();
    } else {
        this.attachElementControlButtons();
    }
};

const ToolbarExtensionName = 'AdjustGeometryExtension';

Autodesk.Viewing.theExtensionManager.registerExtension(ToolbarExtensionName, ToolbarExtension);

export interface ToolbarExtensionType {
    setCurrentBounds: (bounds: any) => void;
    getCurrentBounds: () => any;
    setActiveElement: (activeElement: any) => void;
    setActiveElementName: (name: string | undefined) => void;
    getActiveElement: () => any;
    gettoolCode: (toolName) => any;
    isToolActive: (toolName) => boolean;
    isAdjustToolActive: () => boolean;
    rotateActiveElementRight: () => void;
    rotateActiveElementLeft: () => void;
    moveActiveElement: (event: any, isDragging?: boolean) => void;
    copyActiveElement: (event: any) => void;
    createNewElement: (event: any) => void;
    attachElementControlButtons: () => void;
    detachElementControlButtons: () => void;
    attachElementCreateButton: () => void;
    detachElementCreateButton: () => void;
    activatetoolCode: (tool) => void;
    deactivateTools: (tool?: string) => void;
    setCreateSubmenuItems: (items: GeometryType[] | undefined) => void;
}

export interface TransformTool {
    toolCode: string;
    subtoolCode: string;
}

export default ToolbarExtensionName;

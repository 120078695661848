import { DispatchAction } from '@iolabs/redux-utils';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CloudIcon from '@mui/icons-material/Cloud';
import FolderIcon from '@mui/icons-material/Folder';
import HomeIcon from '@mui/icons-material/Home';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import TreeView from '@mui/lab/TreeView';
import { Box, Skeleton, useTheme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useKeycloak } from '@react-keycloak/web';
import React, { ChangeEvent, SyntheticEvent, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ITree, TreeDataType, TreeVariant } from '../../packages/Api/data/tree/types';
import { setCurrentVersion } from '../../redux/project';
import { IVersion } from '../../redux/project/types';
import {
    onLoadNode,
    setActiveNodeId,
    setActiveVersion,
    setExpandedNodes,
    usePlotActiveNodeId,
    usePlotExpandedNodes,
    usePlotSelectedNodes,
} from '../../redux/tree';
import Icon from '../Icon/Icon';
import StyledTreeItem from './StyledTreeItem';

const useStyles = makeStyles(() =>
    createStyles({
        treeViewBox: {
            height: '100%',
        },
        treeView: {
            minHeight: '100%',
            maxHeight: '100%',
            overflow: 'auto',
        },
        selected: {
            background: 'transparent',
        },
        moreMenu: {
            '& ul': {
                paddingTop: 0,
                paddingBottom: 0,
            },
        },
        itemSkeleton: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '0 0 0 10px',
        },
    }),
);

interface ITreeProps {
    variant: TreeVariant;
    treeData: any;
    treeLoading: boolean;
}

const Tree: React.FC<ITreeProps> = ({ variant, treeData, treeLoading }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { keycloak } = useKeycloak();
    const dispatch = useDispatch<DispatchAction>();
    const [nodeId, setNodeId] = useState<string>('');
    const [anchorMore, setAnchorMore] = useState<null | HTMLElement>(null);
    const openMore = Boolean(anchorMore);

    const plotActiveNodeId = usePlotActiveNodeId();
    const plotExpandedNodes = usePlotExpandedNodes();
    const plotSelectedNodes = usePlotSelectedNodes();

    const activeNodeId = plotActiveNodeId;
    const expanded = plotExpandedNodes;
    const selected = plotSelectedNodes;

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const handleClick = (
        event: ChangeEvent<NonNullable<unknown>>,
        item: ITree,
        // eslint-disable-next-line @typescript-eslint/no-shadow
        variant: TreeVariant,
    ) => {
        // set active node Id of tree item
        setNodeId(item.id);
        dispatch(setActiveNodeId({ variant, activeNodeId: item.id }));
    };

    const handleSetNodeId = (item: ITree) => {
        setNodeId(item.id);
    };

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const handleSetActiveNodeId = (item: ITree, variant: TreeVariant, version?: ITree) => {
        setNodeId(item.id);
        dispatch(setActiveNodeId({ variant, activeNodeId: item.id })); // tree slice
        dispatch(setActiveVersion({ version })); // tree slice
        dispatch(setCurrentVersion(version as IVersion)); // project slice
    };

    const handleNodeToggle = (event: SyntheticEvent<Element, Event>, nodeIds: string[]) => {
        // load children nodes on click on tree item arrow
        const difference = nodeIds.filter(x => !expanded.includes(x));
        if (difference && difference[0]) {
            setNodeId(difference[0]);
            dispatch(
                onLoadNode({
                    token: keycloak?.token as string,
                    variant,
                    nodeId: difference[0] as string,
                    depth: 1,
                    filter: {
                        item: {
                            // include: "*.pdf",
                            depth: 1,
                        },
                    },
                }),
            );
        }

        dispatch(setExpandedNodes({ variant, expandedNodes: nodeIds }));
    };

    const handleMoreOpen = (event: React.MouseEvent<HTMLElement>, item: ITree) => {
        setNodeId(item.id);
        setAnchorMore(event.currentTarget);
    };

    const handleMoreClose = () => {
        setAnchorMore(null);
    };

    const renderIcon = (item: ITree) => {
        switch (item.data.type) {
            case TreeDataType.HUB:
                return CloudIcon;
            case TreeDataType.PROJECT:
                return HomeIcon;
            case TreeDataType.FOLDER:
                return FolderIcon;
            case TreeDataType.ITEM:
                return InsertDriveFileIcon;
            case TreeDataType.VERSION:
                return AccessTimeIcon;
            default:
                return FolderIcon;
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const loading = (variant: TreeVariant, item: ITree) => {
        if (variant === TreeVariant.PLOT && treeLoading && item?.data?.type === TreeDataType.ITEM) {
            return;
        }

        if (item.id === nodeId && treeLoading && item.data.type !== TreeDataType.VERSION) {
            return (
                <Box className={classes.itemSkeleton}>
                    <Skeleton variant="text" width="90%" height={32} />
                    <Skeleton variant="text" width="90%" height={32} />
                </Box>
            );
        } else {
            return renderItems(variant, item);
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const renderItems = (variant: TreeVariant, treeItem: ITree | ITree[]) => {
        const children: ITree[] = Array.isArray(treeItem)
            ? treeItem
            : (treeItem.children as ITree[]);
        const subfolderCount: number = !Array.isArray(treeItem)
            ? (treeItem?.data?.subfoldersCount as number)
            : 0;
        const folder: ITree | null =
            (treeItem as ITree)?.data?.type === TreeDataType.FOLDER ? (treeItem as ITree) : null;

        // disable render all versions in tree item in input variant
        if (variant === TreeVariant.PLOT && (treeItem as ITree)?.data?.type === TreeDataType.ITEM) {
            return;
        }

        if (children && children.length > 0) {
            return children.map(item => {
                // hide versions in input and references variant
                // show only folders in output variant
                if (variant === TreeVariant.PLOT && item.data.type === TreeDataType.VERSION) {
                    return null;
                } else {
                    return renderItem(variant, item, folder);
                }
            });
        } else if (!children && subfolderCount > 0 && !Array.isArray(treeItem)) {
            if (variant === TreeVariant.PLOT && treeItem.data.type === TreeDataType.FOLDER) {
                return <></>;
            } else {
                return null;
            }
        } else if (!children) {
            return null;
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const renderItem = (variant: TreeVariant, item: ITree, folder: ITree | null) => {
        const isActive = activeNodeId === item?.id;

        return (
            <StyledTreeItem
                variant={variant}
                key={item.id}
                nodeId={item.id}
                labelText={item.text}
                labelIcon={renderIcon(item)}
                isActive={isActive}
                item={item}
                folder={folder}
                handleClick={handleClick}
                handleSetNodeId={handleSetNodeId}
                handleSetActiveNodeId={handleSetActiveNodeId}
                handleMoreOpen={handleMoreOpen}
                handleMoreClose={handleMoreClose}
                anchorMore={anchorMore}
                openMore={openMore}
                selected={selected}
            >
                {loading(variant, item)}
            </StyledTreeItem>
        );
    };

    return (
        <Box className={classes.treeViewBox}>
            <TreeView
                className={classes.treeView}
                defaultCollapseIcon={
                    <Icon name="chevron-light-down" size={10} fill={theme.palette.grey['700']} />
                }
                defaultExpandIcon={
                    <Icon name="chevron-light-right" size={10} fill={theme.palette.grey['700']} />
                }
                expanded={expanded}
                selected={selected}
                onNodeToggle={handleNodeToggle}
            >
                {renderItems(variant, treeData)}
            </TreeView>
        </Box>
    );
};

export default Tree;

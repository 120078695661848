import { isPlatform } from '@ionic/react';
import { CircularProgress, Tooltip } from '@mui/material';
import React, { useState } from 'react';

import { IssueAttachmentItem } from '../../../generate/api';
import theme from '../../../theme/Theme';
import Icon from '../../Icon/Icon';
import { handleAttachmentDownload } from '../attachmentDownload';

interface IEditorAttachmentRowProps {
    name: string;
    newAttachment?: boolean;
    guid?: string;
    onDelete?: (guid: string) => void;
    attachment?: IssueAttachmentItem;
}

const EditorAttachmentRow: React.FC<IEditorAttachmentRowProps> = ({
    name,
    newAttachment,
    guid,
    onDelete,
    attachment,
}) => {
    const isPlatformCapacitor = isPlatform('capacitor');
    const handleClickDelete = () => {
        if (guid && onDelete) {
            onDelete(guid);
        }
    };
    const [loading, setLoading] = useState<boolean>(false);

    const handleClickDownload = () => {
        if (!attachment) {
            return;
        }

        setLoading(true);
        handleAttachmentDownload({
            attachment: attachment,
        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        <div>
            <div className="flex justify-between border-t border-gray-200 py-1 gap-2">
                <Tooltip title={name} arrow>
                    <div className="overflow-hidden whitespace-nowrap overflow-ellipsis text-sm">
                        {name}
                    </div>
                </Tooltip>
                <div className="flex items-center">
                    {newAttachment && (
                        <div>
                            <span className="inline uppercase bg-blue-500 mr-2 text-white text-[10px] rounded px-1 py-[2px] leading-none">
                                new
                            </span>
                        </div>
                    )}

                    {!isPlatformCapacitor && !newAttachment && (
                        <div onClick={handleClickDownload}>
                            {loading ? (
                                <CircularProgress size={15} />
                            ) : (
                                <Icon
                                    name="cloud-download"
                                    size={20}
                                    fill={theme.palette.primary.main}
                                    className="cursor-pointer"
                                />
                            )}
                        </div>
                    )}

                    {newAttachment && (
                        <div onClick={handleClickDelete} className="flex items-center">
                            <Icon
                                name="trash-alt"
                                size={16}
                                fill={theme.palette.primary.main}
                                className="cursor-pointer ml-1"
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default EditorAttachmentRow;

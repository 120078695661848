import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { KeycloakProfile } from 'keycloak-js';

import { RootState } from '../store';
import { OnEventOptions, OnProfileOptions, OnTokensOptions } from './types';

export interface IKeycloakState {
    idToken: string;
    refreshToken: string;
    token: string;
    event: unknown | null;
    profile?: KeycloakProfile;
}

const initialState: Readonly<IKeycloakState> = {
    idToken: '',
    refreshToken: '',
    token: '',
    event: null,
};

export const keycloakSlice = createSlice({
    name: 'keycloak',
    initialState,

    reducers: {
        setEvent: (state, action: PayloadAction<unknown>) => {
            state.event = action.payload;
        },

        setToken: (state, action: PayloadAction<Partial<OnTokensOptions>>) => {
            state.idToken = action.payload.idToken as string;
            state.refreshToken = action.payload.refreshToken as string;
            state.token = action.payload.token as string;
        },

        setProfile: (state, action: PayloadAction<OnProfileOptions>) => {
            state.profile = action.payload.profile;
        },
    },
});

export const { setEvent, setToken, setProfile } = keycloakSlice.actions;

export const selectIdToken = (state: RootState): IKeycloakState['idToken'] =>
    state.keycloak.idToken;
export const selectRefreshToken = (state: RootState): IKeycloakState['refreshToken'] =>
    state.keycloak.refreshToken;
export const selectToken = (state: RootState): IKeycloakState['token'] => state.keycloak.token;
export const selectEvent = (state: RootState): IKeycloakState['event'] => state.keycloak.event;
export const selectProfile = (state: RootState): IKeycloakState['profile'] =>
    state.keycloak.profile;

export default keycloakSlice.reducer;

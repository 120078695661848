import { DispatchAction } from '@iolabs/redux-utils';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, ClickAwayListener, Link, Tooltip } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import clsx from 'clsx';
import { isArray, isFunction } from 'lodash';
import { ClassNameMap } from 'notistack';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocalStorage, useReadLocalStorage, useWindowSize } from 'usehooks-ts';

import { DialogContext } from '../../dialogs/DialogProvider/DialogProvider';
import {
    AssetListRequest,
    AssetListResponse,
    AssetReponseItem,
    FilterValue,
    PostedCustomAttributeFilterValue,
} from '../../generate/api';
import { filtersData } from '../../packages/Api/data/assets/client';
import {
    onAssetsList,
    onFiltersDone as onAssetsFiltersDone,
    useAssetsActiveFilters,
    useAssetsAttributeListLoading,
    useAssetsState,
} from '../../redux/assets';
import {
    setAssetsSettings,
    setSelectedAsset,
    useAssetsSettings,
    useProjectState,
    useSelectedAsset,
    useViewableElements,
} from '../../redux/project';
import { ISortOrder } from '../../redux/project/types';
import { useTranslation } from '../../redux/translations/hook';
import ContextMenu, { type IItemDef } from '../ContextMenu/ContextMenu';
import { DF } from '../DialogFactory/DialogFactory';
import { INotificationDialogProps } from '../DialogFactory/NotificationDialog/NotificationDialog';
import Icon from '../Icon/Icon';
import FormattedMessage from '../Translation/FormattedMessage';
import { createTranslationKey } from '../Translation/utils';
import CommentEditor from './CommentEditor';
import messages from './messages';
import { StatusSelection } from './StatusSelection';
import useStyles from './styles';

// todo - clear all selected assets if some change in filter
// todo - clear filters after close assets

export interface IAssets {
    modelLoaded?: boolean;
    viewable?: any;
    scanningOpen: boolean;
}

export interface IAssetRowProps {
    assets: AssetListResponse;
    asset: AssetReponseItem;
    statuses: FilterValue[];
    forgeFileVersionUrn: string;
    forgeProjectId: string;
    scanningOpen: boolean;
    tableRef: any;
}

interface IAssetColumnDef {
    code: string;
    name?: string | ((assets: any) => string);
    visible?: boolean;
    getter: (assetRow: IAssetRowProps, classes: ClassNameMap<string>) => any;
}

//Value type for useLocalStorage hook
type Value<T> = T | null;

const highlightClass = 'bg-blue-100';

/**
 * Define custom attribute column
 * @param code
 */
const cac = (code: string): IAssetColumnDef => {
    return {
        code: code,
        visible: true,
        name: assets =>
            assets?.customAttributeNames?.find(ca => ca.propertyName === code)?.displayName,
        getter: assetRow => {
            const changed = assetRow.asset.customAttributes?.find(ca => ca.attributeCode === code)
                ?.isValueModified as boolean;

            return (
                <td className={clsx(changed && highlightClass)}>
                    {assetRow.asset.customAttributes?.find(ca => ca.attributeCode === code)?.value}
                </td>
            );
        },
    };
};

const stopPropagation = event => {
    event.stopPropagation();
};

const columnsDefinitions: Array<IAssetColumnDef> = [
    cac('areaCustomerText'),
    cac('sectionCustomerText'),
    cac('zoneCustomerText'),
    cac('channelAddressDetNo'),
    cac('zonePlanNo'),
    {
        code: 'status',
        name: 'Status',
        visible: true,

        getter: assetRow => {
            const changed = assetRow.asset.customAttributes?.find(
                ca => ca.attributeCode === 'status',
            )?.isValueModified as boolean;

            return (
                <td className={clsx(changed && highlightClass)}>
                    <Box onClick={stopPropagation}>
                        <StatusSelection statuses={assetRow.statuses} asset={assetRow.asset} />
                    </Box>
                </td>
            );
        },
    },
    cac('deviceSerialNo'),
    cac('line'),
    {
        code: 'name',
        name: 'Name',
        visible: true,
        getter: assetRow => <td>{assetRow.asset.assetName}</td>,
    },
    cac('areaAddress'),
    cac('sectionAddress'),
    cac('zoneAddress'),
    cac('sectionPlanNo'),
    cac('deviceElementId'),
    {
        code: 'comment',
        name: 'Comment',
        visible: true,

        getter: assetRow => {
            const changed = assetRow.asset.customAttributes?.find(
                ca => ca.attributeCode === 'comment',
            )?.isValueModified as boolean;

            return (
                <td className={clsx(changed && highlightClass)}>
                    <Box onClick={stopPropagation}>
                        <CommentEditor asset={assetRow.asset} />
                    </Box>
                </td>
            );
        },
    },
    {
        code: 'bim360',
        name: 'BIM360',
        visible: true,
        getter: (assetRow, classes) => (
            <td>
                {assetRow.asset.forgeAssetLink ? (
                    <Link
                        color={'primary'}
                        href={assetRow.asset.forgeAssetLink}
                        target="_blank"
                        onClick={stopPropagation}
                    >
                        BIM 360
                    </Link>
                ) : (
                    <span className={classes.linkDisabled}>BIM 360</span>
                )}
            </td>
        ),
    },
];

const hasColumn = (columns: string[], columnCode: string) => {
    if (columns.length > 0) {
        return columns.includes(columnCode);
    } else {
        return true;
    }
};

const AssetRow: React.FC<IAssetRowProps> = ({
    assets,
    asset,
    statuses,
    forgeFileVersionUrn,
    forgeProjectId,
    scanningOpen,
    tableRef,
}) => {
    const dispatch = useDispatch<DispatchAction>();
    const selectedAsset = useSelectedAsset();
    const assetsSettings = useAssetsSettings();
    const activeColumns: string[] = useReadLocalStorage('activeColumns') as unknown as string[];

    const revitId = asset?.elementID as string;

    const isChecked = useCallback(() => selectedAsset === revitId, [selectedAsset, revitId]);
    const checked = isChecked();

    // Scroll to selected row if scanning open
    const ignoreScroll = useRef(false); // use for disable scroll to row - if selection is from table
    const rowRef = useRef(null);
    const scrollToRef = rowRefEl => {
        // tableRef.current.scrollTop = rowRef.current.offsetTop - 30;
        tableRef.current.scrollTo({ top: rowRefEl.current.offsetTop - 30, behavior: 'smooth' });
    };
    useEffect(() => {
        if (checked && !ignoreScroll.current) {
            scrollToRef(rowRef);
        }
        if (ignoreScroll.current) {
            ignoreScroll.current = false;
        }
    }, [checked]);

    const handleChange = () => {
        if (!assetsSettings.disableChangeSelection) {
            if (!isChecked()) {
                ignoreScroll.current = true;
                dispatch(setSelectedAsset(revitId));
            } else {
                dispatch(setSelectedAsset(undefined));
            }
        }
        if (!isChecked) {
            dispatch(setSelectedAsset(undefined));
        }
    };

    const classes = useStyles();

    return (
        <tr
            className={clsx(classes.assetRow, checked && classes.assetRowActive)}
            onClick={handleChange}
            ref={rowRef}
        >
            <td>
                <input type="checkbox" checked={checked} onChange={handleChange} />
            </td>
            {columnsDefinitions
                .filter(column => hasColumn(activeColumns, column.code))
                .map(column => (
                    <React.Fragment key={`data-col-${column.code}`}>
                        {column.getter(
                            {
                                assets,
                                asset,
                                statuses,
                                forgeFileVersionUrn,
                                forgeProjectId,
                                scanningOpen,
                                tableRef,
                            },
                            classes,
                        )}
                    </React.Fragment>
                ))}
        </tr>
    );
};

const TranslatedLabel: React.FC<{ columnName?: string }> = ({ columnName }) => {
    const translation = columnName
        ? useTranslation({
              id: createTranslationKey('assets.label', columnName),
              defaultMessage: columnName,
          })
        : '';

    return <>{translation}</>;
};

const SortArrow: React.FC<{ sortColumn: string }> = ({ sortColumn }) => {
    const classes = useStyles();
    const assetsSettings = useAssetsSettings();

    if (assetsSettings.sortColumn != sortColumn) return <></>;

    return assetsSettings.sortOrder === 'asc' ? (
        <Icon name={'chevron-light-up'} size={10} className={classes.thSortOrderIcon} />
    ) : (
        <Icon name={'chevron-light-down'} size={10} className={classes.thSortOrderIcon} />
    );
};

const Assets: React.FC<IAssets> = ({ modelLoaded, viewable, scanningOpen }) => {
    const classes = useStyles();

    const assets = useAssetsState()?.assets?.assetsList;
    const loading = useAssetsAttributeListLoading();

    const [pageNumber, setPageNumber] = useState<number>(0);
    const [columnsSettingsActive, setColumnsSettingsActive] = useState<boolean>(false);

    const dispatch = useDispatch<DispatchAction>();

    const project = useProjectState();

    const forgeHubId = project?.hubId;
    const forgeProjectId = project?.project?.id;
    const fileVersionUrn = project?.file?.currentVersion?.data?.urn;

    const assetFilters = useAssetsActiveFilters();

    const tableRef = useRef(null);
    const theadRef = useRef<HTMLTableSectionElement>(null);
    const [activeColumns, setActiveColumns] = useLocalStorage(
        'activeColumns',
        [] as unknown as string[],
    );
    const [activeColumnsUserId, setActiveColumnsUserId] = useLocalStorage(
        'activeColumnsUserId',
        '',
    );
    const [userColumnsSettings, setUserColumnsSettings] = useLocalStorage(
        'userColumnsSettings',
        {},
    );

    const assetsSettings = useAssetsSettings();
    const [pageLimitListOpen, setPageLimitListOpen] = useState<boolean>(false);

    const { keycloak, initialized: keycloakInitialized } = useKeycloak();
    const viewableElements = useViewableElements();

    const { openDialog } = useContext(DialogContext);

    // revitIds for filtering in current view
    const [revitIds, setRevitIds] = useState<string[]>([]);
    useEffect(() => {
        const revitIdsArray: string[] = [];

        viewableElements?.map(element => {
            revitIdsArray.push(element.externalId);
        });

        setRevitIds(revitIdsArray);
    }, [viewableElements]);

    useEffect(() => {
        const usersColumnsSettingsStore = localStorage.getItem('userColumnsSettings') || '{}';

        //Load and store user specific columns settings
        Promise.resolve(keycloak?.loadUserProfile()).then(data => {
            if (!data.id) return;

            const userColumnsSettingsJsonInitial = JSON.parse(usersColumnsSettingsStore);
            const activeColumnsAll = userColumnsSettingsJsonInitial[data.id] || {};
            const activeColumnsAssets = activeColumnsAll.assets || [];

            columnsDefinitions.map(column => {
                column.visible = hasColumn(activeColumnsAssets, column.code);
            });

            setActiveColumns(activeColumnsAssets);
            setActiveColumnsUserId(data.id);
        });
    }, [keycloak]);

    // Load statuses for change Status
    const [statuses, setStatuses] = useState<FilterValue[]>([]);
    useEffect(() => {
        if (keycloak?.token && fileVersionUrn) {
            // assetsApi
            // .assetFiltersGet({ forgeFileVersionUrn: fileVersionUrn })
            filtersData(keycloak.token, fileVersionUrn)
                .then(response => {
                    // const filterStatuses = response.data.filtersDefinitons?.find(
                    const filterStatuses = response.filtersDefinitons?.find(
                        f => f.parameterName === 'statuses',
                    )?.values;
                    if (filterStatuses) {
                        setStatuses(filterStatuses);
                    }
                    console.log('## Asset filters loaded');

                    // Save filters to redux
                    dispatch(
                        onAssetsFiltersDone({
                            filters: response,
                        }),
                    );
                })
                .catch(error => {
                    openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Error', {
                        content: 'Unable to load filters',
                    });
                    console.error('Assets - Unable to load filters', error);
                });
        }
    }, [keycloak?.token, fileVersionUrn]);

    useEffect(() => {
        setPageNumber(0);
    }, [
        forgeHubId,
        forgeProjectId,
        fileVersionUrn,
        assetFilters,
        viewableElements,
        modelLoaded,
        viewable,
        assetsSettings,
    ]);

    // Set assets
    const updateAssetList = () => {
        if (
            keycloak &&
            keycloak.token &&
            forgeHubId &&
            forgeProjectId &&
            fileVersionUrn &&
            modelLoaded &&
            revitIds.length > 0 &&
            assetsSettings
        ) {
            const data = {
                forgeHubId: forgeHubId,
                forgeProjectId: forgeProjectId,
                fileVersionUrn: fileVersionUrn,
                offset: pageNumber,
                limit: assetsSettings.pageLimit,
                sortColumn: assetsSettings.sortColumn,
                sortDirection: assetsSettings.sortOrder,
                viewableElementRevitIds: revitIds,
                customAttributes: Object.keys(assetFilters)?.map(cak => {
                    return {
                        parameterName: cak,
                        type: 'text',
                        operand: isArray(assetFilters[cak]) ? 'contains' : 'eq',
                        value: assetFilters[cak] /*[0]*/,
                    };
                }) as PostedCustomAttributeFilterValue,
            } as AssetListRequest;

            // Fetch assets by redux middleware
            dispatch(
                onAssetsList({
                    ...data,
                }),
            );
        }
    };
    useEffect(() => {
        updateAssetList();
    }, [
        keycloak,
        keycloakInitialized,
        pageNumber,
        forgeHubId,
        forgeProjectId,
        fileVersionUrn,
        assetFilters,
        revitIds,
        assetsSettings,
    ]);

    const navMaxPage = assets?.filteredRecordCount
        ? Math.ceil(assets.filteredRecordCount / assetsSettings.pageLimit)
        : 0;

    const navActualPage = assets?.offset ? assets.offset + 1 : 1;
    const navAllItemsCount = assets?.filteredRecordCount ?? 0;
    const navItemsFromCount =
        assetsSettings.pageLimit * ((assets?.offset ?? 0) + 1) - assetsSettings.pageLimit + 1;
    const navItemsToCount =
        assetsSettings.pageLimit * ((assets?.offset ?? 0) + 1) -
        assetsSettings.pageLimit +
        (assets?.pagedRecordCount ?? 0);

    const handleFirst = () => {
        setPageNumber(0);
    };
    const handlePrev = () => {
        setPageNumber(pageNumber - 1);
    };
    const handleNext = () => {
        setPageNumber(pageNumber + 1);
    };
    const handleLast = () => {
        setPageNumber(navMaxPage - 1);
    };

    const handleSetPageLimit = (limit: number) => {
        dispatch(setAssetsSettings({ ...assetsSettings, pageLimit: limit }));
        setPageLimitListOpen(false);
    };
    const handleOpenPageLimitList = () => {
        setPageLimitListOpen(!pageLimitListOpen);
    };

    const handleOrderBy = (propertyName: string) => {
        if (propertyName) {
            const sortColumn = propertyName;
            let order: ISortOrder = assetsSettings.sortOrder;
            if (assetsSettings.sortColumn != sortColumn) {
                order = 'asc';
            }
            if (assetsSettings.sortColumn === sortColumn) {
                if (order === 'desc') {
                    order = 'asc';
                } else {
                    order = 'desc';
                }
            }
            dispatch(
                setAssetsSettings({ ...assetsSettings, sortColumn: sortColumn, sortOrder: order }),
            );
        }
    };

    const handleItemsChange = (items: IItemDef[] | undefined) => {
        const columnsSetActive: string[] = [];
        items?.forEach(item => {
            if (item.visible) columnsSetActive.push(item.code);
        });

        setActiveColumns(columnsSetActive);

        const userColumnsSettingsStore = localStorage.getItem('userColumnsSettings') || '{}';
        const userColumnsSettingsJson = JSON.parse(userColumnsSettingsStore);
        if (!userColumnsSettingsJson[activeColumnsUserId]) {
            userColumnsSettingsJson[activeColumnsUserId] = {};
        }
        userColumnsSettingsJson[activeColumnsUserId].assets = columnsSetActive;
        setUserColumnsSettings(userColumnsSettingsJson);
    };

    const ToggleColumnsSettingRef = useRef<HTMLElement>(null);
    const [ContextMenuStyles, setContextMenuStyles] = useState<React.CSSProperties | null>(null);
    const winSize = useWindowSize();
    const handleToggleColumnsSetting = () => {
        setColumnsSettingsActive(!columnsSettingsActive);

        const togglerRect = ToggleColumnsSettingRef.current?.getBoundingClientRect();
        let posRight, posBottom;
        if (togglerRect) {
            posRight = winSize.width - togglerRect.right;
            posBottom = winSize.height - togglerRect.top + 6;
            if (winSize.width < 480) {
                posBottom = posBottom - 10;
            }
        }

        const stylesOverride: React.CSSProperties = {
            bottom: posBottom + 'px',
            right: posRight + 'px',
            left: 'auto',
            top: 'auto',
            maxHeight: '60vh',
            borderBottomRightRadius: 0,
            border: '1px solid black',
            width: '240px',
            zIndex: 100,
        };

        setContextMenuStyles(stylesOverride);
    };

    const tHeader = (columnDef: IAssetColumnDef) => {
        const name = isFunction(columnDef.name) ? columnDef.name(assets) : columnDef.name;

        return (
            <th
                key={`head-${columnDef.code}`}
                onClick={() => handleOrderBy(columnDef.code)}
                className={clsx(
                    { [classes.theadThActive]: assetsSettings.sortColumn === columnDef.code },
                    classes.theadThHover,
                )}
            >
                <SortArrow sortColumn={columnDef.code} />
                <TranslatedLabel columnName={name} />
            </th>
        );
    };

    const ContextMenuTitle = (columnDef: IAssetColumnDef): string => {
        const name = isFunction(columnDef.name) ? columnDef.name(assets) : columnDef.name;
        return name || columnDef.code;
    };

    return (
        <Box className={classes.root}>
            {loading && (
                <Box className={classes.loading}>
                    <Box>
                        <FormattedMessage {...messages.tableLoading} />
                        ...
                    </Box>
                </Box>
            )}
            {!loading && navAllItemsCount === 0 && (
                <Box className={classes.loading}>
                    <Box>
                        <FormattedMessage {...messages.tableNoResults} />
                    </Box>
                </Box>
            )}
            {!loading && navAllItemsCount > 0 && (
                <div className={classes.tableWrapper} ref={tableRef}>
                    <Box className={classes.tableWrapperInner}>
                        <table className={classes.table}>
                            <thead className={classes.theadWrapper} ref={theadRef}>
                                <tr className={classes.thead}>
                                    <th></th>
                                    {columnsDefinitions
                                        .filter(column => hasColumn(activeColumns, column.code))
                                        .map(column => tHeader(column))}
                                </tr>
                            </thead>
                            <tbody>
                                {assets?.assets?.map((asset, index) => {
                                    if (fileVersionUrn && forgeProjectId) {
                                        return (
                                            <AssetRow
                                                key={index}
                                                assets={assets}
                                                asset={asset}
                                                statuses={statuses}
                                                forgeFileVersionUrn={fileVersionUrn}
                                                forgeProjectId={forgeProjectId}
                                                scanningOpen={scanningOpen}
                                                tableRef={tableRef}
                                            />
                                        );
                                    }
                                })}
                            </tbody>
                        </table>
                    </Box>
                </div>
            )}

            <Box className={classes.navigation}>
                <Box className={classes.navigationInner}>
                    <Box>
                        {navItemsFromCount} - {navItemsToCount} / {navAllItemsCount}
                    </Box>

                    <Box display={'flex'}>
                        <Box className={classes.pageLimitBox} position={'relative'} mr={5}>
                            <Box onClick={handleOpenPageLimitList} className={classes.pageLimit}>
                                <FormattedMessage {...messages.tableLinesPerPage} />:{' '}
                                {assetsSettings.pageLimit}{' '}
                                <Icon name={'chevron-light-down'} size={10} />
                            </Box>
                            {pageLimitListOpen && (
                                <ClickAwayListener onClickAway={() => setPageLimitListOpen(false)}>
                                    <Box className={classes.pageLimitList}>
                                        <Box onClick={() => handleSetPageLimit(10)}>10</Box>
                                        <Box onClick={() => handleSetPageLimit(20)}>20</Box>
                                        <Box onClick={() => handleSetPageLimit(50)}>50</Box>
                                        <Box onClick={() => handleSetPageLimit(100)}>100</Box>
                                    </Box>
                                </ClickAwayListener>
                            )}
                        </Box>
                        <Box
                            onClick={handleFirst}
                            className={clsx(
                                classes.navButton,
                                assets?.offset
                                    ? assets?.offset <= 0 && classes.navButtonDisabled
                                    : classes.navButtonDisabled,
                            )}
                        >
                            <Icon name={'nav-arrow-first'} size={20} />
                        </Box>
                        <Box
                            onClick={handlePrev}
                            className={clsx(
                                classes.navButton,
                                assets?.offset
                                    ? assets?.offset <= 0 && classes.navButtonDisabled
                                    : classes.navButtonDisabled,
                            )}
                        >
                            <Icon name={'nav-arrow-prev'} size={20} />
                        </Box>
                        {navActualPage ? navActualPage : 0} of {navMaxPage ? navMaxPage : 0}
                        <Box
                            onClick={handleNext}
                            className={clsx(
                                classes.navButton,
                                !assets?.hasNext && classes.navButtonDisabled,
                            )}
                        >
                            <Icon name={'nav-arrow-next'} size={20} />
                        </Box>
                        <Box
                            onClick={handleLast}
                            className={clsx(
                                classes.navButton,
                                !assets?.hasNext && classes.navButtonDisabled,
                            )}
                        >
                            <Icon name={'nav-arrow-last'} size={20} />
                        </Box>
                        <Box
                            onClick={handleToggleColumnsSetting}
                            ref={ToggleColumnsSettingRef}
                            className={clsx([
                                classes.toggleColumnsSettings,
                                columnsSettingsActive ? 'active' : '',
                            ])}
                        >
                            <Box className="columns-settings-icon">
                                <SettingsIcon fontSize="small" />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <ContextMenu
                targetElement={theadRef}
                items={columnsDefinitions as IItemDef[]}
                itemTemplate={ContextMenuTitle}
                displayed={columnsSettingsActive}
                stylesOverride={ContextMenuStyles}
                disableRightClick={true}
                onSetItems={handleItemsChange}
            />
        </Box>
    );
};

export default Assets;

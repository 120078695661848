import { ScheduleModelOperationRequest } from '../../generate/api';
import TwoWayMap from '../../utils/TwoWayMap';
import { ISelection } from '../Viewer/Viewer';

export interface IScanningProps {
    viewer: any; // todo set as Autodesk.Viewing.Viewer3D
    modelLoaded: boolean;
    viewable: any;
    viewableElements: any; // todo ts
    singleSelection?: ISelection;
    externalIdMap?: TwoWayMap<string, number>;
    adjustGeometryEnabled?: boolean;
}

export interface IAttributeTable {
    editMode?: boolean;
    setEditedAttributes: any; // todo ts
    viewer: Autodesk.Viewing.GuiViewer3D;
    externalIdMap?: TwoWayMap<string, number>;
}

export interface IAttributeMapItemOption {
    value?: string;
    title?: string;
    icon?: string;
}

export interface IAttributesMapItem {
    displayValue: string;
    attributeName: IAttributesNames;
    editable?: boolean;
    newValue?: string;
    value?: string;
    fieldType?: string;
    isBatched?: boolean;
    options?: IAttributeMapItemOption[];
    isForceRewrite?: boolean;
}

export interface ISerialNumberValidationProps {
    isValidating: boolean;
    isValid?: boolean;
    isUnique?: boolean;
    serialNumber?: string;
    validationMessage?: string;
    duplicateElementName?: string;
    overwriteConfirmed?: boolean;
    scheduleModelOperationRequest?: ScheduleModelOperationRequest;
}

export type IAttributesMap = IAttributesMapItem[];

export enum IAttributesNames {
    areaCustomerText = 'SI_BMA_Area customer text',
    areaAddress = 'SI_BMA_Area address',
    channelAddress = 'SI_BMA_Channel address (Det. No.)',
    sectionAddress = 'SI_BMA_Section address',
    sectionCustomerText = 'SI_BMA_Section customer text',
    sectionPlanNumber = 'SI_BMA_Section plan no.',
    zoneAddress = 'SI_BMA_Zone address',
    zoneCustomerText = 'SI_BMA_Zone customer text',
    zoneType = 'SI_BMA_Zone type',
    zonePlanNumber = 'SI_BMA_Zone plan no.',
    serialNumber = 'SI_BMA_Device serial no.',
    assetImage = 'SI_BMA_Asset image',
    transform = 'SI_BMA_Transform',
    sensorCode = 'sensorCode',
}

export enum IRunningLockingState {
    none = 'none',
    running = 'running',
    locked = 'locked',
    failed = 'failed',
}

import { DispatchAction } from '@iolabs/redux-utils';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import FileSearchBar from '../../components/FilesExplorer/FileSearchBar';
import FilesExplorer from '../../components/FilesExplorer/FilesExplorer';
import HeaderSimple from '../../components/HeaderSimple/HeaderSimple';
import FormattedMessage from '../../components/Translation/FormattedMessage';
import TreeWrapper from '../../components/Tree/TreeWrapper';
import { ProjectListItem, RecentFilesResponse, SearchResultFile } from '../../generate/api';
import { filesApi } from '../../packages/Api/data/files/client';
import { projectApi } from '../../packages/Api/data/projects/client';
import { TreeVariant } from '../../packages/Api/data/tree/types';
import { setCurrentVersion, setProject, useProjectState } from '../../redux/project';
import { IProject, IVersion } from '../../redux/project/types';
import { onResetData, usePlotActiveNodeId } from '../../redux/tree';
import { Path } from '../PageRouter';
import messages from './messages';
import style from './projectSetup.module.css';
import SingleLayout from './SingleLayout';
import useStyles from './styles';

export interface IProjectSetup {
    sidebarMode?: boolean;
}

export enum DisplayMode {
    Search,
    Recent,
}

const ProjectSetup: React.FC<IProjectSetup> = ({ sidebarMode }) => {
    const classes = useStyles();

    const dispatch = useDispatch<DispatchAction>();

    const { keycloak, initialized: keycloakInitialized } = useKeycloak();

    const history = useHistory();

    const [projects, setProjects] = useState<ProjectListItem[]>([]);
    const [selectedProject, setSelectedProject] = useState('');
    const [activeProject, setActiveProject] = useState<{ id: string }>();
    const [loaded, setLoaded] = useState<boolean>(false);

    const activeData = usePlotActiveNodeId();
    const project = useProjectState();

    const [displayMode, setDisplayMode] = useState<DisplayMode>(
        sidebarMode ? DisplayMode.Search : DisplayMode.Recent,
    );
    const [recentFiles, setRecentFiles] = useState<RecentFilesResponse>();
    const [searchFilesResult, setSearchFilesResult] = useState<SearchResultFile[]>();

    // Set projects
    useEffect(() => {
        setLoaded(false);
        if (keycloak && keycloak.token && project?.hubId) {
            projectApi
                .projectListGet(project.hubId)
                .then(response => {
                    const projectsResponseData = response.data.projects;

                    // sort projects
                    function compare(a, b) {
                        if (a.forgeProjectName < b.forgeProjectName) {
                            return -1;
                        }
                        if (a.forgeProjectName > b.forgeProjectName) {
                            return 1;
                        }
                        return 0;
                    }

                    projectsResponseData?.sort(compare);
                    setProjects(projectsResponseData ?? []);
                })
                .catch(error => {
                    console.error('FB: Unable to load project list + response', error);
                })
                .finally(() => {
                    setLoaded(true);
                });
        }
    }, [keycloak, keycloakInitialized]);

    // Set project
    useEffect(() => {
        dispatch(onResetData()); // reset tree data on change project

        if (!selectedProject) return;

        setActiveProject({
            id: btoa(
                JSON.stringify({
                    Type: 'project',
                    NodeId: selectedProject,
                    ParentId: project.hubId,
                    RootId: null,
                }),
            ),
        });
    }, [selectedProject]);

    const handleSetProject = event => {
        setSelectedProject(event.target.value as string);
    };

    // Redirect to viewer and set project from state to redux
    useEffect(() => {
        if (activeData) {
            const parsedActiveData = JSON.parse(atob(activeData));
            if (parsedActiveData.Type === 'item') {
                const filteredProject = projects.find(x => x.forgeProjectID === selectedProject);
                if (filteredProject) {
                    dispatch(
                        setProject({
                            type: filteredProject?.forgeProjectType as string,
                            id: filteredProject?.forgeProjectID as string,
                            name: filteredProject?.forgeProjectName as string,
                            isAssetSyncSupported: filteredProject?.isAssetSyncSupported as boolean,
                        }),
                    );
                }

                history.push(Path.VIEWER);
            }
        }
    }, [activeData]);

    // Recent files fetch
    useEffect(() => {
        setSearchFilesResult(undefined);
        if (displayMode == DisplayMode.Recent) {
            filesApi
                .fileFileHistoryListGet()
                .then(response => {
                    setRecentFiles(response.data);
                })
                .catch(error => {
                    console.error('Unable to load recent files', error);
                });
        }
    }, [displayMode]);

    const fileSelected = (selectedFile: SearchResultFile) => {
        if (
            selectedFile &&
            selectedFile.forgeVersionURN &&
            selectedFile.versionNumber &&
            selectedFile.storageURN &&
            selectedFile.fileName &&
            selectedFile.projectName &&
            selectedFile.forgeProjectID &&
            selectedFile.extensionType
        ) {
            const currentVersion: IVersion = {
                id: '',
                text: selectedFile.fileName,
                data: {
                    urn: selectedFile.forgeVersionURN,
                    version: selectedFile.versionNumber,
                    storageId: selectedFile.storageURN,
                    createdTime: '',
                    createdUsername: '',
                    fileSize: 0,
                    fileType: 'rvt',
                    lastModifiedTime: '',
                    lastModifiedUsername: '',
                    type: 'version',
                },
            };

            const currentProject: IProject = {
                type: 'Bim360',
                id: selectedFile.forgeProjectID,
                name: selectedFile.projectName,
                isAssetSyncSupported: true,
            };

            dispatch(setCurrentVersion(currentVersion as IVersion)); // current version slice
            dispatch(setProject(currentProject)); // project slice

            // redirect to viewer
            history.push(Path.VIEWER);
        }
    };

    const onSearchResult = (files: SearchResultFile[]) => {
        setSearchFilesResult(files);
    };

    const onSearchClear = () => {
        setSearchFilesResult(undefined);
    };

    const Content = (
        <div className="flex flex-col h-full">
            {!sidebarMode && <HeaderSimple />}
            <div className="flex-1 max-h-full overflow-auto">
                <Box className={style.root}>
                    {!sidebarMode && (
                        <div className={style.partTabs}>
                            <Button
                                variant={
                                    displayMode == DisplayMode.Recent ? 'contained' : 'outlined'
                                }
                                color={displayMode == DisplayMode.Recent ? 'primary' : 'secondary'}
                                className={classes.button}
                                onClick={() => setDisplayMode(DisplayMode.Recent)}
                            >
                                <h4 className="m-0 text-lg">
                                    <FormattedMessage {...messages.recentFiles} />
                                </h4>
                            </Button>
                            <Button
                                variant={
                                    displayMode == DisplayMode.Search ? 'contained' : 'outlined'
                                }
                                color={displayMode == DisplayMode.Search ? 'primary' : 'secondary'}
                                className={classes.button}
                                onClick={() => setDisplayMode(DisplayMode.Search)}
                            >
                                <h4 className="m-0 text-lg">
                                    <FormattedMessage {...messages.selectFile} />
                                </h4>
                            </Button>
                        </div>
                    )}

                    {displayMode == DisplayMode.Search && (
                        <FileSearchBar
                            onSearchResult={onSearchResult}
                            onSearchClear={onSearchClear}
                        />
                    )}
                    {displayMode == DisplayMode.Recent && (
                        <div className={style.partMain}>
                            <FilesExplorer
                                files={recentFiles?.recentFiles}
                                onFileSelected={fileSelected}
                                isSearch={false}
                            />
                        </div>
                    )}
                    {displayMode == DisplayMode.Search && searchFilesResult && (
                        <div className={style.partMain}>
                            <FilesExplorer
                                files={searchFilesResult}
                                onFileSelected={fileSelected}
                                isSearch={true}
                            />
                        </div>
                    )}
                    {displayMode == DisplayMode.Search && !loaded && (
                        <div className="pt-2">
                            <Typography className={classes.alert}>
                                <FormattedMessage {...messages.loadingProjects} />
                                ...
                            </Typography>
                        </div>
                    )}
                    {displayMode == DisplayMode.Search &&
                        !searchFilesResult &&
                        loaded &&
                        projects?.length > 0 && (
                            <>
                                <FormControl fullWidth variant="filled" style={{ minWidth: 120 }}>
                                    <InputLabel id="label-project">
                                        <FormattedMessage {...messages.project} />
                                    </InputLabel>
                                    <Select
                                        labelId="label-project"
                                        id="select-project"
                                        value={selectedProject}
                                        onChange={handleSetProject}
                                        className={classes.select}
                                    >
                                        {projects.map((projectItem, index) => {
                                            return (
                                                <MenuItem
                                                    value={String(projectItem.forgeProjectID)}
                                                    key={index}
                                                    className="text-sm"
                                                    style={{
                                                        whiteSpace: 'normal',
                                                        wordBreak: 'break-all',
                                                    }}
                                                >
                                                    {projectItem.forgeProjectName}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                                <div className={style.partMain}>
                                    <Box className={classes.fileBox}>
                                        {activeProject && selectedProject && (
                                            <TreeWrapper
                                                variant={TreeVariant.PLOT}
                                                activeProject={activeProject}
                                            />
                                        )}
                                    </Box>
                                </div>
                            </>
                        )}
                    {displayMode == DisplayMode.Search && loaded && projects?.length === 0 && (
                        <div className="pt-2">
                            <Typography className={classes.alert}>No projects available</Typography>
                        </div>
                    )}
                </Box>
            </div>
        </div>
    );

    if (sidebarMode) {
        return Content;
    } else return <SingleLayout>{Content}</SingleLayout>;
};

export default ProjectSetup;

/* tslint:disable */
/* eslint-disable */
/**
 * FireBIM API
 * API definitions for FireBim
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateMarkupRequest } from '../models';
// @ts-ignore
import { FileStream } from '../models';
// @ts-ignore
import { MarkupContentResponse } from '../models';
// @ts-ignore
import { MarkupListRequest } from '../models';
// @ts-ignore
import { MarkupListResponse } from '../models';
// @ts-ignore
import { MarkupResponse } from '../models';
// @ts-ignore
import { MarkupThumbnailRequest } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { UpdateMarkupRequest } from '../models';
/**
 * ForgeMarkupsApi - axios parameter creator
 * @export
 */
export const ForgeMarkupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets markup list tied to object ID.
         * @param {MarkupListRequest} [markupListRequest] Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markupsAllPost: async (markupListRequest?: MarkupListRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Markups/All`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(markupListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates the markup.
         * @param {CreateMarkupRequest} [createMarkupRequest] The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markupsCreatePost: async (createMarkupRequest?: CreateMarkupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Markups/Create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMarkupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uploads the content of the markup.
         * @param {number} objectMarkupId Object markup ID
         * @param {string} [markupContent] Markup content in SVG format.
         * @param {File} [backgroundImage] Background image of where the markup was created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markupsObjectMarkupIdAttachContentPost: async (objectMarkupId: number, markupContent?: string, backgroundImage?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectMarkupId' is not null or undefined
            assertParamExists('markupsObjectMarkupIdAttachContentPost', 'objectMarkupId', objectMarkupId)
            const localVarPath = `/Markups/{objectMarkupId}/AttachContent`
                .replace(`{${"objectMarkupId"}}`, encodeURIComponent(String(objectMarkupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


            if (markupContent !== undefined) { 
                localVarFormParams.append('MarkupContent', markupContent as any);
            }
    
            if (backgroundImage !== undefined) { 
                localVarFormParams.append('BackgroundImage', backgroundImage as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the content of the markup.
         * @param {number} objectMarkupId Object markup ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markupsObjectMarkupIdContentGet: async (objectMarkupId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectMarkupId' is not null or undefined
            assertParamExists('markupsObjectMarkupIdContentGet', 'objectMarkupId', objectMarkupId)
            const localVarPath = `/Markups/{objectMarkupId}/Content`
                .replace(`{${"objectMarkupId"}}`, encodeURIComponent(String(objectMarkupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes the markup.
         * @param {number} objectMarkupId Object markup ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markupsObjectMarkupIdDeleteDelete: async (objectMarkupId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectMarkupId' is not null or undefined
            assertParamExists('markupsObjectMarkupIdDeleteDelete', 'objectMarkupId', objectMarkupId)
            const localVarPath = `/Markups/{objectMarkupId}/Delete`
                .replace(`{${"objectMarkupId"}}`, encodeURIComponent(String(objectMarkupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the markup thumbnail.
         * @param {number} objectMarkupId Object markup ID
         * @param {MarkupThumbnailRequest} [markupThumbnailRequest] Thumbnail request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markupsObjectMarkupIdThumbnailPost: async (objectMarkupId: number, markupThumbnailRequest?: MarkupThumbnailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectMarkupId' is not null or undefined
            assertParamExists('markupsObjectMarkupIdThumbnailPost', 'objectMarkupId', objectMarkupId)
            const localVarPath = `/Markups/{objectMarkupId}/Thumbnail`
                .replace(`{${"objectMarkupId"}}`, encodeURIComponent(String(objectMarkupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(markupThumbnailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the markup.
         * @param {number} objectMarkupId Object markup ID
         * @param {UpdateMarkupRequest} [updateMarkupRequest] The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markupsObjectMarkupIdUpdatePatch: async (objectMarkupId: number, updateMarkupRequest?: UpdateMarkupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectMarkupId' is not null or undefined
            assertParamExists('markupsObjectMarkupIdUpdatePatch', 'objectMarkupId', objectMarkupId)
            const localVarPath = `/Markups/{objectMarkupId}/Update`
                .replace(`{${"objectMarkupId"}}`, encodeURIComponent(String(objectMarkupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMarkupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForgeMarkupsApi - functional programming interface
 * @export
 */
export const ForgeMarkupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForgeMarkupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets markup list tied to object ID.
         * @param {MarkupListRequest} [markupListRequest] Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markupsAllPost(markupListRequest?: MarkupListRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarkupListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markupsAllPost(markupListRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates the markup.
         * @param {CreateMarkupRequest} [createMarkupRequest] The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markupsCreatePost(createMarkupRequest?: CreateMarkupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarkupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markupsCreatePost(createMarkupRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uploads the content of the markup.
         * @param {number} objectMarkupId Object markup ID
         * @param {string} [markupContent] Markup content in SVG format.
         * @param {File} [backgroundImage] Background image of where the markup was created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markupsObjectMarkupIdAttachContentPost(objectMarkupId: number, markupContent?: string, backgroundImage?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarkupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markupsObjectMarkupIdAttachContentPost(objectMarkupId, markupContent, backgroundImage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the content of the markup.
         * @param {number} objectMarkupId Object markup ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markupsObjectMarkupIdContentGet(objectMarkupId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarkupContentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markupsObjectMarkupIdContentGet(objectMarkupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes the markup.
         * @param {number} objectMarkupId Object markup ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markupsObjectMarkupIdDeleteDelete(objectMarkupId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markupsObjectMarkupIdDeleteDelete(objectMarkupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the markup thumbnail.
         * @param {number} objectMarkupId Object markup ID
         * @param {MarkupThumbnailRequest} [markupThumbnailRequest] Thumbnail request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markupsObjectMarkupIdThumbnailPost(objectMarkupId: number, markupThumbnailRequest?: MarkupThumbnailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileStream>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markupsObjectMarkupIdThumbnailPost(objectMarkupId, markupThumbnailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the markup.
         * @param {number} objectMarkupId Object markup ID
         * @param {UpdateMarkupRequest} [updateMarkupRequest] The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markupsObjectMarkupIdUpdatePatch(objectMarkupId: number, updateMarkupRequest?: UpdateMarkupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarkupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markupsObjectMarkupIdUpdatePatch(objectMarkupId, updateMarkupRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForgeMarkupsApi - factory interface
 * @export
 */
export const ForgeMarkupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForgeMarkupsApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets markup list tied to object ID.
         * @param {MarkupListRequest} [markupListRequest] Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markupsAllPost(markupListRequest?: MarkupListRequest, options?: any): AxiosPromise<MarkupListResponse> {
            return localVarFp.markupsAllPost(markupListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates the markup.
         * @param {CreateMarkupRequest} [createMarkupRequest] The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markupsCreatePost(createMarkupRequest?: CreateMarkupRequest, options?: any): AxiosPromise<MarkupResponse> {
            return localVarFp.markupsCreatePost(createMarkupRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uploads the content of the markup.
         * @param {number} objectMarkupId Object markup ID
         * @param {string} [markupContent] Markup content in SVG format.
         * @param {File} [backgroundImage] Background image of where the markup was created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markupsObjectMarkupIdAttachContentPost(objectMarkupId: number, markupContent?: string, backgroundImage?: File, options?: any): AxiosPromise<MarkupResponse> {
            return localVarFp.markupsObjectMarkupIdAttachContentPost(objectMarkupId, markupContent, backgroundImage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the content of the markup.
         * @param {number} objectMarkupId Object markup ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markupsObjectMarkupIdContentGet(objectMarkupId: number, options?: any): AxiosPromise<MarkupContentResponse> {
            return localVarFp.markupsObjectMarkupIdContentGet(objectMarkupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes the markup.
         * @param {number} objectMarkupId Object markup ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markupsObjectMarkupIdDeleteDelete(objectMarkupId: number, options?: any): AxiosPromise<void> {
            return localVarFp.markupsObjectMarkupIdDeleteDelete(objectMarkupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the markup thumbnail.
         * @param {number} objectMarkupId Object markup ID
         * @param {MarkupThumbnailRequest} [markupThumbnailRequest] Thumbnail request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markupsObjectMarkupIdThumbnailPost(objectMarkupId: number, markupThumbnailRequest?: MarkupThumbnailRequest, options?: any): AxiosPromise<FileStream> {
            return localVarFp.markupsObjectMarkupIdThumbnailPost(objectMarkupId, markupThumbnailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the markup.
         * @param {number} objectMarkupId Object markup ID
         * @param {UpdateMarkupRequest} [updateMarkupRequest] The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markupsObjectMarkupIdUpdatePatch(objectMarkupId: number, updateMarkupRequest?: UpdateMarkupRequest, options?: any): AxiosPromise<MarkupResponse> {
            return localVarFp.markupsObjectMarkupIdUpdatePatch(objectMarkupId, updateMarkupRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ForgeMarkupsApi - object-oriented interface
 * @export
 * @class ForgeMarkupsApi
 * @extends {BaseAPI}
 */
export class ForgeMarkupsApi extends BaseAPI {
    /**
     * 
     * @summary Gets markup list tied to object ID.
     * @param {MarkupListRequest} [markupListRequest] Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForgeMarkupsApi
     */
    public markupsAllPost(markupListRequest?: MarkupListRequest, options?: AxiosRequestConfig) {
        return ForgeMarkupsApiFp(this.configuration).markupsAllPost(markupListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates the markup.
     * @param {CreateMarkupRequest} [createMarkupRequest] The request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForgeMarkupsApi
     */
    public markupsCreatePost(createMarkupRequest?: CreateMarkupRequest, options?: AxiosRequestConfig) {
        return ForgeMarkupsApiFp(this.configuration).markupsCreatePost(createMarkupRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uploads the content of the markup.
     * @param {number} objectMarkupId Object markup ID
     * @param {string} [markupContent] Markup content in SVG format.
     * @param {File} [backgroundImage] Background image of where the markup was created.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForgeMarkupsApi
     */
    public markupsObjectMarkupIdAttachContentPost(objectMarkupId: number, markupContent?: string, backgroundImage?: File, options?: AxiosRequestConfig) {
        return ForgeMarkupsApiFp(this.configuration).markupsObjectMarkupIdAttachContentPost(objectMarkupId, markupContent, backgroundImage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the content of the markup.
     * @param {number} objectMarkupId Object markup ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForgeMarkupsApi
     */
    public markupsObjectMarkupIdContentGet(objectMarkupId: number, options?: AxiosRequestConfig) {
        return ForgeMarkupsApiFp(this.configuration).markupsObjectMarkupIdContentGet(objectMarkupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes the markup.
     * @param {number} objectMarkupId Object markup ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForgeMarkupsApi
     */
    public markupsObjectMarkupIdDeleteDelete(objectMarkupId: number, options?: AxiosRequestConfig) {
        return ForgeMarkupsApiFp(this.configuration).markupsObjectMarkupIdDeleteDelete(objectMarkupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the markup thumbnail.
     * @param {number} objectMarkupId Object markup ID
     * @param {MarkupThumbnailRequest} [markupThumbnailRequest] Thumbnail request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForgeMarkupsApi
     */
    public markupsObjectMarkupIdThumbnailPost(objectMarkupId: number, markupThumbnailRequest?: MarkupThumbnailRequest, options?: AxiosRequestConfig) {
        return ForgeMarkupsApiFp(this.configuration).markupsObjectMarkupIdThumbnailPost(objectMarkupId, markupThumbnailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the markup.
     * @param {number} objectMarkupId Object markup ID
     * @param {UpdateMarkupRequest} [updateMarkupRequest] The request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForgeMarkupsApi
     */
    public markupsObjectMarkupIdUpdatePatch(objectMarkupId: number, updateMarkupRequest?: UpdateMarkupRequest, options?: AxiosRequestConfig) {
        return ForgeMarkupsApiFp(this.configuration).markupsObjectMarkupIdUpdatePatch(objectMarkupId, updateMarkupRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

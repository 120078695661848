import { Box } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import { useProjectState } from '../../redux/project';
import { IFileElementStatus } from '../../redux/project/types';
import { ReactComponent as IconAlertRed } from './icons/icon-alert-red.svg';
import { ReactComponent as IconBlue } from './icons/icon-blue.svg';
import { ReactComponent as IconCrossRed } from './icons/icon-cross-red.svg';
import { ReactComponent as IconGray } from './icons/icon-gray.svg';
import { ReactComponent as IconGreen } from './icons/icon-green.svg';
import { ReactComponent as IconPenBlue } from './icons/icon-pen-blue.svg';
import { ReactComponent as IconPenGreen } from './icons/icon-pen-green.svg';
import { ReactComponent as IconPenRed } from './icons/icon-pen-red.svg';
import { ReactComponent as IconQuestionBlue } from './icons/icon-question-blue.svg';
import { ReactComponent as IconQuestionBluePen } from './icons/icon-question-blue-pen.svg';
import { ReactComponent as IconQuestionGray } from './icons/icon-question-gray.svg';
import { ReactComponent as IconQuestionGreen } from './icons/icon-question-green.svg';
import { ReactComponent as IconQuestionGreenPen } from './icons/icon-question-green-pen.svg';
import { ReactComponent as IconQuestionProblem } from './icons/icon-question-problem.svg';
import useStyles from './styles';

interface ISensorHighlighterProps {
    viewer: Autodesk.Viewing.Viewer3D;
    elementStatus: IFileElementStatus;
    externalID: string;
    dbID: number | undefined;
}

const SensorHighlighter: React.FC<ISensorHighlighterProps> = ({
    viewer,
    externalID,
    dbID,
    elementStatus,
}) => {
    const classes = useStyles();

    enum IIconName {
        unconfirmedNoSN = 'unconfirmed-no-sn', // blue question
        unconfirmedHasSN = 'unconfirmed-has-sn', // green question
        unconfirmedEdited = 'unconfirmed-edited', // blue pen
        unconfirmedEditedHasSN = 'unconfirmed-edited-has-sn', // green pen
        notEditable = 'not-editable', // red cross
        notEditableEdited = 'not-editable-edited', // red pen
        confirmedEditedHasSN = 'confirmed-edited-has-sn', // green pen
        confirmedEdited = 'confirmed-edited', // blue pen
        confirmedUnedited = 'confirmed-unedited', // blue
        confirmedHasSN = 'confirmed-has-sn', // green
        elementOpen = 'element-open', // no color
        elementProblem = 'element-problem', // red wit exclamation mark
        // locking:
        lockingOpen = 'locking-open', // no color + question mark
        lockingEditableNoSN = 'locking-editable', // blue + question mark
        lockingEditableHasSN = 'locking-editable-has-sn', // green + question mark
        lockingEditedNoSN = 'locking-edited', // blue + question mark + pen
        lockingEditedHasSN = 'locking-edited-has-sn', // green + question mark + pen
        lockingProblem = 'locking-problem', // red + question mark + exclamation mark
    }
    const [iconName, setIconName] = useState<IIconName>();

    const project = useProjectState();
    const selected = externalID === project.file.selectedAsset;

    useEffect(() => {
        const locking = elementStatus.lockState === 'LockRequested';
        const locked = elementStatus.lockState === 'Locked';
        const busy = elementStatus.lockState === 'Busy';
        const hasSerialNumber = elementStatus.hasSerialNumber;
        const isEdited = elementStatus.isEdited;
        const statusProblem = elementStatus.assetStatus === 'problem';
        const statusOpen = elementStatus.assetStatus === 'open';
        const serialNumberChanged = elementStatus.serialNumberChanged;

        // Locking + New element
        if (locking && statusOpen) {
            setIconName(IIconName.lockingOpen);
        }
        // New element
        else if (statusOpen) {
            setIconName(IIconName.elementOpen);
        }

        // Locking + Problem element
        else if (locking && statusProblem) {
            setIconName(IIconName.lockingProblem);
        }
        // Problem element
        else if (statusProblem) {
            setIconName(IIconName.elementProblem);
        }

        // Locking - no serial number
        else if (locking && !hasSerialNumber && !isEdited) {
            setIconName(IIconName.lockingEditableNoSN);
        }

        // Locking - has serial number
        else if (locking && hasSerialNumber && !isEdited) {
            setIconName(IIconName.lockingEditableHasSN);
        }

        // Locking - edited - serialNumberChanged
        else if (locking && isEdited && serialNumberChanged) {
            setIconName(IIconName.lockingEditedHasSN);
        }

        // Locking - edited
        else if (locking && isEdited) {
            setIconName(IIconName.lockingEditedNoSN);
        }

        // Busy - not edited
        else if (busy && !isEdited) {
            setIconName(IIconName.notEditable);
        }
        // Busy - edited
        else if (busy && isEdited) {
            setIconName(IIconName.notEditableEdited);
        }

        // Locked - edited - serialNumberChanged
        else if (locked && serialNumberChanged) {
            setIconName(IIconName.confirmedEditedHasSN);
        }
        // Locked - edited
        else if (locked && isEdited) {
            setIconName(IIconName.confirmedEdited);
        }
        // Locked - not edited - no serial number
        else if (locked && !hasSerialNumber) {
            setIconName(IIconName.confirmedUnedited);
        }
        // Locked - not edited - has serial number
        else if (locked && hasSerialNumber) {
            setIconName(IIconName.confirmedHasSN);
        }
    }, [elementStatus]);

    const Icon: React.FC = () => {
        switch (iconName) {
            case 'element-open':
                return <IconGray className={classes.svgIcon} />;
            case 'element-problem':
                return <IconAlertRed className={classes.svgIcon} />;
            case 'unconfirmed-no-sn':
                return <IconQuestionBlue className={classes.svgIcon} />;
            case 'unconfirmed-has-sn':
                return <IconQuestionGreen className={classes.svgIcon} />;
            case 'unconfirmed-edited':
                return <IconPenBlue className={classes.svgIcon} />;
            case 'unconfirmed-edited-has-sn':
                return <IconPenGreen className={classes.svgIcon} />;
            case 'not-editable':
                return <IconCrossRed className={classes.svgIcon} />;
            case 'not-editable-edited':
                return <IconPenRed className={classes.svgIcon} />;
            case 'confirmed-edited-has-sn':
                return <IconPenGreen className={classes.svgIcon} />;
            case 'confirmed-edited':
                return <IconPenBlue className={classes.svgIcon} />;
            case 'confirmed-unedited':
                return <IconBlue className={classes.svgIcon} />;
            case 'confirmed-has-sn':
                return <IconGreen className={classes.svgIcon} />;
            case IIconName.lockingOpen:
                return <IconQuestionGray className={classes.svgIcon} />;
            case IIconName.lockingEditableNoSN:
                return <IconQuestionBlue className={classes.svgIcon} />;
            case IIconName.lockingEditableHasSN:
                return <IconQuestionGreen className={classes.svgIcon} />;
            case IIconName.lockingEditedNoSN:
                return <IconQuestionBluePen className={classes.svgIcon} />;
            case IIconName.lockingEditedHasSN:
                return <IconQuestionGreenPen className={classes.svgIcon} />;
            case IIconName.lockingProblem:
                return <IconQuestionProblem className={classes.svgIcon} />;
            default:
                return <></>;
        }
    };

    return (
        <Box className={clsx(classes.highlighter, selected && classes.highlighterSelected)}>
            <Icon />
        </Box>
    );
};

export default SensorHighlighter;

import React from 'react';

import { IFileElementStatus } from '../../redux/project/types';
import FormattedNumber from '../FormatedNumber/FormattedNumber';
import FormattedMessage from '../Translation/FormattedMessage';
import messages from './messages';

export interface IOperationsEditorProps {
    status: IFileElementStatus;
    variant: 'debug' | 'info';
}

const classTag = 'text-[11px] mt-2 bg-[#ffcf00] text-black inline-block rounded px-2 py-[1px] mr-2';

const OperationsDetail: React.FC<IOperationsEditorProps> = ({ status, variant }) => {
    if (variant === 'info') {
        return (
            <>
                {(status.create ||
                    status.move ||
                    status.rotate ||
                    status.copy ||
                    status.delete) && (
                    <div className="my-3 p-2 border border-gray-200">
                        <div>
                            <FormattedMessage {...messages.labelGeometryAdjustments} />:
                        </div>
                        <div className="w-full">
                            {status.create && <div className={classTag}>CREATE</div>}
                            {status.move && <div className={classTag}>MOVE</div>}
                            {status.rotate && (
                                <div className={classTag}>ROTATE {status.rotate.angle}°</div>
                            )}
                            {status.copy && <div className={classTag}>COPY</div>}
                            {status.delete && <div className={classTag}>DELETE</div>}
                        </div>
                    </div>
                )}
            </>
        );
    }

    return (
        <>
            {(status.create || status.move || status.rotate || status.copy || status.delete) && (
                <div className="p-2 my-3">
                    <div className="font-bold mb-2">
                        A<FormattedMessage {...messages.labelGeometryAdjustments} />
                    </div>
                    <table className="w-full">
                        <tbody>
                            {status.create && (
                                <tr className="border-t border-gray-200">
                                    <td>
                                        <div className={classTag}>CREATED</div>
                                    </td>
                                    <td className="text-[12px]">
                                        {/*Code: {status.create.sourceElementTemplate.code}, x:{' '}*/}
                                        <FormattedNumber>
                                            {status.create.targetRealWorldCoords?.imperial?.x}
                                        </FormattedNumber>
                                        , y:{' '}
                                        <FormattedNumber>
                                            {status.create.targetRealWorldCoords?.imperial?.y}
                                        </FormattedNumber>
                                        , z:{' '}
                                        <FormattedNumber>
                                            {status.create.targetRealWorldCoords?.imperial?.z}
                                        </FormattedNumber>
                                    </td>
                                </tr>
                            )}
                            {status.move && (
                                <tr className="border-t border-gray-200">
                                    <td>
                                        <div className={classTag}>MOVED</div>
                                    </td>
                                    <td className="text-[12px]">
                                        x:{' '}
                                        <FormattedNumber>
                                            {status.move.deltaWorldReal.x}
                                        </FormattedNumber>
                                        , y:{' '}
                                        <FormattedNumber>
                                            {status.move.deltaWorldReal.y}
                                        </FormattedNumber>
                                        , z:{' '}
                                        <FormattedNumber>
                                            {status.move.deltaWorldReal.z}
                                        </FormattedNumber>
                                    </td>
                                </tr>
                            )}
                            {status.rotate && (
                                <tr className="border-t border-gray-200">
                                    <td>
                                        <div className={classTag}>ROTATED</div>
                                    </td>
                                    <td>{status.rotate.angle}°</td>
                                </tr>
                            )}
                            {status.copy && (
                                <tr className="border-t border-gray-200">
                                    <td>
                                        <div className={classTag}>COPIED</div>
                                    </td>
                                    <td className="text-[12px]">
                                        x:{' '}
                                        <FormattedNumber>
                                            {status.copy.targetRealWorldCoords?.imperial.x}
                                        </FormattedNumber>
                                        , y:{' '}
                                        <FormattedNumber>
                                            {status.copy.targetRealWorldCoords?.imperial.y}
                                        </FormattedNumber>
                                        , z:{' '}
                                        <FormattedNumber>
                                            {status.copy.targetRealWorldCoords?.imperial.z}
                                        </FormattedNumber>
                                    </td>
                                </tr>
                            )}
                            {status.delete && (
                                <tr className="border-t border-gray-200">
                                    <td>
                                        <div className={classTag}>DELETED</div>
                                    </td>
                                    <td></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
};

export default OperationsDetail;

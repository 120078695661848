import { defineMessages } from 'react-intl';

export default defineMessages({
    search: {
        id: 'components.filter.search',
        defaultMessage: 'Search',
    },
    filter: {
        id: 'components.filter.filter',
        defaultMessage: 'Filters',
    },
    clearAll: {
        id: 'components.filter.clearAll',
        defaultMessage: 'Clear all',
    },
    viewBy: {
        id: 'components.filter.viewBy',
        defaultMessage: 'View by:',
    },
    viewAsFilter: {
        id: 'components.filter.viewAsFilter',
        defaultMessage: 'Filter',
    },
    viewAsFolders: {
        id: 'components.filter.viewAsFolders',
        defaultMessage: 'Folders',
    },
    selected: {
        id: 'components.fieldSelect.selected',
        defaultMessage: 'selected',
    },
    selectPlaceholder: {
        id: 'components.fieldSelect.placeholder',
        defaultMessage: 'select',
    },
    dateRangePlaceholder: {
        id: 'components.fieldDateRange.placeholder',
        defaultMessage: 'Select date',
    },
    // field types
    fieldType: {
        id: 'components.filter.fieldType',
        defaultMessage: 'Type',
    },
    fieldProject: {
        id: 'components.filter.fieldProject',
        defaultMessage: 'Project',
    },
    fieldStatus: {
        id: 'components.filter.fieldStatus',
        defaultMessage: 'Status',
    },
    fieldLocation: {
        id: 'components.filter.fieldLocation',
        defaultMessage: 'Location',
    },
    fieldSubLocation: {
        id: 'components.filter.fieldSubLocation',
        defaultMessage: 'Include sub-locations',
    },
    fieldAssignedTo: {
        id: 'components.filter.fieldAssignedTo',
        defaultMessage: 'Assigned to',
    },
    fieldDueDate: {
        id: 'components.filter.fieldDueDate',
        defaultMessage: 'Due date',
    },
    fieldRootCause: {
        id: 'components.filter.fieldRootCause',
        defaultMessage: 'Root cause',
    },
    fieldCreatedBy: {
        id: 'components.filter.fieldCreatedBy',
        defaultMessage: 'Created by',
    },
    fieldCreatedOn: {
        id: 'components.filter.fieldCreatedOn',
        defaultMessage: 'Created on',
    },
    // field placeholders
    fieldTypePlaceholder: {
        id: 'components.filter.fieldTypePlaceholder',
        defaultMessage: 'Select a type',
    },
    fieldProjectPlaceholder: {
        id: 'components.filter.fieldProjectPlaceholder',
        defaultMessage: 'Select a project',
    },
    fieldStatusPlaceholder: {
        id: 'components.filter.fieldStatusPlaceholder',
        defaultMessage: 'Select a status',
    },
    fieldLocationPlaceholder: {
        id: 'components.filter.fieldLocationPlaceholder',
        defaultMessage: 'Select a location',
    },
    fieldAssignedToPlaceholder: {
        id: 'components.filter.fieldAssignedToPlaceholder',
        defaultMessage: 'Select a user, role, or company',
    },
    fieldDueDatePlaceholder: {
        id: 'components.filter.fieldDueDatePlaceholder',
        defaultMessage: 'Choose date',
    },
    fieldRootCausePlaceholder: {
        id: 'components.filter.fieldRootCausePlaceholder',
        defaultMessage: 'Select a root cause',
    },
    fieldCreatedByPlaceholder: {
        id: 'components.filter.fieldCreatedByPlaceholder',
        defaultMessage: 'Select a user',
    },
    fieldCreatedOnPlaceholder: {
        id: 'components.filter.fieldCreatedOnPlaceholder',
        defaultMessage: 'Choose date',
    },
    // filter group titles
    defaultFilter: {
        id: 'components.filter.defaultFilter',
        defaultMessage: 'Default filters',
    },
    customFilter: {
        id: 'components.filter.customFilter',
        defaultMessage: 'Custom attributes filters',
    },
    // statuses
    statusDraft: {
        id: 'components.filter.statusDraft',
        defaultMessage: 'Draft',
    },
    statusOpen: {
        id: 'components.filter.statusOpen',
        defaultMessage: 'Open',
    },
    statusClosed: {
        id: 'components.filter.statusClosed',
        defaultMessage: 'Closed',
    },
    statusAnswered: {
        id: 'components.filter.statusAnswered',
        defaultMessage: 'Answered',
    },
    statusWorkCompleted: {
        id: 'components.filter.statusWorkCompleted',
        defaultMessage: 'Work completed',
    },
    statusVoid: {
        id: 'components.filter.statusVoid',
        defaultMessage: 'Void',
    },
    statusNotApproved: {
        id: 'components.filter.statusNotApproved',
        defaultMessage: 'Not approved',
    },
    statusInDispute: {
        id: 'components.filter.statusInDispute',
        defaultMessage: 'In dispute',
    },
    statusReadyToInspect: {
        id: 'components.filter.statusReadyToInspect',
        defaultMessage: 'Ready to inspect',
    },
    // group texts
    groupTextUser: {
        id: 'components.filter.groupTextUser',
        defaultMessage: 'User',
    },
    groupTextRole: {
        id: 'components.filter.groupTexRole',
        defaultMessage: 'Role',
    },
    groupTextCompany: {
        id: 'components.filter.groupTexCompany',
        defaultMessage: 'Company',
    },
    // custom attributes
    fieldCustomAttribute: {
        id: 'components.filter.fieldCustomAttribute',
        defaultMessage: 'Custom attributes',
    },
    fieldCustomAttributePlaceholder: {
        id: 'components.filter.fieldCustomAttributePlaceholder',
        defaultMessage: 'Select custom attribute',
    },
    fieldValue: {
        id: 'components.filter.customAttributes.value',
        defaultMessage: 'Value',
    },
    fieldValuePlaceholder: {
        id: 'components.filter.customAttributes.valuePlaceholder',
        defaultMessage: 'Choose value',
    },
    fieldOperand: {
        id: 'components.filter.customAttributes.operand',
        defaultMessage: 'Operand',
    },
    fieldOperandPlaceholder: {
        id: 'components.filter.customAttributes.operandPlaceholder',
        defaultMessage: 'Choose operand',
    },
    fieldNumber: {
        id: 'components.filter.customAttributes.number',
        defaultMessage: 'Number',
    },
    fieldNumberPlaceholder: {
        id: 'components.filter.customAttributes.numberPlaceholder',
        defaultMessage: 'Fill number',
    },
    fieldText: {
        id: 'components.filter.customAttributes.text',
        defaultMessage: 'Text',
    },
    fieldTextPlaceholder: {
        id: 'components.filter.customAttributes.textPlaceholder',
        defaultMessage: 'Fill text',
    },
    // operands
    operandUndefined: {
        id: 'components.filter.customAttributes.operand.undefined',
        defaultMessage: 'Is not defined',
    },
    operandDefined: {
        id: 'components.filter.customAttributes.operand.defined',
        defaultMessage: 'Is defined',
    },
    operandEmpty: {
        id: 'components.filter.customAttributes.operand.empty',
        defaultMessage: 'Is empty',
    },
    operandNotEmpty: {
        id: 'components.filter.customAttributes.operand.notEmpty',
        defaultMessage: 'Is not empty',
    },
    operandEquals: {
        id: 'components.filter.customAttributes.operand.equals',
        defaultMessage: 'Equals',
    },
    operandNotEquals: {
        id: 'components.filter.customAttributes.operand.notEquals',
        defaultMessage: 'Not equals',
    },
    operandGt: {
        id: 'components.filter.customAttributes.operand.gt',
        defaultMessage: 'Greater than',
    },
    operandEgt: {
        id: 'components.filter.customAttributes.operand.egt',
        defaultMessage: 'Equal or greater than',
    },
    operandLt: {
        id: 'components.filter.customAttributes.operand.lt',
        defaultMessage: 'Lower than',
    },
    operandElt: {
        id: 'components.filter.customAttributes.operand.elt',
        defaultMessage: 'Equal or lower than',
    },
    operandStartsWith: {
        id: 'components.filter.customAttributes.operand.startsWith',
        defaultMessage: 'Starts with',
    },
    operandEndsWith: {
        id: 'components.filter.customAttributes.operand.endsWith',
        defaultMessage: 'Ends with',
    },
    operandContains: {
        id: 'components.filter.customAttributes.operand.contains',
        defaultMessage: 'Contains',
    },

    // presets
    presetsSave: {
        id: 'components.filter.presets.save',
        defaultMessage: 'Save filter', // Filter Speichern
    },
    presetsManager: {
        id: 'components.filter.presets.presetsManager',
        defaultMessage: 'Saved filters', // Gespeicherte Filter
    },
});

import { DispatchAction } from '@iolabs/redux-utils';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import {
    Checkbox,
    IconButton,
    InputAdornment,
    ListSubheader,
    MenuItem,
    TextField,
    Theme,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { createStyles, makeStyles } from '@mui/styles';
import { isArray, isEmpty, isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { VariableSizeList as List } from 'react-window';

import { onFilterAssetClearAll, onFilterAssets, useAssetsActiveFilters } from '../../redux/assets';
import { AssetsFilters } from '../../redux/assets/types';
import { onFilter as onIssuesFilter, useIssuesFilters } from '../../redux/issues';
import { IIssuesFilters, IssuesFiltersKeys } from '../../redux/issues/types';
import { useTranslation } from '../../redux/translations/hook';
// import StatusIndicator from "../DataGrid/StatusIndicator";
// import { IStatusIndicatorType } from "../DataGrid/types";
import messages from './messages';
import { IFilterDefinition, IFilterGroupValue, IFilterType, IFilterValue } from './types';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        listSubheader: {
            paddingLeft: 0,
            paddingRight: 0,
            height: '27px',
            '&.Mui-disabled': {
                opacity: 1,
            },
        },
        listSubheaderSpan: {
            color: theme.palette.text.primary,
            fontSize: theme.typography.pxToRem(12),
            fontWeight: 600,
            backgroundColor: theme.palette.grey[50],
            lineHeight: '30px',
            width: '100%',
        },
        expandedButton: {
            marginRight: theme.spacing(1),
        },
        placeholder: {
            color: theme.palette.text.disabled,
        },
        textField: {
            '& .MuiOutlinedInput-root, & .MuiSelect-root': {
                color: theme.palette.grey[300],
            },
        },
        checkbox: {
            padding: theme.spacing(0, 1, 0, 0),
            '& svg': {
                color: theme.palette.primary.dark,
            },
        },
    }),
);

interface IFieldSelectProps {
    type: IFilterType;
    filterDefinition: IFilterDefinition;
}

const FieldSelect: React.FC<IFieldSelectProps> = ({ type, filterDefinition }) => {
    const classes = useStyles();
    const dispatch = useDispatch<DispatchAction>();
    const issuesFilters: IIssuesFilters = useIssuesFilters();
    const assetsFilters = useAssetsActiveFilters();

    const [state, setState] = useState<any>([]);
    const [expanded, setExpanded] = useState<string[]>([]);

    const handleExpanded = (optionGroupText: string) => event => {
        event.stopPropagation();
        let newExpanded: string[];
        if (expanded?.indexOf(optionGroupText) !== -1) {
            newExpanded = expanded.filter(id => id !== optionGroupText);
        } else {
            newExpanded = [optionGroupText, ...expanded];
        }
        setExpanded(newExpanded);
    };

    const handleChange = (event, newValue) => {
        const value = filterDefinition.multiChoice ? newValue?.map(v => v.value) : newValue?.value;

        if ((value === '' || value === undefined) && type === IFilterType.ASSETS) {
            dispatch(
                onFilterAssetClearAll({
                    key: filterDefinition?.parameterName as string,
                }),
            );
        } else {
            dispatch(
                type === IFilterType.ISSUES
                    ? onIssuesFilter({
                          key: filterDefinition?.parameterName as IssuesFiltersKeys,
                          value: value,
                      })
                    : onFilterAssets({
                          key: filterDefinition?.parameterName as string,
                          value: value,
                      }),
            );
        }
    };

    const preFilterValues = event => {
        if (isArray(event.target.value)) {
            const nonNull = event.target.value.filter(v => !isEmpty(v));
            return isEmpty(nonNull) ? undefined : nonNull;
        } else {
            return isEmpty(event.target.value) ? undefined : event.target.value;
        }
    };

    const handleChange2 = event => {
        const values = preFilterValues(event);
        if (!isEqual(issuesFilters[filterDefinition.parameterName as string], values)) {
            dispatch(
                onIssuesFilter({
                    key: filterDefinition?.parameterName as IssuesFiltersKeys,
                    value: values,
                }),
            );
        }
    };

    const renderValues = (filterValues: IFilterValue[]) => {
        filterValues.sort(function (a, b) {
            const keyA = a.optionText;
            const keyB = b.optionText;
            if (keyA && keyB) {
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
            }
            return 0;
        });
        return filterValues?.map((filterValue, index) => (
            <MenuItem key={index} value={filterValue?.value}>
                {filterDefinition?.multiChoice && (
                    <Checkbox
                        size="small"
                        checked={state.includes(filterValue)}
                        className={classes.checkbox}
                    />
                )}
                {filterDefinition?.parameterName === 'statuses' ? (
                    <>TODO: STATUS INDICATOR</>
                ) : (
                    // <StatusIndicator
                    //     indicatorType={IStatusIndicatorType.SELECT}
                    //     status={filterValue?.value as string}
                    //     statusText={filterValue?.optionText}
                    // />
                    filterValue?.optionText
                )}
            </MenuItem>
        ));
    };

    const renderVirtually = () => {
        return false;
        return filterDefinition.parameterName === 'locations';
    };

    const renderGroupValues = (groupValue: IFilterGroupValue) => {
        return filterDefinition?.parameterName === 'assignedTo'
            ? expanded?.includes(groupValue?.optionGroupText as string)
                ? renderValues(groupValue?.values)
                : null
            : renderValues(groupValue?.values);
    };

    const prepareSelectedOptions = selectedValues => {
        let filters: IFilterValue[] = [];
        if (filterDefinition?.hasGroups) {
            filterDefinition.groupedValues.forEach(gv => {
                filters = filters.concat(gv.values);
            });
        } else {
            filters = filterDefinition?.values;
        }

        const values = filters.filter(option => selectedValues?.includes(option.value));
        return filterDefinition.multiChoice ? values : values?.[0];
    };

    // use redux filters state as local component state
    useEffect(() => {
        if (type === IFilterType.ISSUES) {
            if (issuesFilters[filterDefinition?.parameterName as string] !== undefined) {
                setState(
                    prepareSelectedOptions(
                        issuesFilters[filterDefinition?.parameterName as string],
                    ),
                );
            } else {
                setState([]);
            }
        } else {
            if (assetsFilters[filterDefinition?.parameterName as string] !== undefined) {
                setState(
                    prepareSelectedOptions(
                        assetsFilters[filterDefinition?.parameterName as string],
                    ),
                );
            } else {
                setState([]);
            }
        }
    }, [issuesFilters, assetsFilters]);

    // translations
    const transSelected = useTranslation({ ...messages.selected });
    const transPlaceholder = useTranslation({ ...messages.selectPlaceholder });

    const transFieldType = useTranslation({ ...messages.fieldType });
    const transFieldTypePlaceholder = useTranslation({ ...messages.fieldTypePlaceholder });
    const transFieldStatus = useTranslation({ ...messages.fieldStatus });
    const transFieldStatusPlaceholder = useTranslation({ ...messages.fieldStatusPlaceholder });
    const transFieldProject = useTranslation({ ...messages.fieldProject });
    const transFieldProjectPlaceholder = useTranslation({
        ...messages.fieldProjectPlaceholder,
    });
    const transFieldLocation = useTranslation({ ...messages.fieldLocation });
    const transFieldLocationPlaceholder = useTranslation({
        ...messages.fieldLocationPlaceholder,
    });
    const transFieldAssignedTo = useTranslation({ ...messages.fieldAssignedTo });
    const transFieldAssignedToPlaceholder = useTranslation({
        ...messages.fieldAssignedToPlaceholder,
    });
    const transFieldRootCause = useTranslation({ ...messages.fieldRootCause });
    const transFieldRootCausePlaceholder = useTranslation({
        ...messages.fieldRootCausePlaceholder,
    });
    const transFieldCreatedBy = useTranslation({ ...messages.fieldCreatedBy });
    const transFieldCreatedByPlaceholder = useTranslation({
        ...messages.fieldCreatedByPlaceholder,
    });
    const transFieldCustomAttribute = useTranslation({ ...messages.fieldCustomAttribute });
    const transFieldCustomAttributePlaceholder = useTranslation({
        ...messages.fieldCustomAttributePlaceholder,
    });

    const transGroupTextUser = useTranslation({ ...messages.groupTextUser });
    const transGroupTextRole = useTranslation({ ...messages.groupTextRole });
    const transGroupTextCompany = useTranslation({ ...messages.groupTextCompany });

    /**
     * Mapping DE locale for filterDefinition label and placeholder
     * @param filterDefinitionProp
     */
    const localeMapping = (
        filterDefinitionProp: IFilterDefinition,
    ): { label: string; placeholder: string } => {
        switch (filterDefinitionProp?.parameterName) {
            case 'types': {
                return { label: transFieldType, placeholder: transFieldTypePlaceholder };
            }
            case 'statuses': {
                return { label: transFieldStatus, placeholder: transFieldStatusPlaceholder };
            }
            case 'projects': {
                return { label: transFieldProject, placeholder: transFieldProjectPlaceholder };
            }
            case 'locations': {
                return { label: transFieldLocation, placeholder: transFieldLocationPlaceholder };
            }
            case 'assignedTo': {
                return {
                    label: transFieldAssignedTo,
                    placeholder: transFieldAssignedToPlaceholder,
                };
            }
            case 'rootCauses': {
                return { label: transFieldRootCause, placeholder: transFieldRootCausePlaceholder };
            }
            case 'createdBy': {
                return { label: transFieldCreatedBy, placeholder: transFieldCreatedByPlaceholder };
            }
            case 'customAttributes': {
                return {
                    label: transFieldCustomAttribute,
                    placeholder: transFieldCustomAttributePlaceholder,
                };
            }
            default: {
                return {
                    label: filterDefinitionProp?.caption as string,
                    placeholder: transPlaceholder,
                };
            }
        }
    };

    /**
     * Mapping DE locale for option group text
     * @param groupText
     */
    const localeMappingGroupText = (groupText: string): string => {
        switch (groupText) {
            case 'User': {
                return transGroupTextUser;
            }
            case 'Role': {
                return transGroupTextRole;
            }
            case 'Company': {
                return transGroupTextCompany;
            }
            default: {
                return groupText;
            }
        }
    };

    const { label, placeholder } = localeMapping(filterDefinition);

    const renderGroup =
        () =>
        ({ index, style }) => {
            const groupValue = filterDefinition?.groupedValues[index];
            return [
                groupValue?.values?.length > 0 && (
                    <MenuItem key={index} className={classes.listSubheader}>
                        <ListSubheader
                            component="span"
                            classes={{ root: classes.listSubheaderSpan }}
                        >
                            {filterDefinition?.parameterName === 'assignedTo' && (
                                <IconButton
                                    aria-label="expansion"
                                    size="small"
                                    onClick={handleExpanded(groupValue?.optionGroupText as string)}
                                    className={classes.expandedButton}
                                >
                                    {expanded?.includes(groupValue?.optionGroupText as string) ? (
                                        <ExpandLessIcon fontSize="small" />
                                    ) : (
                                        <ExpandMoreIcon fontSize="small" />
                                    )}
                                </IconButton>
                            )}
                            {localeMappingGroupText(groupValue?.optionGroupText as string)}
                        </ListSubheader>
                    </MenuItem>
                ),
                groupValue?.values?.length > 0 && renderGroupValues(groupValue),
            ];
        };

    const getGroupSize = () => index => {
        const group: IFilterGroupValue = filterDefinition?.groupedValues[index];

        let itemSize = 27; // header
        itemSize += group.values.length * 34.84; // items
        return itemSize;
    };
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) - 200;

    if (filterDefinition?.hasGroups) {
        return (
            <TextField
                id={filterDefinition?.parameterName}
                required
                fullWidth
                select
                label={label}
                variant="outlined"
                margin="dense"
                className={classes.textField}
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    // show search icon inside textField
                    startAdornment:
                        filterDefinition?.parameterName === 'locations' ||
                        filterDefinition?.parameterName === 'types' ||
                        filterDefinition?.parameterName === 'assignedTo' ||
                        filterDefinition?.parameterName === 'rootCauses' ||
                        filterDefinition?.parameterName === 'createdBy' ||
                        filterDefinition?.parameterName === 'customAttributes' ? (
                            <InputAdornment position="start">
                                <SearchIcon fontSize="small" />
                            </InputAdornment>
                        ) : null,
                }}
                SelectProps={{
                    multiple: !!filterDefinition?.multiChoice,
                    value: state?.map(o => o.value),
                    displayEmpty: true,
                    onChange: handleChange2,
                    renderValue: selected => {
                        if ((selected as any).length === 0) {
                            return <span className={classes.placeholder}>{placeholder}</span>;
                        }
                        return filterDefinition?.multiChoice
                            ? `${(selected as any).length} ${transSelected}`
                            : (selected as any);
                    },
                    MenuProps: {
                        // getContentAnchorEl: null,
                    },
                }}
            >
                {filterDefinition?.hasGroups ? (
                    renderVirtually() ? (
                        <List
                            itemCount={filterDefinition.groupedValues?.length}
                            itemSize={getGroupSize()}
                            height={vh}
                        >
                            {renderGroup()}
                        </List>
                    ) : (
                        filterDefinition?.groupedValues?.map((groupValue, index) =>
                            renderGroup()({ index: index, style: {} }),
                        )
                    )
                ) : (
                    [renderValues(filterDefinition?.values)]
                )}
            </TextField>
        );
    }

    return (
        <Autocomplete
            id={filterDefinition?.parameterName}
            className={classes.textField}
            options={filterDefinition?.values}
            multiple={!!filterDefinition?.multiChoice}
            value={state}
            onChange={handleChange}
            renderInput={params => (
                <TextField
                    {...params}
                    required
                    label={label}
                    className={classes.textField}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <>
                                <InputAdornment position="start">
                                    <SearchIcon fontSize="small" />
                                </InputAdornment>
                                {params.InputProps.startAdornment}
                            </>
                        ),
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            )}
            getOptionLabel={option => {
                return option?.optionText ?? '';
            }}
        />
    );
};

export default FieldSelect;

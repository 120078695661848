import { createStyles, makeStyles } from '@mui/styles';

import theme from '../../theme/Theme';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            background: 'white',
            borderTop: '1px solid',
            '& *': {
                boxSizing: 'border-box',
            },
        },

        tableWrapper: {
            overflowX: 'auto',
            minWidth: '100%',
            height: '342px',
            position: 'relative',
        },
        tableWrapperInner: {
            position: 'absolute',
            minWidth: '100%',
        },
        table: {
            minWidth: '100%',
        },
        theadWrapper: {
            position: 'sticky',
            top: 0,
        },
        thead: {
            background: '#EBF7F8',
            '& th': {
                padding: '0 8px',
                border: '1px solid #E0E0E0',
                textAlign: 'left',
                whiteSpace: 'nowrap',
                fontSize: '.75rem',
                height: '30px',
            },
        },
        theadThHover: {
            cursor: 'pointer',
            '&:hover': {
                background: '#c3ebef',
            },
        },
        theadThActive: {
            background: '#9dd4d9',
        },
        assetRow: {
            '& td': {
                padding: '0 8px',
                border: '1px solid #E0E0E0',
                whiteSpace: 'nowrap',
                fontSize: '.75rem',
                height: '30px',
            },
            '&:hover': {
                background: '#fafafa',
            },
        },
        thSortOrderIcon: {
            marginRight: '5px',
        },
        loading: {
            height: '342px',
            padding: theme.spacing(2),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        navigation: {
            background: '#EDEDED',
            userSelect: 'none',
        },
        navigationInner: {
            background: '#EDEDED',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '6px 10px',
        },
        navButton: {
            borderRadius: '3px',
            display: 'inline-block',
            marginLeft: '4px',
            marginRight: '4px',
            cursor: 'pointer',
            height: '20px',
            verticalAlign: 'top',
            '&:hover': {
                background: '#cfcfcf',
            },
        },
        navButtonDisabled: {
            opacity: '0.5',
            pointerEvents: 'none',
            cursor: 'default',
            '&:hover': {
                background: 'none',
            },
        },
        linkDisabled: {
            color: '#ddd',
        },
        pageLimit: {
            cursor: 'pointer',
        },
        pageLimitList: {
            right: '0',
            bottom: '23px',
            position: 'absolute',
            background: 'white',
            border: '1px solid',
            borderRadius: '5px',
            overflow: 'hidden',
            '& div': {
                textAlign: 'right',
                padding: '2px 8px',
                cursor: 'pointer',
                '&:hover': {
                    background: '#ddd',
                },
            },
        },
        buttonsMenuNav: {
            position: 'absolute',
            zIndex: 10,
            top: '-88px',
            right: '4px',
        },
        buttonMenuButton: {
            background: theme.palette.primary.main,
            color: '#fff',
            padding: '0 15px',
            height: '40px',
            display: 'inline-bLock',
            lineHeight: '40px',
            cursor: 'pointer',
            right: 0,
            marginRight: '5px',
            '&:hover': {
                background: theme.palette.primary.dark,
            },
        },
        buttonDisabled: {
            cursor: 'none',
            background: '#c7c7c7',
            pointerEvents: 'none',
            color: '#6c6c6c',
        },
        modalCreateButton: {
            margin: '0 auto',
        },
        modalLoading: {
            position: 'absolute',
            inset: '0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'white',
        },
        checkboxDueDate: {
            padding: '0 5px 0 0',
        },
        labelRequired: {
            color: '#ff0000',
        },
        pushpinLabel: {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            height: '1.6rem',
            fontSize: '.75rem',
            lineHeight: '1.65rem',
            padding: '0 0.5rem',
            whiteSpace: 'nowrap',
            position: 'absolute',
            borderRadius: '.4rem',
            border: '2px solid #fff',
        },
        issueHighlighted: {
            '&>td': {
                background: '#EBF7F8',
            },
        },
        pushpinHighlighted: {
            '& .object-layer-object-pushpin': {
                backgroundColor: 'red!important',
            },
            '& .pushpin-label': {
                backgroundColor: 'red',
            },
        },
        createDialog: {
            '& .MuiPaper-root': {
                maxWidth: '400px',
                width: '100%',
            },
        },
    }),
);

export default useStyles;

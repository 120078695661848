/* tslint:disable */
/* eslint-disable */
/**
 * FireBIM API
 * API definitions for FireBim
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Indicating in what state is a revit file version.
 * @export
 * @enum {string}
 */

export const AssetSyncStatus = {
    Translating: 'Translating',
    Ready: 'Ready',
    Processing: 'Processing',
    Synchronized: 'Synchronized',
    Retry: 'Retry',
    Failed: 'Failed',
    Delete: 'Delete',
    Ignore: 'Ignore',
    Skipped: 'Skipped',
    NoElements: 'NoElements'
} as const;

export type AssetSyncStatus = typeof AssetSyncStatus[keyof typeof AssetSyncStatus];




import { defineMessages } from 'react-intl';

export default defineMessages({
    // Titles
    titleEditingSession: {
        id: 'components.editSession.title.editingSession',
        defaultMessage: 'Editing session',
    },
    titleSerialNumber: {
        id: 'components.editSession.title.serialNumber',
        defaultMessage: 'Serial number',
    },
    titleSelectItem: {
        id: 'components.editSession.title.selectItem',
        defaultMessage: 'Select item',
    },

    // Buttons
    buttonEdit: {
        id: 'components.editSession.button.edit',
        defaultMessage: 'Edit',
    },
    buttonScan: {
        id: 'components.editSession.button.scan',
        defaultMessage: 'Scan',
    },
    buttonManualInput: {
        id: 'components.editSession.button.manualInput',
        defaultMessage: 'Manual input',
    },
    buttonCancel: {
        id: 'components.editSession.button.cancel',
        defaultMessage: 'Cancel',
    },
    buttonConfirm: {
        id: 'components.editSession.button.confirm',
        defaultMessage: 'Confirm',
    },
    buttonScanAgain: {
        id: 'components.editSession.button.scanAgain',
        defaultMessage: 'Scan again',
    },
    buttonDiscard: {
        id: 'components.editSession.button.discard',
        defaultMessage: 'Discard',
    },
    buttonElementDiscard: {
        id: 'components.editSession.button.elementDiscard',
        defaultMessage: 'Discard changes',
    },
    buttonCloseSessionAndSave: {
        id: 'components.editSession.button.closeSessionAndSave',
        defaultMessage: 'Close session and save',
    },
    buttonClose: {
        id: 'components.editSession.button.close',
        defaultMessage: 'Close',
    },
    buttonRetry: {
        id: 'components.editSession.button.retry',
        defaultMessage: 'Retry',
    },

    // Labels
    labelZoneCustomerText: {
        id: 'components.editSession.label.zoneCustomerText',
        defaultMessage: 'Zone customer text',
    },
    labelZonePlanNumber: {
        id: 'components.editSession.label.zonePlanNumber',
        defaultMessage: 'Zone plan number',
    },
    labelSerialNumber: {
        id: 'components.editSession.label.serialNumber',
        defaultMessage: 'Serial number',
    },
    labelAreaAddress: {
        id: 'components.editSession.label.areaAddress',
        defaultMessage: 'Area address',
    },
    labelAreaCustomerText: {
        id: 'components.editSession.label.areaCustomerText',
        defaultMessage: 'Area customer text',
    },
    labelChannelAddress: {
        id: 'components.editSession.label.channelAddress',
        defaultMessage: 'Channel address',
    },
    labelSectionAddress: {
        id: 'components.editSession.label.sectionAddress',
        defaultMessage: 'Section address',
    },
    labelSectionCustomerText: {
        id: 'components.editSession.label.sectionCustomerText',
        defaultMessage: 'Section customer text',
    },
    labelSectionPlanNumber: {
        id: 'components.editSession.label.sectionPlanNumber',
        defaultMessage: 'Section plan number',
    },
    labelZoneAddress: {
        id: 'components.editSession.label.zoneAddress',
        defaultMessage: 'Zone address',
    },
    labelZoneType: {
        id: 'components.editSession.label.zoneType',
        defaultMessage: 'Zone type',
    },
    labelGeometryAdjustments: {
        id: 'components.editSession.label.geometryAdjustments',
        defaultMessage: 'Geometry adjustments',
    },

    // Messages
    messageLockingElements: {
        id: 'components.editSession.message.lockingElements',
        defaultMessage: 'Locking elements...',
    },
    messageVersionOfModelIsNotLatest: {
        id: 'components.editSession.message.versionOfModelIsNotLatest',
        defaultMessage: 'Version of model is not latest',
    },
    messageErrorDuringLockingModel: {
        id: 'components.editSession.message.errorDuringLockingModel',
        defaultMessage: 'An error occurred during locking model',
    },
    messageModelBeingProcessed: {
        id: 'components.editSession.message.modelBeingProcessed',
        defaultMessage: 'Model is being processed, please try again later',
    },
    messageModelWasSuccessfullyLocked: {
        id: 'components.editSession.message.modelWasSuccessfullyLocked',
        defaultMessage: 'Model was successfully locked',
    },
    messageUserSubmittedModel: {
        // TODO: not used
        id: 'components.editSession.message.userSubmittedModel',
        defaultMessage: 'User {userName} has successfully submitted to central model',
    },
    messageYourChangesHaveBeenSuccessfullySubmitted: {
        id: 'components.editSession.message.yourChangesHaveBeenSuccessfullySubmitted',
        defaultMessage: 'Your changes have been successfully submitted',
    },
    messageModelWasSuccessfullyUnlocked: {
        id: 'components.editSession.message.modelWasSuccessfullyUnlocked',
        defaultMessage: 'Model was successfully unlocked',
    },
    messageModelWasSuccessfullyUnlockedDueToForceRelease: {
        id: 'components.editSession.message.modelWasSuccessfullyUnlockedDueToForceRelease',
        defaultMessage: 'Model was successfully unlocked due to force release',
    },
    messageErrorDuringLockingView: {
        id: 'components.editSession.message.errorDuringLockingView',
        defaultMessage:
            'An error occurred during locking view initiated by "{userName}". Please try again later.',
    },
    messageModelProcessingFailed: {
        id: 'components.editSession.message.modelProcessingFailed',
        defaultMessage: 'Model processing has failed',
    },
    messageModelProcessingDueToForceLockReleaseFailed: {
        id: 'components.editSession.message.modelProcessingDueToForceLockReleaseFailed',
        defaultMessage: 'Model processing due to force lock release has failed',
    },
    messageModelUnlockingFailed: {
        id: 'components.editSession.message.modelUnlockingFailed',
        defaultMessage: 'Model unlocking has failed',
    },
    messageModelUnlockingDueToForceLockReleaseFailed: {
        id: 'components.editSession.message.modelUnlockingDueToForceLockReleaseFailed',
        defaultMessage: 'Model unlocking due to force lock release has failed',
    },
    messagePublishingModel: {
        id: 'components.editSession.message.publishingModel',
        defaultMessage: 'Publishing model...',
    },
    messagePublishingModelDueToForceLockRelease: {
        id: 'components.editSession.message.publishingModelDueToForceLockRelease',
        defaultMessage: 'Publishing model due to force lock release...',
    },
    messageModelPublishingFailed: {
        id: 'components.editSession.message.modelPublishingFailed',
        defaultMessage: 'Model publishing has failed',
    },
    messageModelPublishingDueToForceLockReleaseFailed: {
        id: 'components.editSession.message.modelPublishingDueToForceLockReleaseFailed',
        defaultMessage: 'Model publishing due to force lock release has failed',
    },
    messageSynchronizingAssets: {
        id: 'components.editSession.message.synchronizingAssets',
        defaultMessage: 'Synchronizing assets...',
    },
    messageAssetSynchronizationComplete: {
        id: 'components.editSession.message.assetSynchronizationComplete',
        defaultMessage: 'Asset synchronization complete.',
    },
    messageNewVersionHasBeenCreatedDueToForceRelease: {
        id: 'components.editSession.message.newVersionHasBeenCreatedDueToForceRelease',
        defaultMessage: 'New version has been created due to force release.',
    },
    messagePublishingModelHasFailed: {
        id: 'components.editSession.message.publishingModelHasFailed',
        defaultMessage: 'Publishing model has failed',
    },
    messagePublishingModelDueToForceLockReleaseHasFailed: {
        id: 'components.editSession.message.publishingModelDueToForceLockReleaseHasFailed',
        defaultMessage: 'Publishing model due to force lock release has failed',
    },
    messageForcefullyUnlockingElements: {
        id: 'components.editSession.message.forcefullyUnlockingElements',
        defaultMessage: 'Forcefully unlocking elements...',
    },
    messageUnlockingModelElements: {
        id: 'components.editSession.message.unlockingModelElements',
        defaultMessage: 'Unlocking model elements...',
    },
    messageModelIsBeingProcessedPleaseTryAgainLater: {
        id: 'components.editSession.message.modelIsBeingProcessedPleaseTryAgainLater',
        defaultMessage: 'Model is being processed, please try again later',
    },
    messageAnErrorOccurredDuringUnlockingModel: {
        id: 'components.editSession.message.anErrorOccurredDuringUnlockingModel',
        defaultMessage: 'An error occurred during unlocking model',
    },
    messageMissingSerialNumber: {
        id: 'components.editSession.message.missingSerialNumber',
        defaultMessage: 'Missing serialNumber',
    },
    messageElementChangeSuccessfullyScheduled: {
        id: 'components.editSession.message.elementChangeSuccessfullyScheduled',
        defaultMessage: 'Element change successfully scheduled',
    },
    messageErrorOccurredDuringSavingElementChanges: {
        id: 'components.editSession.message.errorOccurredDuringSavingElementChanges',
        defaultMessage: 'Error occurred during saving element changes',
    },
    messageNoOperationsToPerform: {
        id: 'components.editSession.message.noOperationsToPerform',
        defaultMessage: 'No operations to perform',
    },
    messageProcessingModelChanges: {
        id: 'components.editSession.message.processingModelChanges',
        defaultMessage: 'Processing model changes...',
    },
    messagePerformOperationsError: {
        id: 'components.editSession.message.performOperationsError',
        defaultMessage: 'PerformOperations error',
    },
    messageModelTransformationInProgressError: {
        id: 'components.editSession.message.messageModelTransformationInProgressError',
        defaultMessage: 'Model is currently being processed by another user',
    },
    messageSerialNumberWasChanged: {
        id: 'components.editSession.message.serialNumberWasChanged',
        defaultMessage: 'Serial number was changed',
    },
    messageLockingOperationIsRunning: {
        id: 'components.editSession.message.lockingOperationIsRunning',
        defaultMessage: 'Locking operation is running...',
    },
    messageLockingOperationHasFailed: {
        id: 'components.editSession.message.lockingOperationHasFailed',
        defaultMessage: 'Locking operation has failed',
    },
    unlockedOperationsModalHeadline: {
        id: 'components.editSession.modal.unlockedOperationsModalHeadline',
        defaultMessage: 'Warning',
    },
    modelContainsNotProcessedElements: {
        id: 'components.editSession.modal.modelContainsNotProcessedElements',
        defaultMessage: 'Model contains changes that cannot be processed',
    },
    unlockedOperationsDiscarded: {
        id: 'components.editSession.modal.unlockedOperationsDiscarded',
        defaultMessage: 'All operations on the following elements will be discarded',
    },
    daFailuredDaElementsModalHeadline: {
        id: 'components.editSession.modal.daFailuredDaElementsModalHeadline',
        defaultMessage:
            'Unable to modify elements below most likely because of other user using it',
    },
    serialNumberValidationModalTitle: {
        id: 'components.editSession.modal.serialNumberValidationModalTitle',
        defaultMessage: 'Serial number is already in use',
    },
    serialNumberValidationModalAccept: {
        id: 'components.editSession.modal.serialNumberValidationModalAccept',
        defaultMessage: 'Accept',
    },
    serialNumberValidationModalDiscard: {
        id: 'components.editSession.modal.serialNumberValidationModalDiscard',
        defaultMessage: 'Discard',
    },
    serialNumberValidationInvalid: {
        id: 'components.editSession.modal.serialNumberValidationInvalid',
        defaultMessage: 'Invalid serial number',
    },
    serialNumberValidationExists: {
        id: 'components.editSession.modal.serialNumberValidationExists',
        defaultMessage: 'SN already exists in: %ZONETEXT_ADDRESS%',
    },
    serialNumberValidationModalText1: {
        id: 'components.editSession.modal.serialNumberValidationModalText1',
        defaultMessage: 'Serial number "%SERIAL_NUMBER%" is already present on another element',
    },
    serialNumberValidationModalText2: {
        id: 'components.editSession.modal.serialNumberValidationModalText2',
        defaultMessage:
            'Would you like to remove it from %DUPLICATE_ELEMENT% and save it to currently selected element?',
    },
    confirmSingleElementDiscardModalText: {
        id: 'components.editSession.modal.confirmSingleElementDiscardModalText',
        defaultMessage: 'Do you really want to discard changes on this element?',
    },
    singleElementSuccess: {
        id: 'components.editSession.modal.singleElementSuccess',
        defaultMessage: 'Changes successfully discarded',
    },
    singleElementError: {
        id: 'components.editSession.modal.singleElementError',
        defaultMessage: 'An error occurred during discarding changes',
    },
    singleElementDiscardInProgress: {
        id: 'components.editSession.message.singleElementDiscardInProgress',
        defaultMessage: 'Discarding element changes...',
    },
    messageDeleteElementPermissionError: {
        id: 'components.editSession.message.deleteElementPermissionError',
        defaultMessage: "You don't have permission to delete elements.",
    },
    messageCantCopyInEditMode: {
        id: 'components.editSession.message.cantCopyInEditMode',
        defaultMessage: "Can't copy in edit mode.",
    },
    messageScheduleOperation: {
        id: 'components.editSession.message.scheduleOperation',
        defaultMessage: 'Schedule operation',
    },
    confirmDiscardAllChangesModalText: {
        id: 'components.editSession.modal.confirmDiscardAllChangesModalText',
        defaultMessage: 'Do you really want to discard all changes?',
    },
    confirmPerformAllChangesModalText: {
        id: 'components.editSession.modal.confirmPerformAllChangesModalText',
        defaultMessage: 'Do you really want to publish all changes?',
    },
    messageCannotCopyElementMissingCoordinates: {
        id: 'components.editSession.message.cannotCopyElementMissingCoordinates',
        defaultMessage: 'Cannot copy element - missing coordinates. Please contact support.',
    },
    // "You can't copy new scheduled element."
    messageCannotCopyNewScheduledElement: {
        id: 'components.editSession.message.cannotCopyNewScheduledElement',
        defaultMessage: "You can't copy new scheduled element.",
    },
    // "You can't copy new copied scheduled element."
    messageCannotCopyNewCopiedScheduledElement: {
        id: 'components.editSession.message.cannotCopyNewCopiedScheduledElement',
        defaultMessage: "You can't copy new copied scheduled element.",
    },

    // fsm
    messageOpeningFSMExport: {
        id: 'components.editSession.message.openingFSMExport',
        defaultMessage: 'Opening FSM Export...',
    },
});

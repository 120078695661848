/**
 * NavigationMenu:
 * - Click on Export button in menu
 * - set in redux "exportState = initializing"
 *
 * Scanning:
 * - useEffect handle change of exportState
 * - start initLockElements with variant "fsmExport"
 * - when locking done - set "exportState = initializingDone"
 */

import '@nosferatu500/react-sortable-tree/style.css';

import { Button, Modal } from '@mui/material';
import fileDownload from 'js-file-download';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DialogContext } from '../../dialogs/DialogProvider/DialogProvider';
import {
    CloudModelLockStatus,
    FsmScheduleRequest,
    FsmTreeRequest,
    FsmTreeResponse,
} from '../../generate/api';
import { fsmApi, fsmExport } from '../../packages/Api/data/fsm/client';
import { useEditingState } from '../../redux/editing/hooks';
import { IExportRunningState, onExportState } from '../../redux/export/exportSlice';
import { useExportState } from '../../redux/export/hooks';
import { addNotification } from '../../redux/notifier';
import {
    hideGlobalOverlay,
    setGlobalOverlay,
    setMenu,
    useMenu,
    useProjectState,
} from '../../redux/project';
import { DF } from '../DialogFactory/DialogFactory';
import { INotificationDialogProps } from '../DialogFactory/NotificationDialog/NotificationDialog';
import FsmTree from './FsmTree';

// Utils
// ----------------------------------------------------------------------------
const getSystemNumber = (viewer): Promise<string> => {
    // const getSystemNumber = (viewer: Autodesk.Viewing.GuiViewer3D): Promise<string> => {
    return new Promise((resolve, reject) => {
        console.log('getSystemNumber');
        const modelId = viewer?.model?.getRootId();
        const attributeNamePattern = /Plant[\s]*Number[\n]*/gi;
        viewer?.model?.getProperties(modelId, propsResult => {
            const foundProperty = propsResult.properties.find(property => {
                return (
                    attributeNamePattern.test(property.attributeName) ||
                    attributeNamePattern.test(property.displayName)
                );
            });

            if (foundProperty) {
                const val = foundProperty.displayValue ?? foundProperty?.value;
                if (val !== undefined && val !== null && val !== '') {
                    resolve(String(val)); // Always return the value as a string
                } else {
                    reject(new Error('System number is empty or undefined.'));
                }
            } else {
                reject(new Error('No matching property found.'));
            }
        });
    });
};

// Return fsm file
const startDownload = async ({ projectID, systemNumber, versionUrn }) => {
    return new Promise(async (resolve, reject) => {
        if (!projectID || !systemNumber || !versionUrn) {
            reject();
            return;
        }

        fsmExport({
            projectID: projectID,
            systemNumber: systemNumber,
            versionUrn: versionUrn,
        })
            // fsmApi
            //     .fsmExportPost({
            //         projectID: projectID,
            //         systemNumber: systemNumber,
            //         versionUrn: versionUrn,
            //     })
            .then(response => {
                fileDownload(response, systemNumber ? `${systemNumber}.fsm` : '71000000.fsm');
                console.log('FSM / ', response);
                return Promise.resolve();
            })
            .catch(error => {
                console.log(error);
                return Promise.reject(error);
            });
    });
};

interface IFsmExportProps {
    viewer: Autodesk.Viewing.GuiViewer3D;
}

const FsmExport: React.FC<IFsmExportProps> = ({ viewer }) => {
    const dispatch = useDispatch();
    const project = useProjectState();
    const exportState = useExportState();
    const editingState = useEditingState();
    const reduxMenu = useMenu();
    const forgeProjectId = project?.project?.id;
    const fileVersionUrn = project?.file?.currentVersion?.data?.urn;
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [systemNumber, setSystemNumber] = useState<string>();
    const [treeRareData, setTreeRareData] = useState<FsmTreeResponse>();
    const [scheduleRequestData, setScheduleRequestData] = useState<FsmScheduleRequest>();
    const { openDialog } = useContext(DialogContext);

    const modalOpen = reduxMenu.exportOpen;

    // Init - Fetch data
    useEffect(() => {
        if (
            editingState.lockState === CloudModelLockStatus.Locked &&
            exportState.runningState === IExportRunningState.initializingDone
        ) {
            fetchData().then(r => {
                console.log(r);
            });
        }
    }, [editingState.lockState, exportState.runningState]);

    // Watch Export running state
    useEffect(() => {
        if (exportState.runningState === IExportRunningState.exportStart) {
            startExportFile();
        }
        if (exportState.runningState === IExportRunningState.initializingKeepOverlay) {
            dispatch(
                setGlobalOverlay({
                    show: true,
                    text: 'Exporting...', // todo translate
                }),
            );
        }
    }, [exportState.runningState]);

    // Fetch data from API - OK
    async function fetchData() {
        const currentSystemNumber = await getSystemNumber(viewer);
        setSystemNumber(currentSystemNumber);

        if (!forgeProjectId || !fileVersionUrn || !currentSystemNumber) {
            setErrorMessage('Missing data.');
            return;
        }
        const fetchRequestData: FsmTreeRequest = {
            projectID: forgeProjectId,
            versionUrn: fileVersionUrn,
            systemNumber: currentSystemNumber,
        };

        setLoading(true);
        return fsmApi
            .fsmTreePost(fetchRequestData)
            .then(response => {
                console.log('response fsmTreePost', response.data);
                setTreeRareData(response.data);
                setErrorMessage(undefined);
            })
            .catch(error => {
                let message = '';

                switch (error.response.data.ErrorCode) {
                    case 'FileVersionContainsNoAssets':
                        message = 'File version contains no assets.';
                        break;
                    case 'FileVersionNotFound':
                        message = 'File version not found.';
                        break;
                    case 'FileVersionFireSystemError':
                        message = 'File version fire system error.';
                        break;
                    case 'FileVersionNotExclusivelyLocked':
                        message =
                            'Model must be exclusively locked by you only in order to continue.';
                        break;
                    default:
                        message = 'Unknown error.';
                }

                openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Error', {
                    content: message,
                });
                console.error('FSM export Error:', message);
                handleClose();
            })
            .finally(() => {
                setLoading(false);
                dispatch(hideGlobalOverlay());
                return 'Data fetched';
            });
    }

    // Schedule + Export or run new DA activity of type Export
    const handleFsmSchedule = () => {
        console.log('Export');
        console.log(scheduleRequestData);
        // todo - show overlay
        dispatch(
            setGlobalOverlay({
                show: true,
                text: 'Exporting...', // todo translate
            }),
        );
        fsmApi
            .fsmSchedulePost(scheduleRequestData)
            .then(response => {
                console.log(response);

                const operationCount = response.data.operationCount ?? 0;

                if (operationCount === 0) {
                    console.log('operationCount === 0');
                    dispatch(hideGlobalOverlay());
                    dispatch(onExportState(IExportRunningState.exportStart));
                }
                if (operationCount > 0) {
                    console.log('operationCount > 0');
                    dispatch(onExportState(IExportRunningState.initializingKeepOverlay));
                }
            })
            .catch(error => {
                console.log(error);
                dispatch(hideGlobalOverlay());
                if (error.response.data.ErrorCode === 'ModelTransformationInProgress') {
                    dispatch(
                        addNotification({
                            variant: 'error',
                            message: 'Model is currently being processed, try again later', // todo translate
                        }),
                    );
                }
            });
    };

    const startExportFile = () => {
        startDownload({
            projectID: forgeProjectId,
            systemNumber: systemNumber,
            versionUrn: fileVersionUrn,
        })
            .then(r => {
                console.log(r);
                dispatch(hideGlobalOverlay());
            })
            .finally(() => {
                dispatch(onExportState(IExportRunningState.initializingDone));
            });
    };

    // Close modal
    const handleClose = () => {
        dispatch(setMenu({ exportOpen: false }));
    };

    // Handle new request data from FsmTree component
    const handleNewRequestData = (newRequestData: FsmScheduleRequest | undefined) => {
        setScheduleRequestData(newRequestData);
    };

    if (
        !exportState.runningState ||
        exportState.runningState === IExportRunningState.initializing ||
        editingState.lockState !== CloudModelLockStatus.Locked
    ) {
        return <></>;
    }

    return (
        <Modal
            open={modalOpen}
            onClose={handleClose}
            className="flex justify-center items-center p-10"
        >
            <div className="bg-white w-full max-w-[800px] rounded h-[96vh] flex flex-col">
                <div className="p-5 border-b border-gray-200 font-bold">
                    <div className="">FSM Export</div>
                </div>
                <div className="p-5 flex-1">
                    <div className="flex flex-col h-full relative z-10">
                        {errorMessage && (
                            <div className="mb-6 p-4 bg-red-100 text-center rounded">
                                {errorMessage}
                            </div>
                        )}

                        {loading ? (
                            <div className="mt-6 p-4 bg-gray-100 text-center rounded">
                                Loading...
                            </div>
                        ) : (
                            <>
                                <FsmTree
                                    rareTreeData={treeRareData}
                                    onRequestData={handleNewRequestData}
                                />
                            </>
                        )}
                    </div>
                </div>
                <div className="p-2 border-t border-gray-200 flex justify-end">
                    <Button onClick={handleClose} variant="outlined" className="mr-3">
                        Cancel
                    </Button>
                    <Button onClick={handleFsmSchedule} variant="contained" disabled={loading}>
                        Export
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default FsmExport;

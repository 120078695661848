/*
this should store all the data for the scanning and adjust geometry
target is to split scanning.tsx into smaller components
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAttributesMap, IRunningLockingState } from '../../components/Scanning/types';
import { CloudModelLockStatus } from '../../generate/api';
import {
    IFileElementCopy,
    IFileElementCreate,
    IFileElementMove,
    IFileElementRemoval,
    IFileElementRotate,
} from '../project/types';
import { RootState } from '../store';

export interface IElementScheduleOperations {
    elementID: string;
    elementName?: string;
    move?: IFileElementMove;
    copy?: IFileElementCopy;
    rotate?: IFileElementRotate;
    create?: IFileElementCreate;
    delete?: IFileElementRemoval;
    scheduled?: boolean;
}

export enum ModeTypes {
    default = 'default',
    scan = 'scan',
    edit = 'edit',
}

export interface IEditingState {
    runningLockingState: IRunningLockingState;
    lockState?: CloudModelLockStatus;
    elementsScheduleOperations: IElementScheduleOperations[];
    mode: ModeTypes;
    selectedElementAttributes?: IAttributesMap;
    selectedElementAttributesCache?: IAttributesMap;
    onForceRewriteAttributesByCopy?: boolean;
}

const initialState: Readonly<IEditingState> = {
    runningLockingState: IRunningLockingState.none,
    lockState: undefined,
    elementsScheduleOperations: [],
    mode: ModeTypes.default,
};

const editingSlice = createSlice({
    name: 'editing',
    initialState,
    reducers: {
        // not used anywhere yet
        setRunningLockingState: (state, action: PayloadAction<IRunningLockingState>) => {
            state.runningLockingState = action.payload;
        },
        setLockState: (state, action: PayloadAction<CloudModelLockStatus | undefined>) => {
            state.lockState = action.payload;
        },
        setElementScheduleOperations: (
            state,
            action: PayloadAction<IElementScheduleOperations>,
        ) => {
            // find element by elementID
            // if found, update it
            // if not found, add it
            if (action.payload) {
                const currentElement = state.elementsScheduleOperations?.find(
                    element => element.elementID === action.payload?.elementID,
                );
                if (currentElement) {
                    if (action.payload.move) {
                        currentElement.move = action.payload.move;
                    }
                    if (action.payload.copy) {
                        currentElement.copy = action.payload.copy;
                    }
                    if (action.payload.rotate) {
                        currentElement.rotate = action.payload.rotate;
                    }
                    if (action.payload.create) {
                        currentElement.create = action.payload.create;
                    }
                    if (action.payload.delete) {
                        currentElement.delete = action.payload.delete;
                    }
                } else {
                    state.elementsScheduleOperations?.push(action.payload);
                }
            }
        },
        removeScheduleForElement: (state, action: PayloadAction<string>) => {
            state.elementsScheduleOperations = state.elementsScheduleOperations?.filter(
                element => element.elementID !== action.payload,
            );
        },
        clearAllScheduleOperations: state => {
            state.elementsScheduleOperations = [];
        },
        setMode: (state, action: PayloadAction<ModeTypes>) => {
            state.mode = action.payload;
        },

        /*
         * list of attributes for selected element with scheduled changes
         */
        setSelectElementAttributes: (state, action: PayloadAction<IAttributesMap | undefined>) => {
            state.selectedElementAttributes = action.payload;
        },

        // used for caching attributes for copy element operation
        setSelectedElementAttributesToCache: (
            state,
            action: PayloadAction<IAttributesMap | undefined>,
        ) => {
            state.selectedElementAttributesCache = action.payload;
        },

        setOnForceRewriteAttributesByCopy: (state, action: PayloadAction<boolean>) => {
            state.onForceRewriteAttributesByCopy = action.payload;
        },
    },
});

export const {
    setRunningLockingState,
    setLockState,
    setElementScheduleOperations,
    removeScheduleForElement,
    clearAllScheduleOperations,
    setMode,
    setSelectElementAttributes,
    setSelectedElementAttributesToCache,
    setOnForceRewriteAttributesByCopy,
} = editingSlice.actions;

export const selectEditingState = (state: RootState) => state.editing;

export default editingSlice.reducer;

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Button, ClickAwayListener, Divider } from '@mui/material';
import clsx from 'clsx';
import React, { useState } from 'react';

import { useProfile } from '../../redux/keyclock';
import AppVersion from '../AppVersion/AppVersion';
import LanguageSwitcher from '../Header/LanguageSwitcher';
import Icon from '../Icon/Icon';
import Logout from '../Logout/Logout';
import messages from '../NavigationMenu/messages';
import useMenuStyles from '../NavigationMenu/styles';
import FormattedMessage from '../Translation/FormattedMessage';
import style from './headerSimple.module.css';
import { ReactComponent as LogoSvg } from './logo-siemens.svg';

const HeaderSimple: React.FC = () => {
    const userData = useProfile();
    const classes = useMenuStyles();
    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    const handleToggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleClickAway = event => {
        // Check if the click target is not the button itself
        if (!event.target.closest('button')) {
            setMenuOpen(false);
        }
    };

    return (
        <header className="z-10">
            <div className="bg-white border-b border-gray-200 h-[50px] w-full flex justify-between items-center">
                <div className={style.logo}>
                    <LogoSvg />
                </div>
                <div className="relative">
                    <div>
                        <button
                            className={clsx(classes.menuItem, menuOpen && classes.menuItemActive)}
                            onClick={handleToggleMenu}
                        >
                            <MenuIcon />
                        </button>
                    </div>

                    {menuOpen && (
                        <ClickAwayListener onClickAway={handleClickAway}>
                            <Box className={classes.verticalMenu}>
                                <Box className={classes.headerUserName}>
                                    {`${userData?.firstName} ${userData?.lastName}`}
                                </Box>

                                <Divider className={classes.divider} />

                                <div
                                    className={clsx(classes.menuItemLanguage, classes.menuItemRow)}
                                >
                                    <Icon name={'menu-language'} size={40} />
                                    <LanguageSwitcher />
                                </div>

                                <Divider className={classes.divider} />

                                <div>
                                    <Logout>
                                        <Button className={classes.menuItemRow}>
                                            <ExitToAppIcon className={classes.menuItemRowIcon} />

                                            <FormattedMessage {...messages.logout} />
                                        </Button>
                                    </Logout>
                                </div>

                                <Divider className={classes.divider} />

                                <AppVersion />
                            </Box>
                        </ClickAwayListener>
                    )}
                </div>
            </div>
        </header>
    );
};

export default HeaderSimple;

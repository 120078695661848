import { defineMessages } from 'react-intl';

export default defineMessages({
    // Buttons
    buttonCreateIssue: {
        id: 'components.issues.table.button.create',
        defaultMessage: 'Create issue',
    },
    buttonReload: {
        id: 'components.issues.table.button.reload',
        defaultMessage: 'Reload',
    },

    // Table headings
    tableLoading: {
        id: 'components.issues.table.loading',
        defaultMessage: 'Loading',
    },
    tableLinesPerPage: {
        id: 'components.issues.table.linesPerPage',
        defaultMessage: 'Lines per page',
    },
    tableTitleType: {
        id: 'components.issues.table.columnTitle.type',
        defaultMessage: 'Type',
    },
    tableTitleStatus: {
        id: 'components.issues.table.columnTitle.status',
        defaultMessage: 'Status',
    },
    tableTitleTitle: {
        id: 'components.issues.table.columnTitle.title',
        defaultMessage: 'Title',
    },
    tableTitleAssignTo: {
        id: 'components.issues.table.columnTitle.assignTo',
        defaultMessage: 'Assign To',
    },
    tableTitleDueDate: {
        id: 'components.issues.table.columnTitle.dueDate',
        defaultMessage: 'Due Date',
    },
    tableTitleLocation: {
        id: 'components.issues.table.columnTitle.location',
        defaultMessage: 'Location',
    },
    tableTitleLocationDetails: {
        id: 'components.issues.table.columnTitle.locationDetails',
        defaultMessage: 'Location Details',
    },
    tableTitleOwner: {
        id: 'components.issues.table.columnTitle.owner',
        defaultMessage: 'Owner',
    },
    tableTitleRootCause: {
        id: 'components.issues.table.columnTitle.rootCause',
        defaultMessage: 'Root Cause',
    },
    tableTitleAttachments: {
        id: 'components.issues.table.columnTitle.attachments',
        defaultMessage: 'Attachments',
    },
    tableTitleMarkup: {
        id: 'components.issues.table.columnTitle.markup',
        defaultMessage: 'Markup',
    },
    tableTitleDescription: {
        id: 'components.issues.table.columnTitle.description',
        defaultMessage: 'Description',
    },
    tableTitleBIM360: {
        id: 'components.issues.table.columnTitle.bim360',
        defaultMessage: 'BIM 360',
    },

    // Error messages
    errorIssueList: {
        id: 'components.issues.table.error.list',
        defaultMessage: 'Loading list of issues was not successful',
    },
    errorClearCache: {
        id: 'components.issues.table.error.clearCache',
        defaultMessage: 'Clear cache was not successful',
    },
});

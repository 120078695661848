import { createStyles, makeStyles } from '@mui/styles';

import theme from '../../theme/Theme';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            width: '300px',
            background: theme.palette.common.white,
            borderRight: '1px solid #ddd',
            flex: 'none',
            position: 'absolute',
            zIndex: 2,
            height: '100%',
            [theme.breakpoints.up('md')]: {
                position: 'relative',
            },
        },
        rootHidden: {
            width: '0px !important',
            padding: '0px !important',
            borderRight: '3px solid ' + theme.palette.primary.main + ' !important',
        },
        children: {
            maxHeight: 'calc(100vh - 50px)',
            height: '100%',
            overflow: 'auto',
        },
        childrenHidden: {
            display: 'none !important',
        },
        rootClose: {
            left: '-300px',
        },
        arrow: {
            position: 'absolute',
            top: '60px',
            width: '30px',
            height: '60px',
            background: theme.palette.primary.main,
            borderRadius: '0 5px 5px 0',
            right: '-30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            '&:hover': {
                background: theme.palette.primary.dark,
            },
        },
    }),
);

export default useStyles;

import ArrowRightAlt from '@mui/icons-material/ArrowRightAlt';
import { Box, Divider, Grid, Paper, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { differenceInCalendarMonths, format, Locale } from 'date-fns';
import { de } from 'date-fns/locale';
import React from 'react';

import { DateRange, DefinedRange, NavigationAction, Setter } from '../types';
import { MARKERS } from './DateRangePicker';
import DefinedRanges from './DefinedRanges';
import Month from './Month';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            padding: '4px 10px',
        },
        headerItem: {
            flex: 1,
            textAlign: 'center',
        },
        divider: {
            borderLeft: `1px solid ${theme.palette.action.hover}`,
            marginBottom: 20,
        },
    }),
);

interface MenuProps {
    dateRange: DateRange;
    ranges: DefinedRange[];
    minDate: Date;
    maxDate: Date;
    firstMonth: Date;
    secondMonth: Date;
    locale: Locale;
    setFirstMonth: Setter<Date>;
    setSecondMonth: Setter<Date>;
    setDateRange: Setter<DateRange>;
    helpers: {
        inHoverRange: (day: Date) => boolean;
    };
    handlers: {
        onDayClick: (day: Date) => void;
        onDayHover: (day: Date) => void;
        onMonthNavigate: (marker: symbol, action: NavigationAction) => void;
    };
}

const Menu: React.FunctionComponent<MenuProps> = (props: MenuProps) => {
    const classes = useStyles();

    const {
        ranges,
        dateRange,
        minDate,
        maxDate,
        firstMonth,
        locale,
        setFirstMonth,
        secondMonth,
        setSecondMonth,
        setDateRange,
        helpers,
        handlers,
    } = props;

    const { startDate, endDate } = dateRange;
    const canNavigateCloser = differenceInCalendarMonths(secondMonth, firstMonth) >= 2;
    const commonProps = {
        dateRange,
        minDate,
        maxDate,
        helpers,
        handlers,
    };
    return (
        <Paper elevation={5} square>
            <Grid container direction="row" wrap="nowrap">
                <Grid>
                    <Grid container className={classes.header} alignItems="center">
                        <Grid item className={classes.headerItem}>
                            <Typography variant="subtitle1">
                                {startDate
                                    ? format(startDate, 'MMMM d, yyyy', { locale: de })
                                    : 'Start Date'}
                            </Typography>
                        </Grid>
                        <Grid item className={classes.headerItem}>
                            <ArrowRightAlt color="action" />
                        </Grid>
                        <Grid item className={classes.headerItem}>
                            <Typography variant="subtitle1">
                                {endDate
                                    ? format(endDate, 'MMMM d, yyyy', { locale: de })
                                    : 'End Date'}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid container direction="row" justifySelf="center" wrap="nowrap">
                        <Month
                            {...commonProps}
                            value={firstMonth}
                            locale={locale}
                            setValue={setFirstMonth}
                            navState={[true, canNavigateCloser]}
                            marker={MARKERS.FIRST_MONTH}
                        />
                        <div className={classes.divider} />
                        <Month
                            {...commonProps}
                            value={secondMonth}
                            locale={locale}
                            setValue={setSecondMonth}
                            navState={[canNavigateCloser, true]}
                            marker={MARKERS.SECOND_MONTH}
                        />
                    </Grid>
                </Grid>
                <div className={classes.divider} />
                <Grid>
                    <DefinedRanges
                        selectedRange={dateRange}
                        ranges={ranges}
                        setRange={setDateRange}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

export default Menu;

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Button, ClickAwayListener, Divider, Tooltip } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import clsx from 'clsx';
import { KeycloakProfile } from 'keycloak-js';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import InfoModal from '../../components/Modals/InfoModal';
import { DialogContext } from '../../dialogs/DialogProvider/DialogProvider';
import { CloudModelLockStatus } from '../../generate/api';
import { useEditingState } from '../../redux/editing/hooks';
import { IExportRunningState, onExportState } from '../../redux/export/exportSlice';
import { setMenu, setSidebar, useMenu, useUser } from '../../redux/project';
import { roleTechnician } from '../../redux/project/types';
import { useTranslation } from '../../redux/translations/hook';
import AppVersion from '../AppVersion/AppVersion';
import { DF } from '../DialogFactory/DialogFactory';
import { INotificationDialogProps } from '../DialogFactory/NotificationDialog/NotificationDialog';
import LanguageSwitcher from '../Header/LanguageSwitcher';
import Icon from '../Icon/Icon';
import Logout from '../Logout/Logout';
import FormattedMessage from '../Translation/FormattedMessage';
import messages from './messages';
import useMenuStyles from './styles';

export interface INavigationMenu {
    modelLoaded: boolean;
}

const NavigationMenu: React.FC<INavigationMenu> = ({ modelLoaded }) => {
    const dispatch = useDispatch();

    const user = useUser();

    const classes = useMenuStyles();

    const reduxMenu = useMenu();

    const editingState = useEditingState();

    const { openDialog } = useContext(DialogContext);

    const { keycloak } = useKeycloak();

    const [exportDisabled, setExportDisabled] = useState<boolean>(true);
    const [fileBrowserDisabled, setFileBrowserDisabled] = useState<boolean>(false);
    const [scanningDisabled, setScanningDisabled] = useState<boolean>(true);
    const [assetsDisabled, setAssetsDisabled] = useState<boolean>(false);
    const [commissioningDisabled, setCommissioningDisabled] = useState<boolean>(false);
    const [filterDisabled, setFilterDisabled] = useState<boolean>(false);
    const [markupDisabled, setMarkupDisabled] = useState<boolean>(false);
    const [issuesDisabled, setIssuesDisabled] = useState<boolean>(false);
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [userData, setUserData] = useState<KeycloakProfile | null>(null);
    const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false);

    const handleToggleAssets = () => {
        setMenuOpen(false);
        const isAssetsOpen = !reduxMenu.assetsOpen;
        dispatch(setMenu({ assetsOpen: isAssetsOpen }));
        if (isAssetsOpen) {
            dispatch(setMenu({ issuesOpen: false, markupsOpen: false, fileBrowserOpen: false }));
        }
    };

    const handleToggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleClickAway = event => {
        // Check if the click target is not the button itself
        if (!event.target.closest('button')) {
            setMenuOpen(false);
        }
    };

    const handleToggleExport = () => {
        setMenuOpen(false);
        dispatch(setMenu({ exportOpen: true }));
        dispatch(onExportState(IExportRunningState.initializing));
    };

    const handleToggleFileBrowser = () => {
        setMenuOpen(false);
        const isFileBrowserOpen = !reduxMenu.fileBrowserOpen;
        dispatch(setMenu({ fileBrowserOpen: isFileBrowserOpen }));
        if (isFileBrowserOpen) {
            dispatch(
                setMenu({
                    assetsOpen: false,
                    scanningOpen: false,
                    markupsOpen: false,
                    issuesOpen: false,
                }),
            );
            dispatch(setSidebar(true));
        }
    };

    const checkScheduleOperations = () => {
        if (
            editingState.elementsScheduleOperations &&
            editingState.elementsScheduleOperations.length > 0
        ) {
            console.log('elementsScheduleOperations exists = Modal alert');
            openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Error', {
                content: unsavedOperationsExists,
            });
        } else {
            console.log('elementsScheduleOperations DONT exists = nothing happens');
        }
    };

    const handleToggleScanning = () => {
        if (modelLoaded) {
            const isScanningOpen = !reduxMenu.scanningOpen;
            if (reduxMenu.scanningOpen) {
                checkScheduleOperations();
            }
            dispatch(setMenu({ scanningOpen: isScanningOpen }));
            dispatch(setSidebar(isScanningOpen));
            if (isScanningOpen) {
                dispatch(
                    setMenu({
                        assetsOpen: false,
                        fileBrowserOpen: false,
                        commissioningOpen: false,
                        markupsOpen: false,
                        issuesOpen: false,
                    }),
                );
            }
        }
    };

    const handleToggleCommissioning = () => {
        const isCommissioningOpen = !reduxMenu.commissioningOpen;
        dispatch(setMenu({ commissioningOpen: isCommissioningOpen }));
        if (isCommissioningOpen) {
            dispatch(
                setMenu({
                    fileBrowserOpen: false,
                    scanningOpen: false,
                    markupsOpen: false,
                    issuesOpen: false,
                }),
            );
        }
    };

    const handleToggleMarkups = () => {
        setMenuOpen(false);

        if (reduxMenu.scanningOpen) {
            checkScheduleOperations();
            handleToggleScanning(); // close scanning
        }

        const isMarkupsOpen = !reduxMenu.markupsOpen;
        dispatch(setMenu({ markupsOpen: isMarkupsOpen }));
        if (isMarkupsOpen) {
            dispatch(
                setMenu({
                    assetsOpen: false,
                    fileBrowserOpen: false,
                    commissioningOpen: false,
                    filterOpen: false,
                    issuesOpen: false,
                    scanningOpen: false,
                }),
            );
            dispatch(setSidebar(true));
        }
    };

    const handleToggleIssues = () => {
        setMenuOpen(false);
        const isIssuesOpen = !reduxMenu.issuesOpen;
        dispatch(setMenu({ issuesOpen: isIssuesOpen }));
        if (isIssuesOpen) {
            dispatch(
                setMenu({
                    assetsOpen: false,
                    commissioningOpen: false,
                    markupsOpen: false,
                    fileBrowserOpen: false,
                    scanningOpen: false,
                }),
            );
        }
    };

    const handleToggleHelp = () => {
        setMenuOpen(false);
        setIsHelpOpen(true);
    };

    useEffect(() => {
        Promise.resolve(keycloak?.loadUserProfile()).then(data => {
            setUserData(data as KeycloakProfile);
        });
    }, [keycloak]);

    useEffect(() => {
        if (modelLoaded) {
            // setExportDisabled(false);
            setScanningDisabled(false);
        }
    }, [modelLoaded]);

    useEffect(() => {
        if (reduxMenu.scanningOpen) {
            setExportDisabled(false);
            setFileBrowserDisabled(false);
            // setScanningDisabled(false);
            setCommissioningDisabled(true);
            setFilterDisabled(true);
            setMarkupDisabled(false);
            setIssuesDisabled(false);
        } else {
            setExportDisabled(true);
            setFileBrowserDisabled(false);
            // setScanningDisabled(false);
            setCommissioningDisabled(false);
            setFilterDisabled(false);
            setMarkupDisabled(false);
            setIssuesDisabled(false);
        }
    }, [reduxMenu.scanningOpen]);

    useEffect(() => {
        if (editingState.lockState === CloudModelLockStatus.Locked) {
            setExportDisabled(false);
        } else {
            setExportDisabled(true);
        }
    }, [editingState]);

    // Filter
    const handleToggleFilter = () => {
        setMenuOpen(false);
        const isFilterOpen = !reduxMenu.filterOpen;
        dispatch(setMenu({ filterOpen: isFilterOpen }));
        dispatch(setSidebar(isFilterOpen));
        if (!(reduxMenu.issuesOpen || reduxMenu.assetsOpen)) {
            dispatch(setMenu({ filterOpen: false }));
        }
    };
    useEffect(() => {
        if (reduxMenu.assetsOpen || reduxMenu.issuesOpen) {
            setFilterDisabled(false);
        } else {
            setFilterDisabled(true);
            dispatch(setMenu({ filterOpen: false }));
        }
    }, [reduxMenu.assetsOpen, reduxMenu.issuesOpen]);

    useEffect(() => {
        if (reduxMenu.filterOpen) {
            dispatch(setMenu({ markupsOpen: false, fileBrowserOpen: false }));
        }
    }, [reduxMenu.filterOpen]);

    // Translations
    const transEditingSession = useTranslation({ ...messages.editingSession });
    const transCommissioning = useTranslation({ ...messages.commissioning });
    const unsavedOperationsExists = useTranslation({ ...messages.unsavedOperationsExists });

    // Roles
    const [exportAllowed, setExportAllowed] = useState<boolean>(false);
    const [editSessionAllowed, setEditSessionAllowed] = useState<boolean>(false);

    useEffect(() => {
        if (user?.forgeRoles?.some(role => roleTechnician.includes(role))) {
            setExportAllowed(true);
        } else {
            setExportAllowed(false);
        }
        if (user?.hasFireBimRole) {
            setEditSessionAllowed(true);
        } else {
            setEditSessionAllowed(false);
        }
    }, [user]);

    return (
        <>
            <Box className={clsx(classes.root)}>
                {editSessionAllowed && (
                    <Tooltip title={transEditingSession} arrow>
                        <div>
                            <button
                                className={clsx(
                                    classes.menuItem,
                                    reduxMenu.scanningOpen && classes.menuItemActive,
                                )}
                                onClick={handleToggleScanning}
                                disabled={scanningDisabled}
                            >
                                <Icon name={'menu-edit'} size={40} />
                            </button>
                        </div>
                    </Tooltip>
                )}
                {/*
                <Tooltip title={transCommissioning} arrow>
                    <div>
                        <button
                            className={clsx(
                                classes.menuItem,
                                reduxMenu.commissioningOpen && classes.menuItemActive,
                            )}
                            onClick={handleToggleCommissioning}
                            disabled={commissioningDisabled}
                        >
                            <Icon name={'menu-commissioning'} size={40} />
                        </button>
                    </div>
                </Tooltip>
                */}
                <div>
                    <button
                        className={clsx(classes.menuItem, menuOpen && classes.menuItemActive)}
                        onClick={handleToggleMenu}
                    >
                        <MenuIcon />
                    </button>
                </div>

                {menuOpen && (
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <Box className={classes.verticalMenu}>
                            <Box className={classes.headerUserName}>
                                {`${userData?.firstName} ${userData?.lastName}`}
                            </Box>

                            <Divider className={classes.divider} />

                            {exportAllowed && (
                                <div>
                                    <Button
                                        className={clsx(
                                            classes.menuItemRow,
                                            reduxMenu.exportOpen && classes.menuItemActive,
                                        )}
                                        onClick={handleToggleExport}
                                        disabled={exportDisabled}
                                    >
                                        <Icon name={'menu-export'} size={40} />
                                        <FormattedMessage {...messages.export} />
                                    </Button>
                                </div>
                            )}

                            <div>
                                <Button
                                    className={clsx(
                                        classes.menuItemRow,
                                        reduxMenu.fileBrowserOpen && classes.menuItemActive,
                                    )}
                                    onClick={handleToggleFileBrowser}
                                    disabled={fileBrowserDisabled}
                                >
                                    <Icon name={'menu-file-browser'} size={40} />

                                    <FormattedMessage {...messages.fileBrowser} />
                                </Button>
                            </div>

                            <div>
                                <Button
                                    className={clsx(
                                        classes.menuItemRow,
                                        reduxMenu.issuesOpen && classes.menuItemActive,
                                    )}
                                    onClick={handleToggleIssues}
                                    disabled={issuesDisabled}
                                >
                                    <Icon name={'menu-issue'} size={40} />

                                    <FormattedMessage {...messages.issues} />
                                </Button>
                            </div>

                            <div>
                                <Button
                                    className={clsx(
                                        classes.menuItemRow,
                                        reduxMenu.filterOpen && classes.menuItemActive,
                                    )}
                                    onClick={handleToggleFilter}
                                    disabled={filterDisabled}
                                >
                                    <Icon name={'menu-filter'} size={40} />

                                    <FormattedMessage {...messages.filter} />
                                </Button>
                            </div>

                            <div>
                                <Button
                                    className={clsx(
                                        classes.menuItemRow,
                                        reduxMenu.markupsOpen && classes.menuItemActive,
                                    )}
                                    onClick={handleToggleMarkups}
                                    disabled={markupDisabled}
                                >
                                    <Icon name={'menu-markup'} size={40} />

                                    <FormattedMessage {...messages.markup} />
                                </Button>
                            </div>

                            <div>
                                <Button
                                    className={clsx(
                                        classes.menuItemRow,
                                        reduxMenu.assetsOpen && classes.menuItemActive,
                                    )}
                                    onClick={handleToggleAssets}
                                    disabled={assetsDisabled}
                                >
                                    <Icon name={'menu-assets'} size={40} />

                                    <FormattedMessage {...messages.assets} />
                                </Button>
                            </div>

                            <Divider className={classes.divider} />

                            {/*<div>*/}
                            {/*    <Button*/}
                            {/*        className={clsx(classes.menuItemRow)}*/}
                            {/*        onClick={handleToggleHelp}*/}
                            {/*        disabled={assetsDisabled}*/}
                            {/*    >*/}
                            {/*        <Icon name={'menu-info'} size={40} />*/}

                            {/*        <FormattedMessage {...messages.help} />*/}
                            {/*    </Button>*/}
                            {/*</div>*/}

                            <div className={clsx(classes.menuItemLanguage, classes.menuItemRow)}>
                                <Icon name={'menu-language'} size={40} />
                                <LanguageSwitcher />
                            </div>

                            <Divider className={classes.divider} />

                            <div>
                                <Logout>
                                    <Button className={classes.menuItemRow}>
                                        <ExitToAppIcon className={classes.menuItemRowIcon} />

                                        <FormattedMessage {...messages.logout} />
                                    </Button>
                                </Logout>
                            </div>

                            <Divider className={classes.divider} />

                            <AppVersion />
                        </Box>
                    </ClickAwayListener>
                )}
            </Box>

            {/* info modal that is shown any failure elements exists in design automation response */}
            <InfoModal
                opened={isHelpOpen}
                title={useTranslation({ ...messages.help })}
                onCloseClick={() => setIsHelpOpen(false)}
            >
                <div>
                    <p>
                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Quisque tincidunt
                        scelerisque libero. Etiam sapien elit, consequat eget, tristique non,
                        venenatis quis, ante. Praesent id justo in neque elementum ultrices. Nullam
                        rhoncus aliquam metus. Nullam sit amet magna in magna gravida vehicula. Nunc
                        auctor. Etiam dui sem, fermentum vitae, sagittis id, malesuada in, quam.
                        Suspendisse nisl. Nam libero tempore, cum soluta nobis est eligendi optio
                        cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis
                        voluptas assumenda est, omnis dolor repellendus.
                    </p>
                </div>
            </InfoModal>
        </>
    );
};

export default NavigationMenu;

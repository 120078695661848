import { Button, Dialog, DialogContent, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useState } from 'react';

import { AssetReponseItem, AssetUpdateRequest } from '../../generate/api';
import { useAppDispatch } from '../../hooks';
import { assetsApi } from '../../packages/Api/data/assets/client';
import { onUpdateAssetsListRow } from '../../redux/assets';
import { useProjectState, useUser } from '../../redux/project';
import { roleElectrician } from '../../redux/project/types';
import FormattedMessage from '../Translation/FormattedMessage';
import messages from './messages';

interface ICommentEditorProps {
    asset?: AssetReponseItem;
}

const CommentEditor: React.FC<ICommentEditorProps> = ({ asset }) => {
    const project = useProjectState();

    useEffect(() => {
        const newValue = asset?.customAttributes?.find(ca => ca.attributeCode === 'comment')
            ?.value as string;
        setValue(newValue);
        setEditingValue(newValue);
    }, []);

    const [value, setValue] = useState<string>('');
    const [editingValue, setEditingValue] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const user = useUser();

    const [isEditing, setIsEditing] = useState<boolean>(false);

    const handleChanges = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setEditingValue(event.target.value as string);
    };

    const handleCLoseDialog = () => {
        if (!isEditing) {
            setOpen(false);
        }
    };

    const handleOpen = e => {
        if (!canEdit) return;
        e.stopPropagation();
        setOpen(true);
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCancel = () => {
        setEditingValue(value);
        setIsEditing(false);
    };

    // const revitId = asset?.customAttributes?.find(x => x.attributeCode === 'revitId')?.value;
    const revitId = asset?.elementID as string;

    const handleSave = () => {
        if (!revitId) {
            console.log('No revitId', asset);
            return;
        }

        setIsSaving(true);

        const data: AssetUpdateRequest = {
            forgeProjectID: project.project?.id as string,
            forgeFileVersionUrn: project.file.currentVersion?.data.urn as string,
            assetName: asset?.assetName as string,
            statusName: asset?.statusName as string, // todo test
            elementID: revitId as string,
            comment: editingValue,
        };
        assetsApi
            .assetUpdatePost(data)
            .then(response => {
                const responseComment = response.data.asset?.customAttributes?.find(
                    x => x.attributeCode === 'comment',
                )?.value as string;
                dispatch(
                    onUpdateAssetsListRow({
                        elementId: revitId as string,
                        target: 'comment',
                        value: responseComment,
                    }),
                );
                setValue(responseComment);
                setEditingValue(responseComment);
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                setIsSaving(false);
                setIsEditing(false);
                setOpen(false);
            });
    };

    // Roles
    const [canEdit, setCanEdit] = useState(false);
    useEffect(() => {
        if (user?.hasFireBimRole) {
            setCanEdit(true);
        }
        if (user?.forgeRoles?.some(role => roleElectrician.includes(role))) {
            setCanEdit(false);
        }
    }, [user]);

    return (
        <>
            <div
                className="min-w-[100px] min-h-[28px] max-w-[300px] w-full text-ellipsis overflow-hidden whitespace-nowrap"
                onClick={handleOpen}
            >
                {value}
            </div>
            <Dialog open={open} fullWidth onClose={handleCLoseDialog}>
                <DialogContent>
                    <div>
                        <div className="font-bold text-md mb-2">
                            <FormattedMessage {...messages.titleComment} />:
                        </div>
                        {isEditing ? (
                            <TextField
                                multiline
                                rows={4}
                                onChange={handleChanges}
                                value={editingValue}
                                fullWidth
                            />
                        ) : (
                            <TextField
                                multiline
                                rows={4}
                                onChange={handleChanges}
                                value={editingValue}
                                fullWidth
                                disabled
                            />
                        )}
                    </div>
                    <div className="flex gap-2">
                        {isEditing ? (
                            <>
                                <Button
                                    onClick={handleSave}
                                    variant={'contained'}
                                    disabled={isSaving}
                                >
                                    {isSaving && (
                                        <CircularProgress
                                            size={10}
                                            color="primary"
                                            sx={{ mr: 1 }}
                                        />
                                    )}
                                    <FormattedMessage {...messages.buttonSave} />
                                </Button>
                                <Button
                                    onClick={handleCancel}
                                    variant={'outlined'}
                                    disabled={isSaving}
                                >
                                    <FormattedMessage {...messages.buttonCancel} />
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button onClick={handleEdit} variant={'contained'}>
                                    <FormattedMessage {...messages.buttonEdit} />
                                </Button>
                                <Button onClick={handleClose} variant={'outlined'}>
                                    <FormattedMessage {...messages.buttonClose} />
                                </Button>
                            </>
                        )}
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default CommentEditor;

import { Typography } from '@mui/material';
import React from 'react';

import { IBox } from '../Viewer/SensorsWrapper';
import useStyles from '../Viewer/styles';

export interface IBoxOperationsInfo {
    box: IBox;
}

const BoxOperationsInfo: React.FC<IBoxOperationsInfo> = ({ box }) => {
    const classes = useStyles();
    return (
        <>
            {(box.rotated || box.created || box.copied || box.deleted || box.moved) && (
                <div className={classes.operationsInfo}>
                    {box.created && (
                        <Typography className={classes.operationsInfoItem}>CREATED</Typography>
                    )}
                    {box.copied && (
                        <Typography className={classes.operationsInfoItem}>COPIED</Typography>
                    )}
                    {box.moved && (
                        <Typography className={classes.operationsInfoItem}>MOVED</Typography>
                    )}
                    {box.rotated && (
                        <Typography className={classes.operationsInfoItem}>
                            ROTATED: {Math.round(box.rotate?.angle ?? 0)}°
                        </Typography>
                    )}
                    {box.deleted && (
                        <Typography className={classes.operationsInfoItem}>DELETED</Typography>
                    )}
                </div>
            )}
        </>
    );
};

export default BoxOperationsInfo;

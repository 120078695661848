import { isPlatform } from '@ionic/react';
import { Tooltip } from '@mui/material';
import React from 'react';

import config from '../../config/config';

const AppVersion: React.FC = () => {
    const isPlatformCapacitor = isPlatform('capacitor');
    const version = config.build.lastVersion;
    const tooltipTitle = `${config.build.lastUpdate} - ${version}`;

    return (
        <>
            <div className="p-2 text-sm flex justify-between gap-4">
                <div className="font-bold">Version:</div>
                {isPlatformCapacitor ? (
                    <div className="overflow-auto">
                        <div className="truncate">{version}</div>
                    </div>
                ) : (
                    <Tooltip title={tooltipTitle} arrow>
                        <div className="overflow-auto">
                            <div className="truncate">{version}</div>
                        </div>
                    </Tooltip>
                )}
            </div>
        </>
    );
};

export default AppVersion;

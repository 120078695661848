import clsx from 'clsx';
import React from 'react';

import { IssueMarkup } from '../../../generate/api';
import FormattedMessage from '../../Translation/FormattedMessage';
import messages from '../messagesSidebar';
import MarkupThumbnail from './MarkupThumbnail';

interface IMarkupSelectorProps {
    markups: IssueMarkup[];
    open: boolean;
    onSelectMarkup: (markup: IssueMarkup) => void;
}

interface IMarkupRowProps {
    markup: IssueMarkup;
    onSelectMarkup: (markup: IssueMarkup) => void;
}

const MarkupRow: React.FC<IMarkupRowProps> = ({ markup, onSelectMarkup }) => {
    const handleSelectMarkup = (selectedMarkup: IssueMarkup) => {
        onSelectMarkup(selectedMarkup);
    };

    return (
        <div
            className={clsx(
                'flex gap-2 cursor-pointer hover:bg-gray-100 p-2',
                markup.isAttachedToIssue && 'opacity-30 pointer-events-none cursor-default',
            )}
            onClick={() => handleSelectMarkup(markup)}
        >
            <div className="max-w-[100px] ">
                <MarkupThumbnail markup={markup} />
            </div>
            <div className="overflow-hidden whitespace-nowrap overflow-ellipsis text-sm">
                {markup.name}
            </div>
        </div>
    );
};

const MarkupSelector: React.FC<IMarkupSelectorProps> = ({ markups, onSelectMarkup, open }) => {
    if (!open) {
        return <></>;
    }

    if (markups.length === 0) {
        return (
            <div className="border border-primary rounded p-5 text-center">
                <FormattedMessage {...messages.messageNoMarkups} />
            </div>
        );
    }

    return (
        <div className="border border-primary rounded">
            {markups?.map((markup, index) => {
                return <MarkupRow key={index} markup={markup} onSelectMarkup={onSelectMarkup} />;
            })}
        </div>
    );
};

export default MarkupSelector;

import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import '../../theme/variables.css';

import { Plugins } from '@capacitor/core';
import { LanguageProvider } from '@iolabs/language-provider';
import { GlobalDialogProvider } from '@iolabs/wip-dialog';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import React from 'react';
import { Route } from 'react-router-dom';

import DialogProvider from '../../dialogs/DialogProvider/DialogProvider';
import LoginPage from '../../pages/LoginPage/LoginPage';
import SecurityProvider from '../../redux/keyclock/provider';
import { StoreProvider } from '../../redux/provider';
import theme from '../../theme/Theme';
import SignalRProvider from '../SignalRProvider/SignalRProvider';
import LanguageProvider2 from '../Translation/LanguageProvider';
import de from './../../translations/de.json';
import en from './../../translations/en.json';
import fr from './../../translations/fr.json';
import App from './App';
import { PrivateRoute } from './PrivateRoute';
import PublicApp from './PublicApp';

const { Network } = Plugins;

type NetworkStatus = {
    connected: boolean;
    connectionType: string;
};

const AppWrapper: React.FC = () => {
    // const [networkStatus, setNetworkStatus] = useState<NetworkStatus>({
    //     connected: true,
    //     connectionType: "wifi",
    // });

    // useEffect(() => {
    //     // detecting connection status only in native platforms
    //     Network.addListener("networkStatusChange", (status) => {
    //         setNetworkStatus(status);
    //     });
    // }, []);

    const renderApp = () => {
        return (
            <SecurityProvider>
                <LanguageProvider2>
                    <SignalRProvider>
                        <GlobalDialogProvider>
                            <DialogProvider>
                                <IonApp>
                                    <IonReactRouter>
                                        <IonRouterOutlet animated={false}>
                                            <Route path="/public" component={PublicApp} />
                                            <PrivateRoute component={App} />
                                            <Route path="/" exact component={LoginPage} />
                                        </IonRouterOutlet>
                                    </IonReactRouter>
                                </IonApp>
                            </DialogProvider>
                        </GlobalDialogProvider>
                    </SignalRProvider>
                </LanguageProvider2>
            </SecurityProvider>
        );
    };

    return (
        <StoreProvider>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <LanguageProvider
                        defaultLocale="en"
                        messages={{
                            de,
                            fr,
                            en,
                        }}
                    >
                        {renderApp()}
                    </LanguageProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </StoreProvider>
    );
};

export default AppWrapper;

/* tslint:disable */
/* eslint-disable */
/**
 * FireBIM API
 * API definitions for FireBim
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateTranslationRequest } from '../models';
// @ts-ignore
import { CreateTranslationResponse } from '../models';
// @ts-ignore
import { LanguageListResponse } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { TranslationListResponse } from '../models';
/**
 * TranslationsModuleApi - axios parameter creator
 * @export
 */
export const TranslationsModuleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates translation for given translation key if does not exists.
         * @param {CreateTranslationRequest} [createTranslationRequest] CREATE translation request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationsCreatePost: async (createTranslationRequest?: CreateTranslationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Translations/Create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTranslationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Exports all translations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationsExportGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Translations/Export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Imports all translations.
         * @param {File} [fileData] Posted excel file.
         * @param {boolean} [createNonExistent] Allow creating non-existent translations (based on key)?.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationsImportPost: async (fileData?: File, createNonExistent?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Translations/Import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


            if (fileData !== undefined) { 
                localVarFormParams.append('FileData', fileData as any);
            }
    
            if (createNonExistent !== undefined) { 
                localVarFormParams.append('CreateNonExistent', createNonExistent as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets supported languages list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationsLanguagesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Translations/Languages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all translations for given language.
         * @param {string} [language] Language code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationsListGet: async (language?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Translations/List`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reloads all translations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationsReloadGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Translations/Reload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TranslationsModuleApi - functional programming interface
 * @export
 */
export const TranslationsModuleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TranslationsModuleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates translation for given translation key if does not exists.
         * @param {CreateTranslationRequest} [createTranslationRequest] CREATE translation request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async translationsCreatePost(createTranslationRequest?: CreateTranslationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateTranslationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.translationsCreatePost(createTranslationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Exports all translations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async translationsExportGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.translationsExportGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Imports all translations.
         * @param {File} [fileData] Posted excel file.
         * @param {boolean} [createNonExistent] Allow creating non-existent translations (based on key)?.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async translationsImportPost(fileData?: File, createNonExistent?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.translationsImportPost(fileData, createNonExistent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets supported languages list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async translationsLanguagesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LanguageListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.translationsLanguagesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all translations for given language.
         * @param {string} [language] Language code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async translationsListGet(language?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TranslationListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.translationsListGet(language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reloads all translations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async translationsReloadGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.translationsReloadGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TranslationsModuleApi - factory interface
 * @export
 */
export const TranslationsModuleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TranslationsModuleApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates translation for given translation key if does not exists.
         * @param {CreateTranslationRequest} [createTranslationRequest] CREATE translation request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationsCreatePost(createTranslationRequest?: CreateTranslationRequest, options?: any): AxiosPromise<CreateTranslationResponse> {
            return localVarFp.translationsCreatePost(createTranslationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Exports all translations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationsExportGet(options?: any): AxiosPromise<File> {
            return localVarFp.translationsExportGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Imports all translations.
         * @param {File} [fileData] Posted excel file.
         * @param {boolean} [createNonExistent] Allow creating non-existent translations (based on key)?.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationsImportPost(fileData?: File, createNonExistent?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.translationsImportPost(fileData, createNonExistent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets supported languages list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationsLanguagesGet(options?: any): AxiosPromise<LanguageListResponse> {
            return localVarFp.translationsLanguagesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all translations for given language.
         * @param {string} [language] Language code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationsListGet(language?: string, options?: any): AxiosPromise<TranslationListResponse> {
            return localVarFp.translationsListGet(language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reloads all translations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationsReloadGet(options?: any): AxiosPromise<void> {
            return localVarFp.translationsReloadGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TranslationsModuleApi - object-oriented interface
 * @export
 * @class TranslationsModuleApi
 * @extends {BaseAPI}
 */
export class TranslationsModuleApi extends BaseAPI {
    /**
     * 
     * @summary Creates translation for given translation key if does not exists.
     * @param {CreateTranslationRequest} [createTranslationRequest] CREATE translation request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationsModuleApi
     */
    public translationsCreatePost(createTranslationRequest?: CreateTranslationRequest, options?: AxiosRequestConfig) {
        return TranslationsModuleApiFp(this.configuration).translationsCreatePost(createTranslationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Exports all translations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationsModuleApi
     */
    public translationsExportGet(options?: AxiosRequestConfig) {
        return TranslationsModuleApiFp(this.configuration).translationsExportGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Imports all translations.
     * @param {File} [fileData] Posted excel file.
     * @param {boolean} [createNonExistent] Allow creating non-existent translations (based on key)?.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationsModuleApi
     */
    public translationsImportPost(fileData?: File, createNonExistent?: boolean, options?: AxiosRequestConfig) {
        return TranslationsModuleApiFp(this.configuration).translationsImportPost(fileData, createNonExistent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets supported languages list.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationsModuleApi
     */
    public translationsLanguagesGet(options?: AxiosRequestConfig) {
        return TranslationsModuleApiFp(this.configuration).translationsLanguagesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all translations for given language.
     * @param {string} [language] Language code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationsModuleApi
     */
    public translationsListGet(language?: string, options?: AxiosRequestConfig) {
        return TranslationsModuleApiFp(this.configuration).translationsListGet(language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reloads all translations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationsModuleApi
     */
    public translationsReloadGet(options?: AxiosRequestConfig) {
        return TranslationsModuleApiFp(this.configuration).translationsReloadGet(options).then((request) => request(this.axios, this.basePath));
    }
}

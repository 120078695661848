import { DispatchAction } from '@iolabs/redux-utils';
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    MenuItem,
    Select,
    Stack,
    TextField,
} from '@mui/material';
import format from 'date-fns/format';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
    DateOnly,
    ForgeProjectRole,
    Issue,
    IssueAttachmentItem,
    IssueAttachmentRequest,
    IssueCreateRequest,
    IssueMarkup,
    IssuePrepareRequest,
    IssueRelevantMarkupListRequest,
    IssueUpdateRequest,
    UploadFileResponse,
} from '../../../generate/api';
import { issuesApi } from '../../../packages/Api/data/issues/client';
import { uploadApi } from '../../../packages/Api/data/upload/client';
import {
    setCurrenIssuePushpin,
    setCurrentIssue,
    setCurrentIssueDetails,
    setMode,
    setPushpinInsertMode,
    useIssuesState,
} from '../../../redux/issues';
import { addNotification } from '../../../redux/notifier';
import { useProjectState } from '../../../redux/project';
import { useTranslation } from '../../../redux/translations/hook';
import { getObjectId } from '../../Markups/createObjectId';
import FormattedMessage from '../../Translation/FormattedMessage';
import DueDatePicker from '../DueDatePicker';
import messages from '../messagesSidebar';
import useStyles from '../styles';
import EditorAttachmentRow from './EditorAttachmentRow';
import EditorCurrentMarkup from './EditorCurrentMarkup';
import MarkupSelector from './MarkupSelector';

interface IEditorProps {
    viewable?: any;
    onCancel: () => void;
    onSave: () => void;
}

const Editor: React.FC<IEditorProps> = ({ onCancel, onSave, viewable }) => {
    const classes = useStyles();
    const dispatch = useDispatch<DispatchAction>();
    const project = useProjectState();
    const forgeProjectId = project?.project?.id;
    const fileVersionUrn = project?.file?.currentVersion?.data?.urn;

    useEffect(() => {
        return () => {
            dispatch(setMode('view'));
        };
    }, []);

    // From redux
    const reduxIssues = useIssuesState();
    const currentIssue = reduxIssues.currentIssue;
    const currentIssueDetails = reduxIssues.currentIssueDetails;
    const mode = reduxIssues.mode;
    const currentPushpin = reduxIssues.currentPushpin;
    const pushpinInsertMode = reduxIssues.pushpin?.insertMode;

    // Roles
    const [roles, setRoles] = useState<ForgeProjectRole[]>([]);

    // Edited fields
    const [title, setTitle] = useState<string>('');
    const [assignTo, setAssignTo] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [dueDate, setDueDate] = useState<string | undefined>(new Date().toJSON());
    const [dueDateChecked, setDueDateChecked] = useState<boolean>(false);
    const [newAttachments, setNewAttachments] = useState<UploadFileResponse[]>([]);
    const [currentMarkup, setCurrentMarkup] = useState<IssueMarkup>();

    // Upload
    const [uploading, setUploading] = useState<boolean>(false);

    // Markups
    const [markups, setMarkups] = useState<IssueMarkup[]>([]);
    const [markupsSelectorOpen, setMarkupsSelectorOpen] = useState<boolean>(false);

    // Request data for submit
    const [requestData, setRequestData] = useState<IssueCreateRequest | IssueUpdateRequest>();

    // Saving
    const [saving, setSaving] = useState<boolean>(false);
    const [saveAvailable, setSaveAvailable] = useState<boolean>(false);

    // -------------------------------------------------------

    // Cache
    const [cacheIssue, setCacheIssue] = useState<Issue>();
    const [cacheAttachments, setCacheAttachments] = useState<IssueAttachmentItem[] | []>();

    // Translations
    const transMessageIssueUpdated = useTranslation({
        ...messages.messageIssueUpdated,
    });
    const transMessageIssueUpdateFailed = useTranslation({
        ...messages.messageIssueUpdateFailed,
    });
    const transMessageIssueCreated = useTranslation({
        ...messages.messageIssueCreated,
    });
    const transMessageIssueCreationFailed = useTranslation({
        ...messages.messageIssueCreationFailed,
    });

    // INIT Edit
    useEffect(() => {
        const findAssigneeRoleId = (): string => {
            const memberGroupID = currentIssueDetails?.issue?.assignedToUserID;
            return (_.find(roles, { memberGroupID: memberGroupID })?.forgeRoleID as string) ?? '';
        };

        setCacheIssue(currentIssueDetails?.issue);
        setCacheAttachments(currentIssueDetails?.attachments ?? []);
        setTitle(currentIssueDetails?.issue?.title ?? '');
        setAssignTo(findAssigneeRoleId());
        setDescription(currentIssueDetails?.issue?.description ?? '');

        // Pushpin
        dispatch(
            setCurrenIssuePushpin({
                objectId: currentIssueDetails?.issue?.linkedDocument?.objectID as number,
                coordinates: {
                    x: currentIssueDetails?.issue?.linkedDocument?.position?.x,
                    y: currentIssueDetails?.issue?.linkedDocument?.position?.y,
                    z: currentIssueDetails?.issue?.linkedDocument?.position?.z,
                },
                externalId: currentIssueDetails?.issue?.linkedDocument?.externalID,
            }),
        );
        if (mode === 'create') {
            dispatch(setPushpinInsertMode(true));
        }

        // Due date
        setDueDate(currentIssueDetails?.issue?.dueDate ?? '');
        if (currentIssueDetails?.issue?.dueDate) {
            setDueDateChecked(true);
        }

        // Markup
        const objectMarkupID = currentIssueDetails?.issue?.objectMarkupID;
        const markup = _.find(markups, { objectMarkupID: objectMarkupID }) as IssueMarkup;
        setCurrentMarkup(markup);
    }, [currentIssueDetails, roles, mode, markups]);

    // INIT
    useEffect(() => {
        fetchPrepareIssue();
        fetchGetMarkups();
        return () => {
            dispatch(setCurrenIssuePushpin(undefined));
            dispatch(setPushpinInsertMode(false));
        };
    }, []);

    // INIT create
    useEffect(() => {
        if (mode === 'create') {
            dispatch(setCurrentIssueDetails(undefined));
            dispatch(setCurrentIssue(undefined));
        }
    }, [mode]);

    const handleTitleChange = event => {
        setTitle(event.target.value as string);
    };

    const handleAssignToChange = event => {
        setAssignTo(event.target.value as string);
    };

    const handleDescriptionChange = event => {
        setDescription(event.target.value as string);
    };

    const handleDueDateChange = date => {
        if (!date) {
            return;
        }
        const newDate = new Date(date);
        const formattedDate = format(newDate, 'yyyy-MM-dd');
        setDueDate(formattedDate);
    };

    const handleDueDateCheckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDueDateChecked(event.target.checked);
        if (!event.target.checked) {
            setDueDate(undefined);
        }
    };

    const handleFileUpload = event => {
        const file = event.target.files[0];
        setUploading(true);
        uploadApi
            .apiUploadUploadFilePost(file)
            .then(response => {
                console.log('## Upload - Upload file', response);
                setNewAttachments([...newAttachments, response.data]);
            })
            .catch(error => {
                console.error('## Upload - Upload file', error);
                dispatch(
                    addNotification({
                        variant: 'error',
                        message: `Problem with uploading file`,
                    }),
                );
            })
            .finally(() => {
                setUploading(false);
            });
    };

    const handleDeleteNewAttachment = (guid: string) => {
        // index of attachment in newAttachments
        const index = _.findIndex(newAttachments, { guid: guid });
        if (index === -1) return;

        // remove attachment from newAttachments
        const newAttachmentsCopy = [...newAttachments];
        newAttachmentsCopy.splice(index, 1);

        // update state
        setNewAttachments(newAttachmentsCopy);
    };

    const fetchPrepareIssue = () => {
        const newRequestData: IssuePrepareRequest = {
            forgeProjectId: forgeProjectId as string,
        };
        issuesApi
            .issuesPrepareIssuePost(newRequestData)
            .then(response => {
                console.log('## Issues - Prepare issue', response);
                setRoles(response.data.roles as ForgeProjectRole[]);
            })
            .catch(error => {
                console.error('## Issues - Prepare issue', error);
                dispatch(
                    addNotification({
                        variant: 'error',
                        message: `Problem with fetching roles`,
                    }),
                );
            });
    };

    const fetchGetMarkups = () => {
        const newRequestData: IssueRelevantMarkupListRequest = {
            forgeProjectId: project.project?.id,
            forgeVersionUrn: fileVersionUrn,
            forgeViewableId: viewable.id.toString(),
            objectID: getObjectId(fileVersionUrn, viewable.guid as string),
        };
        console.log('requestData', newRequestData);
        issuesApi
            .issuesRelevantMarkupsPost(newRequestData)
            .then(response => {
                console.log('## Issues - Relevant markups', response);
                setMarkups(response.data.markups as IssueMarkup[]);
            })
            .catch(error => {
                console.error('## Issues - Relevant markups', error);
                dispatch(
                    addNotification({
                        variant: 'error',
                        message: `Problem with fetching related markups`,
                    }),
                );
            });
    };

    const handleSelectedMarkup = (markup: IssueMarkup) => {
        console.log('handleSelectedMarkup', markup);
        setMarkupsSelectorOpen(false);
        setCurrentMarkup(markup);
    };

    const handleClickSelectMarkup = () => {
        setMarkupsSelectorOpen(true);
    };

    const handleClickUnlinkMarkup = () => {
        setCurrentMarkup(undefined);

        /*
         * TODO
         *  get id unlinked markup
         *  in relevant markups find unlinked markup
         *  set for this markup isAttachedToIssue = false
         * */
    };

    const handleClickAddPushpin = () => {
        dispatch(setPushpinInsertMode(true));
    };

    const handleClickCancel = () => {
        onCancel();
    };

    const handleClickSave = () => {
        setSaving(true);

        // prepare requestData
        const prepareRequestData: IssueUpdateRequest = {
            ...requestData,
        } as IssueUpdateRequest;
        delete prepareRequestData.linkedDocument?.createdAtVersion;
        delete prepareRequestData.linkedDocument?.closedAtVersion;

        if ((prepareRequestData?.dueDate as string) === '') {
            prepareRequestData.dueDate = undefined;
        }

        issuesApi
            .issuesUpdatePost(prepareRequestData)
            .then(response => {
                console.log('## Issues - Update', response);
                dispatch(
                    addNotification({
                        variant: 'success',
                        message: transMessageIssueUpdated,
                    }),
                );
                onSave();
            })
            .catch(error => {
                console.error('## Issues - Update', error);
                dispatch(
                    addNotification({
                        variant: 'error',
                        message: transMessageIssueUpdateFailed,
                    }),
                );
            })
            .finally(() => {
                setSaving(false);
            });
    };

    const handleClickCreate = () => {
        setSaving(true);
        issuesApi
            .issuesCreatePost(requestData as IssueCreateRequest)
            .then(response => {
                console.log('## Issues - Create', response);
                dispatch(
                    addNotification({
                        variant: 'success',
                        message: transMessageIssueCreated,
                    }),
                );
                onSave();
            })
            .catch(error => {
                console.error('## Issues - Create', error);
                dispatch(
                    addNotification({
                        variant: 'error',
                        message: transMessageIssueCreationFailed,
                    }),
                );
            })
            .finally(() => {
                setSaving(false);
            });
    };

    // Mandatory form fields validation for save button availability
    useEffect(() => {
        if (
            title &&
            title.length > 0 &&
            assignTo &&
            assignTo.length > 0 &&
            currentPushpin?.externalId &&
            currentPushpin?.externalId.length > 0
        ) {
            setSaveAvailable(true);
        } else {
            setSaveAvailable(false);
        }
    }, [title, assignTo, currentPushpin?.externalId]);

    // Update data flow
    // -------------------------------------------------------

    useEffect(() => {
        const currentAttachments: IssueAttachmentRequest[] = (cacheAttachments || []).map(
            attachment => {
                return {
                    fileName: attachment.name as string,
                    guid: attachment.issueAttachmentID as string,
                    type: 'Forge',
                };
            },
        );

        const newUploadedAttachments: IssueAttachmentRequest[] = newAttachments.map(attachment => {
            return {
                fileName: attachment.fileName as string,
                guid: attachment.guid as string,
                type: 'Temp',
            };
        });

        const toRequestData: IssueCreateRequest | IssueUpdateRequest = {
            forgeIssueId: currentIssue?.forgeIssueID,
            forgeProjectId: forgeProjectId as string,
            forgeVersionUrn: fileVersionUrn as string,
            title: title,
            assigneeRoleId: assignTo,
            dueDate: dueDate as DateOnly,
            description: description,
            linkedDocument: {
                type: 'TwoDVectorPushpin',
                createdAtVersion: project.file.currentVersion?.data.version,
                closedAtVersion: project.file.currentVersion?.data.version,
                details: {
                    externalId: currentPushpin?.externalId,
                    objectId: currentPushpin?.objectId as number,
                    position: currentPushpin?.coordinates,
                    viewable: {
                        guid: viewable?.guid(),
                        is3D: viewable?.is3D(),
                        name: viewable?.name(),
                        viewableId: viewable?.data?.viewableID,
                    },
                },
            },
            attachments: [...currentAttachments, ...newUploadedAttachments],
            objectMarkupID: currentMarkup?.objectMarkupID,
        };

        if (String(dueDate) === '') {
            delete toRequestData.dueDate;
        }

        // console.log('requestData', toRequestData);
        setRequestData(toRequestData);
    }, [
        title,
        assignTo,
        description,
        dueDate,
        dueDateChecked,
        newAttachments,
        currentMarkup,
        currentPushpin,
    ]);

    return (
        <div className="relative">
            {saving && (
                <div className="absolute inset-0 bg-white bg-opacity-70 flex items-center justify-center z-10">
                    <CircularProgress size={50} />
                </div>
            )}
            <div className="flex justify-between">
                <div className="text-lg font-bold">
                    {mode === 'create' && <FormattedMessage {...messages.titleCreateIssue} />}
                    {mode === 'edit' && <FormattedMessage {...messages.titleUpdateIssue} />}
                </div>
            </div>

            <div className="border-t border-gray-200 my-5"></div>

            <Box mb={2}>
                <label htmlFor="label-title">
                    <Box fontWeight="bold">
                        <FormattedMessage {...messages.labelTitle} />{' '}
                        <span className={classes.labelRequired}>*</span>
                    </Box>
                    <TextField
                        id="label-title"
                        variant="outlined"
                        fullWidth
                        value={title}
                        onChange={handleTitleChange}
                        size="small"
                        required /* todo */
                    />
                </label>
            </Box>

            <Box mb={2}>
                <label htmlFor="label-select">
                    <Box fontWeight="bold">
                        <FormattedMessage {...messages.labelAssignTo} />{' '}
                        <span className={classes.labelRequired}>*</span>
                    </Box>
                    <FormControl fullWidth>
                        <Select
                            id="label-select"
                            variant="outlined"
                            value={assignTo}
                            onChange={handleAssignToChange}
                            size="small"
                        >
                            <MenuItem value="">-</MenuItem>
                            {roles.map((role, index) => {
                                if (!role.isActive) return false;
                                if (!role.forgeRoleID) return false;
                                return (
                                    <MenuItem value={role.forgeRoleID} key={index}>
                                        {role.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </label>
            </Box>

            <Box mb={3}>
                <label htmlFor="label-due-date">
                    <Box fontWeight="bold">
                        <Checkbox
                            id="label-due-date"
                            checked={dueDateChecked}
                            onChange={handleDueDateCheckedChange}
                            className={classes.checkboxDueDate}
                            color="primary"
                        />
                        <FormattedMessage {...messages.labelDueDate} />
                    </Box>
                    {dueDateChecked && (
                        <DueDatePicker onChange={handleDueDateChange} value={dueDate} />
                    )}
                </label>
            </Box>

            <Box mb={3}>
                <label htmlFor="label-desc">
                    <Box fontWeight="bold">
                        <FormattedMessage {...messages.labelDescription} />
                    </Box>
                    <TextField
                        id="label-desc"
                        multiline
                        rows={3}
                        fullWidth
                        variant="outlined"
                        value={description}
                        size="small"
                        onChange={handleDescriptionChange}
                    />
                </label>
            </Box>

            <Box mb={3}>
                <label>
                    <Box fontWeight="bold">
                        <FormattedMessage {...messages.labelMarkup} />
                    </Box>
                </label>

                <div className="my-3">
                    <EditorCurrentMarkup markup={currentMarkup} />
                </div>
                <div className="my-3">
                    <MarkupSelector
                        markups={markups}
                        onSelectMarkup={handleSelectedMarkup}
                        open={markupsSelectorOpen}
                    />
                </div>

                <div className="my-3">
                    <span className="flex gap-2 justify-between">
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleClickSelectMarkup}
                        >
                            <FormattedMessage {...messages.buttonSelectMarkup} />
                        </Button>
                        {currentMarkup && (
                            <Button
                                variant="outlined"
                                color="error"
                                onClick={handleClickUnlinkMarkup}
                            >
                                <FormattedMessage {...messages.buttonUnlinkMarkup} />
                            </Button>
                        )}
                    </span>
                </div>
            </Box>

            <Box mb={3}>
                <label>
                    <Box fontWeight="bold">
                        <FormattedMessage {...messages.labelAttachments} />
                    </Box>
                </label>
                <div className="my-3">
                    {cacheAttachments?.map((attachment, index) => {
                        return (
                            <EditorAttachmentRow
                                key={index}
                                name={attachment.name as string}
                                attachment={attachment}
                            />
                        );
                    })}
                    {newAttachments.map((attachment, index) => {
                        return (
                            <EditorAttachmentRow
                                key={index}
                                name={attachment.fileName as string}
                                guid={attachment.guid as string}
                                newAttachment
                                onDelete={handleDeleteNewAttachment}
                            />
                        );
                    })}
                </div>
                <div>
                    {uploading ? (
                        <div className="flex gap-2 bg-gray-100 p-2 rounded mb-2">
                            <CircularProgress size={20} />
                            <div>
                                <FormattedMessage {...messages.messageUploading} />
                            </div>
                        </div>
                    ) : (
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Button variant="outlined" component="label">
                                <FormattedMessage {...messages.buttonUploadNewFile} />
                                <input
                                    hidden
                                    accept="image/*"
                                    multiple
                                    type="file"
                                    onChange={handleFileUpload}
                                />
                            </Button>
                        </Stack>
                    )}
                </div>
            </Box>

            <Box mb={3}>
                <label>
                    <Box fontWeight="bold">
                        <FormattedMessage {...messages.labelPushpin} />{' '}
                        <span className={classes.labelRequired}>*</span>
                    </Box>
                </label>
                <div className="my-3">
                    {!pushpinInsertMode && (
                        <Button variant="outlined" color="primary" onClick={handleClickAddPushpin}>
                            <FormattedMessage {...messages.buttonPlacePushpin} />
                        </Button>
                    )}
                    {currentPushpin?.externalId ? (
                        <div className="mt-2 text-green-500 font-bold text-sm">
                            <FormattedMessage {...messages.messagePushpinPlacedCorrectly} />
                        </div>
                    ) : (
                        <div className="mt-2 text-red-500 font-bold text-sm">
                            <FormattedMessage {...messages.messagePushpinPlacedIncorrectly} />
                        </div>
                    )}
                </div>
            </Box>

            <div className="border-t border-gray-200 my-5"></div>

            <div className="flex w-full gap-4 mb-20">
                <Button variant="outlined" color="primary" className="" onClick={handleClickCancel}>
                    <FormattedMessage {...messages.buttonCancel} />
                </Button>
                <div className="flex-1">
                    {mode === 'create' && (
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleClickCreate}
                            disabled={!saveAvailable}
                        >
                            <FormattedMessage {...messages.buttonCreate} />
                        </Button>
                    )}

                    {mode === 'edit' && (
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleClickSave}
                            disabled={!saveAvailable}
                        >
                            <FormattedMessage {...messages.buttonUpdate} />
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Editor;

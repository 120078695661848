import { isPlatform } from '@ionic/react';
import { Button } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Path } from '../PageRouter';
import styles from './login.module.css';
import { ReactComponent as LogoSvg } from './logo-siemens.svg';

const LoginPage = () => {
    const { keycloak, initialized: keycloakInitialized } = useKeycloak();
    const history = useHistory();

    useEffect(() => {
        if ((keycloakInitialized && keycloak?.authenticated == true) || isPlatform('desktop')) {
            history.push(Path.PROJECT_SETUP);
        }
    }, [keycloakInitialized, keycloak]);

    return (
        <div className="p-10 h-full flex items-center justify-center">
            <div>
                <div className="w-[200px] flex">
                    <div className={styles.logo}>
                        <LogoSvg className={styles.logo} />
                    </div>
                </div>
                <Button
                    href={Path.PROJECT_SETUP}
                    variant={'contained'}
                    className="text-xl table px-7 py-3 mx-auto"
                >
                    Login
                </Button>
            </div>
        </div>
    );
};

export default LoginPage;

import React from 'react';
import { useHistory } from 'react-router-dom';

import { Path } from '../../pages/PageRouter';
import { ReactComponent as LogoSvg } from './icons/logo-siemens.svg';
import { ReactComponent as LogoMobileSvg } from './icons/logo-siemens-mobile.svg';

const Logo: React.FC = () => {
    const history = useHistory();

    const handleRootClick = () => {
        window.location.reload();
        history.push(Path.ROOT);
    };

    return (
        <>
            <div
                className="hidden md:flex items-center mx-5 cursor-pointer"
                onClick={handleRootClick}
            >
                <LogoSvg />
            </div>
            <div
                className="flex md:hidden items-center mx-3 cursor-pointer"
                onClick={handleRootClick}
            >
                <LogoMobileSvg />
            </div>
        </>
    );
};

export default Logo;

export enum IFilterType {
    ISSUES = 'issues',
    ASSETS = 'assets',
}

export enum IFilterView {
    FILTER = 'filter',
    TREE = 'tree',
}

export type IFilterGroup = {
    controlGroupID: number;
    title: string;
};

export type IFilterDefinition = {
    order: number;
    controlGroupID: number;
    caption?: string;
    dataType?: string;
    multiChoice: boolean;
    hasGroups: boolean;
    parameterName?: string;
    values: IFilterValue[];
    groupedValues: IFilterGroupValue[];
    multiTypeValues: IMultiTypeValue[];
};

export type IFilterValue = {
    optionText?: string;
    value?: string;
};

export type IFilterGroupValue = {
    optionGroupText?: string;
    values: IFilterValue[];
};

export type IMultiTypeValue = {
    displayName?: string;
    parameterName?: string;
    type?: string;
    operands?: IFilterValue[];
    values?: IFilterValue[];
    intellisenseValues?: [string];
};

export type IDateRange = {
    id: string;
    open: boolean;
    startDate: Date | undefined;
    endDate: Date | undefined;
};

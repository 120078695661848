import { DispatchAction } from '@iolabs/redux-utils';
import { useDispatch } from 'react-redux';

import alarmhorn from '!!raw-loader!./../Markups/symbols/alarmakustisch.svg';
import manualCallpoint from '!!raw-loader!./../Markups/symbols/handfeuermelder.svg';
import rauchmelder from '!!raw-loader!./../Markups/symbols/rauchmelder.svg';

import {
    ModelElementDataRequest,
    SingleElementDiscardRequest,
    SingleElementDiscardResponse,
} from '../../generate/api';
import { filesApi } from '../../packages/Api/data/files/client';
import { hideGlobalOverlay } from '../../redux/project';
import { ISelection } from '../Viewer/Viewer';
import { ReactComponent as alarmhornPath } from './../Markups/symbols/alarmakustisch.svg';
import { ReactComponent as manualCallpointPath } from './../Markups/symbols/handfeuermelder.svg';
import { ReactComponent as rauchmelderPath } from './../Markups/symbols/rauchmelder.svg';
import { IAttributesMap, IAttributesMapItem, IAttributesNames } from './types';

interface IElementAttributesData {
    fileVersionUrn?: string;
    singleSelection?: ISelection;
    projectID?: string;
    viewableID: string;
    keycloak: any;
}

export const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        const r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

export const randSuffix = () => {
    return 'xxxxxxxx'.replace(/[xy]/g, c => {
        const r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

export const getTemporaryUUID = () => {
    return uuidv4();
};

// TODO - called multiple times for each selected element
export async function elementAttributesData({
    fileVersionUrn,
    singleSelection,
    projectID,
    viewableID,
    keycloak,
}: IElementAttributesData): Promise<IAttributesMap | undefined> {
    const attributesMap: IAttributesMap = [
        {
            displayValue: 'Zone customer text',
            attributeName: IAttributesNames.zoneCustomerText,
            editable: true,
        },
        {
            displayValue: 'Zone plan no.',
            attributeName: IAttributesNames.zonePlanNumber,
            editable: true,
        },
        {
            displayValue: 'Serial number',
            attributeName: IAttributesNames.serialNumber,
            editable: true,
        },
        {
            displayValue: 'Area address',
            attributeName: IAttributesNames.areaAddress,
        },
        {
            displayValue: 'Area customer text',
            attributeName: IAttributesNames.areaCustomerText,
        },
        {
            displayValue: 'Channel address',
            attributeName: IAttributesNames.channelAddress,
        },
        {
            displayValue: 'Section address',
            attributeName: IAttributesNames.sectionAddress,
        },
        {
            displayValue: 'Section customer text',
            attributeName: IAttributesNames.sectionCustomerText,
        },
        {
            displayValue: 'Section plan no.',
            attributeName: IAttributesNames.sectionPlanNumber,
        },
        {
            displayValue: 'Zone address',
            attributeName: IAttributesNames.zoneAddress,
        },
        {
            displayValue: 'Zone type',
            attributeName: IAttributesNames.zoneType,
        },
    ];

    if (keycloak?.token && fileVersionUrn && singleSelection?.externalId && projectID) {
        const data: ModelElementDataRequest = {
            projectID: projectID,
            fileVersionUrn: fileVersionUrn,
            viewableID: viewableID,
            elementID: singleSelection.externalId,
            elementName: singleSelection.name || '',
        };

        return new Promise(resolve => {
            filesApi
                .fileVersionElementDataPost(data)
                .then(responseData => {
                    const versionOperationsData = responseData.data?.operations?.filter(
                        x => x.operationType === 'SetAttribute',
                    );

                    const resolveMap: IAttributesMap = attributesMap.map((attribute, index) => {
                        let lastUpdatedItem;

                        versionOperationsData?.map(versionOperationsDataSingle => {
                            const lastUpdated = versionOperationsDataSingle?.parameters?.find(
                                x => x.name === attribute.attributeName,
                            );
                            if (lastUpdated && !lastUpdatedItem) {
                                lastUpdatedItem = lastUpdated;
                            }
                        });

                        const attributesMapItem: IAttributesMapItem = {
                            displayValue: attribute.displayValue,
                            attributeName: attribute.attributeName,
                            editable: attribute.editable,
                            value: singleSelection?.properties.find(
                                x => x.attributeName === attribute.attributeName,
                            )?.displayValue as string | undefined,
                            isBatched: responseData.data?.isBatched,
                        };

                        if (lastUpdatedItem) {
                            return {
                                ...attributesMapItem,
                                newValue: lastUpdatedItem.value,
                            };
                        } else {
                            return attributesMapItem;
                        }
                    });

                    resolve(resolveMap);
                })
                .catch(error => {
                    console.error(error);
                });
        });
    }
}

export async function singleElementDiscardCall({
    fileVersionUrn,
    singleSelection,
    projectID,
    viewableID,
    keycloak,
}: IElementAttributesData): Promise<SingleElementDiscardResponse | undefined> {
    if (keycloak?.token && fileVersionUrn && singleSelection?.externalId && projectID) {
        const data: SingleElementDiscardRequest = {
            projectID: projectID,
            fileVersionUrn: fileVersionUrn,
            viewableID: viewableID,
            elementID: singleSelection.externalId,
            elementName: singleSelection.name || '',
        };

        return new Promise(resolve => {
            filesApi
                .fileVersionSingleElementDiscardPost(data)
                .then(responseData => {
                    resolve(responseData.data);
                })
                .catch(error => {
                    console.error(error);
                    resolve(undefined);
                });
        });
    } else {
        return Promise.resolve(undefined);
    }
}

export const getSensorSvg = (sensorType: string) => {
    switch (sensorType) {
        case 'FDO221':
            return rauchmelder;
        case 'FDM223_FDMH293R':
            return manualCallpoint;
        case 'FDSB221':
        default:
            return alarmhorn;
    }
};

export const getSensorSvgPath = (sensorType: string) => {
    switch (sensorType) {
        case 'FDO221':
            return rauchmelderPath;
        case 'FDM223_FDMH293R':
            return manualCallpointPath;
        case 'FDSB221':
        default:
            return alarmhornPath;
    }
};

export enum IMethodName {
    onDesignAutomationComplete = 'onDesignAutomationComplete',
    onDesignAutomationFailure = 'onDesignAutomationFailure',
    onModelPublishStarted = 'onModelPublishStarted',
    onModelPublishComplete = 'onModelPublishComplete',
    onModelPublishFailure = 'onModelPublishFailure',
    onAssetSyncStarted = 'onAssetSyncStarted',
    onAssetSyncComplete = 'onAssetSyncComplete',
    onAssetSyncFailure = 'onAssetSyncFailure',
    onForceReleaseRequested = 'onForceReleaseRequested',
}

/**
 * Extracts the code from the data matrix.
 * small: 1S4A5F24E
 * big: 1PA5Q00004813+2P41+1S4A5F24E+16D20110719
 * if the code starts with 1S, remove it
 * required result: 4A5F24E
 * @param inputCode The input code.
 */
export const extractCodeFromDataMatrix = (inputCode: string) => {
    // Small code
    if (inputCode.length <= 9) {
        return inputCode?.toLowerCase()?.startsWith('1s') ? inputCode.substring(2) : inputCode;
    }

    // Big code
    // Split the input code by '+'
    const parts = inputCode.split('+');

    // Get the part after the second '+'
    const partAfterSecondPlus = parts.length >= 3 ? parts[2] : '';

    // Check if the part starts with '1S' and remove it
    return partAfterSecondPlus.startsWith('1S')
        ? partAfterSecondPlus.substring(2)
        : partAfterSecondPlus;
};

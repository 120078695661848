import { DispatchAction } from '@iolabs/redux-utils';
import { Box, Link, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useKeycloak } from '@react-keycloak/web';
import React from 'react';
import { useDispatch } from 'react-redux';

import { onFilterReset as onAssetsFilterReset } from '../../redux/assets';
import { onFilterReset as onIssuesFilterReset, useIssuesFilterIsActive } from '../../redux/issues';
import { useTranslation } from '../../redux/translations/hook';
import FieldBoolean from './FieldBoolean';
import FieldDateRange from './FieldDateRange';
import FieldMultiType from './FieldMultiType';
import FieldSelect from './FieldSelect';
import messages from './messages';
import { IFilterDefinition, IFilterGroup, IFilterType } from './types';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paperHeader: {
            height: '20px',
            minHeight: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: theme.spacing(1),
            borderBottom: `1px solid ${theme.palette.grey[100]}`,
        },
        paperView: {
            padding: theme.spacing(1),
        },
        buttonGroupRoot: {
            marginTop: theme.spacing(0.5),
            width: '100%',
            '& button': {
                width: '100%',
                fontSize: theme.typography.pxToRem(12),
            },
        },
        buttonRoot: {
            color: theme.palette.text.secondary,
            background: theme.palette.grey[50],
            borderRadius: 0,
            padding: '5px',
            margin: '2px 0 0 0 !important',
            width: '35px',
            height: '35px',
            border: `1px solid transparent`,
            '&:hover': {
                background: theme.palette.grey[100],
                borderColor: theme.palette.grey[300],
            },
            '&.active': {
                borderColor: theme.palette.secondary.main,
            },
        },
        buttonDisabled: {
            color: `${theme.palette.common.white} !important`,
            backgroundColor: `${theme.palette.text.secondary} !important`,
        },
        paperBody: {
            padding: theme.spacing(1),
            overflow: 'auto',
            flex: 1,
        },
        paperFooter: {
            padding: theme.spacing(1),
            alignContent: 'flex-end',
            alignSelf: 'center',
        },
        field: {
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5),
            '& .MuiOutlinedInput-inputMarginDense': {
                paddingTop: '12px',
                paddingBottom: '12px',
            },
            '& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense': {
                paddingTop: '4px',
                paddingBottom: '4px',
            },
            '& .MuiFormLabel-root': {
                color: theme.palette.text.primary,
            },
            '& input, & .MuiSelect-root': {
                ...theme.typography.body2,
                color: theme.palette.text.primary,
            },
            '& textarea': {
                ...theme.typography.body2,
                color: theme.palette.text.primary,
            },
        },
        title: {
            display: 'block',
            marginTop: theme.spacing(1),
            fontWeight: 'bold',
        },
        flexBox: {
            display: 'flex',
            flexFlow: 'row nowrap',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '100%',
        },
        clearLink: {},
        filterGroupBox: {
            position: 'relative',
            display: 'flex',
            flexFlow: 'row nowrap',
            justifyContent: 'space-between',
        },
        presetsLink: {
            position: 'absolute',
            right: 0,
            top: theme.spacing(2),
        },
    }),
);

interface IFilterProps {
    type: IFilterType;
    filterGroups: IFilterGroup[];
    filterDefinitions: IFilterDefinition[];
    loading?: boolean;
    handleOpenPresetsManager?: (
        event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLSpanElement>,
    ) => void;
    handleOpenCreatePreset?: (
        event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLSpanElement>,
    ) => void;
}

const Filter: React.FC<IFilterProps> = ({
    type,
    filterGroups,
    filterDefinitions,
    loading,
    handleOpenPresetsManager,
    handleOpenCreatePreset,
}) => {
    const classes = useStyles();
    const { keycloak } = useKeycloak();
    const dispatch = useDispatch<DispatchAction>();

    // issues
    const isIssuesFilterActive: boolean = useIssuesFilterIsActive();

    const renderFormField = (filterDefinition: IFilterDefinition) => {
        switch (filterDefinition?.dataType) {
            // select
            case 'array': {
                return <FieldSelect type={type} filterDefinition={filterDefinition} />;
            }
            case 'daterange': {
                return <FieldDateRange type={type} filterDefinition={filterDefinition} />;
            }
            case 'boolean': {
                return <FieldBoolean type={type} filterDefinition={filterDefinition} />;
            }
            case 'multiType': {
                return <FieldMultiType type={type} filterDefinition={filterDefinition} />;
            }
            case 'tree': {
                // TODO: not implemented yet (Jakub Jirous 2021-04-13 11:34:15)
                break;
            }
            default: {
                break;
            }
        }
    };

    const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

    const handleFilterReset = (
        event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLSpanElement>,
    ) => {
        preventDefault(event);
        dispatch(type === IFilterType.ISSUES ? onIssuesFilterReset() : onAssetsFilterReset());
    };

    // translations
    const transFilter = useTranslation({ ...messages.filter });
    const transClearAll = useTranslation({ ...messages.clearAll });
    const transDefaultFilter = useTranslation({ ...messages.defaultFilter });
    const transCustomFilter = useTranslation({ ...messages.customFilter });
    const transViewBy = useTranslation({ ...messages.viewBy });
    const transViewAsFilter = useTranslation({ ...messages.viewAsFilter });
    const transViewAsFolders = useTranslation({ ...messages.viewAsFolders });
    const transPresetsSave = useTranslation({ ...messages.presetsSave });
    const transPresetsManager = useTranslation({ ...messages.presetsManager });

    /**
     * Mapping DE locale for filterGroup title
     * @param filterGroup
     */
    const localeMapping = (filterGroup: IFilterGroup): string => {
        switch (filterGroup?.controlGroupID) {
            case 1: {
                return transDefaultFilter;
            }
            case 2: {
                return transCustomFilter;
            }
            default: {
                return filterGroup?.title;
            }
        }
    };

    return (
        <Box>
            <Box className={classes.paperHeader}>
                <Typography variant="body2">
                    <strong>{transFilter}</strong>
                </Typography>
                <Box className={classes.flexBox}>
                    <Link
                        href="#"
                        color="primary"
                        className={classes.clearLink}
                        onClick={handleFilterReset}
                    >
                        {transClearAll}
                    </Link>
                </Box>
            </Box>
            <Box className={classes.paperBody}>
                {filterGroups?.map((filterGroup, index) => (
                    <React.Fragment key={index}>
                        {/*<Box className={classes.filterGroupBox}>*/}
                        {/*    <Typography variant="caption" className={classes.title}>*/}
                        {/*        {localeMapping(filterGroup)}*/}
                        {/*    </Typography>*/}
                        {/*    */}
                        {/*</Box>*/}
                        {filterDefinitions?.map((filterDefinition, index2) => (
                            <Box key={`${index}-${index2}`} className={classes.field}>
                                {filterDefinition?.controlGroupID ===
                                    filterGroup?.controlGroupID && (
                                    <>{renderFormField(filterDefinition)}</>
                                )}
                            </Box>
                        ))}
                    </React.Fragment>
                ))}
            </Box>
            {/*<Box className={classes.paperFooter}>*/}
            {/*    <Button variant="contained" color="default" size="small" onClick={handleOpenCreatePreset}>*/}
            {/*        {transPresetsSave}*/}
            {/*    </Button>*/}
            {/*</Box>*/}
        </Box>
    );
};

export default Filter;

import {
    ModelLockElement,
    ModelLockInfoRequest,
    ModelLockUser,
    ScheduledOperationListRequest,
    ScheduledOperationListResponse,
} from '../../generate/api';
import { filesApi } from '../../packages/Api/data/files/client';
import { setElementsStatus, setLockedModelInfo, setModelAssetStatuses } from '../../redux/project';
import { IFileElementsStatus, IViewableElement } from '../../redux/project/types';
import { store } from '../../redux/store';
import TwoWayMap from '../../utils/TwoWayMap';
import { IAttributesNames } from './types';

interface IUpdateElementsStatus {
    externalIdMap?: TwoWayMap<string, number>;
    viewer: Autodesk.Viewing.Viewer3D;
    viewableElements: IViewableElement[];
    modifications?: any;
}

interface IReturnData {
    isSomeOperations: boolean;
    statusesFromLocal: boolean;
    users: ModelLockUser[];
}

export const updateLockInfo = async (): Promise<void> => {
    const project = store.getState().project.project;

    const forgeProjectId = project?.id;
    const fileVersionUrn = store.getState().project.file.currentVersion?.data.urn;
    const dispatch = store.dispatch;

    if (!forgeProjectId || !fileVersionUrn) {
        return Promise.resolve();
    }

    const data: ModelLockInfoRequest = {
        fileVersionUrn: fileVersionUrn,
        projectID: forgeProjectId,
    };

    try {
        const response = await filesApi.fileVersionLockInfoPost(data);

        // Update lockedModelInfo
        // ----------------------------------------
        dispatch(setLockedModelInfo(response.data.lockedModel));
        console.log('setLockedModelInfo');
        dispatch(setModelAssetStatuses(response.data.assets || []));
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const getModifications = async (
    viewable,
    callback: (modifications: ScheduledOperationListResponse) => void,
): Promise<void> => {
    const project = store.getState().project.project;

    const forgeProjectId = project?.id;
    const fileVersionUrn = store.getState().project.file.currentVersion?.data.urn;
    const dispatch = store.dispatch;

    const viewableID = viewable?.data?.viewableID;

    if (!forgeProjectId || !fileVersionUrn) {
        return Promise.resolve();
    }

    const data: ScheduledOperationListRequest = {
        fileVersionUrn: fileVersionUrn,
        projectID: forgeProjectId,
        viewableID: viewableID,
    };

    try {
        const response = await filesApi.fileVersionModificationsPost(data);
        callback(response.data);
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/*
 * get lockInfo elements from api
 * get elements from viewer
 * compare elements from api and viewer
 * update elementsStatus in redux
 *
 * get lockInfo users from api
 * update usersLockedInModel in redux
 *
 *  if api return empty list - update elements only local and define only if element has serialNumber
 *
 * in response return:
 * - isSomeOperations - if some elements has operationCount > 0
 * - statusesFromLocal - if statuses came from local or from api
 * - users - users from api
 */
export const updateElementsStatus = async ({
    externalIdMap,
    viewer,
    viewableElements,
    modifications,
}: IUpdateElementsStatus): Promise<{ isSomeOperations: boolean }> => {
    const project = store.getState().project;
    const forgeProjectId = project?.project?.id;
    const fileVersionUrn = project.file.currentVersion?.data.urn;
    const reduxModelAssetStatuses = project.modelAssetStatuses;

    // console.log('Updating modifications: ', modifications);

    const returnData: IReturnData = {
        isSomeOperations: false,
        statusesFromLocal: false,
        users: [],
    };
    if (!forgeProjectId || !fileVersionUrn) {
        return Promise.resolve(returnData);
    }

    const dispatch = store.dispatch;
    const reduxElementsStatus = store.getState().project.file.elementsStatus;
    const reduxLockedModelInfo = store.getState().project.lockedModelInfo;

    try {
        // Update elements in model
        // ----------------------------------------
        const list = reduxLockedModelInfo?.elements as ModelLockElement[];
        let dbIds = list
            ?.map(element => externalIdMap?.get(element.elementID as string))
            .filter(dbId => dbId !== undefined) as number[];

        const elementsInProgress = list?.filter(
            element => !externalIdMap?.get(element.elementID as string),
        );

        // define if statuses came from local or from api
        let isStatusesFromLocal = true;

        if (dbIds?.length > 0) {
            isStatusesFromLocal = false;
        }

        // get dbIds from viewer
        if (!dbIds?.length) {
            const externalIds = viewableElements.map(x => x.externalId);
            dbIds = externalIds.map(x => externalIdMap?.get(x) as number);
        }
        returnData.statusesFromLocal = isStatusesFromLocal;

        return await new Promise(resolve => {
            viewer.model?.getBulkProperties(
                dbIds,
                { propFilter: [IAttributesNames.serialNumber, 'externalId'] },
                elements => {
                    const newElementsStatus: IFileElementsStatus = {};

                    elements.forEach(modelElement => {
                        // get serialNumber value
                        const serialNumberValue = modelElement.properties.find(
                            x => x.attributeName === IAttributesNames.serialNumber,
                        )?.displayValue;

                        const key = modelElement.externalId as string;

                        // define new element
                        newElementsStatus[key] = {};

                        // set status from viewer - if is "problem" or "open"
                        enum EStatus {
                            open = 'Offen/Ouvert',
                            problem = 'Problem/Problème',
                        }

                        // find status from modelAssetStatuses
                        const modelAssetStatus = reduxModelAssetStatuses?.find(
                            x => x.elementID === modelElement.externalId,
                        );

                        // console.log('Model asset status: ', modelAssetStatus);

                        // if modelsAssetStatus from Redux exist
                        if (modelAssetStatus) {
                            if (modelAssetStatus.assetStatus === EStatus.open) {
                                newElementsStatus[key].assetStatus = 'open';
                            } else if (modelAssetStatus.assetStatus === EStatus.problem) {
                                newElementsStatus[key].assetStatus = 'problem';
                            }
                        }
                        // else if model is locked - modelsAssetStatus from locked model info
                        else {
                            // from lockInfo in redux
                            const lockedModelInfoElement = reduxLockedModelInfo?.elements?.find(
                                x => x.elementID === modelElement.externalId,
                            );
                            const status = lockedModelInfoElement?.assetStatus;
                            if (status === EStatus.open) {
                                newElementsStatus[key].assetStatus = 'open';
                            } else if (status === EStatus.problem) {
                                newElementsStatus[key].assetStatus = 'problem';
                            }
                        }

                        // before check if statuses exist from lockinfo
                        if (isStatusesFromLocal) {
                            newElementsStatus[key].lockState = 'Locked';
                            newElementsStatus[key].hasSerialNumber = !!serialNumberValue;
                            newElementsStatus[key].isLocked = true; // todo - must be because: ui/src/redux/project/reducer.ts:204 - "if (elementsStatus[externalId]?.isLocked)"
                        } else {
                            // check if element is in list
                            const currentElement = list.find(
                                x => x.elementID === modelElement.externalId,
                            );
                            if (!currentElement) return;

                            newElementsStatus[key].elementName = currentElement.elementName;
                            newElementsStatus[key].isEdited = currentElement.isEdited;
                            newElementsStatus[key].lockState = currentElement.lockState;
                            newElementsStatus[key].operationCount = currentElement.operationCount;
                            newElementsStatus[key].hasSerialNumber = !!serialNumberValue;
                            newElementsStatus[key].serialNumberChanged =
                                currentElement.serialNumberChanged;
                            newElementsStatus[key].isLocked = true; // todo - must be because: ui/src/redux/project/reducer.ts:204 - "if (elementsStatus[externalId]?.isLocked)"
                        }
                    });

                    //Assign elements in progress
                    elementsInProgress?.forEach(temporaryElement => {
                        const key = temporaryElement.elementID as string;

                        newElementsStatus[key] = {
                            elementName: temporaryElement.elementName,
                            isEdited: temporaryElement.isEdited,
                            lockState: temporaryElement.lockState,
                            operationCount: temporaryElement.operationCount,
                            hasSerialNumber: false,
                            serialNumberChanged: temporaryElement.serialNumberChanged,
                            isLocked: true,
                            //temporaryElement.isLocked, // todo - must be because: ui/src/redux/project/reducer.ts:204 - "if (elementsStatus[externalId]?.isLocked)"
                        };
                    });

                    // update elementsStatus
                    dispatch(setElementsStatus(newElementsStatus));

                    returnData.isSomeOperations = Object.keys(newElementsStatus).some(key => {
                        return (newElementsStatus[key]?.operationCount as number) > 0;
                    });

                    resolve(returnData);
                },
            );
        });
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const updateLockInfoAndElementsStatus = async ({
    externalIdMap,
    viewer,
    viewableElements,
}: IUpdateElementsStatus): Promise<{ isSomeOperations: boolean }> => {
    await updateLockInfo();
    return updateElementsStatus({ externalIdMap, viewer, viewableElements });
};

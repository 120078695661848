import { Box, Popover } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';

import { DialogContext } from '../../dialogs/DialogProvider/DialogProvider';
import { AssetReponseItem, AssetUpdateRequest, FilterValue } from '../../generate/api';
import { useAppDispatch } from '../../hooks';
import { assetsApi } from '../../packages/Api/data/assets/client';
import { onUpdateAssetsListRow } from '../../redux/assets';
import { useProjectState, useUser } from '../../redux/project';
import { roleElectrician } from '../../redux/project/types';
import { DF } from '../DialogFactory/DialogFactory';
import { INotificationDialogProps } from '../DialogFactory/NotificationDialog/NotificationDialog';
import Icon from '../Icon/Icon';
import FormattedMessage from '../Translation/FormattedMessage';
import { createTranslationKey } from '../Translation/utils';
import messages from './messages';
import useStyles from './styles';

interface IStatusSelectionProps {
    statuses: FilterValue[];
    asset: AssetReponseItem;
}

const toCamelCase = str => {
    return _.camelCase(str);
};

const createKey = str => {
    return 'assets.status' + '.' + toCamelCase(str);
};

export const StatusSelection: React.FC<IStatusSelectionProps> = ({ statuses, asset }) => {
    const { keycloak, initialized: keycloakInitialized } = useKeycloak();
    const [statusValue, setStatusValue] = useState('');
    const classes = useStyles();
    const project = useProjectState();
    const dispatch = useAppDispatch();
    const user = useUser();

    const { openDialog } = useContext(DialogContext);

    useEffect(() => {
        setStatusValue(asset.statusName as string);
    }, [asset]);

    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'status-selection' : undefined;

    const handleChangeStatus = (event, value) => {
        event.stopPropagation();
        handleClose();

        // const revitId = asset?.customAttributes?.find(x => x.attributeCode === 'revitId')?.value;
        const revitId = asset?.elementID as string;
        const comment = asset?.customAttributes?.find(x => x.attributeCode === 'comment')
            ?.value as string;

        const data: AssetUpdateRequest = {
            forgeProjectID: project.project?.id as string,
            forgeFileVersionUrn: project.file.currentVersion?.data.urn as string,
            assetName: asset.assetName as string,
            statusName: value,
            elementID: revitId,
            comment: comment,
            backupOperation: 'RemoveBackup',
        };

        if (keycloak?.token) {
            assetsApi
                .assetUpdatePost(data)
                .then(response => {
                    const newValue = response.data.asset?.statusName || '';
                    setStatusValue(newValue);
                    dispatch(
                        onUpdateAssetsListRow({
                            elementId: revitId,
                            target: 'status',
                            value: newValue,
                        }),
                    );
                })
                .catch(error => {
                    console.error(error);
                    openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Error', {
                        content: <FormattedMessage {...messages.errorUnableToUpdateAsset} />,
                    });
                    console.error('Assets - Unable to update asset', error);
                });
        }
    };

    // Roles
    const [canEdit, setCanEdit] = useState(false);
    useEffect(() => {
        if (user?.hasFireBimRole) {
            setCanEdit(true);
        }
        if (user?.forgeRoles?.some(role => roleElectrician.includes(role))) {
            setCanEdit(false);
        }
    }, [user]);

    const TransTitle: React.FC = () => {
        return (
            <FormattedMessage
                id={createTranslationKey('assets.status', statusValue)}
                defaultMessage={statusValue}
            />
        );
    };

    return (
        <>
            {canEdit ? (
                <>
                    <Box aria-describedby={id} onClick={handleClick}>
                        <Icon name={'chevron-light-down'} size={8} /> <TransTitle />
                    </Box>

                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        className={classes.statusPopover}
                    >
                        <>
                            {statuses.map((status, index) => {
                                return (
                                    <Box
                                        key={index}
                                        onClick={event => handleChangeStatus(event, status.value)}
                                        className={classes.statusPopoverItem}
                                    >
                                        <FormattedMessage
                                            id={createKey(status.optionText)}
                                            defaultMessage={String(status.optionText)}
                                        />
                                    </Box>
                                );
                            })}
                        </>
                    </Popover>
                </>
            ) : (
                <Box>
                    <TransTitle />
                </Box>
            )}
        </>
    );
};

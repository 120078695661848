import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ModelAsset, ModelLock, ObjectMarkup, UserInfo } from '../../generate/api';
import { RootState } from '../store';
import {
    IAssetSettings,
    IBluetoothDevice,
    ICurrentViewable,
    IFileElementsStatus,
    IGlobalOverlay,
    IMenu,
    IProject,
    IProjectState,
    ISelectedAsset,
    ISelectedMarkup,
    IUser,
    IVersion,
    IViewableElement,
    UserRoles,
} from './types';
import PropertyResult = Autodesk.Viewing.PropertyResult;
import _ from 'lodash';

const initialState: Readonly<IProjectState> = {
    hubId: 'b.032c6efb-c282-40cf-9207-df4772782dd0',
    file: {
        selectedAsset: undefined,
        viewableElements: [],
        elementsStatus: {},
    },
    menu: {
        filterOpen: false,
        assetsOpen: false,
        fileBrowserOpen: false,
        scanningOpen: false,
        commissioningOpen: false,
        markupsOpen: false,
        issuesOpen: false,
        exportOpen: false,
    },
    sidebar: {
        open: false,
    },
    assetsSettings: {
        pageLimit: 50,
        sortColumn: 'name',
        sortOrder: 'asc',
        disableChangeSelection: false,
    },
    globalOverlay: {
        text: '',
        show: false,
    },
    viewerItems: {},
    bluetooth: {},
    markups: {
        list: [],
    },
};

export const projectSlice = createSlice({
    name: 'project',
    initialState: initialState,
    reducers: {
        setCurrentVersion: (state, action: PayloadAction<IVersion>) => {
            state.file.currentVersion = action.payload;
        },

        setCurrentViewable: (state, action: PayloadAction<ICurrentViewable>) => {
            state.file.currentViewable = action.payload;
        },

        setMenu: (state, action: PayloadAction<Partial<IMenu>>) => {
            state.menu = { ...state.menu, ...action.payload };
        },

        setSidebar: (state, action: PayloadAction<boolean>) => {
            state.sidebar.open = action.payload;
        },

        setGlobalOverlay: (state, action: PayloadAction<IGlobalOverlay>) => {
            state.globalOverlay = action.payload;
        },

        hideGlobalOverlay: (state, action: PayloadAction) => {
            state.globalOverlay = initialState.globalOverlay;
        },

        setProject: (state, action: PayloadAction<IProject>) => {
            state.project = action.payload;
        },

        setViewableElements: (state, action: PayloadAction<IViewableElement[]>) => {
            state.file.viewableElements = action.payload;
            state.file.selectedAsset = undefined;
        },

        setLockedModelInfo: (state, action: PayloadAction<ModelLock | undefined>) => {
            state.lockedModelInfo = action.payload;
        },

        setModelAssetStatuses: (state, action: PayloadAction<ModelAsset[]>) => {
            state.modelAssetStatuses = action.payload;
        },

        // assets
        setSelectedAsset: (state, action: PayloadAction<ISelectedAsset>) => {
            state.file.selectedAsset = action.payload;
        },
        setSelectedAssetProps: (state, action: PayloadAction<PropertyResult | undefined>) => {
            state.file.selectedAssetProps = action.payload;
        },

        setAssetsSettings: (state, action: PayloadAction<Partial<IAssetSettings>>) => {
            state.assetsSettings = {
                ...state.assetsSettings,
                ...action.payload,
            };
        },

        // elements status
        setElementsStatus: (state, action: PayloadAction<IFileElementsStatus>) => {
            state.file.elementsStatus = action.payload;
        },

        // bluetooth
        setSelectedBluetoothDevice: (state, action: PayloadAction<IBluetoothDevice>) => {
            state.bluetooth.selectedDevice = action.payload;
        },

        // markups
        setMarkupsList: (state, action: PayloadAction<ObjectMarkup[]>) => {
            state.markups.list = action.payload;
        },
        setSelectedMarkup: (state, action: PayloadAction<ISelectedMarkup>) => {
            state.markups.selected = action.payload;
        },
        unselectMarkup: (state, action: PayloadAction) => {
            delete state.markups.selected;
        },
        setMarkupIsEdited: (state, action: PayloadAction<boolean>) => {
            state.markups.isEdited = action.payload;
        },
        setActiveMarkupID: (state, action: PayloadAction<number | null | undefined>) => {
            state.markups.onSetActiveMarkupID = action.payload;
        },
        setMarkupsLoading: (state, action: PayloadAction<boolean>) => {
            state.markups.loading = action.payload;
        },

        // User
        setUser: (state, action: PayloadAction<UserInfo | undefined>) => {
            function containsUserRole(stringsArray) {
                return stringsArray.some(item => Object.values(UserRoles).includes(item));
            }

            if (action.payload?.forgeRoles && containsUserRole(action.payload.forgeRoles)) {
                state.user = { ...action.payload, hasFireBimRole: true };
            } else {
                state.user = { ...action.payload, hasFireBimRole: false };
            }
            // state.user = { ...action.payload, hasFireBimRole: false }; // todo test
        },
    },
});

export const {
    setCurrentVersion,
    setCurrentViewable,
    setMenu,
    setSidebar,
    setGlobalOverlay,
    hideGlobalOverlay,
    setProject,
    setViewableElements,
    setLockedModelInfo,
    setModelAssetStatuses,

    // assets
    setSelectedAsset,
    setSelectedAssetProps,
    setAssetsSettings,

    // elements status
    setElementsStatus,

    // bluetooth
    setSelectedBluetoothDevice,

    // markups
    setMarkupsList,
    setSelectedMarkup,
    unselectMarkup,
    setMarkupIsEdited,
    setActiveMarkupID,
    setMarkupsLoading,

    // User
    setUser,
} = projectSlice.actions;

export const selectProjectState = (state: RootState) => state.project;
export const selectMenu = (state: RootState): IMenu => state.project.menu;
export const selectSidebar = (state: RootState): boolean => state.project.sidebar.open;
export const selectViewableElements = (state: RootState) =>
    state.project.file.viewableElements || [];
export const selectSelectedAsset = (state: RootState) =>
    state.project.file.selectedAsset || undefined;
export const selectAssetsSettings = (state: RootState) => state.project.assetsSettings;
export const selectGlobalOverlay = (state: RootState) => state.project.globalOverlay;
export const selectElementsStatus = (state: RootState) => state.project.file.elementsStatus;
export const selectSelectedBluetoothDevice = (state: RootState) =>
    state.project.bluetooth.selectedDevice as IBluetoothDevice;
export const selectMarkups = (state: RootState) => state.project.markups;
export const selectLockedModelInfo = (state: RootState): ModelLock | undefined =>
    state.project.lockedModelInfo;
export const selectUser = (state: RootState): IUser | undefined => state.project.user;

export default projectSlice.reducer;

import { DispatchAction } from '@iolabs/redux-utils';
import { Badge, CircularProgress, Paper, Popover, Theme, Typography } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { createStyles, makeStyles } from '@mui/styles';
import { useKeycloak } from '@react-keycloak/web';
import clsx from 'clsx';
import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { DialogContext } from '../../dialogs/DialogProvider/DialogProvider';
import { ITree, TreeDataType, TreeVariant } from '../../packages/Api/data/tree/types';
import { onLoadNode, usePlotLoading } from '../../redux/tree';
import { DF } from '../DialogFactory/DialogFactory';
import { INotificationDialogProps } from '../DialogFactory/NotificationDialog/NotificationDialog';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        badgeRoot: {},
        badgeBadge: {
            padding: '0 2px',
            right: '8px',
            color: theme.palette.common.white,
            fontSize: theme.typography.pxToRem(9),
        },
        labelIcon: {
            fill: theme.palette.grey['700'],
            marginRight: theme.spacing(1),
        },
        labelText: {
            fontWeight: 'inherit',
            flexGrow: 1,
            whiteSpace: 'nowrap',
        },
        disabled: {
            opacity: 0.5,
        },
        paper: {
            padding: theme.spacing(1, 1.5),
        },
        circularProgress: {
            color: theme.palette.common.black,
        },
        ul: {
            margin: 0,
            padding: 0,
            listStyleType: 'none',
            '& li': {
                cursor: 'pointer',
                margin: theme.spacing(0.5, 0),
                '&.active': {
                    fontWeight: theme.typography.fontWeightBold,
                    color: theme.palette.primary.main,
                },
            },
        },
    }),
);

type BagedTreeItemProps = {
    isActive: boolean;
    variant: TreeVariant;
    labelIcon: React.ElementType<SvgIconProps>;
    labelText: string;
    itemVersion: number | undefined;
    item: ITree;
    folder: ITree | null;
    handleSetNodeId: (item: ITree) => void;
    handleSetActiveNodeId: (item: ITree, variant: TreeVariant, version?: ITree) => void;
};

function BagedTreeItem(props: BagedTreeItemProps) {
    const {
        isActive,
        variant,
        labelIcon: LabelIcon,
        labelText,
        itemVersion,
        item,
        folder,
        handleSetNodeId,
        handleSetActiveNodeId,
    } = props;

    const classes = useStyles();
    const { keycloak } = useKeycloak();
    const dispatch = useDispatch<DispatchAction>();
    const loading: boolean = usePlotLoading();

    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
    const [hasChildren, setHasChildren] = React.useState<boolean>(false);
    const [wasOpened, setWasOpened] = React.useState<boolean>(false);
    const [version, setVersion] = React.useState<number | undefined>(itemVersion);

    const openPopover = Boolean(anchorEl);
    const id = openPopover ? 'version-popover' : undefined;

    const { openDialog } = useContext(DialogContext);

    useEffect(() => {
        if (item?.children && item?.children?.length > 0) {
            setHasChildren(true);
        }
    }, [item]);

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const handleLoadVersions = (item: ITree, variant: TreeVariant) => {
        handleSetNodeId(item);

        // load versions
        if (!hasChildren && !wasOpened) {
            dispatch(
                onLoadNode({
                    token: keycloak?.token as string,
                    variant,
                    nodeId: item.id,
                    depth: 1,
                }),
            );
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const handleSetItem = (folder: ITree | null, item: ITree) => {
        if (item?.data?.lastVersionTreeNode?.data) {
            handleSetActiveNodeId(item, variant, item?.data?.lastVersionTreeNode);
            setVersion(item?.data?.lastVersionTreeNode?.data?.version);
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const handleSetItemVersion = (folder: ITree | null, item: ITree, versionItem: ITree) => {
        handleSetActiveNodeId(item, variant, versionItem);
        setVersion(versionItem?.data?.version);
        handleClosePopover();
    };

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const handleOpenPopover = (event: React.MouseEvent<HTMLSpanElement>, item: ITree) => {
        setWasOpened(true);

        // open popover
        setAnchorEl(event.currentTarget as HTMLDivElement);

        // loading all tree item versions from API
        handleLoadVersions(item, variant);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    // Check if item is C4R model
    const c4rModel = 'items:autodesk.bim360:C4RModel';
    const disabled = item.data.type === TreeDataType.ITEM && item?.data?.extensionType != c4rModel;
    const handleClickDisabled = () => {
        openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Error', {
            content: 'Model type is not supported (not C4R model)',
        });
    };

    return (
        <>
            {version && (
                <>
                    <Badge
                        badgeContent={`V${version}`}
                        color="primary"
                        classes={{ root: classes.badgeRoot, badge: classes.badgeBadge }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        onClick={event => handleOpenPopover(event, item)}
                    >
                        <LabelIcon className={classes.labelIcon} />
                    </Badge>
                    <Popover
                        id={id}
                        open={openPopover}
                        anchorEl={anchorEl}
                        onClose={handleClosePopover}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <Paper className={classes.paper}>
                            {!hasChildren && loading ? (
                                <CircularProgress size={20} className={classes.circularProgress} />
                            ) : (
                                <ul className={classes.ul}>
                                    {item?.children?.map((versionItem, index) => {
                                        const itemDisabled = item?.data?.extensionType != c4rModel;
                                        if (itemDisabled) {
                                            return (
                                                <li
                                                    key={index}
                                                    onClick={() => handleClickDisabled()}
                                                    className={clsx(
                                                        isActive &&
                                                            version === versionItem?.data?.version
                                                            ? 'active'
                                                            : '',
                                                        itemDisabled && classes.disabled,
                                                    )}
                                                >
                                                    {`V${versionItem?.data?.version} - ${versionItem?.text}`}
                                                </li>
                                            );
                                        }

                                        return (
                                            <li
                                                key={index}
                                                onClick={() =>
                                                    handleSetItemVersion(folder, item, versionItem)
                                                }
                                                className={
                                                    isActive &&
                                                    version === versionItem?.data?.version
                                                        ? 'active'
                                                        : ''
                                                }
                                            >
                                                {`V${versionItem?.data?.version} - ${versionItem?.text}`}
                                            </li>
                                        );
                                    })}
                                </ul>
                            )}
                        </Paper>
                    </Popover>
                </>
            )}
            {disabled ? (
                <Typography
                    variant="body2"
                    className={clsx(classes.labelText, disabled && classes.disabled)}
                    onClick={() => handleClickDisabled()}
                >
                    {labelText}
                </Typography>
            ) : (
                <Typography
                    variant="body2"
                    className={classes.labelText}
                    onClick={() => handleSetItem(folder, item)}
                >
                    {labelText}
                </Typography>
            )}
        </>
    );
}

export default BagedTreeItem;

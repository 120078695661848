import config from '../../../../config/config';
import { APIUploadModuleApi, Configuration } from '../../../../generate/api';
import { store } from '../../../../redux/store';

export const uploadApi = new APIUploadModuleApi(
    new Configuration({
        basePath: config.api.baseUrl,
        accessToken: () => {
            return store.getState().keycloak.token as string;
        },
    }),
);

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';

interface IModalProps {
    opened: boolean;
    onCloseClick: () => void;
    title?: string;
    children: React.ReactNode;
}

const InfoModal: React.FC<IModalProps> = ({ title, opened, onCloseClick, children }) => {
    return (
        <Dialog open={opened} maxWidth="xl" onClose={onCloseClick}>
            <DialogTitle>{title ? title : 'Dialog'}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" size="medium" onClick={onCloseClick}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InfoModal;

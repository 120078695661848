import { Box, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { useEffect } from 'react';

import useFooterContent from '../../components/FooterProvider/useFooterContent';
import { template } from '../../theme/Theme';
import { dateIsAfter } from '../../utils/Date';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
            padding: theme.spacing(1),
            // height: `calc(100% - 60px - ${theme.spacing(1)})`,
            height: `calc(100% - ${theme.spacing(1)})`, // disabled footer
            background: template.bodyBgColor,
            [theme.breakpoints.up('md')]: {
                // height: `calc(100% - 30px - ${theme.spacing(1)})`,
                height: `calc(100% - ${theme.spacing(1)})`, // disabled footer
            },
        },
        noPadding: {
            padding: 0,
            // height: `calc(100% - 60px + ${theme.spacing(1)})`,
            height: `100%`, // disabled footer
            [theme.breakpoints.up('md')]: {
                // height: `calc(100% - 30px + ${theme.spacing(1)})`,
                height: `100%`, // disabled footer
            },
        },
    }),
);

interface IPageProps extends React.HTMLAttributes<HTMLElement> {
    noPadding?: boolean;
}

const Page: React.FC<IPageProps> = (props: IPageProps) => {
    const { children, noPadding } = props;

    const classes = useStyles();
    const { setFooterContent } = useFooterContent();

    return <Box className={clsx(classes.root, { [classes.noPadding]: noPadding })}>{children}</Box>;
};

export default Page;

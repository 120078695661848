import { DispatchAction } from '@iolabs/redux-utils';
import { Box } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';

import { setMenu } from '../../redux/project';
import { useMarkups } from '../../redux/project';
import { unselectMarkup } from '../../redux/project';
import FormattedMessage from '../Translation/FormattedMessage';
import messages from './messages';
import useStyles from './styles';

const HideSelectedMarkup: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch<DispatchAction>();
    const selectedMarkup = useMarkups().selected;

    const handleCLick = () => {
        dispatch(unselectMarkup());

        // if closing markup that has beed opened from issue. User is redirected back to that issue
        if (selectedMarkup?.fromIssue) {
            dispatch(setMenu({ markupsOpen: false, issuesOpen: true }));
        }
    };

    if (!selectedMarkup) return <></>;

    return (
        <Box onClick={handleCLick} className={classes.hideMarkupButton}>
            <FormattedMessage {...messages.buttonHideMarkup} />
        </Box>
    );
};

export default HideSelectedMarkup;

import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

import assetsReducer from './assets/assetsSlice';
import { listenerMiddleware as assetsMiddleware } from './assets/middleware';
import editingReducer from './editing/editingSlice';
import exportReducer from './export/exportSlice';
import issuesReducer from './issues/issuesSlice';
import keycloakReducer from './keyclock/keycloakSlice';
import notifierReducer from './notifier/notifierSlice';
import { listenerMiddleware as projectMiddleware } from './project/middleware';
import projectReducer from './project/projectSlice';
import signalRReducer from './signalR/signalrSlice';
import translationReducer from './translations/translationsSlice';
import { listenerMiddleware as treeMiddleware } from './tree/middleware';
import treeReducer from './tree/treeSlice';

export const store = configureStore({
    reducer: {
        keycloak: keycloakReducer,
        notifier: notifierReducer,
        assets: assetsReducer,
        tree: treeReducer,
        project: projectReducer,
        issues: issuesReducer,
        translations: translationReducer,
        editing: editingReducer,
        export: exportReducer,
        signalR: signalRReducer,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat([
            assetsMiddleware.middleware,
            treeMiddleware.middleware,
            projectMiddleware.middleware,
        ]),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

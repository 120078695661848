/* tslint:disable */
/* eslint-disable */
/**
 * FireBIM API
 * API definitions for FireBim
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { UploadFileResponse } from '../models';
/**
 * APIUploadModuleApi - axios parameter creator
 * @export
 */
export const APIUploadModuleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download file from temporary location.
         * @param {string} fileGuid File identifier
         * @param {string} fileName File name
         * @param {string} mimeType Mime type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUploadFileGuidDownloadGet: async (fileGuid: string, fileName: string, mimeType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileGuid' is not null or undefined
            assertParamExists('apiUploadFileGuidDownloadGet', 'fileGuid', fileGuid)
            // verify required parameter 'fileName' is not null or undefined
            assertParamExists('apiUploadFileGuidDownloadGet', 'fileName', fileName)
            // verify required parameter 'mimeType' is not null or undefined
            assertParamExists('apiUploadFileGuidDownloadGet', 'mimeType', mimeType)
            const localVarPath = `/ApiUpload/{fileGuid}/Download`
                .replace(`{${"fileGuid"}}`, encodeURIComponent(String(fileGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }

            if (mimeType !== undefined) {
                localVarQueryParameter['mimeType'] = mimeType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload file to temporary location and return temporary file info.
         * @param {File} [fileData] Posted file.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUploadUploadFilePost: async (fileData?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ApiUpload/UploadFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


            if (fileData !== undefined) { 
                localVarFormParams.append('FileData', fileData as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * APIUploadModuleApi - functional programming interface
 * @export
 */
export const APIUploadModuleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = APIUploadModuleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Download file from temporary location.
         * @param {string} fileGuid File identifier
         * @param {string} fileName File name
         * @param {string} mimeType Mime type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUploadFileGuidDownloadGet(fileGuid: string, fileName: string, mimeType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUploadFileGuidDownloadGet(fileGuid, fileName, mimeType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload file to temporary location and return temporary file info.
         * @param {File} [fileData] Posted file.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUploadUploadFilePost(fileData?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadFileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUploadUploadFilePost(fileData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * APIUploadModuleApi - factory interface
 * @export
 */
export const APIUploadModuleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = APIUploadModuleApiFp(configuration)
    return {
        /**
         * 
         * @summary Download file from temporary location.
         * @param {string} fileGuid File identifier
         * @param {string} fileName File name
         * @param {string} mimeType Mime type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUploadFileGuidDownloadGet(fileGuid: string, fileName: string, mimeType: string, options?: any): AxiosPromise<File> {
            return localVarFp.apiUploadFileGuidDownloadGet(fileGuid, fileName, mimeType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload file to temporary location and return temporary file info.
         * @param {File} [fileData] Posted file.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUploadUploadFilePost(fileData?: File, options?: any): AxiosPromise<UploadFileResponse> {
            return localVarFp.apiUploadUploadFilePost(fileData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * APIUploadModuleApi - object-oriented interface
 * @export
 * @class APIUploadModuleApi
 * @extends {BaseAPI}
 */
export class APIUploadModuleApi extends BaseAPI {
    /**
     * 
     * @summary Download file from temporary location.
     * @param {string} fileGuid File identifier
     * @param {string} fileName File name
     * @param {string} mimeType Mime type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIUploadModuleApi
     */
    public apiUploadFileGuidDownloadGet(fileGuid: string, fileName: string, mimeType: string, options?: AxiosRequestConfig) {
        return APIUploadModuleApiFp(this.configuration).apiUploadFileGuidDownloadGet(fileGuid, fileName, mimeType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload file to temporary location and return temporary file info.
     * @param {File} [fileData] Posted file.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIUploadModuleApi
     */
    public apiUploadUploadFilePost(fileData?: File, options?: AxiosRequestConfig) {
        return APIUploadModuleApiFp(this.configuration).apiUploadUploadFilePost(fileData, options).then((request) => request(this.axios, this.basePath));
    }
}

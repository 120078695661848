import { BluetoothSerial } from '@awesome-cordova-plugins/bluetooth-serial';
import { IonLoading } from '@ionic/react';
import { Fab } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { useESelectedBluetoothDevice } from '../../redux/project';
import { IBluetoothDevice } from '../../redux/project/types';
import Icon from '../Icon/Icon';

interface ICommissioningProgress {
    done?: boolean;
    success?: boolean;
    message?: string;
}

let idx = 1;

const sendCommissioningString = (
    data: string,
    selectedBluetoothDevice: IBluetoothDevice,
    onReportProgress?: (progress: ICommissioningProgress) => void,
) => {
    const bl = BluetoothSerial;

    const deviceName = selectedBluetoothDevice.name;

    console.log(`Sending commissioning: >>${data}<<`);

    if (onReportProgress) {
        onReportProgress({
            message: `Reading device list, looking for device '${deviceName}'`,
        });
    }

    bl.list().then(
        devices => {
            const device = devices.find(x => x.name == deviceName);

            if (onReportProgress) {
                onReportProgress({
                    message: `Connecting to device with MAC address '${device.address}'`,
                });
            }

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            bl.connect(
                device.address,

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                result => {
                    if (onReportProgress) {
                        onReportProgress({
                            message: `Connected to device, writing commissioning string`,
                        });
                    }
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    bl.write(
                        data,

                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        // eslint-disable-next-line @typescript-eslint/no-shadow
                        result => {
                            if (onReportProgress) {
                                onReportProgress({
                                    message: `Commissioning send, disconnecting from device`,
                                });
                            }
                            bl.disconnect();
                            if (onReportProgress) {
                                onReportProgress({
                                    done: true,
                                    success: true,
                                    message: `Successfully disconnected from device`,
                                });
                            }
                        },
                        // eslint-disable-next-line @typescript-eslint/no-shadow
                        result => {
                            if (onReportProgress) {
                                onReportProgress({
                                    success: false,
                                    message: `Error writing to device: ${result}, disconnecting`,
                                });
                            }

                            bl.disconnect();

                            if (onReportProgress) {
                                onReportProgress({
                                    done: true,
                                    success: false,
                                    message: `Error writing to device: ${result}`,
                                });
                            }
                        },
                    );
                },
                result => {
                    if (onReportProgress) {
                        onReportProgress({
                            done: true,
                            success: false,
                            message: `Error connecting to device: ${result}`,
                        });
                    }
                },
            ).subscribe({
                next: value => console.log(`Value is ${value}`),
                error: err => console.error('XXXX', err),
                complete: () => console.log(`Completed`),
            });
        },
        err => {
            if (onReportProgress) {
                onReportProgress({
                    done: true,
                    success: false,
                    message: `Error reading BT devices: ${err}`,
                });
            }
        },
    );
};

const handleCommissioning = (
    selection: number[],
    viewer: Autodesk.Viewing.Viewer3D,
    onCommissionStringPrepared: (commissioning: string) => void,
): string[] => {
    viewer.clearSelection();

    const commissionStrings: string[] = [];

    if (selection.length > 0) {
        selection.forEach(dbId => {
            viewer.getProperties(dbId, props => {
                let zoneHostId;
                let sequenceNo;
                let channelId;

                const propList = props.properties;

                for (const p of propList) {
                    if (p.displayName == 'SI_BMA_Zone elementId') {
                        if (typeof p.displayValue !== 'number') {
                            zoneHostId = p.displayValue.split('/')[0];
                        }
                        if (typeof p.displayValue !== 'number') {
                            sequenceNo = p.displayValue.split('/')[1];
                        }
                    }

                    if (p.displayName == 'SI_BMA_Channel address (Det. No.)') {
                        channelId = p.displayValue;
                    }
                }
                const par1 = (parseInt(zoneHostId) << 8) + parseInt(channelId);
                onCommissionStringPrepared(
                    `{\"method\":\"mclink.txid\",\"params\":[${par1}, ${sequenceNo}],\"id\":${idx}}\n`,
                );

                idx = idx + 1;
            });
        });
    }
    return commissionStrings;
};

interface ICommissioningButtonProps {
    viewer: Autodesk.Viewing.Viewer3D;
}

const CommissioningButton: React.FC<ICommissioningButtonProps> = ({ viewer }) => {
    const [selected, setSelected] = useState<number[]>([]);

    const [isProcessing, setIsProcessing] = useState<ICommissioningProgress>();

    const selectedBluetoothDevice = useESelectedBluetoothDevice();

    useEffect(() => {
        viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, setSelection);
        return () => {
            viewer.removeEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, setSelection);
        };
    }, []);

    const setSelection = selection => {
        setSelected(selection.dbIdArray ?? []);
    };

    const handleClick = () => {
        setIsProcessing({
            message: 'Preparing commissioning string',
        });
        handleCommissioning(selected, viewer, commissioningString => {
            console.log(`Commission string [${commissioningString}]`, commissioningString);
            setIsProcessing({
                message: `Commissioning string '${commissioningString}' prepared, sending to BT device`,
            });
            sendCommissioningString(commissioningString, selectedBluetoothDevice, progress => {
                setIsProcessing(progress);
            });
        });
    };

    return (
        <>
            <IonLoading
                key={isProcessing?.message}
                isOpen={Boolean(isProcessing)}
                message={isProcessing?.message}
                spinner="circular"
                keyboardClose={false}
                showBackdrop={true}
                duration={!isProcessing?.done ? undefined : 2000}
            />
            <Fab
                size="large"
                style={{
                    position: 'absolute',
                    bottom: 16,
                    right: 16,
                    zIndex: 9,
                }}
                color="primary"
                aria-label="commissioning"
                onClick={handleClick}
                disabled={selected?.length != 1 || !selectedBluetoothDevice?.name}
            >
                {/*<Bluetooth />*/}
                <Icon name={'menu-commissioning'} size={40} />
            </Fab>
        </>
    );
};

export default CommissioningButton;

export interface ITreeFail {
    error: any;
    message: string;
}

export interface IVersion {
    id: string;
    text: string;
    data: {
        createdTime: string;
        createdUsername: string;
        fileSize: number;
        fileType: string;
        lastModifiedTime: string;
        lastModifiedUsername: string;
        storageId: string;
        type: string;
        urn: string;
        version: number;
    };
}

export interface ITreeRoot {
    id: string;
    parentId: string;
    data: {
        type: TreeDataType;
    };
    children: ITree[];
}

export interface ITree {
    id: string;
    text: string;
    data: {
        type: TreeDataType;
        urn: string;

        storageId?: string;
        fileType?: string;
        version?: number;
        fileSize?: number;
        subfoldersCount?: number;
        parentFolderUrn?: string;

        lastModifiedTime?: string;
        createdTime?: string;
        lastModifiedUsername?: string;
        createdUsername?: string;
        extensionType?: string;

        lastVersionTreeNode?: ILastVersionTreeNode;
    };
    children?: ITree[];
    loaded?: boolean;
    pathIds?: string[];
}

export interface ILastVersionTreeNode {
    id: string;
    text: string;
    data: {
        type: TreeDataType;
        urn: string;

        storageId?: string;
        fileType?: string;
        version?: number;
        fileSize?: number;
        markupsCount?: number;

        lastModifiedTime?: string;
        createdTime?: string;
        lastModifiedUsername?: string;
        createdUsername?: string;
    };
}

export interface IAccount {
    id: string;
    name: string;
    urn: string;
}

export interface IProject {
    id: string;
    name: string;
    urn: string;
    account: IAccount;
}

export enum TreeDataType {
    HUB = 'hub',
    PROJECT = 'project',
    FOLDER = 'folder',
    ITEM = 'item',
    VERSION = 'version',
}

export enum TreeVariant {
    PLOT = 'plot',
}

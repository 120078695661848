import { DispatchAction } from '@iolabs/redux-utils';
import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TranslationListResponse } from '../../generate/api';
import { translateApi } from '../../packages/Api/data/translation/client';
import { useProfile } from '../../redux/keyclock';
import { RootState } from '../../redux/store';
import {
    setLanguages,
    setLoaded,
    setTranslation,
} from '../../redux/translations/translationsSlice';

interface ILanguageProviderProps {
    children: React.ReactNode;
}

/**
 * This component is used to provide the language context to the app.
 * - Load the translations from the API
 * - If translations are not found in redux, load them from the API
 * - If language is changed, load the translations for the new language
 */
const LanguageProvider: React.FC<ILanguageProviderProps> = ({ children }) => {
    const dispatch = useDispatch<DispatchAction>();
    const translations = useSelector((state: RootState) => state.translations);
    const profile = useProfile();
    const { keycloak, initialized: keycloakInitialized } = useKeycloak();

    useEffect(() => {
        if (keycloak && keycloakInitialized) {
            fetchLanguages();
        }
    }, [keycloak && keycloakInitialized, profile]);

    useEffect(() => {
        if (translations.currentLanguage) {
            loadTranslationsForLanguage(translations.currentLanguage);
        }
    }, [translations]);

    function fetchLanguages() {
        if (keycloak && keycloakInitialized) {
            translateApi
                .translationsLanguagesGet()
                .then(response => {
                    dispatch(
                        setLanguages(
                            response.data?.languages?.map(x => {
                                return x.code as string;
                            }) ?? [],
                        ),
                    );
                    console.log('TP: Languages loaded', response.data);
                })
                .catch(error => {
                    console.error('TP: Unable to load languages + response', error);
                });
        }
    }

    function fetchTranslationsForLanguage(lang: string) {
        if (keycloak && keycloakInitialized) {
            let translationData: TranslationListResponse;
            dispatch(setLoaded(false));
            translateApi
                .translationsListGet(lang.toUpperCase())
                .then(response => {
                    translationData = response.data;
                    dispatch(setTranslation(translationData));
                    console.log('TP: Translations loaded', translationData);
                })
                .catch(error => {
                    console.error('TP: Unable to load translations + response', error);
                })
                .finally(() => {
                    return translationData;
                });
        }
    }

    function loadTranslationsForLanguage(lang: string) {
        if (translations.items.length > 0) {
            const translationForCurrentLanguage = translations.items.find(
                x => x.language?.toLowerCase() == lang.toLowerCase(),
            );

            if (!translationForCurrentLanguage) {
                fetchTranslationsForLanguage(lang);
            }
        } else {
            if (keycloak && keycloak.token) {
                fetchTranslationsForLanguage(lang);
            }
        }
    }

    return <>{children}</>;
};

export default LanguageProvider;

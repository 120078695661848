import { createStyles, makeStyles } from '@mui/styles';

import theme from '../../theme/Theme';

const primary = '#009999';
const white = '#FFFFFF';
const gray = '#F3F4F6';

const useStyles = makeStyles(() =>
    createStyles({
        searchWrapper: {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: gray,
        },

        searchInput: {
            padding: '1rem',
            width: '100%',
            backgroundColor: gray,
        },

        closeIcon: {
            display: 'flex',
            backgroundColor: gray,
            padding: '1rem',
            cursor: 'pointer',
        },

        explorerWrapper: {
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            height: '100%',
            overflowY: 'auto',
            [theme.breakpoints.up('sm')]: {
                padding: '1rem',
                border: '1px solid #DDDDDD',
            },
        },

        fileTileWrapper: {
            display: 'flex',
            gap: '0.5rem',
            '&:hover': {
                backgroundColor: gray,
                cursor: 'pointer',
            },
        },

        fileTileIconWrapper: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: gray,
            width: '3.5rem',
            height: '3.5rem',
            flex: 'none',
        },

        fileTileIconVersion: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: primary,
            color: white,
            padding: '0.5rem',
            borderRadius: '50%',
            width: '2.5rem',
            height: '2.5rem',
            fontSize: '0.8rem',
        },

        fileTileContent: {
            padding: '0.25rem',
        },

        '@media (max-width: 400px)': {
            explorerWrapper: {
                padding: '0.5rem',
            },
        },
    }),
);

export default useStyles;

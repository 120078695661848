import { AxiosResponse } from 'axios';

import config from '../../../../config/config';
import { Configuration, ForgeDataManagementApi } from '../../../../generate/api';
import { getAuthorizationToken } from '../../../../redux/keyclock/middleware';
import { store } from '../../../../redux/store';
import ApiClient from '../../ApiClient';
import { IAccount, IProject, ITree, ITreeRoot, TreeDataType } from './types';

const treeApi = new ApiClient('/DataManagement', config.api.baseUrl);

export const treeDataNode = (
    token: string,
    nodeId: string,
    depth: number,
    filter: object = {},
): Promise<ITreeRoot> => {
    ApiClient.setAuthorizationToken(token);

    return treeApi
        .post(`/tree?nodeId=${nodeId}&depth=${depth}`, filter)
        .then((response: AxiosResponse<ITreeRoot>) => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            return Promise.reject(error);
        });
};

/**
 * Gets tree nodes manually.
 * @param token - Keycloak token
 * @param parentId - URN of parent folder
 * @param nodeId - URN of current folder
 * @param rootId - ForgeHubID
 * @param depth - Nesting depth to load
 * @param nodeType
 */
export const treeDataManual = (
    token: string,
    parentId: string,
    nodeId: string,
    rootId: string,
    depth: number,
    nodeType: string = TreeDataType.FOLDER,
): Promise<ITreeRoot> => {
    ApiClient.setAuthorizationToken(token);

    return treeApi
        .post(
            `/tree/manual?parentId=${parentId}&nodeId=${nodeId}&rootId=${rootId}&depth=${depth}&nodeType=${nodeType}`,
        )
        .then((response: AxiosResponse<ITreeRoot>) => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            return Promise.reject(error);
        });
};

export const getProjects = (token: string, depth = 2, filter: object = {}): Promise<IProject[]> => {
    ApiClient.setAuthorizationToken(token);

    return treeApi
        .post(`/tree?depth=${depth}`, filter)
        .then((response: AxiosResponse<ITreeRoot>) => {
            return Promise.resolve(treeResponseToProjects(response.data?.children));
        });
};

export const treeResponseToProjects = (response: ITree[]): IProject[] => {
    const projects: IProject[] = [];

    response.forEach(account => {
        const acc: IAccount = {
            id: account.id,
            name: account.text,
            urn: account.data.urn,
        };
        account.children?.forEach(project => {
            projects.push({
                account: acc,
                id: project.id,
                name: project.text,
                urn: project.data.urn,
            });
        });
    });

    return projects;
};

export const dataManagementApi = new ForgeDataManagementApi(
    new Configuration({
        basePath: config.api.baseUrl,
        accessToken: () => {
            return store.getState().keycloak.token as string;
        },
    }),
);

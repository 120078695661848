import './objectLayer.scss';

import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import ObjectWrapper from './ObjectWrapper';
import {
  getClientCoordsFromEvent,
  getItemsPositions,
  getWorldCoordsFromViewerCoords,
  IItemExtended,
  ItemState,
  selectIntersectedObject,
} from './viewerUtils';

interface IPoint3 {
  x: number;
  y: number;
  z: number;
}

export interface IPushpinsItem<TItem> {
  /**
   * X, Y, Z coordinates in the world coordination system of the pushpin
   */
  coordinates: THREE.Vector3 | IPoint3;

  /**
   * Identifier of the object in the model which the pushpin is associated to
   */
  objectId?: number;

  /**
   * Unique key to set to top element of the item
   */
  key?: string | number;

  /**
   * Data which will be passed to render function
   */
  data?: TItem;

  /**
   * Class names which will be appended to top level element of pushpin
   */
  classNames?: string;
}

export interface IObjectLayerProps<TItem> {
  /**
   * Instance of Forge Viewer
   */
  viewer: Autodesk.Viewing.Viewer3D;

  /**
   * List of objects to render
   */
  items?: IPushpinsItem<TItem>[];

  /**
   * Render action for rendering content of the object
   */
  renderPushpinContent?: (
    item: TItem, // TODO
    anchorElement: HTMLElement | null,
    handleClose?: () => void,
  ) => ReactElement;

  /**
   * Flag to disable selection
   */
  disableSelection?: boolean;

  /**
   * Place new Object wrapper onto layer when clicking
   */
  placeOnClick?: boolean;

  /**
   * Action which is used when the viewer is clicked, contains useful point information
   */
  onCanvasClick?: (pushpin: IPushpinsItem<TItem>) => void;

  /**
   * Event called when new pusphin placed or changed its position
   * @param pushpin
   */
  onPushpinChanged?: (pushpin: IPushpinsItem<TItem>) => void;

  /**
   * Event called when new pusphin is clicked
   * @param pushpin
   */
  onPushpinClicked?: (pushpin?: TItem) => void;
}

const ObjectLayer = <TItem,>({
  viewer,
  onCanvasClick,
  items,
  renderPushpinContent,
  disableSelection,
  placeOnClick,
  onPushpinChanged,
  onPushpinClicked,
}: IObjectLayerProps<TItem>) => {
  const isDragging = useRef(false);
  const [pushpinAdded, setPushpinAdded] = useState<IPushpinsItem<TItem> | undefined>();
  const [extendedItems, setExtendedItems] = useState<IItemExtended<TItem>[]>([]);
  const [modelLoaded, setModelLoaded] = useState<boolean>(false);

  const onViewerClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const viewerCoords = getClientCoordsFromEvent(event);
    const worldCoords = getWorldCoordsFromViewerCoords(viewer, viewerCoords);
    const dbId = selectIntersectedObject(viewer, viewerCoords);
    setPushpinAdded({
      coordinates: worldCoords,
      objectId: dbId,
    });
    event.stopPropagation();
  };

  const handleViewerClick = (e: Event) => {
    if (!isDragging.current) {
      onViewerClick(e as unknown as React.MouseEvent<HTMLElement, MouseEvent>);
    } else {
      isDragging.current = false;
    }
  };

  const calculatePositions = useCallback(() => {
    const itemsPositions: IItemExtended<TItem>[] = [];
    if (items) {
      itemsPositions.push(...getItemsPositions(viewer, items, ItemState.EXISTING));
    }
    if (placeOnClick && pushpinAdded) {
      itemsPositions.push(...getItemsPositions(viewer, [pushpinAdded], ItemState.ADDED));
    }
    setExtendedItems(itemsPositions);
  }, [viewer, items, pushpinAdded, placeOnClick]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleViewerMouseMove = (e: Event) => {
    isDragging.current = true;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleViewerMouseDown = (e: Event) => {
    isDragging.current = false;
  };

  useEffect(() => {
    viewer.canvas.addEventListener('click', handleViewerClick);
    return () => {
      viewer.canvas.removeEventListener('click', handleViewerClick);
    };
  }, [viewer]);

  // add extension listeners
  useEffect(() => {
    if (viewer) {
      // mark model as loaded
      if (viewer.model) {
        setModelLoaded(true);
      } else {
        viewer.addEventListener(Autodesk.Viewing.MODEL_ROOT_LOADED_EVENT, () => {
          setModelLoaded(true);
        });
      }
      if (disableSelection) {
        viewer.disableSelection(true);
      }
      viewer.canvas.addEventListener('click', handleViewerClick);
      viewer.canvas.addEventListener('mousedown', handleViewerMouseDown, {
        capture: true,
      });
      viewer.canvas.addEventListener('mousemove', handleViewerMouseMove);
    }
    return () => {
      if (viewer?.canvas) {
        viewer.canvas.removeEventListener('click', handleViewerClick);
        viewer.canvas.removeEventListener('mousedown', handleViewerMouseDown, {
          capture: true,
        });
        viewer.canvas.removeEventListener('mousemove', handleViewerMouseMove);
      }
    };
  }, [viewer]);

  useEffect(() => {
    if (viewer) {
      viewer.addEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, calculatePositions);
      calculatePositions();
    }
    return () => {
      viewer.removeEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, calculatePositions);
    };
  }, [items, pushpinAdded]);

  useEffect(() => {
    if (pushpinAdded) {
      if (onCanvasClick) {
        onCanvasClick(pushpinAdded);
      }
      if (onPushpinChanged && placeOnClick) {
        onPushpinChanged(pushpinAdded);
      }
      if (!placeOnClick) {
        // clear pushpin added when not in place-on-click mode
        setPushpinAdded(undefined);
      }
      calculatePositions();
    }
  }, [pushpinAdded]);

  return createPortal(
    <div className={'object-layer-wrapper'}>
      {modelLoaded &&
        extendedItems.map((item, index) => (
          <div
            key={`item-box-${item.key ?? index}`}
            className={`object-layer-itemBox object-layer-itemBox--${item.state} ${item.classNames}`}
            style={{
              left: `${item.clientCoords.x}px`,
              top: `${item.clientCoords.y}px`,
              // transform: `scale(${getScale(item)})`,
              zIndex: item.zIndex,
            }}
          >
            <ObjectWrapper<TItem>
              item={item.data}
              renderContent={renderPushpinContent}
              onPushpinClicked={onPushpinClicked}
            />
          </div>
        ))}
    </div>,
    viewer.container,
  );
};

export default ObjectLayer;

import { DispatchAction } from '@iolabs/redux-utils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, MenuItem, Popover, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DialogContext } from '../../dialogs/DialogProvider/DialogProvider';
import { useProjectState } from '../../redux/project';
import { RootState } from '../../redux/store';
import { useTranslation, useTranslations } from '../../redux/translations/hook';
import { setLanguage } from '../../redux/translations/translationsSlice';
import { DF } from '../DialogFactory/DialogFactory';
import { INotificationDialogProps } from '../DialogFactory/NotificationDialog/NotificationDialog';
import messages from './messages';

const useLanguageSwitcherStyles = makeStyles((theme: Theme) =>
    createStyles({
        switcherButton: {
            marginRight: theme.spacing(2),
            fontSize: theme.typography.pxToRem(15),
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            verticalAlign: 'middle',
            paddingLeft: theme.spacing(2),
        },
        switcherButtonArrow: {
            fontSize: theme.typography.pxToRem(14),
            marginLeft: '4px',
        },
    }),
);

const LanguageChangedDialog: React.FC = () => {
    const { openDialog } = useContext(DialogContext);
    const currentLanguage = useSelector((state: RootState) => state.translations.currentLanguage);
    const [lastLanguage, setLastLanguage] = React.useState<string>();
    const project = useProjectState();
    const translations = useTranslations();

    const transMessageLanguageChanged = useTranslation({
        ...messages.messageLanguageChanged,
    });
    const transMessageViewerReloadRequired = useTranslation({
        ...messages.messageViewerReloadRequired,
    });

    useEffect(() => {
        // show this message only when is loaded Forge Viewer
        if (project.file.currentVersion?.text === undefined) return;

        // if translations for current language is not loaded, return
        if (!translations.items.some(item => item.language === currentLanguage)) return;

        setLastLanguage(currentLanguage);
        if (lastLanguage && lastLanguage !== currentLanguage) {
            openDialog<INotificationDialogProps>(
                DF.NOTIFICATION_DIALOG,
                transMessageLanguageChanged,
                {
                    content: transMessageViewerReloadRequired,
                },
            );
        }
    }, [translations]);

    return null;
};

const LanguageSwitcher = () => {
    // const { languages, currentLanguage, setCurrentLanguage } = useLanguageProvider();
    const classes = useLanguageSwitcherStyles();
    const dispatch = useDispatch<DispatchAction>();
    const languages = useSelector((state: RootState) => state.translations.languages);
    const currentLanguage = useSelector((state: RootState) => state.translations.currentLanguage);

    const handleLanguageToggle = language => {
        dispatch(setLanguage(language));
        setAnchorEl(null);
    };

    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

    const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'language-popover' : undefined;

    return (
        <>
            <LanguageChangedDialog />
            <Box aria-describedby={id} onClick={handleOpen} className={classes.switcherButton}>
                <Box>{currentLanguage?.toUpperCase()}</Box>
                <ExpandMoreIcon className={classes.switcherButtonArrow} />
            </Box>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {languages?.map((language, index) => {
                    return (
                        <MenuItem
                            onClick={() => handleLanguageToggle(language)}
                            key={index}
                            disabled={currentLanguage === language}
                        >
                            <Typography variant="inherit">{language?.toUpperCase()}</Typography>
                        </MenuItem>
                    );
                })}
            </Popover>
        </>
    );
};

export default LanguageSwitcher;

import React, { useEffect } from 'react';

import { IssueMarkup } from '../../../generate/api';
import MarkupThumbnail from './MarkupThumbnail';

interface IEditorCurrentMarkupProps {
    markup?: IssueMarkup;
}

const EditorCurrentMarkup: React.FC<IEditorCurrentMarkupProps> = ({ markup }) => {
    useEffect(() => {}, [markup]);

    return (
        <div>
            {markup && (
                <div className="border-gray-200 p4 rounded">
                    <MarkupThumbnail markup={markup} />
                    <div>{markup.name}</div>
                </div>
            )}
        </div>
    );
};

export default EditorCurrentMarkup;

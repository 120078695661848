import React from 'react';

import { useTranslation } from '../../redux/translations/hook';

export interface IFormattedMessageProps {
    id: string;
    defaultMessage: string;
}

const FormattedMessage: React.FC<IFormattedMessageProps> = ({ id, defaultMessage }) => {
    const translation = useTranslation({ id: id, defaultMessage: defaultMessage });
    return <>{translation}</>;
};

export default FormattedMessage;

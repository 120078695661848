import { Capacitor } from '@capacitor/core';
import { Barcode, BarcodeFormat, BarcodeScanner } from '@capacitor-mlkit/barcode-scanning';
import React, { useEffect, useState } from 'react';

const supportedPlatforms: string[] = ['android', 'ios'];
const checkModulePlatform = 'android';

enum MlKitErrors {
    NOT_SUPPORTED = 'NOT_SUPPORTED',
    PERMISSIONS_FAILED = 'PERMISSIONS_FAILED',
    SCANNING_FAILED = 'SCANNING_FAILED',
    MODULE_CHECK_FAILED = 'MODULE_CHECK_FAILED',
    SCANNING_CANCELLED = 'SCANNING_CANCELLED',
}

/**
 * Stop scanning with external scanner
 */
const stopScan = async () => {
    // Make all elements in the WebView visible again
    document.querySelector('body')?.classList.remove('barcode-scanner-active');

    // Remove all listeners
    await BarcodeScanner.removeAllListeners();

    // Stop the barcode scanner
    await BarcodeScanner.stopScan();
};

/**
 * Scan a single barcode
 *
 * @returns Promise<Barcode>
 */
const scanSingleBarcode = async (): Promise<Barcode> => {
    return new Promise(async (resolve, reject) => {
        document.querySelector('body')?.classList.add('barcode-scanner-active');

        BarcodeScanner.scan({
            formats: [BarcodeFormat.DataMatrix],
        })
            .then(result => {
                document.querySelector('body')?.classList.remove('barcode-scanner-active');
                resolve(result.barcodes[0]);
            })
            .catch(error => {
                reject(error);
            });
    });
};

/**
 * Check if the device supports the barcode scanner
 *
 * @returns true if the device supports the barcode scanner
 */
const isSupported = async () => {
    const { supported } = await BarcodeScanner.isSupported();
    return supported;
};

/**
 * Check if the scanner module is available on the android device
 *
 * @returns true if the scanner module is available
 */
const checkScannerModule = async () => {
    if (!isValidPlatform(checkModulePlatform)) {
        console.log('## MlKit | Module - checking | skipped because of no ', checkModulePlatform);
        return true;
    }

    const { available } = await BarcodeScanner.isGoogleBarcodeScannerModuleAvailable();
    console.log('## MlKit | Module - checking | checked - avaliable: ', available);
    return available;
};

/**
 * Install the scanner module
 *
 * @param onScannerModuleInstalled Callback function to be called when the module is installed
 */
const installScannerModule = async (onScannerModuleInstalled: React.RefCallback<void>) => {
    await BarcodeScanner.installGoogleBarcodeScannerModule().then(async () => {
        const available = await checkScannerModule();
        if (available) {
            console.log('## MlKit | Module - installed');
            onScannerModuleInstalled();
        }
    });
};

/**
 * Check if the user has granted permission to use the camera
 *
 * @returns 'granted' | 'denied' | 'prompt'
 */
const checkPermissions = async () => {
    const { camera } = await BarcodeScanner.checkPermissions();
    return camera;
};

/**
 * Request permission to use the camera
 *
 * @returns 'granted' | 'denied' | 'prompt'
 */
const requestPermissions = async () => {
    const { camera } = await BarcodeScanner.requestPermissions();
    return camera;
};

/**
 * Check if the platform is supported
 *
 * @returns true if the platform is supported
 */
const isValidPlatform = (checkPlatform: string | boolean = false): boolean => {
    const platform = Capacitor.getPlatform();
    if (!checkPlatform) return supportedPlatforms.includes(platform);

    return platform === checkPlatform;
};

interface IBarcodeScannerProps {
    onScanSuccess?: React.RefCallback<string>;
    onSupportFailed?: (error: string) => void;
}

const MlKitBarcodeScanner: React.FC<IBarcodeScannerProps> = ({
    onScanSuccess,
    onSupportFailed,
}) => {
    const [barcode, setBarcode] = useState<string>('');
    const [error, setError] = useState<string>('');

    const handleScannerConfigFlow = async (onConfigFlowSuccess: () => void) => {
        console.log('## MlKit | Scan Config Flow - started');

        const supported = await isSupported();
        if (!supported) {
            if (onSupportFailed) onSupportFailed(MlKitErrors.NOT_SUPPORTED);
            return false;
        }

        const camera = await checkPermissions();
        if (camera !== 'granted') {
            const request = await requestPermissions();
            if (request !== 'granted') {
                if (onSupportFailed) onSupportFailed(MlKitErrors.PERMISSIONS_FAILED);
                return false;
            }
        }

        const scannerModuleAvailable = await checkScannerModule();
        if (!scannerModuleAvailable) {
            await installScannerModule(() => {
                onConfigFlowSuccess();
            });
            return false;
        }

        console.log('## MlKit | Scan Config Flow - finished');
        onConfigFlowSuccess();
    };

    //Scan barcode - module already checked
    const scanBarcodeChecked = () => {
        console.log('## MlKit | Scanning');
        scanSingleBarcode()
            .then((barcodeResult: Barcode) => {
                console.log(
                    '## MlKit | Scanning - success | result',
                    JSON.stringify(barcodeResult),
                );
                stopScan();
                setBarcode(barcodeResult.displayValue);
                if (onScanSuccess) onScanSuccess(barcodeResult.displayValue);
            })
            .catch(scanningError => {
                if (!scanningError.toString().includes('canceled')) {
                    console.log('## MlKit | Scanning - error', scanningError);
                    setError(scanningError);
                    if (onSupportFailed) onSupportFailed(MlKitErrors.SCANNING_FAILED);
                } else {
                    console.log('## MlKit | Scanning - cancelled by user');
                    if (onScanSuccess) onScanSuccess(MlKitErrors.SCANNING_CANCELLED);
                }
            });
    };

    //Scan barcode including check
    const scanBarcode = async () => {
        console.log('## MlKit | Module - checking');
        const moduleChecked = await checkScannerModule();
        if (!moduleChecked) {
            console.log('## MlKit | Module - not available');
            if (onSupportFailed) onSupportFailed(MlKitErrors.MODULE_CHECK_FAILED);
            return;
        }

        scanBarcodeChecked();
    };

    //Handle config flow upon component mount
    useEffect(() => {
        handleScannerConfigFlow(() => {
            scanBarcode();
        });
    }, []);

    return <></>;
};

export default MlKitBarcodeScanner;
export { isValidPlatform, MlKitErrors };

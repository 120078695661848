import { darken, lighten, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            width: '100%',
        },
        tabs: {
            minHeight: '30px',
        },
        tab: {
            minHeight: '30px',
            textTransform: 'uppercase',
            color: darken(theme.palette.text.secondary, 0.2),
        },
        buttons: {
            left: theme.spacing(1),
            top: theme.spacing(7),
            zIndex: 20,
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                top: theme.spacing(1),
                display: 'flex',
            },
        },
        layerButton: {
            paddingRight: theme.spacing(2),
            alignSelf: 'start',
            textAlign: 'left',
            height: '28px',
            backgroundColor: theme.palette.common.white,
            fontSize: theme.typography.pxToRem(10),
            border: '1px solid #000',
            marginLeft: theme.spacing(1),
            marginBottom: theme.spacing(1),
            color: theme.palette.text.primary,
            display: 'block',
            '&:hover': {
                backgroundColor: lighten(theme.palette.primary.main, 0.6),
                '& $layerButtonIcon': {
                    backgroundColor: lighten(theme.palette.primary.main, 0.6),
                },
            },
        },
        layerButtonInner: {
            textWrap: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'block',
        },
        layerButtonIcon: {
            position: 'absolute',
            right: 0,
            top: 0,
            width: '30px',
            height: '100%',
            alignItems: 'center',
            display: 'flex',
            background: '#fff',
            // '&:hover': {
            //     backgroundColor: lighten(theme.palette.primary.main, 0.5),
            // },
        },
        arrows: {
            // marginTop: theme.spacing(1),
            // [theme.breakpoints.up("md")]: {
            //   marginTop: 0
            // }
        },
        arrow: {
            backgroundColor: theme.palette.common.white,
            fontSize: theme.typography.pxToRem(10),
            border: '1px solid #000',
            minWidth: 'auto',
            '& *': {
                margin: '0',
            },
            '&:hover': {
                backgroundColor: lighten(theme.palette.primary.main, 0.6),
            },
        },
        arrowPrev: {
            borderTopRightRadius: '0',
            borderBottomRightRadius: '0',
            borderRight: '0',
        },
        arrowNext: {
            borderTopLeftRadius: '0',
            borderBottomLeftRadius: '0',
        },
        circularProgress: {
            color: theme.palette.primary.main,
            margin: '3px',
        },
        swipeableViews: {
            maxHeight: 'calc(100vh - 170px)',
        },
        listItem: {
            paddingTop: 0,
            paddingBottom: 0,
        },
        listItemText: {
            marginTop: '3px',
            marginBottom: '3px',
        },
        versionMenuItem: {
            padding: '3px 10px',
            cursor: 'pointer',
            '&:hover': {
                background: '#ddd',
            },
        },
        disabled: {
            '& *': {
                color: theme.palette.text.primary,
                borderColor: theme.palette.grey[100] + ' !important',
            },
        },
    }),
);

export default useStyles;

import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogTitle, IconButton, Typography } from '@mui/material';
import React, { useContext } from 'react';

import { DialogContext } from '../DialogProvider/DialogProvider';
import useStyles from './styles';

const DialogWrapper: React.FC = () => {
    const classes = useStyles();
    const { dialogs, toggleDialog } = useContext(DialogContext);

    return (
        <>
            {dialogs?.map((dialog, index) => (
                <Dialog
                    key={index}
                    open={!!dialog?.open}
                    onClose={() => toggleDialog(dialog?.id)}
                    aria-labelledby="dialog-wrapper-title"
                    aria-describedby="dialog-wrapper-description"
                    maxWidth={dialog?.maxWidth}
                    fullWidth={dialog?.fullWidth}
                    className={classes.dialog}
                    scroll="paper"
                >
                    {dialog?.title && (
                        <DialogTitle
                            component="span"
                            id="dialog-wrapper-title"
                            classes={{ root: classes.dialogTitleRoot }}
                        >
                            <Typography variant="h5">{dialog?.title}</Typography>
                        </DialogTitle>
                    )}

                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={() => toggleDialog(dialog?.id)}
                    >
                        <CloseIcon />
                    </IconButton>
                    {typeof dialog?.dialogContent === 'function'
                        ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          //@ts-ignore
                          dialog?.dialogContent(dialog.data)
                        : dialog?.dialogContent}
                </Dialog>
            ))}
        </>
    );
};

export default DialogWrapper;

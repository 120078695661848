import { BluetoothSerial } from '@awesome-cordova-plugins/bluetooth-serial';
import { DispatchAction } from '@iolabs/redux-utils';
import { isPlatform } from '@ionic/react';
import { Settings } from '@mui/icons-material';
import { Box, Button, Fab, Modal } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setSelectedBluetoothDevice, useESelectedBluetoothDevice } from '../../redux/project';
import { IBluetoothDevice } from '../../redux/project/types';
import useStyles from './styles';

const CommissioningSettings: React.FC = () => {
    const classes = useStyles();

    const dispatch = useDispatch<DispatchAction>();

    const selectedBluetoothDevice = useESelectedBluetoothDevice();

    // const deviceListExample = [
    //   {name: '[AV] Samsung Soundbar K335 K-Series', address: 'D0:03:DF:56:56:55', id: 'D0:03:DF:56:56:55', class: 1044},
    //   {name: 'Trust SPK-05', address: 'F0:13:C3:49:85:72', id: 'F0:13:C3:49:85:72', class: 1044},
    //   {name: 'SteamController', address: 'DA:89:7D:D3:ED:BE', id: 'DA:89:7D:D3:ED:BE', class: 1408},
    //   {name: 'AB Shutter 3', address: '00:1D:AE:46:BB:2D', id: '00:1D:AE:46:BB:2D', class: 1344},
    //   {name: 'Trust SPK-05', address: 'F0:13:C3:3A:F7:E3', id: 'F0:13:C3:3A:F7:E3', class: 1044},
    //   {name: 'NGS ARTICA JELLY', address: 'C8:57:6F:5B:E1:2B', id: 'C8:57:6F:5B:E1:2B', class: 1028},
    //   {name: 'Niceboy HIVE pods 2', address: '00:00:11:11:16:52', id: '00:00:11:11:16:52', class: 1048},
    //   {name: 'SE-MS7BT', address: 'F4:0E:11:71:07:2B', id: 'F4:0E:11:71:07:2B', class: 1028},
    //   {name: 'Trust SPK-05', address: 'F0:13:C3:49:96:14', id: 'F0:13:C3:49:96:14', class: 1044},
    // ]

    const [deviceList, setDeviceList] = useState<IBluetoothDevice[]>([]);

    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);

    const openModal = () => {
        scanDevices();
        setOpen(true);
    };

    const scanDevices = () => {
        BluetoothSerial.list().then(devices => {
            console.debug(devices);
            if (devices) {
                setDeviceList(devices);
            }
        });
    };

    const setDevice = (event, device: IBluetoothDevice) => {
        console.log(device);
        dispatch(setSelectedBluetoothDevice(device));
        setOpen(false);
    };

    // Open setting on init if no bluetooth device selected
    useEffect(() => {
        if (!selectedBluetoothDevice?.name) {
            setOpen(true);
        }
    }, []);

    return (
        <>
            <Fab
                style={{
                    position: 'absolute',
                    bottom: 90,
                    right: 23,
                    zIndex: 9,
                }}
                color="primary"
                aria-label="commissioning"
                onClick={openModal}
            >
                <Settings />
            </Fab>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={classes.modal}>
                    {isPlatform('ios') || isPlatform('android') ? (
                        <>
                            <Box className={classes.title}>Select bluetooth device:</Box>

                            <Box mb={2} mt={2}>
                                <Button
                                    onClick={scanDevices}
                                    variant={'contained'}
                                    color={'primary'}
                                    fullWidth
                                >
                                    Search bluetooth devices
                                </Button>
                            </Box>

                            {deviceList.map((device, index) => {
                                return (
                                    <Box
                                        key={index}
                                        className={clsx(
                                            classes.listItem,
                                            device.address === selectedBluetoothDevice?.address &&
                                                classes.listItemActive,
                                        )}
                                        onClick={event => setDevice(event, device)}
                                    >
                                        {device.name}
                                    </Box>
                                );
                            })}
                        </>
                    ) : (
                        <Box className={classes.title}>Please use android device</Box>
                    )}
                </Box>
            </Modal>
        </>
    );
};

export default CommissioningSettings;

import { IonRouterOutlet } from '@ionic/react';
import { useKeycloak } from '@react-keycloak/web';
import React from 'react';
import { Route } from 'react-router';

import { IIcon } from '../components/Icon/IconSet';
import { KeycloakRealmRole } from '../redux/keyclock/types';
import DashboardPage from './DashboardPage/DashboardPage';
import IconsPage from './IconsPage/IconsPage';
import NotFoundPage from './NotFoundPage/NotFoundPage';
import ProjectSetupPage from './ProjectSetup/ProjectSetup';
import TranslationsList from './TranslationsList/TranslationsList';
import ViewerPage from './ViewerPage/ViewerPage';

export interface AppPage {
    title: string;
    path: string;
    exact?: boolean;
    icon: IIcon;
    component?: any;
    pages?: AppPage;
    wip?: boolean;
    inMenu?: boolean;
    inDashboard?: boolean;
    children?: AppPage[];
    requiredRole?: KeycloakRealmRole;
    disabled?: boolean;
}

export enum Path {
    ROOT = '/',
    ICONS = '/icons',
    OFFLINE = '/offline-test',
    VIEWER = '/viewer',
    PROJECT_SETUP = '/project-setup',
    TRANSLATIONS_LIST = '/translations-list',
}

export const appPages: AppPage[] = [
    // {
    //     title: 'Dashboard',
    //     path: Path.ROOT,
    //     icon: 'chevron-left',
    //     exact: true,
    //     component: DashboardPage,
    // },
    {
        title: 'Viewer',
        // path: Path.OFFLINE,
        path: Path.VIEWER,
        // path: Path.ROOT,
        icon: 'dashboard-platform',
        exact: true,
        inDashboard: true,
        component: ViewerPage,
    },
    {
        title: 'Project setup',
        path: Path.PROJECT_SETUP,
        icon: 'dashboard-platform',
        exact: true,
        inDashboard: true,
        component: ProjectSetupPage,
    },
    {
        title: 'Translations list',
        path: Path.TRANSLATIONS_LIST,
        // path: Path.PROJECT_SETUP,
        icon: 'dashboard-platform',
        exact: true,
        inDashboard: true,
        component: TranslationsList,
    },
];

export const hasRouteAccess = (keycloak: Keycloak.KeycloakInstance, appPage: AppPage) => {
    return !appPage.requiredRole || keycloak?.hasRealmRole(appPage.requiredRole);
};

const PageRouter: React.FC = () => {
    const { keycloak, initialized: keycloakInitialized } = useKeycloak();

    return (
        <IonRouterOutlet>
            {keycloakInitialized &&
                keycloak &&
                appPages
                    .filter(appPage => hasRouteAccess(keycloak, appPage))
                    .map((appPage, index) => (
                        <Route
                            key={index}
                            path={appPage.path}
                            exact={appPage.exact}
                            render={props => {
                                return <appPage.component {...props} title={appPage.title} />;
                            }}
                        />
                    ))}
            {/*<Route path={Path.ICONS} exact={true} component={IconsPage} />*/}
            <Route component={NotFoundPage} />
        </IonRouterOutlet>
    );
};

export default PageRouter;

import { useSelector } from 'react-redux';

import {
    selectActiveProject,
    selectPlotActiveNodeId,
    selectPlotActiveVersion,
    selectPlotData,
    selectPlotExpandedNodes,
    selectPlotLoading,
    selectPlotSelectedNodes,
    selectProjects,
} from './treeSlice';

// plot
export const usePlotData = () => useSelector(selectPlotData);
export const usePlotLoading = () => useSelector(selectPlotLoading);
export const usePlotActiveNodeId = () => useSelector(selectPlotActiveNodeId);
export const usePlotActiveVersion = () => useSelector(selectPlotActiveVersion);
export const usePlotExpandedNodes = () => useSelector(selectPlotExpandedNodes);
export const usePlotSelectedNodes = () => useSelector(selectPlotSelectedNodes);

// project
export const useActiveProject = () => useSelector(selectActiveProject);
export const useProjects = () => useSelector(selectProjects);

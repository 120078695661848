import '@iolabs/forge-viewer-markups-gui/dist/main.css';
import './guiControlbar.css';

import {
    DefaultTools,
    MarkupsGuiExtension,
    setPickerOptions,
} from '@iolabs/forge-viewer-markups-gui';
import { IToolDefinition } from '@iolabs/forge-viewer-markups-gui/dist/Tools';
import { DispatchAction } from '@iolabs/redux-utils';
import { useKeycloak } from '@react-keycloak/web';
import htmlModule from 'html';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import alamakustischNichAddressiert from '!!raw-loader!./symbols/alamakustisch_nich_addressiert.svg';
import alarmOptischAkustisch from '!!raw-loader!./symbols/alarm_optisch_akustisch.svg';
import alarmakustisch from '!!raw-loader!./symbols/alarmakustisch.svg';
import alarmindikator from '!!raw-loader!./symbols/alarmindikator.svg';
import alarmoptischNichAddressiert from '!!raw-loader!./symbols/alarmoptisch_nich_addressiert.svg';
import antenna from '!!raw-loader!./symbols/antenna.svg';
import bmaBedieneterminal from '!!raw-loader!./symbols/bma_bedieneterminal.svg';
import bmaZentrale from '!!raw-loader!./symbols/bma_zentrale.svg';
import door1 from '!!raw-loader!./symbols/door_1.svg';
import door2 from '!!raw-loader!./symbols/door_2.svg';
import doorSliding from '!!raw-loader!./symbols/door_sliding.svg';
import flammenmelder from '!!raw-loader!./symbols/flammenmelder.svg';
import handfeuermelder from '!!raw-loader!./symbols/handfeuermelder.svg';
import kanalmelder from '!!raw-loader!./symbols/kanalmelder.svg';
import linearerRauchmelder from '!!raw-loader!./symbols/linearer_rauchmelder.svg';
import linienbausteinen from '!!raw-loader!./symbols/linienbausteinen.svg';
import linientrenner from '!!raw-loader!./symbols/linientrenner.svg';
import mehrkriterienmelder from '!!raw-loader!./symbols/mehrkriterienmelder.svg';
import mehrkriterienmelderHb from '!!raw-loader!./symbols/mehrkriterienmelder_hb.svg';
import mehrkriterienmelderHd from '!!raw-loader!./symbols/mehrkriterienmelder_hd.svg';
import rauchmelder from '!!raw-loader!./symbols/rauchmelder.svg';
import rauchmelderHb from '!!raw-loader!./symbols/rauchmelder_hb.svg';
import rauchmelderHd from '!!raw-loader!./symbols/rauchmelder_hd.svg';
import rauchmelderMitSignalsockel from '!!raw-loader!./symbols/rauchmelder_mit_signalsockel.svg';
import rechtig from '!!raw-loader!./symbols/rechtig.svg';
import reflektorLinearerRauchmelder from '!!raw-loader!./symbols/reflektor_linearer_rauchmelder.svg';
import sicherheitbarriere from '!!raw-loader!./symbols/sicherheitbarriere.svg';
import sprinklerprufbox from '!!raw-loader!./symbols/sprinklerprufbox.svg';
import stockwerkbedienung from '!!raw-loader!./symbols/stockwerkbedienung.svg';
import turmagnetNichAddressiert from '!!raw-loader!./symbols/turmagnet_nich_addressiert.svg';
import warmemelder from '!!raw-loader!./symbols/warmemelder.svg';
import zusatzAlarmAkustisch from '!!raw-loader!./symbols/zusatz_alarm_akustisch.svg';
import zusatzAlarmOptischAkustisch from '!!raw-loader!./symbols/zusatz_alarm_optisch_akustisch.svg';

import { DialogContext } from '../../dialogs/DialogProvider/DialogProvider';
import { CreateMarkupRequest, ObjectMarkup } from '../../generate/api';
import { markupsApi2 } from '../../packages/Api/data/markups/client';
import { addNotification } from '../../redux/notifier';
import {
    setMarkupIsEdited,
    setMarkupsList,
    setMarkupsLoading,
    useMarkups,
} from '../../redux/project';
import { useTranslation } from '../../redux/translations/hook';
import { DF } from '../DialogFactory/DialogFactory';
import { INotificationDialogProps } from '../DialogFactory/NotificationDialog/NotificationDialog';
import { createStampTool } from '../Viewer/extensions/StampExtensions';
import { getObjectId } from './createObjectId';
import HideSelectedMarkup from './HideSelectedMarkup';
import messages from './messages';
interface IMarkupData {
    content: any;
    viewerState: any;
    name?: string;
}

interface IMarkupsProps {
    viewer: Autodesk.Viewing.Viewer3D;
    viewable?: any;
    fileVersionUrn?: string;
}

export const createTool = (code: string, name: string, icon: string): IToolDefinition => {
    return {
        code: code,
        name: name,
        icon: icon,
        toolFactory: extension => {
            return createStampTool(
                extension,
                extension.viewer.container.querySelector(`#markups-${code} svg`),
            );
        },
    };
};

export const Tools: IToolDefinition[] = [
    ...DefaultTools,
    {
        code: 'architecture',
        name: 'Architecture symbols',
        icon: 'A',
        childTools: [
            createTool('D1', 'Door 1', door1),
            createTool('D2', 'Door 2', door2),
            createTool('DS', 'Door Sliding', doorSliding),
        ],
    },
    {
        code: 'siemens1',
        name: 'Siemens symbols 1',
        icon: 'B',
        childTools: [
            createTool('AND', 'Alamakustisch nich addressiert', alamakustischNichAddressiert),
            createTool('APA', 'Alarm optisch akustisch', alarmOptischAkustisch),
            createTool('ALM', 'Alarmakustisch', alarmakustisch),
            createTool('ALMD', 'Alarmindikator', alarmindikator),
            createTool('AND', 'Alarmoptisch nich addressiert', alarmoptischNichAddressiert),
            createTool('ANT', 'Antenna', antenna),
            createTool('BB', 'BMA Bedieneterminal', bmaBedieneterminal),
            createTool('BZ', 'BMA Zentrale', bmaZentrale),
            createTool('FLM', 'Flammenmelder', flammenmelder),
        ],
    },
    {
        code: 'siemens2',
        name: 'Siemens symbols 2',
        icon: 'B',
        childTools: [
            createTool('HF', 'Handfeuermelder', handfeuermelder),
            createTool('KM', 'Kanalmelder', kanalmelder),
            createTool('LR', 'Linearer Rauchmelder', linearerRauchmelder),
            createTool('LB', 'Linienbausteinen', linienbausteinen),
            createTool('LN', 'Linientrenner', linientrenner),
            createTool('MM', 'Mehrkriterienmelder', mehrkriterienmelder),
            createTool('MMHB', 'Mehrkriterienmelder_HB', mehrkriterienmelderHb),
            createTool('MMHD', 'Mehrkriterienmelder_HD', mehrkriterienmelderHd),
            createTool('RMS', 'Rauchmelder mit Signalsockel', rauchmelderMitSignalsockel),
            createTool('RC', 'Rauchmelder', rauchmelder),
            createTool('RHB', 'Rauchmelder_HB', rauchmelderHb),
        ],
    },
    {
        code: 'siemens3',
        name: 'Siemens symbols 3',
        icon: 'B',
        childTools: [
            createTool('RHD', 'Rauchmelder_HD', rauchmelderHd),
            createTool('LRL', 'Reflektor Linearer Rauchmelder', reflektorLinearerRauchmelder),
            createTool('RCH', 'Rechtig', rechtig),
            createTool('SB', 'Sicherheitbarriere', sicherheitbarriere),
            createTool('SPB', 'Sprinklerprüfbox', sprinklerprufbox),
            createTool('SW', 'Stockwerkbedienung', stockwerkbedienung),
            createTool('TNA', 'Türmagnet nich addressiert', turmagnetNichAddressiert),
            createTool('WM', 'Wärmemelder', warmemelder),
            createTool('ZAA', 'Zusatz Alarm akustisch', zusatzAlarmAkustisch),
            createTool('ZOA', 'Zusatz Alarm optisch akustisch', zusatzAlarmOptischAkustisch),
        ],
    },
];

const Markups: React.FC<IMarkupsProps> = ({ viewer, viewable, fileVersionUrn }) => {
    const dispatch = useDispatch<DispatchAction>();
    const { keycloak } = useKeycloak();

    const [markupsCoreExtension, setMarkupsCoreExtension] = useState<any>();
    const [markupsGuiExtension, setMarkupsGuiExtension] = useState<MarkupsGuiExtension>();

    const selectedMarkup = useMarkups().selected;
    const [markup, setMarkup] = useState<IMarkupData>();

    const { openDialog } = useContext(DialogContext);

    // translations
    const transMessageMarkupCreated = useTranslation({
        ...messages.messageMarkupCreated,
    });
    const transMessageAttachMarkupNotSuccessful = useTranslation({
        ...messages.messageAttachMarkupNotSuccessful,
    });
    const transMessageCreatingMarkupNotSuccessful = useTranslation({
        ...messages.messageCreatingMarkupNotSuccessful,
    });

    // render markups when viewer is ready
    useEffect(() => {
        viewer.loadExtension('Autodesk.Viewing.MarkupsCore', {}).then(extension => {
            setMarkupsCoreExtension(extension);
        });

        MarkupsGuiExtension.register(viewer, {
            // showNameInput: !isIssuePage && issueTab !== IIssueTab.Create, // default true
            // showSaveButton: !isIssuePage && issueTab !== IIssueTab.Create, // default true
            saveHandler: handleSaveMarkups,
            changeHandler: handleChangeMarkup,
            tools: Tools,
            controlbarModifier: (container: HTMLElement) => {
                // container.style.left = '400px';
            },
        }).then((extension: MarkupsGuiExtension) => {
            setPickerOptions({
                swatches: ['rgba(255, 0, 0, 1)', 'rgb(16,63,251)', 'rgba(255, 146, 36, 1)'],
                inline: true,
                showAlways: true,
                components: {
                    preview: false,
                    hue: false,
                },
            });
            setMarkupsGuiExtension(extension);
        });

        return () => {
            viewer.unloadExtension('Autodesk.Viewing.MarkupsCore');
            MarkupsGuiExtension.unload(viewer);
        };
    }, [viewer]);

    useEffect(() => {
        if (viewer) {
            if (markup) {
                // show markup
                markupsGuiExtension?.showMarkup(
                    htmlModule.prettyPrint(markup.content),
                    markup.viewerState,
                    markup.name,
                    false,
                );
            } else {
                // hide markup
                markupsGuiExtension?.hideMarkup();
            }
        }
    }, [viewer, markup]);

    const handleChangeMarkup = markups => {
        if (markups?.length > 0) {
            console.log(markups);
            dispatch(setMarkupIsEdited(true));
        }
    };

    const handleSaveMarkups = (markupContent, viewerState, backgroundPng, name) => {
        const objectId = getObjectId(fileVersionUrn, viewable.guid);

        const getBlobImg = async () => {
            return fetch(backgroundPng).then(r => r.blob());
        };

        // prepare data
        const data: CreateMarkupRequest = {
            objectId: objectId,
            markup: {
                description: '',
                name: name,
                placement: JSON.stringify(viewerState),
            },
        };

        getBlobImg().then(bg => {
            // create markup
            if (keycloak?.token) {
                markupsApi2
                    .markupsCreatePost(data)
                    .then(response => {
                        const objectMarkupID = response.data.markup?.objectMarkupID;

                        // attach content
                        const img = new File([bg], 'file.png');
                        const formData = new FormData();
                        formData.append('BackgroundImage', img, 'file.png');
                        formData.append('MarkupContent', markupContent);

                        console.log('## Markups - Markup created');

                        if (keycloak?.token && objectMarkupID) {
                            dispatch(setMarkupsLoading(true));
                            markupsApi2
                                .markupsObjectMarkupIdAttachContentPost(
                                    objectMarkupID,
                                    markupContent,
                                    img,
                                )
                                .then(() => {
                                    // show toaster
                                    dispatch(
                                        addNotification({
                                            variant: 'success',
                                            message: transMessageMarkupCreated,
                                        }),
                                    );

                                    console.log(
                                        '## Markups - Markup content attached to created markup',
                                    );

                                    // load all markups
                                    if (keycloak?.token) {
                                        markupsApi2
                                            .markupsAllPost({ objectId })
                                            .then(markupsAllResponseData => {
                                                dispatch(
                                                    setMarkupsList(
                                                        markupsAllResponseData.data
                                                            .markups as ObjectMarkup[],
                                                    ),
                                                );
                                            })
                                            .catch(error => {
                                                console.error(error);
                                            })
                                            .finally(() => {
                                                dispatch(setMarkupsLoading(false));
                                            });
                                    }

                                    // hide markup
                                    markupsGuiExtension?.deactivateTools(true);

                                    dispatch(setMarkupIsEdited(false));
                                })
                                .catch(error => {
                                    console.error(
                                        'Markups - Unable to attach markup content to created markup',
                                        error,
                                    );
                                    openDialog<INotificationDialogProps>(
                                        DF.NOTIFICATION_DIALOG,
                                        'Error',
                                        {
                                            content: transMessageAttachMarkupNotSuccessful,
                                        },
                                    );
                                });
                        }
                    })
                    .catch(error => {
                        console.error('Markups - Unable to create markup', error);
                        openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Error', {
                            content: transMessageCreatingMarkupNotSuccessful,
                        });
                    });
            }
        });
    };

    useEffect(() => {
        if (selectedMarkup) {
            setMarkup({
                content: selectedMarkup.content,
                viewerState: JSON.parse(selectedMarkup.placement as string),
                name: selectedMarkup.name as string,
            });
        } else {
            setMarkup(undefined);
        }
    }, [selectedMarkup]);

    return <HideSelectedMarkup />;
};

export default Markups;

import { ModelLockInfoRequest } from '../../generate/api';
import { filesApi } from '../../packages/Api/data/files/client';
import { setLockedModelInfo } from '../../redux/project';
import { store } from '../../redux/store';

export const fetchUsersLockedInModelAndSaveToRedux = () => {
    const fileVersionUrn = store.getState().project.file.currentVersion?.data.urn as string;
    const forgeProjectId = store.getState().project.project?.id;
    const dispatch = store.dispatch;

    if (!forgeProjectId || !fileVersionUrn) {
        return;
    }

    const data: ModelLockInfoRequest = {
        fileVersionUrn: fileVersionUrn,
        projectID: forgeProjectId,
    };

    filesApi
        .fileVersionLockInfoPost(data)
        .then(response => {
            dispatch(setLockedModelInfo(response.data.lockedModel));
            console.log('setLockedModelInfo');
        })
        .catch(error => {
            console.error(error);
        });
};

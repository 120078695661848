import { DispatchAction } from '@iolabs/redux-utils';
import _ from 'lodash';
import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { DialogContext } from '../../../dialogs/DialogProvider/DialogProvider';
import { hideGlobalOverlay, setCurrentVersion, useProjectState } from '../../../redux/project';
import { useSignalRState } from '../../../redux/signalR/hooks';
import { useTranslation } from '../../../redux/translations/hook';
import { DF } from '../../DialogFactory/DialogFactory';
import { INotificationDialogProps } from '../../DialogFactory/NotificationDialog/NotificationDialog';
import messages from '../../Scanning/messages';
import { IMethodName } from '../../Scanning/utils';
import { checkResponseAccess } from '../SignalRProvider';

const SignalOnAssetSyncComplete: React.FC = () => {
    const signalRState = useSignalRState();
    const dispatch = useDispatch<DispatchAction>();
    const project = useProjectState();

    const { openDialog } = useContext(DialogContext);

    // Trans
    const transMessageAssetSynchronizationComplete = useTranslation({
        ...messages.messageAssetSynchronizationComplete,
    });
    const transMessageNewVersionHasBeenCreatedDueToForceRelease = useTranslation({
        ...messages.messageNewVersionHasBeenCreatedDueToForceRelease,
    });

    useEffect(() => {
        // Common code
        // ====================================================================
        const responseData = signalRState?.data;

        // Check if we have access to this response
        if (!checkResponseAccess(responseData)) return;
        // ====================================================================

        // Check if this is the correct signal
        if (signalRState?.methodName != IMethodName.onAssetSyncComplete) return;

        // ====================================================================

        // Show notification
        let message = transMessageAssetSynchronizationComplete;
        if (responseData.isForceRelease) {
            message = transMessageNewVersionHasBeenCreatedDueToForceRelease;
        }
        console.log(
            `## Asset synchronization is completed, switching to new version ${responseData?.NewVersionUrn} now..`,
        );

        dispatch(hideGlobalOverlay());

        // Redirect to new version
        if (responseData.NewVersionUrn) {
            console.log('## New version urn', responseData.NewVersionUrn);
            // todo fetch missing data from tree?
            //  now is only override urn, but other data old (like lastModifiedTime, createdTime etc.)
            const currentVersionData = _.cloneDeep(project.file.currentVersion);
            if (!currentVersionData) {
                console.log('## No current version data');
                return;
            }
            currentVersionData.data.urn = responseData.NewVersionUrn;
            currentVersionData.data.version = currentVersionData.data.version + 1;
            console.log('## New version data', currentVersionData);
            dispatch(setCurrentVersion(currentVersionData));

            openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Success', {
                content: message,
            });
        }
    }, [signalRState]);

    return <div></div>;
};

export default SignalOnAssetSyncComplete;

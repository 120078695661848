import { isPlatform } from '@ionic/react';
import {
    AppBar,
    Backdrop,
    Box,
    Container,
    CssBaseline,
    Drawer,
    IconButton,
    Modal,
    useTheme,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { ReactNode, useEffect, useState } from 'react';

import headerLogo from '../../assets/images/logo-header.svg';
import drawerLogoSm from '../../assets/images/logo-io.svg';
import drawerLogo from '../../assets/images/logo-io.svg';
import { useGlobalOverlay, useProjectState } from '../../redux/project';
import theme from '../../theme/Theme';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import HeaderProvider from '../HeaderProvider/HeaderProvider';
import Icon from '../Icon/Icon';
import Menu from '../Menu/Menu';
import useStyles from './styles';

export const defaultDrawerWidth = 240;
export const defaultHeaderHeight = 50;
export const defaultFooterHeight = 20;

export type StylesProps = {
    drawerWidth?: number;
    headerHeight?: string;
    headerBarColor?: string;
    headerBarBackground?: string;
    drawerColor?: string;
    drawerBackground?: string;
    drawerHeaderColor?: string;
    drawerHeaderBackground?: string;
    drawerPaperColor?: string;
    drawerPaperBackground?: string;
    footerHeight?: string;
    footerBarColor?: string;
    footerBarBackground?: string;
    drawerDisabled?: boolean;
};

interface ILayoutProps extends React.HTMLAttributes<HTMLElement> {
    logo?: string;
    header?: {
        rightMenu?: ReactNode;
    };
    drawer?: {
        disabled?: boolean;
    };
    stylesProps?: StylesProps;
    headerEnabled?: boolean;
}

const globalOverlayStyles = makeStyles(() =>
    createStyles({
        globalOverlay: {
            // position: 'fixed',
            // top: '0',
            // right: '0',
            // bottom: '0',
            // left: '0',
            // background: 'rgba(0,0,0,0.85)',
            // zIndex: 1200,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            color: theme.palette.common.white,
            fontSize: '1.2rem',
            userSelect: 'none',
        },
        globalOverlayInner: {
            padding: '2rem',
            borderRadius: '0.5rem',
            background: '#282828',
        },
        globalOverlayLoader: {
            display: 'table',
            margin: '0 auto 1rem',
        },
    }),
);

const Layout: React.FC<ILayoutProps> = (props: ILayoutProps) => {
    const { children, logo, header, drawer, stylesProps, headerEnabled } = props;

    if (stylesProps) {
        stylesProps.drawerDisabled = !!drawer?.disabled;
    }

    const classes = useStyles(stylesProps);

    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const globalOverlay = useGlobalOverlay();
    const globalOverlayCLasses = globalOverlayStyles();

    return (
        <HeaderProvider>
            <Container
                className={clsx(classes.root, { [classes.loadedModel]: !headerEnabled })}
                maxWidth={false}
            >
                <CssBaseline />

                {/*{headerEnabled && (*/}
                <AppBar
                    elevation={0}
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: !drawer?.disabled && drawerOpen,
                    })}
                >
                    <Header
                        drawerDisabled={drawer?.disabled}
                        logo={logo ? logo : headerLogo}
                        rightMenu={header?.rightMenu}
                        drawerOpen={drawerOpen}
                        handleDrawerOpen={handleDrawerOpen}
                        stylesProps={{
                            headerHeight: stylesProps?.headerHeight,
                        }}
                    />
                </AppBar>
                {/*)}*/}

                {drawer?.disabled ? null : (
                    <Drawer
                        variant="permanent"
                        className={clsx(classes.drawer, {
                            [classes.drawerOpen]: drawerOpen,
                            [classes.drawerClose]: !drawerOpen,
                        })}
                        classes={{
                            paper: clsx(classes.drawerPaper, {
                                [classes.drawerOpen]: drawerOpen,
                                [classes.drawerClose]: !drawerOpen,
                            }),
                            paperAnchorLeft: classes.drawerAnchorLeft,
                        }}
                    >
                        <div className={classes.drawerHeader}>
                            <Box
                                className={clsx(classes.logo, {
                                    [classes.logoShift]: drawerOpen,
                                })}
                                onClick={handleDrawerOpen}
                            >
                                <img alt="" src={drawerOpen ? drawerLogo : drawerLogoSm} />
                            </Box>

                            <IconButton color="inherit" size="small" onClick={handleDrawerClose}>
                                {theme.direction === 'ltr' ? (
                                    <Icon
                                        name="chevron-left-circle"
                                        fill={theme.palette.primary.main}
                                        size={20}
                                    />
                                ) : (
                                    <Icon
                                        name="chevron-right-circle"
                                        fill={theme.palette.primary.main}
                                        size={20}
                                    />
                                )}
                            </IconButton>
                        </div>
                        <Menu
                            drawerOpen={drawerOpen}
                            handleDrawerOpen={handleDrawerOpen}
                            handleDrawerClose={handleDrawerClose}
                        />
                    </Drawer>
                )}

                <main
                    className={clsx(classes.main, {
                        [classes.mainShift]: drawerOpen,
                    })}
                >
                    <div
                        className={clsx(classes.content, {
                            [classes.hybridContent]: isPlatform('hybrid'),
                        })}
                    >
                        {children}
                    </div>
                </main>

                {/*<AppBar*/}
                {/*    component="footer"*/}
                {/*    elevation={0}*/}
                {/*    className={clsx(classes.footerBar, {*/}
                {/*        [classes.footerBarShift]: drawerOpen,*/}
                {/*    })}*/}
                {/*>*/}
                {/*    <Footer*/}
                {/*        stylesProps={{*/}
                {/*            footerHeight: stylesProps?.footerHeight,*/}
                {/*            footerBarColor: stylesProps?.footerBarColor,*/}
                {/*            footerBarBackground: stylesProps?.footerBarBackground,*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</AppBar>*/}
            </Container>

            <Modal open={globalOverlay?.show}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: bTheme => bTheme.zIndex.drawer + 1 }}
                    open={globalOverlay?.show}
                >
                    <Box className={globalOverlayCLasses.globalOverlay}>
                        <Box className={globalOverlayCLasses.globalOverlayInner}>
                            <Box className={globalOverlayCLasses.globalOverlayLoader}>
                                <CircularProgress />
                            </Box>
                            <Box>{globalOverlay?.text}</Box>
                        </Box>
                    </Box>
                </Backdrop>
            </Modal>
        </HeaderProvider>
    );
};

export default Layout;

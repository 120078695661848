import SortableTree, {
    changeNodeAtPath,
    getFlatDataFromTree,
    TreeItem,
} from '@nosferatu500/react-sortable-tree';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import { FsmOperation, FsmScheduleRequest, FsmTreeResponse } from '../../generate/api';
import { useProjectState } from '../../redux/project';

interface ICommonTreeAttributes {
    id?: string | null;
    address?: number | null;
    oldAddress?: string | null;
    name?: string | null;
    oldName?: string | null;
    count?: number | null;
    title?: string | null;
    expanded?: boolean | null;
}
interface IArea extends ICommonTreeAttributes {
    type: 'area';
    sections?: ISection[];
}
interface ISection extends ICommonTreeAttributes {
    type: 'section';
    zones?: IZone[];
}
interface IZone extends ICommonTreeAttributes {
    type: 'zone';
    expanded: boolean;
}
interface IFsmTreeItem extends IArea {
    [x: string]: any;
}

type IFsmTreeItems = IFsmTreeItem[];

interface IFsmTreeProps {
    onRequestData: (requestData: FsmScheduleRequest | undefined) => void;
    rareTreeData?: FsmTreeResponse;
}

// Utils
// ----------------------------------------------------------------------------
export const prepareTreeData = (data: FsmTreeResponse): IFsmTreeItems | undefined => {
    return data.areas?.map(area => {
        return {
            id: area.id,
            address: Number(area.address) ?? undefined,
            oldAddress: area.address,
            name: area.name,
            oldName: area.name,
            count: area.count,
            title: `#${area.address} - ${area.name} (${area.count})`,
            type: 'area',
            expanded: true,
            children: area.sections?.map(section => {
                return {
                    id: section.id,
                    address: Number(section.address) ?? undefined,
                    oldAddress: section.address,
                    name: section.name,
                    oldName: section.name,
                    count: section.count,
                    title: `#${section.address} - ${section.name} (${section.count})`,
                    type: 'section',
                    expanded: false,
                    children: section.zones?.map(zone => {
                        return {
                            id: zone.id,
                            address: Number(zone.address) ?? undefined,
                            oldAddress: zone.address,
                            name: zone.name,
                            oldName: zone.name,
                            count: zone.count,
                            title: `#${zone.address} - ${zone.name} (${zone.count})`,
                            type: 'zone',
                            expanded: false,
                        };
                    }),
                };
            }),
        };
    });
};

const updateAddresses = data => {
    let levelOneCounter = 11;
    let levelTwoCounter = 1;
    let levelThreeCounter = 2;

    function updateAddress(node, level) {
        if (level === 1) {
            if (levelOneCounter % 10 === 0) {
                levelOneCounter += 1;
            }
            node.address = levelOneCounter;
            levelOneCounter += 1;
        } else if (level === 2) {
            node.address = 100 + levelTwoCounter;
            levelTwoCounter++;
        } else if (level === 3) {
            node.address = levelThreeCounter;
            levelThreeCounter++;
        }

        if (node.children && node.children.length > 0) {
            for (const child of node.children) {
                updateAddress(child, level + 1);
            }
        }
    }

    for (const area of data) {
        updateAddress(area, 1);
    }

    return data;
};

const findHighestAreaAddress = (treeData: IFsmTreeItems): number => {
    let highestAddress = 0;
    treeData?.forEach(area => {
        if (area.address && area.address > highestAddress) {
            highestAddress = area.address;
        }
    });
    return highestAddress;
};
const findHighestSectionAddress = (treeData: IFsmTreeItems): number => {
    let highestAddress = 0;
    treeData?.forEach(area => {
        area.children?.forEach(section => {
            if (section.address && section.address > highestAddress) {
                highestAddress = section.address;
            }
        });
    });
    return highestAddress;
};
const findHighestZoneAddress = (treeData: IFsmTreeItems): number => {
    let highestAddress = 0;
    treeData?.forEach(area => {
        area.children?.forEach(section => {
            section.children?.forEach(zone => {
                if (zone.address && zone.address > highestAddress) {
                    highestAddress = zone.address;
                }
            });
        });
    });
    return highestAddress;
};

const getLastAddresses = (treeData: any) => {
    return {
        lastAreaAddress: findHighestAreaAddress(treeData),
        lastSectionAddress: findHighestSectionAddress(treeData),
        lastZoneAddress: findHighestZoneAddress(treeData),
    };
};

const FsmTree: React.FC<IFsmTreeProps> = ({ onRequestData, rareTreeData }) => {
    const project = useProjectState();
    const forgeProjectId = project?.project?.id;
    const fileVersionUrn = project?.file?.currentVersion?.data?.urn;
    const viewableId = project?.file?.currentViewable?.viewableID;

    const [treeData, setTreeData] = React.useState<TreeItem[]>();
    const [lastAreaAddress, setLastAreaAddress] = useState<number | undefined>();
    const [lastSectionAddress, setLastSectionAddress] = useState<number | undefined>();
    const [lastZoneAddress, setLastZoneAddress] = useState<number | undefined>();
    const [lastMovedNode, setLastMovedNode] = useState<TreeItem | undefined>(undefined); // todo

    useEffect(() => {
        if (!rareTreeData) {
            console.warn('Missing data', { rareTreeData });
            return;
        }

        let newTreeData = prepareTreeData(rareTreeData);
        newTreeData = updateAddresses(newTreeData);
        setTreeData(newTreeData as TreeItem[]);

        setLastAreaAddress(Number(rareTreeData.lastAreaAddress));
        setLastSectionAddress(Number(rareTreeData.lastSectionAddress));
        setLastZoneAddress(Number(rareTreeData.lastZoneAddress));
        // setLastAreaAddress(12); // TODO TESTING
        // setLastSectionAddress(111); // TODO TESTING
        // setLastZoneAddress(12); // TODO TESTING

        return () => {
            setTreeData([]);
            setLastAreaAddress(undefined);
            setLastSectionAddress(undefined);
            setLastZoneAddress(undefined);
        };
    }, [rareTreeData]);

    // Prepare and Get request data for export
    const getRequestData = (): FsmScheduleRequest | undefined => {
        if (!forgeProjectId || !fileVersionUrn || !viewableId) {
            console.warn('Missing data', { forgeProjectId }, { fileVersionUrn }, { viewableId });
            return;
        }

        const lastAddresses = getLastAddresses(treeData);

        const flatData = getFlatDataFromTree({
            treeData: treeData,
            getNodeKey: ({ node }) => node.id, // This ensures your "id" properties are exported in the path
            ignoreCollapsed: false, // Makes sure you traverse every node in the tree, not just the visible ones
        });

        // map over all nodes in flatData
        // return if node has oldAddress and oldName and they are different
        const changedNodes = flatData.filter(node => {
            if (
                node.node.oldAddress !== node.node.address ||
                node.node.oldName !== node.node.name
            ) {
                return node.node;
            }
        });

        const updatedRequestData: FsmOperation[] = changedNodes.map(node => {
            return {
                type: node.node.type,
                oldAddress: String(node.node.oldAddress),
                newAddress: String(node.node.address),
                oldText: node.node.oldName,
                newText: node.node.name,
                id: node.node.id,
            };
        });

        return {
            // bypass: true, // todo only for testing
            viewableID: viewableId,
            operations: updatedRequestData,
            versionUrn: fileVersionUrn,
            projectID: forgeProjectId,
            lastAreaAddress: String(lastAddresses.lastAreaAddress),
            lastSectionAddress: String(lastAddresses.lastSectionAddress),
            lastZoneAddress: String(lastAddresses.lastZoneAddress),
        };
    };

    // Send request data to parent component when treeData changes
    useEffect(() => {
        onRequestData(getRequestData());
    }, [treeData]);

    /**
     * Compare old state of tree with new state of tree
     * If last moved node is before locked node - return old state of tree
     */
    const canUpdateTree = (currentTreeData, newTreeData): boolean => {
        console.log({ currentTreeData }, { newTreeData }, { lastMovedNode });
        if (!lastMovedNode) {
            return true;
        }
        if (lastMovedNode.type === 'area') {
            if (lastMovedNode.address <= Number(lastAreaAddress)) {
                return false;
            }
        }
        if (lastMovedNode.type === 'section') {
            if (lastMovedNode.address <= Number(lastSectionAddress)) {
                return false;
            }
        }
        if (lastMovedNode.type === 'zone') {
            if (lastMovedNode.address <= Number(lastZoneAddress)) {
                return false;
            }
        }
        return true;
    };

    // After each update in tree - update treeData state
    const updateTreeData = (newTreeData: TreeItem[]) => {
        console.log('update', newTreeData);
        newTreeData = updateAddresses(newTreeData);
        const canUpdate = canUpdateTree(treeData, newTreeData);
        if (canUpdate) {
            setTreeData(newTreeData);
        } else {
            setTreeData(updateAddresses(treeData));
        }
    };

    const canDrag = ({ node }) => {
        // TODO THIS WORKS
        if (node.type === 'area' && lastAreaAddress && node.address <= lastAreaAddress) {
            return false;
        }
        if (node.type === 'section' && lastSectionAddress && node.address <= lastSectionAddress) {
            return false;
        }
        if (node.type === 'zone' && lastZoneAddress && node.address <= lastZoneAddress) {
            return false;
        }
        return true;
    };

    // Check if node can be dropped to new position
    const canDrop = ({ node, nextParent, prevPath, nextPath, prevTreeIndex, nextTreeIndex }) => {
        // console.log(
        //     { node },
        //     { nextParent },
        //     { prevPath },
        //     { nextPath },
        //     { prevTreeIndex },
        //     { nextTreeIndex },
        // );

        setLastMovedNode(node);

        // Allow move only inside same type
        if (node?.type === 'zone' && nextParent?.type !== 'section') {
            return false;
        }
        if (node?.type === 'section' && nextParent?.type !== 'area') {
            return false;
        }

        return true;
    };

    // const getNodeKey = ({ treeIndex }) => treeIndex;
    const getNodeKey = ({ node }) => node.id;

    const lockedNode = (node): boolean => {
        if (node.type === 'area' && node.address <= Number(lastAreaAddress)) {
            return true;
        }
        if (node.type === 'section' && node.address <= Number(lastSectionAddress)) {
            return true;
        }
        if (node.type === 'zone' && node.address <= Number(lastZoneAddress)) {
            return true;
        }
        return false;
    };

    const generateNodeProps = ({ node, path }) => ({
        title: (
            <>
                {lockedNode(node) && (
                    <span className="w-[11px] h-[11px] rounded-full bg-red-600 inline-block mr-3"></span>
                )}
                <span className="min-w-[40px] inline-block">#{node.address}</span>
                <input
                    // style={{ fontSize: '0.9rem' }}
                    value={node.name}
                    className={clsx(
                        'mx-2 w-[350px]',
                        node.name.length > 30 ? 'text-[11px]' : 'text-[13px]',
                    )}
                    onChange={event => {
                        const name = event.target.value;
                        updateTreeData(
                            changeNodeAtPath({
                                treeData: treeData,
                                path,
                                getNodeKey,
                                newNode: { ...node, name: name },
                            }),
                        );
                    }}
                />
                <span className="min-w-[40px] inline-block">{node.count}</span>
            </>
        ),
    });

    return (
        <div className="h-full flex flex-col">
            {treeData ? (
                <>
                    <div className="mb-2 text-sm flex gap-2">
                        <div className="bg-gray-100 p-1 px-2 rounded">
                            Last locked area address: {/* todo translate */}
                            <span className="font-bold"> {lastAreaAddress || '-'}</span>
                        </div>
                        <div className="bg-gray-100 p-1 px-2 rounded">
                            Last locked section address: {/* todo translate */}
                            <span className="font-bold"> {lastSectionAddress || '-'}</span>
                        </div>
                        <div className="bg-gray-100 p-1 px-2 rounded">
                            Last locked zone address: {/* todo translate */}
                            <span className="font-bold"> {lastZoneAddress || '-'}</span>
                        </div>
                    </div>
                    <SortableTree
                        treeData={treeData}
                        // getNodeKey={({ node }) => node.id}
                        getNodeKey={getNodeKey}
                        onChange={updateTreeData}
                        maxDepth={3}
                        rowHeight={38}
                        scaffoldBlockPxWidth={25}
                        canDrop={canDrop}
                        canDrag={canDrag}
                        generateNodeProps={generateNodeProps}
                    />
                </>
            ) : (
                <div className="mt-6 p-4 bg-gray-100 text-center rounded">No data</div>
            )}
        </div>
    );
};

export default FsmTree;

import { DispatchAction } from '@iolabs/redux-utils';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, MenuItem, TextField, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { onFilterAssets, onFilterAssetsClear } from '../../redux/assets';
import {
    onFilterCustomAttributes as onIssuesFilterCustomAttributes,
    onFilterCustomAttributesClear as onIssuesFilterCustomAttributesClear,
} from '../../redux/issues';
import { IssuesFiltersKeys } from '../../redux/issues/types';
import { useTranslation } from '../../redux/translations/hook';
import messages from './messages';
import { IFilterDefinition, IFilterType, IMultiTypeValue } from './types';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        box: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        clearBox: {
            minWidth: '20px',
        },
        textField: {
            marginRight: '8px',
            '& .MuiOutlinedInput-root, & .MuiSelect-root': {
                minWidth: 'auto',
                color: theme.palette.grey[300],
            },
        },
        placeholder: {
            color: theme.palette.grey[300],
            '& .MuiSelect-root': {
                color: `${theme.palette.text.disabled} !important`,
            },
        },
    }),
);

interface IFieldMultiTypeDateProps {
    type: IFilterType;
    filterDefinition: IFilterDefinition;
    multiTypeValue: IMultiTypeValue;
    handleClearAttribute: () => void;
    localeMappingOperand: (operand: string) => string;
    defaultOperand?: string;
    defaultValue?: string;
}

const FieldMultiTypeDate: React.FC<IFieldMultiTypeDateProps> = ({
    type,
    filterDefinition,
    multiTypeValue,
    handleClearAttribute,
    localeMappingOperand,
    defaultOperand,
    defaultValue,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch<DispatchAction>();

    const [selectOperand, setSelectOperand] = useState<string>(
        defaultOperand ? defaultOperand : '',
    );
    const [selectValue, setSelectValue] = useState<string>(defaultValue ? defaultValue : '');
    const [disabledValue, setDisabledValue] = useState<boolean>(true);

    const handleChangeOperand = event => {
        setSelectOperand(event.target.value);
    };

    const handleChangeValue = event => {
        setSelectValue(event.target.value);
    };

    const handleClear = () => {
        handleClearAttribute();
        setSelectOperand('');
        setSelectValue('');
        dispatch(
            type === IFilterType.ISSUES
                ? onIssuesFilterCustomAttributesClear({
                      key: filterDefinition?.parameterName as IssuesFiltersKeys,
                      value: {
                          parameterName: multiTypeValue?.parameterName as string,
                          type: multiTypeValue?.type as string,
                          operand: selectOperand,
                          value: selectValue,
                      },
                  })
                : onFilterAssetsClear({
                      key: filterDefinition?.parameterName as string,
                      value: {
                          parameterName: multiTypeValue?.parameterName as string,
                          type: multiTypeValue?.type as string,
                          operand: selectOperand,
                          value: selectValue,
                      },
                  }),
        );
    };

    useEffect(() => {
        if (
            selectOperand === 'startswith' ||
            selectOperand === 'endswith' ||
            selectOperand === 'contains' ||
            selectOperand === 'eq' ||
            selectOperand === 'noteq'
        ) {
            setDisabledValue(false);
        } else {
            setSelectValue('');
            setDisabledValue(true);
        }
    }, [selectOperand, selectValue]);

    useEffect(() => {
        if (selectOperand === '') {
            return;
        }
        if (selectOperand === 'startswith' && selectValue === '') {
            return;
        }
        if (selectOperand === 'endswith' && selectValue === '') {
            return;
        }
        if (selectOperand === 'contains' && selectValue === '') {
            return;
        }
        if (selectOperand === 'eq' && selectValue === '') {
            return;
        }
        if (selectOperand === 'noteq' && selectValue === '') {
            return;
        }

        dispatch(
            type === IFilterType.ISSUES
                ? onIssuesFilterCustomAttributes({
                      key: filterDefinition?.parameterName as IssuesFiltersKeys,
                      value: {
                          parameterName: multiTypeValue?.parameterName as string,
                          type: multiTypeValue?.type as string,
                          operand: selectOperand,
                          value: selectValue,
                      },
                  })
                : onFilterAssets({
                      key: filterDefinition?.parameterName as string,
                      value: {
                          parameterName: multiTypeValue?.parameterName as string,
                          type: multiTypeValue?.type as string,
                          operand: selectOperand,
                          value: selectValue,
                      },
                  }),
        );
    }, [selectOperand, selectValue]);

    // translations
    const transFieldOperand = useTranslation({ ...messages.fieldOperand });
    const transFieldOperandPlaceholder = useTranslation({
        ...messages.fieldOperandPlaceholder,
    });
    const transFieldValue = useTranslation({ ...messages.fieldValue });
    const transFieldTextPlaceholder = useTranslation({ ...messages.fieldTextPlaceholder });

    return (
        <Box className={classes.box}>
            <TextField
                id={`${multiTypeValue?.parameterName}-operand`}
                required
                fullWidth
                select
                label={transFieldOperand}
                variant="outlined"
                margin="dense"
                className={clsx(classes.textField, {
                    [classes.placeholder]: selectOperand === '',
                })}
                InputLabelProps={{
                    shrink: true,
                }}
                SelectProps={{
                    value: selectOperand,
                    displayEmpty: true,
                    onChange: handleChangeOperand,
                    MenuProps: {
                        // getContentAnchorEl: null,
                    },
                }}
            >
                <MenuItem value="">{transFieldOperandPlaceholder}</MenuItem>
                {multiTypeValue?.operands?.map((operand, index) => (
                    <MenuItem key={index} value={operand?.value}>
                        {localeMappingOperand(operand?.optionText as string)}
                    </MenuItem>
                ))}
            </TextField>
            <TextField
                id={`${multiTypeValue?.parameterName}-value`}
                required={!disabledValue}
                disabled={disabledValue}
                fullWidth
                label={transFieldValue}
                value={selectValue}
                placeholder={transFieldTextPlaceholder}
                variant="outlined"
                margin="dense"
                className={classes.textField}
                onChange={handleChangeValue}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <Box className={classes.clearBox}>
                {selectOperand !== '' && (
                    <IconButton onClick={handleClear}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                )}
            </Box>
        </Box>
    );
};

export default FieldMultiTypeDate;

import { Box, MenuItem, Select, TextField } from '@mui/material';
import clsx from 'clsx';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import { useTranslation } from '../../redux/translations/hook';
import { createTranslationKey } from '../Translation/utils';
import useStyles from './styles';
import { IAttributesMap, IAttributesMapItem } from './types';

interface IPropertiesEditorProps {
    attributes: IAttributesMap;
    setAttributes: any; // todo ts
}

interface IPropertyEditorRowProps {
    displayValue: string;
    value?: string;
    attribute: IAttributesMapItem;
    onAttributeChange: (attribute: IAttributesMapItem) => void;
}

const TranslatedLabel: React.FC<{ columnName?: string }> = ({ columnName }) => {
    const translation = columnName
        ? useTranslation({
              id: createTranslationKey('assets.label', columnName),
              defaultMessage: columnName,
          })
        : '';

    return <>{translation}</>;
};

const PropertyEditorRow: React.FC<IPropertyEditorRowProps> = ({
    displayValue,
    value,
    attribute,
    onAttributeChange,
}) => {
    const [formValue, setFormValue] = useState<string>('');

    const fieldType = attribute.fieldType || 'text';
    const options = attribute.options || [];

    useEffect(() => {
        let newValue = value || '';
        if (options && options.length > 0 && !options.find(option => option.value === newValue)) {
            newValue = '';
        }
        setFormValue(newValue);
    }, [value]);

    useEffect(() => {
        if (attribute.isForceRewrite) {
            const newValue = attribute.newValue || attribute.value || '';
            updateValue(String(newValue));
        }
    }, []);

    const handleChange = event => {
        updateValue(event.target.value as string);
    };

    const updateValue = (handledNewValue: string) => {
        let newValue = handledNewValue;
        if (options && options.length > 0 && !options.find(option => option.value == newValue)) {
            newValue = '';
        }

        setFormValue(newValue);

        // update attribute and send to parent
        const updatedAttribute = _.cloneDeep(attribute);
        if (newValue != value || attribute.isForceRewrite) {
            updatedAttribute.newValue = newValue;
        }
        onAttributeChange(updatedAttribute);
    };

    return (
        <Box mb={1}>
            <TranslatedLabel columnName={displayValue} />
            {fieldType === 'text' && (
                <TextField
                    variant="outlined"
                    fullWidth
                    value={formValue}
                    onChange={handleChange}
                    size="small"
                    margin="none"
                />
            )}
            {fieldType === 'select' && (
                <Select
                    variant="outlined"
                    fullWidth
                    value={formValue}
                    onChange={handleChange}
                    size="small"
                    displayEmpty
                    margin="none"
                >
                    {options?.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.title}
                        </MenuItem>
                    ))}
                </Select>
            )}
        </Box>
    );
};

const PropertiesEditor: React.FC<IPropertiesEditorProps> = ({ attributes, setAttributes }) => {
    const classes = useStyles();

    const [updatedAttributes, setUpdatedAttributes] = useState<IAttributesMap>(attributes);

    const onAttributeChange = (attribute: IAttributesMapItem) => {
        const newAttributes = updatedAttributes.map(attributeOrigin => {
            if (attributeOrigin.attributeName === attribute.attributeName) {
                if (attribute.newValue !== attribute.value || attribute.isForceRewrite) {
                    return { ...attributeOrigin, newValue: attribute.newValue };
                }
            }
            return attributeOrigin;
        });
        setUpdatedAttributes(newAttributes);
    };

    useEffect(() => {
        setAttributes(updatedAttributes);
    }, [updatedAttributes]);

    // If attribute isForceRewrite (if duplicate element) then set newValue to value
    useEffect(() => {
        const newAttributes = attributes.map(attribute => {
            // if duplicate duplicated element
            if (attribute.newValue && !attribute.value) {
                return {
                    ...attribute,
                    value: attribute.newValue,
                };
            }

            // if duplicate element
            else if (attribute.isForceRewrite) {
                return {
                    ...attribute,
                    newValue: attribute.newValue || attribute.value,
                };
            }

            return attribute;
        });
        setUpdatedAttributes(newAttributes);
    }, [attributes]);

    return (
        <div>
            <div>
                {/* Render editable attributes */}
                {attributes.map((attribute, index) => {
                    const value = attribute.hasOwnProperty('newValue')
                        ? attribute.newValue
                        : attribute.value;

                    if (attribute.editable) {
                        return (
                            <PropertyEditorRow
                                key={index}
                                displayValue={attribute.displayValue}
                                value={value}
                                attribute={attribute}
                                onAttributeChange={onAttributeChange}
                            />
                        );
                    }

                    return <React.Fragment key={index}></React.Fragment>;
                })}
            </div>
            <div>
                {/* Render non-editable attributes into table */}
                <table className={clsx(classes.table, 'mt-6')}>
                    <tbody>
                        {attributes.map((attribute, index) => {
                            const value = attribute.hasOwnProperty('newValue')
                                ? attribute.newValue
                                : attribute.value;

                            if (!attribute.editable) {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <TranslatedLabel columnName={attribute.displayValue} />
                                        </td>
                                        <td>{value}</td>
                                    </tr>
                                );
                            }

                            return <React.Fragment key={index}></React.Fragment>;
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default PropertiesEditor;

/* tslint:disable */
/* eslint-disable */
/**
 * FireBIM API
 * API definitions for FireBim
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddRecentFileRequest } from '../models';
// @ts-ignore
import { CreateModelLockRequest } from '../models';
// @ts-ignore
import { CreateModelLockResponse } from '../models';
// @ts-ignore
import { ElementListRequest } from '../models';
// @ts-ignore
import { ElementListResponse } from '../models';
// @ts-ignore
import { FileVersionsResponse } from '../models';
// @ts-ignore
import { LockSummaryResponse } from '../models';
// @ts-ignore
import { ModelElementDataRequest } from '../models';
// @ts-ignore
import { ModelElementDataResponse } from '../models';
// @ts-ignore
import { ModelLockInfoRequest } from '../models';
// @ts-ignore
import { ModelLockInfoResponse } from '../models';
// @ts-ignore
import { PerformModelOperationsRequest } from '../models';
// @ts-ignore
import { PerformModelOperationsResponse } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { RecentFilesResponse } from '../models';
// @ts-ignore
import { ReleaseModelLockRequest } from '../models';
// @ts-ignore
import { ReleaseModelLockResponse } from '../models';
// @ts-ignore
import { ScheduleModelOperationRequest } from '../models';
// @ts-ignore
import { ScheduleModelOperationResponse } from '../models';
// @ts-ignore
import { ScheduledOperationListRequest } from '../models';
// @ts-ignore
import { ScheduledOperationListResponse } from '../models';
// @ts-ignore
import { SearchFilesResponse } from '../models';
// @ts-ignore
import { SensorListResponse } from '../models';
// @ts-ignore
import { SingleElementDiscardRequest } from '../models';
// @ts-ignore
import { SingleElementDiscardResponse } from '../models';
/**
 * FilesModuleApi - axios parameter creator
 * @export
 */
export const FilesModuleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Adds recently opened file.
         * @param {AddRecentFileRequest} [addRecentFileRequest] Add recent file request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileFileHistoryAddPost: async (addRecentFileRequest?: AddRecentFileRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/File/File/History/Add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addRecentFileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets recently opened files for user.
         * @param {number} [count] Max recent files count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileFileHistoryListGet: async (count?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/File/File/History/List`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets files available for user filtered by name.
         * @param {string} [query] Search files query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileFileSearchGet: async (query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/File/File/Search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets locked model list with users having the model locked.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileLockSummaryGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/File/LockSummary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets supported sensors list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileSensorsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/File/Sensors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets info about single element and its scheduled operation under current model version lock.
         * @param {ModelElementDataRequest} [modelElementDataRequest] Model element request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileVersionElementDataPost: async (modelElementDataRequest?: ModelElementDataRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/File/Version/ElementData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modelElementDataRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all lockable BMA elements inside a model.
         * @param {ElementListRequest} [elementListRequest] Element list request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileVersionElementsListPost: async (elementListRequest?: ElementListRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/File/Version/Elements/List`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(elementListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the file version list.
         * @param {string} projectID Forge project ID.
         * @param {string} itemUrn Item urn of forge model.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileVersionListGet: async (projectID: string, itemUrn: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('fileVersionListGet', 'projectID', projectID)
            // verify required parameter 'itemUrn' is not null or undefined
            assertParamExists('fileVersionListGet', 'itemUrn', itemUrn)
            const localVarPath = `/File/Version/List`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }

            if (itemUrn !== undefined) {
                localVarQueryParameter['ItemUrn'] = itemUrn;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Locks all BMA elements inside a viewable of a model.
         * @param {CreateModelLockRequest} [createModelLockRequest] Create model lock request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileVersionLockElementsPost: async (createModelLockRequest?: CreateModelLockRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/File/Version/LockElements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createModelLockRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets users having the model locked for given file version.
         * @param {ModelLockInfoRequest} [modelLockInfoRequest] Model lock info request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileVersionLockInfoPost: async (modelLockInfoRequest?: ModelLockInfoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/File/Version/LockInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modelLockInfoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets info about all elements with unprocessed scheduled operations under current model version lock.
         * @param {ScheduledOperationListRequest} [scheduledOperationListRequest] Model element request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileVersionModificationsPost: async (scheduledOperationListRequest?: ScheduledOperationListRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/File/Version/Modifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scheduledOperationListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Proceeds all scheduled operations under current user\'s model version lock.
         * @param {PerformModelOperationsRequest} [performModelOperationsRequest] Schedule model operation request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileVersionPerformOperationsPost: async (performModelOperationsRequest?: PerformModelOperationsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/File/Version/PerformOperations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(performModelOperationsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Schedule new operation under current model version lock.
         * @param {ScheduleModelOperationRequest} [scheduleModelOperationRequest] Schedule model operation request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileVersionScheduleOperationPost: async (scheduleModelOperationRequest?: ScheduleModelOperationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/File/Version/ScheduleOperation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scheduleModelOperationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Discards changes on single element.
         * @param {SingleElementDiscardRequest} [singleElementDiscardRequest] Single element discard request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileVersionSingleElementDiscardPost: async (singleElementDiscardRequest?: SingleElementDiscardRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/File/Version/SingleElementDiscard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(singleElementDiscardRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Forcibly unlocks all locked BMA elements in a model and discard any local changes.
         * @param {ReleaseModelLockRequest} [releaseModelLockRequest] Release model lock request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileVersionUnlockElementsForcePost: async (releaseModelLockRequest?: ReleaseModelLockRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/File/Version/UnlockElements/Force`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(releaseModelLockRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unlocks all locked BMA elements in a model.
         * @param {ReleaseModelLockRequest} [releaseModelLockRequest] Release model lock request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileVersionUnlockElementsPost: async (releaseModelLockRequest?: ReleaseModelLockRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/File/Version/UnlockElements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(releaseModelLockRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FilesModuleApi - functional programming interface
 * @export
 */
export const FilesModuleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FilesModuleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Adds recently opened file.
         * @param {AddRecentFileRequest} [addRecentFileRequest] Add recent file request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileFileHistoryAddPost(addRecentFileRequest?: AddRecentFileRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileFileHistoryAddPost(addRecentFileRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets recently opened files for user.
         * @param {number} [count] Max recent files count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileFileHistoryListGet(count?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecentFilesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileFileHistoryListGet(count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets files available for user filtered by name.
         * @param {string} [query] Search files query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileFileSearchGet(query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchFilesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileFileSearchGet(query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets locked model list with users having the model locked.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileLockSummaryGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LockSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileLockSummaryGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets supported sensors list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileSensorsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SensorListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileSensorsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets info about single element and its scheduled operation under current model version lock.
         * @param {ModelElementDataRequest} [modelElementDataRequest] Model element request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileVersionElementDataPost(modelElementDataRequest?: ModelElementDataRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelElementDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileVersionElementDataPost(modelElementDataRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all lockable BMA elements inside a model.
         * @param {ElementListRequest} [elementListRequest] Element list request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileVersionElementsListPost(elementListRequest?: ElementListRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ElementListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileVersionElementsListPost(elementListRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the file version list.
         * @param {string} projectID Forge project ID.
         * @param {string} itemUrn Item urn of forge model.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileVersionListGet(projectID: string, itemUrn: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileVersionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileVersionListGet(projectID, itemUrn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Locks all BMA elements inside a viewable of a model.
         * @param {CreateModelLockRequest} [createModelLockRequest] Create model lock request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileVersionLockElementsPost(createModelLockRequest?: CreateModelLockRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateModelLockResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileVersionLockElementsPost(createModelLockRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets users having the model locked for given file version.
         * @param {ModelLockInfoRequest} [modelLockInfoRequest] Model lock info request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileVersionLockInfoPost(modelLockInfoRequest?: ModelLockInfoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelLockInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileVersionLockInfoPost(modelLockInfoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets info about all elements with unprocessed scheduled operations under current model version lock.
         * @param {ScheduledOperationListRequest} [scheduledOperationListRequest] Model element request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileVersionModificationsPost(scheduledOperationListRequest?: ScheduledOperationListRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduledOperationListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileVersionModificationsPost(scheduledOperationListRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Proceeds all scheduled operations under current user\'s model version lock.
         * @param {PerformModelOperationsRequest} [performModelOperationsRequest] Schedule model operation request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileVersionPerformOperationsPost(performModelOperationsRequest?: PerformModelOperationsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerformModelOperationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileVersionPerformOperationsPost(performModelOperationsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Schedule new operation under current model version lock.
         * @param {ScheduleModelOperationRequest} [scheduleModelOperationRequest] Schedule model operation request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileVersionScheduleOperationPost(scheduleModelOperationRequest?: ScheduleModelOperationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduleModelOperationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileVersionScheduleOperationPost(scheduleModelOperationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Discards changes on single element.
         * @param {SingleElementDiscardRequest} [singleElementDiscardRequest] Single element discard request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileVersionSingleElementDiscardPost(singleElementDiscardRequest?: SingleElementDiscardRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SingleElementDiscardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileVersionSingleElementDiscardPost(singleElementDiscardRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Forcibly unlocks all locked BMA elements in a model and discard any local changes.
         * @param {ReleaseModelLockRequest} [releaseModelLockRequest] Release model lock request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileVersionUnlockElementsForcePost(releaseModelLockRequest?: ReleaseModelLockRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReleaseModelLockResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileVersionUnlockElementsForcePost(releaseModelLockRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unlocks all locked BMA elements in a model.
         * @param {ReleaseModelLockRequest} [releaseModelLockRequest] Release model lock request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileVersionUnlockElementsPost(releaseModelLockRequest?: ReleaseModelLockRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReleaseModelLockResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileVersionUnlockElementsPost(releaseModelLockRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FilesModuleApi - factory interface
 * @export
 */
export const FilesModuleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FilesModuleApiFp(configuration)
    return {
        /**
         * 
         * @summary Adds recently opened file.
         * @param {AddRecentFileRequest} [addRecentFileRequest] Add recent file request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileFileHistoryAddPost(addRecentFileRequest?: AddRecentFileRequest, options?: any): AxiosPromise<boolean> {
            return localVarFp.fileFileHistoryAddPost(addRecentFileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets recently opened files for user.
         * @param {number} [count] Max recent files count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileFileHistoryListGet(count?: number, options?: any): AxiosPromise<RecentFilesResponse> {
            return localVarFp.fileFileHistoryListGet(count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets files available for user filtered by name.
         * @param {string} [query] Search files query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileFileSearchGet(query?: string, options?: any): AxiosPromise<SearchFilesResponse> {
            return localVarFp.fileFileSearchGet(query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets locked model list with users having the model locked.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileLockSummaryGet(options?: any): AxiosPromise<LockSummaryResponse> {
            return localVarFp.fileLockSummaryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets supported sensors list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileSensorsGet(options?: any): AxiosPromise<SensorListResponse> {
            return localVarFp.fileSensorsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets info about single element and its scheduled operation under current model version lock.
         * @param {ModelElementDataRequest} [modelElementDataRequest] Model element request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileVersionElementDataPost(modelElementDataRequest?: ModelElementDataRequest, options?: any): AxiosPromise<ModelElementDataResponse> {
            return localVarFp.fileVersionElementDataPost(modelElementDataRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all lockable BMA elements inside a model.
         * @param {ElementListRequest} [elementListRequest] Element list request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileVersionElementsListPost(elementListRequest?: ElementListRequest, options?: any): AxiosPromise<ElementListResponse> {
            return localVarFp.fileVersionElementsListPost(elementListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the file version list.
         * @param {string} projectID Forge project ID.
         * @param {string} itemUrn Item urn of forge model.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileVersionListGet(projectID: string, itemUrn: string, options?: any): AxiosPromise<FileVersionsResponse> {
            return localVarFp.fileVersionListGet(projectID, itemUrn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Locks all BMA elements inside a viewable of a model.
         * @param {CreateModelLockRequest} [createModelLockRequest] Create model lock request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileVersionLockElementsPost(createModelLockRequest?: CreateModelLockRequest, options?: any): AxiosPromise<CreateModelLockResponse> {
            return localVarFp.fileVersionLockElementsPost(createModelLockRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets users having the model locked for given file version.
         * @param {ModelLockInfoRequest} [modelLockInfoRequest] Model lock info request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileVersionLockInfoPost(modelLockInfoRequest?: ModelLockInfoRequest, options?: any): AxiosPromise<ModelLockInfoResponse> {
            return localVarFp.fileVersionLockInfoPost(modelLockInfoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets info about all elements with unprocessed scheduled operations under current model version lock.
         * @param {ScheduledOperationListRequest} [scheduledOperationListRequest] Model element request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileVersionModificationsPost(scheduledOperationListRequest?: ScheduledOperationListRequest, options?: any): AxiosPromise<ScheduledOperationListResponse> {
            return localVarFp.fileVersionModificationsPost(scheduledOperationListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Proceeds all scheduled operations under current user\'s model version lock.
         * @param {PerformModelOperationsRequest} [performModelOperationsRequest] Schedule model operation request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileVersionPerformOperationsPost(performModelOperationsRequest?: PerformModelOperationsRequest, options?: any): AxiosPromise<PerformModelOperationsResponse> {
            return localVarFp.fileVersionPerformOperationsPost(performModelOperationsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Schedule new operation under current model version lock.
         * @param {ScheduleModelOperationRequest} [scheduleModelOperationRequest] Schedule model operation request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileVersionScheduleOperationPost(scheduleModelOperationRequest?: ScheduleModelOperationRequest, options?: any): AxiosPromise<ScheduleModelOperationResponse> {
            return localVarFp.fileVersionScheduleOperationPost(scheduleModelOperationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Discards changes on single element.
         * @param {SingleElementDiscardRequest} [singleElementDiscardRequest] Single element discard request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileVersionSingleElementDiscardPost(singleElementDiscardRequest?: SingleElementDiscardRequest, options?: any): AxiosPromise<SingleElementDiscardResponse> {
            return localVarFp.fileVersionSingleElementDiscardPost(singleElementDiscardRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Forcibly unlocks all locked BMA elements in a model and discard any local changes.
         * @param {ReleaseModelLockRequest} [releaseModelLockRequest] Release model lock request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileVersionUnlockElementsForcePost(releaseModelLockRequest?: ReleaseModelLockRequest, options?: any): AxiosPromise<ReleaseModelLockResponse> {
            return localVarFp.fileVersionUnlockElementsForcePost(releaseModelLockRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unlocks all locked BMA elements in a model.
         * @param {ReleaseModelLockRequest} [releaseModelLockRequest] Release model lock request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileVersionUnlockElementsPost(releaseModelLockRequest?: ReleaseModelLockRequest, options?: any): AxiosPromise<ReleaseModelLockResponse> {
            return localVarFp.fileVersionUnlockElementsPost(releaseModelLockRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FilesModuleApi - object-oriented interface
 * @export
 * @class FilesModuleApi
 * @extends {BaseAPI}
 */
export class FilesModuleApi extends BaseAPI {
    /**
     * 
     * @summary Adds recently opened file.
     * @param {AddRecentFileRequest} [addRecentFileRequest] Add recent file request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesModuleApi
     */
    public fileFileHistoryAddPost(addRecentFileRequest?: AddRecentFileRequest, options?: AxiosRequestConfig) {
        return FilesModuleApiFp(this.configuration).fileFileHistoryAddPost(addRecentFileRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets recently opened files for user.
     * @param {number} [count] Max recent files count
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesModuleApi
     */
    public fileFileHistoryListGet(count?: number, options?: AxiosRequestConfig) {
        return FilesModuleApiFp(this.configuration).fileFileHistoryListGet(count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets files available for user filtered by name.
     * @param {string} [query] Search files query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesModuleApi
     */
    public fileFileSearchGet(query?: string, options?: AxiosRequestConfig) {
        return FilesModuleApiFp(this.configuration).fileFileSearchGet(query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets locked model list with users having the model locked.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesModuleApi
     */
    public fileLockSummaryGet(options?: AxiosRequestConfig) {
        return FilesModuleApiFp(this.configuration).fileLockSummaryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets supported sensors list.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesModuleApi
     */
    public fileSensorsGet(options?: AxiosRequestConfig) {
        return FilesModuleApiFp(this.configuration).fileSensorsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets info about single element and its scheduled operation under current model version lock.
     * @param {ModelElementDataRequest} [modelElementDataRequest] Model element request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesModuleApi
     */
    public fileVersionElementDataPost(modelElementDataRequest?: ModelElementDataRequest, options?: AxiosRequestConfig) {
        return FilesModuleApiFp(this.configuration).fileVersionElementDataPost(modelElementDataRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all lockable BMA elements inside a model.
     * @param {ElementListRequest} [elementListRequest] Element list request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesModuleApi
     */
    public fileVersionElementsListPost(elementListRequest?: ElementListRequest, options?: AxiosRequestConfig) {
        return FilesModuleApiFp(this.configuration).fileVersionElementsListPost(elementListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the file version list.
     * @param {string} projectID Forge project ID.
     * @param {string} itemUrn Item urn of forge model.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesModuleApi
     */
    public fileVersionListGet(projectID: string, itemUrn: string, options?: AxiosRequestConfig) {
        return FilesModuleApiFp(this.configuration).fileVersionListGet(projectID, itemUrn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Locks all BMA elements inside a viewable of a model.
     * @param {CreateModelLockRequest} [createModelLockRequest] Create model lock request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesModuleApi
     */
    public fileVersionLockElementsPost(createModelLockRequest?: CreateModelLockRequest, options?: AxiosRequestConfig) {
        return FilesModuleApiFp(this.configuration).fileVersionLockElementsPost(createModelLockRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets users having the model locked for given file version.
     * @param {ModelLockInfoRequest} [modelLockInfoRequest] Model lock info request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesModuleApi
     */
    public fileVersionLockInfoPost(modelLockInfoRequest?: ModelLockInfoRequest, options?: AxiosRequestConfig) {
        return FilesModuleApiFp(this.configuration).fileVersionLockInfoPost(modelLockInfoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets info about all elements with unprocessed scheduled operations under current model version lock.
     * @param {ScheduledOperationListRequest} [scheduledOperationListRequest] Model element request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesModuleApi
     */
    public fileVersionModificationsPost(scheduledOperationListRequest?: ScheduledOperationListRequest, options?: AxiosRequestConfig) {
        return FilesModuleApiFp(this.configuration).fileVersionModificationsPost(scheduledOperationListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Proceeds all scheduled operations under current user\'s model version lock.
     * @param {PerformModelOperationsRequest} [performModelOperationsRequest] Schedule model operation request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesModuleApi
     */
    public fileVersionPerformOperationsPost(performModelOperationsRequest?: PerformModelOperationsRequest, options?: AxiosRequestConfig) {
        return FilesModuleApiFp(this.configuration).fileVersionPerformOperationsPost(performModelOperationsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Schedule new operation under current model version lock.
     * @param {ScheduleModelOperationRequest} [scheduleModelOperationRequest] Schedule model operation request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesModuleApi
     */
    public fileVersionScheduleOperationPost(scheduleModelOperationRequest?: ScheduleModelOperationRequest, options?: AxiosRequestConfig) {
        return FilesModuleApiFp(this.configuration).fileVersionScheduleOperationPost(scheduleModelOperationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Discards changes on single element.
     * @param {SingleElementDiscardRequest} [singleElementDiscardRequest] Single element discard request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesModuleApi
     */
    public fileVersionSingleElementDiscardPost(singleElementDiscardRequest?: SingleElementDiscardRequest, options?: AxiosRequestConfig) {
        return FilesModuleApiFp(this.configuration).fileVersionSingleElementDiscardPost(singleElementDiscardRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Forcibly unlocks all locked BMA elements in a model and discard any local changes.
     * @param {ReleaseModelLockRequest} [releaseModelLockRequest] Release model lock request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesModuleApi
     */
    public fileVersionUnlockElementsForcePost(releaseModelLockRequest?: ReleaseModelLockRequest, options?: AxiosRequestConfig) {
        return FilesModuleApiFp(this.configuration).fileVersionUnlockElementsForcePost(releaseModelLockRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unlocks all locked BMA elements in a model.
     * @param {ReleaseModelLockRequest} [releaseModelLockRequest] Release model lock request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesModuleApi
     */
    public fileVersionUnlockElementsPost(releaseModelLockRequest?: ReleaseModelLockRequest, options?: AxiosRequestConfig) {
        return FilesModuleApiFp(this.configuration).fileVersionUnlockElementsPost(releaseModelLockRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

import { AxiosResponse } from 'axios';

import config from '../../../../config/config';
import { Configuration, ForgeMarkupsApi } from '../../../../generate/api';
import { getAuthorizationToken } from '../../../../redux/keyclock/middleware';
import { store } from '../../../../redux/store';
import ApiClient from '../../ApiClient';
import { IMarkupResponse, IMarkupThumbnailRequest, IObjectMarkupId } from './types';

const markupsApi = new ApiClient('/Markups', config.api.baseUrl);

// Thumbnail
// todo - use new api
//  problem with new api - it returns data in binary format: �PNG\r\n\x1A\n\x00\x00\x00\rIHDR\
//  old api return blob
export const markupThumbnail = (
    token: string,
    objectMarkupID: IObjectMarkupId,
    data: IMarkupThumbnailRequest,
) => {
    ApiClient.setAuthorizationToken(token);

    return markupsApi
        .post(`/${objectMarkupID}/Thumbnail`, data, { responseType: 'blob' })
        .then((response: AxiosResponse<any>) => {
            // todo AxiosResponse<IMarkupThumbnailResponse>
            return Promise.resolve(response.data);
        })
        .catch(error => {
            return Promise.reject(error);
        });
};

export const markupsApi2 = new ForgeMarkupsApi(
    new Configuration({
        basePath: config.api.baseUrl,
        accessToken: () => {
            return store.getState().keycloak.token as string;
        },
    }),
);

import { defineMessages } from 'react-intl';
export default defineMessages({
    title: {
        id: 'components.markups.title',
        defaultMessage: 'Markups',
    },

    // Buttons
    buttonRename: {
        id: 'components.markups.button.rename',
        defaultMessage: 'Rename',
    },
    buttonDelete: {
        id: 'components.markups.button.delete',
        defaultMessage: 'Delete',
    },
    buttonStorno: {
        id: 'components.markups.button.storno',
        defaultMessage: 'Storno',
    },
    buttonOk: {
        id: 'components.markups.button.ok',
        defaultMessage: 'Ok',
    },
    buttonHideMarkup: {
        id: 'components.markups.button.hideMarkup',
        defaultMessage: 'Hide Markup',
    },

    // Messages
    messageLoadingListNotSuccessful: {
        id: 'components.markups.message.loadingListNotSuccessful',
        defaultMessage: 'Loading of list of markups was not successful',
    },
    messageUnableToUpdateMarkup: {
        id: 'components.markups.message.unableToUpdateMarkup',
        defaultMessage: 'Unable to update markup',
    },
    messageMarkupUpdated: {
        id: 'components.markups.message.markupUpdated',
        defaultMessage: 'Markup updated',
    },
    messageMarkupDeleted: {
        id: 'components.markups.message.markupDeleted',
        defaultMessage: 'Markup deleted',
    },
    messageMarkupCreated: {
        id: 'components.markups.message.markupCreated',
        defaultMessage: 'Markup created',
    },
    messageDoYouWantDeleteThisMarkup: {
        id: 'components.markups.message.doYouWantDeleteThisMarkup',
        defaultMessage: 'Do you want delete this markup?',
    },
    messageDeletingMarkupNotSuccessful: {
        id: 'components.markups.message.deletingMarkupNotSuccessful',
        defaultMessage: 'Deleting markup was not successful',
    },
    messageAttachMarkupNotSuccessful: {
        id: 'components.markups.message.attachMarkupNotSuccessful',
        defaultMessage: 'Attaching markup was not successful',
    },
    messageCreatingMarkupNotSuccessful: {
        id: 'components.markups.message.creatingMarkupNotSuccessful',
        defaultMessage: 'Creating markup was not successful',
    },
    messageNoMarkupsCreatedYet: {
        id: 'components.markups.message.noMarkupsCreatedYet',
        defaultMessage: 'No markups created yet',
    },
});

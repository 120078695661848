import { DispatchAction } from '@iolabs/redux-utils';
import { Box, Button, DialogActions, DialogContent, MenuItem } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import clsx from 'clsx';
import { TextField } from 'mui-rff';
import React, { useCallback, useContext, useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';

import config from '../../../config/config';
import { DialogContext } from '../../../dialogs/DialogProvider/DialogProvider';
import { onFeedbackSend } from '../../../redux/feedback';
import { useTranslation } from '../../../redux/translations/hook';
import { DF } from '../DialogFactory';
import messages from './messages';
import useStyles from './styles';
import { FeedbackType, IFeedbackFormErrors, IFeedbackFormValues } from './type';

interface IFeedbackProps {
    location: any;
}

const Feedback: React.FC<IFeedbackProps> = ({ location }) => {
    const classes = useStyles();
    const { keycloak, initialized: keycloakInitialized } = useKeycloak();
    const dispatch = useDispatch<DispatchAction>();
    const { toggleDialog } = useContext(DialogContext);

    const [initialFormValues] = useState<IFeedbackFormValues | null>();

    // translations
    const transFieldType = useTranslation({ ...messages.fieldType });
    const transFieldTypeQuestion = useTranslation({ ...messages.fieldTypeQuestion });
    const transFieldTypeProblem = useTranslation({ ...messages.fieldTypeProblem });
    const transFieldTypeSuggestion = useTranslation({ ...messages.fieldTypeSuggestion });
    const transFieldSubject = useTranslation({ ...messages.fieldSubject });
    const transFieldMessage = useTranslation({ ...messages.fieldMessage });
    const transPlaceholderType = useTranslation({ ...messages.placeholderType });
    const transPlaceholderSubject = useTranslation({ ...messages.placeholderSubject });
    const transPlaceholderMessage = useTranslation({ ...messages.placeholderMessage });
    const transValidationType = useTranslation({ ...messages.validationType });
    const transValidationSubject = useTranslation({ ...messages.validationSubject });
    const transValidationMessage = useTranslation({ ...messages.validationMessage });
    const transBtnSend = useTranslation({ ...messages.btnSend });
    const transMessageSuccess = useTranslation({ ...messages.messageSuccess });
    const transMessageError = useTranslation({ ...messages.messageError });

    const validate = (values: IFeedbackFormValues): IFeedbackFormErrors => {
        const errors: IFeedbackFormErrors = {};
        if (!values?.type) {
            (errors as IFeedbackFormErrors).type = transValidationType;
        }
        if (!values?.subject) {
            (errors as IFeedbackFormErrors).subject = transValidationSubject;
        }
        if (!values?.message) {
            (errors as IFeedbackFormErrors).message = transValidationMessage;
        }
        return errors;
    };

    const onSubmit = useCallback(
        async (values: IFeedbackFormValues) => {
            dispatch(
                onFeedbackSend({
                    token: keycloak?.token as string,
                    data: {
                        applicationCode: config?.healthBoard?.applicationCode,
                        submoduleCode: location.pathname
                            .split('/')
                            .slice(-1)?.[0]
                            ?.replace('-', '_')
                            ?.toUpperCase(),
                        type: values?.type,
                        subject: values?.subject,
                        message: values?.message,
                        reportedFromUrl: window.location.href,
                    },
                    messageSuccess: transMessageSuccess,
                    messageError: transMessageError,
                }),
            );
            toggleDialog(DF.FEEDBACK);
        },
        [keycloakInitialized, toggleDialog, location],
    );

    return (
        <Form onSubmit={onSubmit} initialValues={initialFormValues} validate={validate}>
            {({ handleSubmit, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit}>
                    <DialogContent dividers>
                        <Box className={classes.formContent}>
                            <TextField
                                required
                                name="type"
                                label={transFieldType}
                                placeholder={transPlaceholderType}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                select
                                SelectProps={{
                                    displayEmpty: true,
                                }}
                                className={clsx(classes.textField, {
                                    [classes.placeholder]: !values?.type,
                                })}
                            >
                                <MenuItem value="" disabled>
                                    {transPlaceholderType}
                                </MenuItem>
                                <MenuItem value={FeedbackType.QUESTION}>
                                    {transFieldTypeQuestion}
                                </MenuItem>
                                <MenuItem value={FeedbackType.PROBLEM}>
                                    {transFieldTypeProblem}
                                </MenuItem>
                                <MenuItem value={FeedbackType.SUGGESTION}>
                                    {transFieldTypeSuggestion}
                                </MenuItem>
                            </TextField>

                            <TextField
                                required
                                name="subject"
                                label={transFieldSubject}
                                placeholder={transPlaceholderSubject}
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                required
                                multiline
                                rows={8}
                                name="message"
                                label={transFieldMessage}
                                placeholder={transPlaceholderMessage}
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Box display="flex" justifyContent="center" width="100%">
                            <Button
                                type="submit"
                                variant="outlined"
                                size="small"
                                disabled={submitting || pristine}
                                className={classes.button}
                            >
                                {transBtnSend}
                            </Button>
                        </Box>
                    </DialogActions>
                </form>
            )}
        </Form>
    );
};

export default Feedback;

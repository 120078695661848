export const sanitizeExternalId = (externalId: string) => {
    const ids = externalId.split('/');
    let outputId: string;
    if (ids[1]) {
        outputId = ids[1];
    } else {
        outputId = externalId;
    }
    return outputId;
};

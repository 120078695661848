import { createStyles, makeStyles } from '@mui/styles';

import theme from '../../theme/Theme';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            // position: "absolute",
            // bottom: 0,
            // left: 0,
            // right: 0,
            // zIndex: 1000,
            background: 'white',
            borderTop: '1px solid',
            '& *': {
                boxSizing: 'border-box',
            },
            position: 'relative',
        },
        tableWrapper: {
            overflowX: 'auto',
            minWidth: '100%',
            height: '75px',
            position: 'relative',
        },
        tableWrapperInner: {
            position: 'absolute',
            minWidth: '100%',
        },
        table: {
            minWidth: '100%',
        },
        theadWrapper: {
            position: 'sticky',
            top: 0,
        },
        thead: {
            background: '#EBF7F8',
            '& th': {
                padding: '0 8px',
                border: '1px solid #E0E0E0',
                textAlign: 'left',
                whiteSpace: 'nowrap',
                fontSize: '.75rem',
                height: '30px',
            },
        },
        theadThHover: {
            cursor: 'pointer',
            '&:hover': {
                background: '#c3ebef',
            },
        },
        theadThActive: {
            background: '#9dd4d9',
        },
        assetRow: {
            '& td': {
                padding: '0 8px',
                border: '1px solid #E0E0E0',
                whiteSpace: 'nowrap',
                fontSize: '.75rem',
                height: '30px',
            },
            '&:hover': {
                background: '#fafafa',
                cursor: 'pointer',
            },
        },
        assetRowActive: {
            background: '#EBF7F8',
            '&:hover': {
                background: '#EBF7F8',
            },
        },
        thSortOrderIcon: {
            marginRight: '5px',
        },
        loading: {
            height: '75px',
            padding: theme.spacing(2),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        pageLimitBox: {
            display: 'none',
        },
        '@media (min-width: 480px) and (min-height: 600px)': {
            tableWrapper: {
                height: '160px',
            },
            loading: {
                height: '160px',
            },
            pageLimitBox: {
                display: 'flex',
            },
        },
        '@media (min-width: 1024px) and (min-height: 800px)': {
            tableWrapper: {
                height: '342px',
            },
            loading: {
                height: '342px',
            },
        },
        navigation: {
            background: '#EDEDED',
            userSelect: 'none',
        },
        navigationInner: {
            background: '#EDEDED',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '6px 10px',
        },
        navButton: {
            borderRadius: '3px',
            display: 'inline-block',
            marginLeft: '4px',
            marginRight: '4px',
            cursor: 'pointer',
            height: '20px',
            verticalAlign: 'top',
            '&:hover': {
                background: '#cfcfcf',
            },
        },
        navButtonDisabled: {
            opacity: '0.5',
            pointerEvents: 'none',
            cursor: 'default',
            '&:hover': {
                background: 'none',
            },
        },
        linkDisabled: {
            color: '#ddd',
        },
        pageLimit: {
            cursor: 'pointer',
        },
        pageLimitList: {
            right: '0',
            bottom: '23px',
            position: 'absolute',
            background: 'white',
            border: '1px solid',
            borderRadius: '5px',
            overflow: 'hidden',
            '& div': {
                textAlign: 'right',
                padding: '2px 8px',
                cursor: 'pointer',
                '&:hover': {
                    background: '#ddd',
                },
            },
        },
        statusPopover: {
            '& .MuiPopover-paper': {
                border: '1px solid #000',
            },
        },
        statusPopoverItem: {
            padding: '3px 10px',
            cursor: 'pointer',
            '&:hover': {
                background: '#c3ebef',
            },
        },
        toggleColumnsSettings: {
            margin: '0px',
            marginLeft: '8px',
            padding: '0px',
            display: 'inline-block',
            position: 'relative',
            width: '36px',
            height: '20px',
            zIndex: 102,
            lineHeight: 0,
            '& .columns-settings-icon': {
                width: '36px',
                height: '26px',
                bottom: '-3px',
                left: '0px',
                position: 'absolute',
                zIndex: 102,
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'center',
                cursor: 'pointer',
                transition: 'all 0.3s ease',
                border: '1px solid rgba(0,0,0,0)',
            },
            '& svg': {
                margin: '2px',
            },
            '&:hover .columns-settings-icon': {
                background: theme.palette.grey[100],
                color: theme.palette.primary.dark,
            },
            '&.active .columns-settings-icon': {
                background: theme.palette.common.white,
                border: '1px solid rgba(0,0,0,1)',
                borderTop: '0px',
                borderBottomRightRadius: '3px',
                borderBottomLeftRadius: '3px',
                height: '30px',
                '& svg': {
                    color: theme.palette.primary.dark,
                },
            },
            /*backgroundColor: theme.palette.primary.dark,
            borderColor: theme.palette.primary.dark,
            '& svg path': {
                fill: theme.palette.common.white,
            },*/
        },
    }),
);

export default useStyles;

import { createStyles, makeStyles } from '@mui/styles';

import theme from '../../theme/Theme';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
        },

        title: {
            display: 'table',
            margin: '0 auto 1rem',
            fontWeight: 'normal',
            color: theme.palette.primary.main,
        },

        tableWrapper: {
            // border: '2px solid #44cccd',
            // borderRadius: '8px',
            // padding: '10px',
        },
        table: {
            width: '100%',
            fontSize: '12px',
            '& tr': {
                borderTop: '1px solid #f1f1f1',
            },
            '& td ': {
                padding: '2px 3px',
            },
        },
        isEditedIcon: {
            width: '7px',
            height: '10px',
            background: '#ffcf00',
            borderRadius: '2px',
        },
        serialBox: {
            padding: '10px',
            borderRadius: '8px',
            background: '#f1f1f1',
        },
        serialInput: {
            '& input': {
                textAlign: 'center',
                background: '#fff',
            },
        },
        buttonsGroup: {
            display: 'flex',
            gap: '10px',
        },
        introTitle: {
            textAlign: 'center',
            fontSize: '25px',
            color: '#b9b9b9',
        },
        debugElementsList: {
            height: '100px',
            overflow: 'auto',
            width: '100%',
            background: '#eee',
        },
    }),
);

export default useStyles;

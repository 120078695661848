import { Typography } from '@mui/material';
import React from 'react';

export interface IFormattedNumberProps {
    /**
     * Number to format
     */
    children?: number;

    /**
     * Locale to use
     */
    locale?: string;
}

const FormattedNumber: React.FC<IFormattedNumberProps> = ({
    children: number,
    locale = 'en-US',
}) => {
    // todo where to get locale from?

    return (
        <span title={number?.toLocaleString(locale, { maximumFractionDigits: 10 })}>
            {number?.toLocaleString(
                locale, // leave undefined to use the visitor's browser
                // locale or a string like 'en-US' to override it.
                { minimumFractionDigits: 3 },
            )}
        </span>
    );
};

export default FormattedNumber;

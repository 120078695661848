import { DispatchAction } from '@iolabs/redux-utils';
import { Box, Button, ClickAwayListener, Link } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import clsx from 'clsx';
import moment from 'moment/moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DialogContext } from '../../dialogs/DialogProvider/DialogProvider';
import { DateRange, Issue, IssueSearchRequest, IssueSearchResponse } from '../../generate/api';
import { issuesApi } from '../../packages/Api/data/issues/client';
import {
    onSetIssues,
    setCurrentIssue,
    setIssuesOnRefresh,
    setIssuesSettings,
    setMode,
    setPushpinInsertMode,
    useIssuesFilters,
    useIssuesSettings,
    useIssuesState,
} from '../../redux/issues';
import { setSidebar, useProjectState, useUser } from '../../redux/project';
import { ISortOrder } from '../../redux/project/types';
import { useTranslation } from '../../redux/translations/hook';
import { DF } from '../DialogFactory/DialogFactory';
import { INotificationDialogProps } from '../DialogFactory/NotificationDialog/NotificationDialog';
import Icon from '../Icon/Icon';
import FormattedMessage from '../Translation/FormattedMessage';
import IssuesLayer from './IssuesLayer';
import messages from './messagesTable';
import Reset from './Reset';
import useStyles from './styles';

export interface IIssues {
    viewable?: any;
    viewer: Autodesk.Viewing.Viewer3D;
}

export interface ITableRowProps {
    row: Issue;
    onClick?: (row: any) => void;
    classNames?: string;
}

const TableRow: React.FC<ITableRowProps> = ({ row, onClick, classNames }) => {
    const classes = useStyles();

    let dueDate: any = '';
    if (row.dueDate) {
        dueDate = new Date(row.dueDate);
        dueDate = moment(dueDate).format('DD.MM.YYYY');
    }

    const stopPropagation = event => {
        event.stopPropagation();
    };

    const handleClickRow = () => {
        if (onClick) {
            onClick(row);
        }
    };

    return (
        <tr className={clsx(classes.assetRow, classNames)} onClick={handleClickRow}>
            <td>{row.typeName}</td>
            <td>{row.status}</td>
            <td>{row.title}</td>
            <td>{row.assignedToFullName}</td>
            <td>{dueDate}</td>
            <td>{row.locationName}</td>
            <td>{row.locationDescription}</td>
            <td>{row.createdByFullName}</td>
            <td>{row.rootCauseName}</td>
            <td>{row.attachmentCount}</td>
            <td>{row.objectMarkupID ? 'yes' : ''}</td>
            <td>{row.description}</td>
            <td>
                {row.forgeIssueLink ? (
                    <Link
                        color={'primary'}
                        href={row.forgeIssueLink}
                        target="_blank"
                        onClick={stopPropagation}
                    >
                        BIM 360
                    </Link>
                ) : (
                    <span className={classes.linkDisabled}>BIM 360</span>
                )}
            </td>
        </tr>
    );
};

export interface ISortArrowProps {
    sortColumn: string;
}

const SortArrow: React.FC<ISortArrowProps> = ({ sortColumn }) => {
    const classes = useStyles();
    const issuesSettings = useIssuesSettings();

    if (issuesSettings.sortColumn != sortColumn) return <></>;

    return issuesSettings.sortDirection === 'asc' ? (
        <Icon name={'chevron-light-up'} size={10} className={classes.thSortOrderIcon} />
    ) : (
        <Icon name={'chevron-light-down'} size={10} className={classes.thSortOrderIcon} />
    );
};

const Issues: React.FC<IIssues> = ({ viewable, viewer }) => {
    const classes = useStyles();

    const user = useUser();

    const tableCols: { title: string; name?: string }[] = [
        {
            title: useTranslation({ ...messages.tableTitleType }),
            name: 'typeName',
        },
        {
            title: useTranslation({ ...messages.tableTitleStatus }),
            name: 'status',
        },
        { title: useTranslation({ ...messages.tableTitleTitle }), name: 'title' },
        {
            title: useTranslation({ ...messages.tableTitleAssignTo }),
            name: 'assignedToFullName',
        },
        {
            title: useTranslation({ ...messages.tableTitleDueDate }),
            name: 'dueDate',
        },
        {
            title: useTranslation({ ...messages.tableTitleLocation }),
            name: 'locationName',
        },
        {
            title: useTranslation({ ...messages.tableTitleLocationDetails }),
            name: 'locationDetails',
        },
        {
            title: useTranslation({ ...messages.tableTitleOwner }),
            name: 'createdByFullName',
        },
        {
            title: useTranslation({ ...messages.tableTitleRootCause }),
            name: 'rootCauseName',
        },
        {
            title: useTranslation({ ...messages.tableTitleAttachments }),
            name: 'attachments',
        },
        {
            title: useTranslation({ ...messages.tableTitleMarkup }),
            name: 'markup',
        },
        {
            title: useTranslation({ ...messages.tableTitleDescription }),
            name: 'description',
        },
        { title: useTranslation({ ...messages.tableTitleBIM360 }) },
    ];

    const reduxViewable = useProjectState().file.currentViewable;

    const issuesDefaultState = {
        recordCount: 0,
        issues: [],
    };

    const [issues, setIssues] = useState<IssueSearchResponse>(issuesDefaultState);
    const [latestSelectedIssues, setLatestSelectedIssue] = useState<Issue | undefined>();
    const [loading, setLoading] = useState<boolean>(false);
    const [highlightedIssue, setHighlightedIssue] = useState<string>();

    const dispatch = useDispatch<DispatchAction>();

    const project = useProjectState();
    const reduxIssues = useIssuesState();
    const currentIssue = reduxIssues.currentIssue;

    const onRefresh = reduxIssues?.onRefresh; // if true - refresh issues

    const forgeHubId = project?.hubId;
    const forgeProjectId = project?.project?.id;
    const fileVersionUrn = project?.file?.currentVersion?.data?.urn;

    const tableRef = useRef(null);

    const issuesSettings = useIssuesSettings();
    const [pageLimitListOpen, setPageLimitListOpen] = useState<boolean>(false);

    const issuesFilters = useIssuesFilters();

    const mode = reduxIssues?.mode;

    const { openDialog } = useContext(DialogContext);

    const { keycloak, initialized: keycloakInitialized } = useKeycloak();

    const loadIssues = () => {
        if (
            keycloak?.token &&
            forgeProjectId &&
            fileVersionUrn &&
            forgeHubId &&
            reduxViewable?.viewableID
        ) {
            setLoading(true);

            // const data:IIssuesListRequestData = {
            const data: IssueSearchRequest = {
                limit: issuesSettings.pageLimit,
                offset: issuesSettings.offset,
                sortColumn: issuesSettings.sortColumn,
                sortDirection: issuesSettings.sortDirection,
                searchText: issuesFilters.searchText as string,
                forgeProjectId: forgeProjectId,
                forgeVersionUrn: fileVersionUrn,
                forgeViewableId: reduxViewable?.viewableID,
                statuses: issuesFilters.statuses as string[],
                types: issuesFilters.types as string[],
                assignedTo: issuesFilters.assignedTo as string[],
                createdBy: issuesFilters.createdBy as string[],
                rootCauses: issuesFilters.rootCauses as string[],
                locations: issuesFilters.locations as string[],
                dueDateRange: issuesFilters.dueDateRange as DateRange,
                createdOnRange: issuesFilters.createdOnRange as DateRange,
            };

            issuesApi
                .issuesListPost(data)
                .then(issueListData => {
                    setIssues(issueListData.data);
                    setLoading(false);
                    console.log('Issues - listed', issueListData.data);
                    dispatch(onSetIssues(issueListData.data));
                })
                .catch(error => {
                    setIssues(issuesDefaultState);
                    openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Error', {
                        content: <FormattedMessage {...messages.errorIssueList} />,
                    });
                    console.error('Issues - Unable to load issues', error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        if (onRefresh) {
            reloadCacheAndLoadIssues();
            dispatch(setIssuesOnRefresh(false));
        }
    }, [onRefresh]);

    const reloadCacheAndLoadIssues = () => {
        if (keycloak?.token && forgeProjectId && fileVersionUrn) {
            issuesApi
                .issuesCacheClearPost({
                    forgeProjectId: forgeProjectId,
                    forgeVersionUrn: fileVersionUrn,
                })
                .then(() => {
                    console.log('Issues - Cache cleared');
                })
                .catch(error => {
                    openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Error', {
                        content: <FormattedMessage {...messages.errorClearCache} />,
                    });
                    console.error('Issues - Unable to clear cache', error);
                })
                .finally(() => {
                    loadIssues();
                });
        }
    };

    // Load issues
    useEffect(() => {
        loadIssues();
    }, [
        keycloak,
        keycloakInitialized,
        fileVersionUrn,
        issuesSettings,
        issuesFilters,
        reduxViewable,
    ]);

    useEffect(() => {
        if (latestSelectedIssues && latestSelectedIssues.linkedDocument?.viewerState) {
            const viewerStateJson = JSON.parse(latestSelectedIssues.linkedDocument?.viewerState);
            viewer.restoreState(viewerStateJson);
            // viewer.fitToView([parseInt(latestSelectedIssues.pushpinAttributes.objectID)]);
            // viewer.navigation.setPosition(latestSelectedIssues.pushpinAttributes.location as THREE.Vector3);
        }
    }, [latestSelectedIssues]);

    const navMaxPage = Math.ceil((issues.recordCount ?? 0) / issuesSettings.pageLimit);
    const navActualPage = issuesSettings.offset + 1;
    const navAllItemsCount = issues.recordCount;
    const navItemsFromCount =
        issuesSettings.pageLimit * (issuesSettings.offset + 1) - issuesSettings.pageLimit + 1;
    const navItemsToCount =
        issuesSettings.pageLimit * (issuesSettings.offset + 1) -
        issuesSettings.pageLimit +
        (issues.recordCount ?? 0);

    // disable pushpin mode on leave issues
    useEffect(() => {
        return () => {
            dispatch(setPushpinInsertMode(false));
        };
    }, []);

    const handleFirst = () => {
        dispatch(setIssuesSettings({ ...issuesSettings, offset: 0 }));
    };
    const handlePrev = () => {
        dispatch(setIssuesSettings({ ...issuesSettings, offset: issuesSettings.offset - 1 }));
    };
    const handleNext = () => {
        dispatch(setIssuesSettings({ ...issuesSettings, offset: issuesSettings.offset + 1 }));
    };
    const handleLast = navMaxPageNumber => {
        if (navMaxPageNumber) {
            dispatch(setIssuesSettings({ ...issuesSettings, offset: navMaxPageNumber - 1 }));
        }
    };

    const handleSetPageLimit = (limit: number) => {
        dispatch(setIssuesSettings({ ...issuesSettings, pageLimit: limit, offset: 0 }));
        setPageLimitListOpen(false);
    };
    const handleOpenPageLimitList = () => {
        setPageLimitListOpen(!pageLimitListOpen);
    };

    const handleOrderBy = name => {
        if (name) {
            const sortColumn = name;
            let order: ISortOrder = issuesSettings.sortDirection;
            if (issuesSettings.sortColumn != sortColumn) {
                order = 'asc';
            }
            if (issuesSettings.sortColumn === sortColumn) {
                if (order === 'desc') {
                    order = 'asc';
                } else {
                    order = 'desc';
                }
            }
            dispatch(
                setIssuesSettings({
                    ...issuesSettings,
                    sortColumn: sortColumn,
                    sortDirection: order,
                    offset: 0,
                }),
            );
        }
    };

    const handleClickIssue = (issue: Issue) => () => {
        if (mode === 'edit' || mode === 'create') return;
        if (issue?.forgeIssueID && highlightedIssue !== issue?.forgeIssueID) {
            setLatestSelectedIssue(undefined);
        } else {
            setLatestSelectedIssue(issue);
        }

        dispatch(setCurrentIssue(issue));
    };

    useEffect(() => {
        setHighlightedIssue(currentIssue?.forgeIssueID as string);
    }, [currentIssue]);

    const handleClickCreate2 = () => {
        dispatch(setMode('create'));
        dispatch(setSidebar(true));
    };

    const handleNextArrowsClass = () => {
        return (navActualPage && navMaxPage && navActualPage >= navMaxPage) ||
            issues?.issues?.length == 0 ||
            (issues?.issues?.length && issues?.issues?.length < issuesSettings.pageLimit)
            ? classes.navButtonDisabled
            : '';
    };

    // Roles
    const [isCreateIssueAllowed, setIsCreateIssueAllowed] = useState<boolean>(false);
    useEffect(() => {
        if (user?.hasFireBimRole) {
            setIsCreateIssueAllowed(true);
        }
    }, [user]);

    return (
        <Box position="relative">
            <IssuesLayer viewer={viewer} />

            <Box className={classes.root}>
                {loading && (
                    <Box className={classes.loading}>
                        <Box>
                            <FormattedMessage {...messages.tableLoading} />
                            ...
                        </Box>
                    </Box>
                )}
                {!loading && (
                    <div className={classes.tableWrapper} ref={tableRef}>
                        <Box className={classes.tableWrapperInner}>
                            <table className={classes.table}>
                                <thead className={classes.theadWrapper}>
                                    <tr className={classes.thead}>
                                        {tableCols.map((col, index) => {
                                            const thActive = issuesSettings.sortColumn === col.name;
                                            if (col.name) {
                                                return (
                                                    <th
                                                        key={index}
                                                        onClick={() => handleOrderBy(col.name)}
                                                        className={clsx(
                                                            thActive ? classes.theadThActive : '',
                                                            classes.theadThHover,
                                                        )}
                                                    >
                                                        <SortArrow sortColumn={col.name} />
                                                        {col.title}
                                                    </th>
                                                );
                                            } else {
                                                return <th key={index}>{col.title}</th>;
                                            }
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {issues?.issues?.map((issue, index) => {
                                        return (
                                            <TableRow
                                                key={index}
                                                row={issue}
                                                onClick={handleClickIssue(issue)}
                                                classNames={
                                                    issue.forgeIssueID
                                                        ? highlightedIssue === issue.forgeIssueID
                                                            ? classes.issueHighlighted
                                                            : ''
                                                        : ''
                                                }
                                            />
                                        );
                                    })}
                                </tbody>
                            </table>
                        </Box>
                    </div>
                )}

                <Box className={classes.navigation}>
                    <Box className={classes.navigationInner}>
                        <Box>
                            {navItemsFromCount ? navItemsFromCount : 0} -{' '}
                            {navItemsToCount ? navItemsToCount : 0} /{' '}
                            {navAllItemsCount ? navAllItemsCount : 0}
                        </Box>

                        <Box display={'flex'}>
                            <Box position={'relative'} mr={5}>
                                <Box
                                    onClick={handleOpenPageLimitList}
                                    className={classes.pageLimit}
                                >
                                    <FormattedMessage {...messages.tableLinesPerPage} />:{' '}
                                    {issuesSettings.pageLimit}
                                    <Icon name={'chevron-light-down'} size={10} />
                                </Box>
                                {pageLimitListOpen && (
                                    <ClickAwayListener
                                        onClickAway={() => setPageLimitListOpen(false)}
                                    >
                                        <Box className={classes.pageLimitList}>
                                            <Box onClick={() => handleSetPageLimit(10)}>10</Box>
                                            <Box onClick={() => handleSetPageLimit(20)}>20</Box>
                                            <Box onClick={() => handleSetPageLimit(50)}>50</Box>
                                            <Box onClick={() => handleSetPageLimit(100)}>100</Box>
                                        </Box>
                                    </ClickAwayListener>
                                )}
                            </Box>
                            <Box
                                onClick={handleFirst}
                                className={clsx(
                                    classes.navButton,
                                    issuesSettings.offset <= 0 && classes.navButtonDisabled,
                                )}
                            >
                                <Icon name={'nav-arrow-first'} size={20} />
                            </Box>
                            <Box
                                onClick={handlePrev}
                                className={clsx(
                                    classes.navButton,
                                    issuesSettings.offset <= 0 && classes.navButtonDisabled,
                                )}
                            >
                                <Icon name={'nav-arrow-prev'} size={20} />
                            </Box>
                            {navActualPage ? navActualPage : 0} of {navMaxPage ? navMaxPage : 0}
                            <Box
                                onClick={handleNext}
                                className={clsx(classes.navButton, handleNextArrowsClass())}
                            >
                                <Icon name={'nav-arrow-next'} size={20} />
                            </Box>
                            <Box
                                onClick={() => handleLast(navMaxPage)}
                                className={clsx(classes.navButton, handleNextArrowsClass())}
                            >
                                <Icon name={'nav-arrow-last'} size={20} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box className={classes.buttonsMenuNav}>
                <Box>
                    {isCreateIssueAllowed && (
                        <Button
                            onClick={handleClickCreate2}
                            variant="contained"
                            disabled={mode === 'create' || mode === 'edit'}
                            className="w-[40px] h-[40px] min-w-0 p-0 mb-1"
                        >
                            <Icon name={'plus-circle'} size={25} />
                        </Button>
                    )}
                </Box>
                <Reset reloadCacheAndLoadIssues={reloadCacheAndLoadIssues} />
            </Box>
        </Box>
    );
};

export default Issues;

import { createStyles, makeStyles } from '@mui/styles';

import theme from '../../theme/Theme';

const useStyles = makeStyles(() =>
    createStyles({
        root: {},
        icon: {
            paddingTop: '5px',
            paddingLeft: '4px',
            '& .MuiBadge-badge': {
                right: -2,
                top: 17,
                border: `2px solid ${theme.palette.background.paper}`,
                padding: '0 4px',
                fontSize: '9px',
            },
        },
        table: {
            width: '100%',
            maxWidth: '400px',
            marginBottom: '20px',
        },
        tableThCol: {
            padding: '5px 0',
        },
        tableCol: {
            padding: '5px 0',
            borderTop: '1px solid #ddd',
            '&:hover': {
                cursor: 'default',
            },
        },
        tableColDate: {
            textAlign: 'right',
        },
        hoverInfo: {
            position: 'absolute',
            border: '1px solid lightgray',
            borderRadius: '5px',
            backgroundColor: 'white',
            padding: '0.1rem 0.5rem',
            zIndex: '10',
        },
    }),
);

export default useStyles;

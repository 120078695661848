import { defineMessages } from 'react-intl';

const globalMessages = defineMessages({
    loadingDataError: {
        id: 'components.app.loading-data-error',
        defaultMessage: 'Loading data error. Please try again later.',
    },
    tileButtonOpen: {
        id: 'components.app.tileButtonOpen',
        defaultMessage: 'Open',
    },
});

export default globalMessages;

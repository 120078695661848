import { Box } from '@mui/material';
import React from 'react';

import FormattedMessage from '../../components/Translation/FormattedMessage';
import { SearchResultFile } from '../../generate/api';
import FileTile from './FileTile';
import messages from './messages';
import useStyles from './styles';

interface IModalProps {
    files: SearchResultFile[] | null | undefined;
    onFileSelected: (file: SearchResultFile) => void;
    isSearch: boolean;
}

const FilesExplorer: React.FC<IModalProps> = ({ files, onFileSelected, isSearch }) => {
    const classes = useStyles();

    return (
        <Box className={classes.explorerWrapper}>
            {files && files.length > 0 ? (
                files?.map((file, index) => (
                    <FileTile key={index} file={file} onClick={onFileSelected} />
                ))
            ) : isSearch ? (
                <span>
                    <FormattedMessage {...messages.noFilesFound} />
                </span>
            ) : (
                <span>
                    <FormattedMessage {...messages.noFilesAvailable} />
                </span>
            )}
        </Box>
    );
};

export default FilesExplorer;

import { TreeItem } from '@mui/lab';
import { TreeItemProps } from '@mui/lab/TreeItem/TreeItem';
import { Theme, Typography } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';

import { ITree, TreeDataType, TreeVariant } from '../../packages/Api/data/tree/types';
import BagedTreeItem from './BagedTreeItem';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: theme.palette.text.primary,
            '&:hover > $content': {
                // backgroundColor: theme.palette.grey["50"],
            },
            '& $content.Mui-focused': {
                backgroundColor: theme.palette.grey['50'],
            },
            '&:focus > $content': {
                borderRadius: theme.shape.borderRadius,
            },
            '&$selected > $content': {
                color: theme.palette.text.primary,
                backgroundColor: 'transparent',
                borderRadius: theme.shape.borderRadius,
            },
            '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
                backgroundColor: 'transparent',
            },
        },
        content: {
            color: theme.palette.text.primary,
            paddingRight: theme.spacing(1),
            fontWeight: theme.typography.fontWeightRegular,
            borderRadius: theme.shape.borderRadius,
            '$expanded > &': {
                // change style for expanded nodes
            },
        },
        group: {
            marginLeft: theme.spacing(3),
            '& $content': {
                paddingLeft: 0,
            },
        },
        expanded: {},
        selected: {},
        label: {
            fontWeight: 'inherit',
            color: 'inherit',
            backgroundColor: 'transparent !important',
            paddingLeft: 0,
        },
        labelRoot: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0.5, 0),
            textDecoration: 'none',
            color: 'inherit',
            '& svg': {
                fontSize: '1.3rem',
            },
        },
        labelIcon: {
            fill: theme.palette.grey['700'],
            marginRight: theme.spacing(1),
        },
        labelText: {
            fontWeight: 'inherit',
            flexGrow: 1,
            whiteSpace: 'nowrap',
        },
        activeTreeItem: {
            fontWeight: theme.typography.fontWeightBold,
        },
        activeLabel: {
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.primary.main,
        },
        activeIcon: {
            color: theme.palette.primary.main,
        },
        formControlLabel: {
            background: 'transparent',
            '& .MuiIconButton-colorPrimary': {
                background: 'transparent',
                '&:hover, &:focus': {
                    color: theme.palette.primary.main,
                    background: 'transparent',
                },
            },
        },
        checkbox: {
            padding: '5px',
            '& svg': {
                fontSize: '1.2rem',
            },
        },
    }),
);

type StyledTreeItemProps = TreeItemProps & {
    variant: TreeVariant;
    labelIcon: React.ElementType<SvgIconProps>;
    labelInfo?: string;
    labelText: string;
    isActive: boolean;
    item: ITree;
    folder: ITree | null;
    handleClick: (event: React.MouseEvent<HTMLElement>, item: ITree, variant: TreeVariant) => void;
    handleSetNodeId: (item: ITree) => void;
    handleSetActiveNodeId: (item: ITree, variant: TreeVariant, version?: ITree) => void;
    handleMoreOpen: (event: React.MouseEvent<HTMLElement>, item: ITree) => void;
    handleMoreClose: () => void;
    anchorMore: null | HTMLElement;
    openMore: boolean;
    selected: string[];
};

function StyledTreeItem(props: StyledTreeItemProps) {
    const {
        variant,
        labelText,
        labelIcon: LabelIcon,
        labelInfo,
        isActive,
        item,
        folder,
        handleClick,
        handleSetNodeId,
        handleSetActiveNodeId,
        handleMoreOpen,
        handleMoreClose,
        anchorMore,
        openMore,
        selected,
        ...other
    } = props;

    const classes = useStyles();

    return (
        <TreeItem
            className={clsx({
                [classes.activeTreeItem]: isActive,
            })}
            label={
                variant === TreeVariant.PLOT && item.data.type === TreeDataType.ITEM ? (
                    <span
                        className={clsx(classes.labelRoot, {
                            [classes.activeLabel]: isActive,
                        })}
                    >
                        <BagedTreeItem
                            isActive={isActive}
                            variant={variant}
                            labelIcon={LabelIcon}
                            labelText={labelText}
                            itemVersion={item?.data?.lastVersionTreeNode?.data?.version}
                            item={item}
                            folder={folder}
                            handleSetNodeId={handleSetNodeId}
                            handleSetActiveNodeId={handleSetActiveNodeId}
                        />
                    </span>
                ) : (
                    <span
                        className={
                            isActive
                                ? `${classes.activeLabel} ${classes.labelRoot}`
                                : `${classes.labelRoot}`
                        }
                        onClick={event => handleClick(event, item, variant)}
                    >
                        <LabelIcon className={classes.labelIcon} />
                        <Typography variant="body2" className={classes.labelText}>
                            {variant === TreeVariant.PLOT &&
                            item?.data?.type === TreeDataType.VERSION
                                ? `V${item?.data?.version} - ${labelText}`
                                : labelText}
                        </Typography>
                    </span>
                )
            }
            classes={{
                root: classes.root,
                content: classes.content,
                expanded: classes.expanded,
                selected: classes.selected,
                group: classes.group,
                label: classes.label,
            }}
            {...other}
        />
    );
}

export default StyledTreeItem;

import { defineMessages } from 'react-intl';

export default defineMessages({
    export: {
        id: 'page.viewer.navigationMenu.export',
        defaultMessage: 'Export',
    },
    fileBrowser: {
        id: 'page.viewer.navigationMenu.fileBrowser',
        defaultMessage: 'File browser',
    },
    adjustGeometry: {
        id: 'page.viewer.navigationMenu.adjustGeometry',
        defaultMessage: 'Adjust geometry',
    },
    // scanningSession: {
    //     id: 'page.viewer.navigationMenu.scanningSession',
    //     defaultMessage: 'Scanning session',
    // },
    editingSession: {
        id: 'page.viewer.navigationMenu.editingSession',
        defaultMessage: 'Editing session',
    },
    issues: {
        id: 'page.viewer.navigationMenu.issues',
        defaultMessage: 'Issues',
    },
    commissioning: {
        id: 'page.viewer.navigationMenu.commissioning',
        defaultMessage: 'Commissioning',
    },
    filter: {
        id: 'page.viewer.navigationMenu.filter',
        defaultMessage: 'Filter',
    },
    markup: {
        id: 'page.viewer.navigationMenu.markup',
        defaultMessage: 'Markup',
    },
    assets: {
        id: 'page.viewer.navigationMenu.assets',
        defaultMessage: 'Assets',
    },
    help: {
        id: 'page.viewer.navigationMenu.help',
        defaultMessage: 'Help',
    },
    // menu: {
    //     id: 'page.viewer.navigationMenu.menu',
    //     defaultMessage: 'Menu',
    // },
    logout: {
        id: 'page.viewer.navigationMenu.logout',
        defaultMessage: 'Logout',
    },
    unsavedOperationsExists: {
        id: 'page.viewer.navigationMenu.unsavedOperationsExists',
        defaultMessage:
            'You have some unsaved scheduled operations. All changes will wait here untill saved or discarded',
    },
});

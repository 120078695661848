import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

export interface IFormDatepickerProps {
    value?: string;
    onChange: (date: string | undefined) => void;
}

const DueDatePicker: React.FC<IFormDatepickerProps> = ({ value, onChange }) => {
    const dateFormat = 'YYYY-MM-DDTHH:mm:ss';

    const [newValue, setNewValue] = useState<string>();
    useEffect(() => {
        setNewValue(value);
    }, [value]);

    const handleDateChange = date => {
        const formattedDate = dayjs(date).format(dateFormat);
        setNewValue(formattedDate);

        // if formattedDate invalid
        if (!dayjs(formattedDate).isValid()) {
            setNewValue(undefined);
            onChange(undefined);
            return;
        }

        onChange(formattedDate);
    };
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker value={dayjs(newValue)} onChange={handleDateChange} format="DD.MM.YYYY" />
        </LocalizationProvider>
    );
};

export default DueDatePicker;

import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { translateApi } from '../../packages/Api/data/translation/client';
import { RootState, store } from '../store';
import { addNewTranslation, onKeyExists, selectTranslation } from './translationsSlice';
import { ITranslationCache } from './types';

/**
 * Represents a class that uses translation.
 * @interface
 */
interface IUseTranslation {
    id: string;
    defaultMessage: string;
    vars?: { [key: string]: number | string };
}

/**
 * Returns the whole translations object.
 *
 * @returns {ITranslationCache} - The current translations.
 */
export const useTranslations = (): ITranslationCache => {
    return useSelector((state: RootState) => state.translations);
};

/**
 * Translates a given message based on the provided ID, defaultMessage, and variables.
 * If the translation does not exist, it will attempt to create a new translation.
 *
 * @param {IUseTranslation} params - The parameters for translating the message.
 * @param {string} params.id - The ID of the message.
 * @param {string} params.defaultMessage - The default message if no translation is found.
 * @param {object} params.vars - The variables to be used in the message.
 * @returns {string} - The translated message or the default message if no translation is found.
 */
export const useTranslation = ({ id, defaultMessage, vars }: IUseTranslation): string => {
    const dispatch = store.dispatch;
    const translation = useSelector((state: RootState) => selectTranslation(state, id));
    const keyExist = useSelector((state: RootState) => onKeyExists(state, id));
    const loaded = useSelector((state: RootState) => state.translations.loaded);
    const translations = useSelector((state: RootState) => state.translations.items);

    useEffect(() => {
        if (
            !translation &&
            defaultMessage &&
            id &&
            loaded &&
            !keyExist &&
            translations.length > 0
        ) {
            console.log('TRANSLATOR - creating new translation', { id }, { defaultMessage });
            translateApi
                .translationsCreatePost({ translationKey: id, defaultText: defaultMessage })
                .then(() => {
                    console.log('TRANSLATOR - creating complete');
                    dispatch(addNewTranslation(id));
                })
                .catch(error => {
                    console.error("TRANSLATOR - couldn't create translation", error);
                })
                .finally(() => {});
        }
    }, [id, defaultMessage, translation, keyExist, loaded]);

    let result = translation || defaultMessage || id;

    if (vars && Object.getOwnPropertyNames(vars).length > 0) {
        Object.getOwnPropertyNames(vars).map(property => {
            result = result.replace(`{${property}}`, vars[property].toString());
        });
    }

    return result;
};

/**
 * Returns the current language.
 *
 * @returns {string} - The current language.
 */
export const useCurrentLanguage = (): string => {
    return useSelector((state: RootState) => state.translations.currentLanguage);
};

import React from 'react';

// components.app
import componentsApp from '../../components/App/messages';
import componentsAssets from '../../components/Assets/messages';
import componentsDialogs from '../../components/DialogFactory/Feedback/messages';
import componentsNotificationDialog from '../../components/DialogFactory/NotificationDialog/messages';
import componentsWipDialog from '../../components/DialogFactory/WIP/messages';
import componentsFilter from '../../components/Filter/messages';
import componentsFloatingActionButton from '../../components/FloatingActionButton/messages';
import componentsFooter from '../../components/Footer/messages';
import componentsHeader from '../../components/Header/messages';
import componentsIssuesSidebar from '../../components/Issues/messagesSidebar';
import componentsIssues from '../../components/Issues/messagesTable';
import componentsLayout from '../../components/Layout/messages';
import componentsLockedModelInfo from '../../components/LockedModelInfo/messages';
import componentsMarkups from '../../components/Markups/messages';
import componentsMarkupsTools from '../../components/Markups/messagesTools';
import componentsNavigationMenu from '../../components/NavigationMenu/messages';
import componentsScanning from '../../components/Scanning/messages';
// ----------------------
// Other imports
import FormattedMessage from '../../components/Translation/FormattedMessage';
import LanguageSelector from '../../components/Translation/LanguageSelector';
import componentsTree from '../../components/Tree/messages';
import componentsViewSelector from '../../components/ViewSelector/messages';
import pagesNotFoundPage from '../../pages/NotFoundPage/messages';
import { useProfile } from '../../redux/keyclock';
// ----------------------
// Pages
import pagesProjectSetup from '../ProjectSetup/messages';
import pageViewerPage from '../ViewerPage/messages';

const TranslationsList: React.FC = () => {
    const profile = useProfile();

    const imports = {
        pagesProjectSetup,
        pagesNotFoundPage,
        pageViewerPage,
        componentsApp,
        componentsAssets,
        componentsDialogs,
        componentsNotificationDialog,
        componentsWipDialog,
        componentsFilter,
        componentsFloatingActionButton,
        componentsFooter,
        componentsHeader,
        componentsIssuesSidebar,
        componentsIssues,
        componentsLayout,
        componentsLockedModelInfo,
        componentsMarkups,
        // componentsMarkupsTools,
        componentsNavigationMenu,
        componentsScanning,
        componentsTree,
        componentsViewSelector,
    };

    if (profile?.email !== 'ml@iolabs.ch') {
        return <>no access</>;
    }

    return (
        <div className="h-full overflow-auto">
            <div className="relative">
                <div className="sticky top-0 bg-white px-20 py-10">
                    <LanguageSelector />
                </div>

                <div className="px-20">
                    {Object.entries(imports).map(([importKey, importValue]) => (
                        <div key={importKey} className="mb-10">
                            <div className="font-bold mb-2">{importKey}</div>
                            <div className="bg-gray-100 p-2 rounded">
                                {Object.entries(importValue).map(([key, value]) => (
                                    <div key={key} className="hover:bg-gray-200">
                                        <table className="w-full">
                                            <tbody>
                                                <tr>
                                                    <td className="w-1/2 pr-3">{key}</td>
                                                    <td className="w-1/2">
                                                        <FormattedMessage {...value} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TranslationsList;

import { createListenerMiddleware } from '@reduxjs/toolkit';

import {
    enrichFolderIds,
    mergeNodesToTreeState,
    setDataAsLoaded,
} from '../../components/Tree/Node';
import { dataManagementApi } from '../../packages/Api/data/tree/client';
import { ITree, ITreeFail, TreeDataType } from '../../packages/Api/data/tree/types';
import { store } from '../store';
import {
    onLoadData,
    onLoadDataDone,
    onLoadDataFail,
    onLoadDataLoadingDone,
    onLoadNode,
    onLoadNodeDone,
    onLoadNodeFail,
    selectPlotData,
} from './treeSlice';

export const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
    actionCreator: onLoadNode,
    effect: async (action, listenerApi) => {
        const { token, variant, nodeId, depth, parentNodeType, filter } = action.payload;

        let treeData: ITree[] = [];

        const plotDataState = store.getState().tree.plot.data
            ? [...store.getState().tree.plot.data]
            : [];

        dataManagementApi
            .dataManagementTreePost(nodeId, depth, filter)
            .then(treeRoot => {
                let data = treeRoot.data?.children as ITree['children'];

                if (parentNodeType === TreeDataType.PROJECT) {
                    // skip project files level and directly load all children's files/folders
                    treeData = (
                        data?.[0]?.children?.length !== 0 ? data?.[0]?.children : []
                    ) as ITree[];
                } else {
                    // select tree state
                    treeData = plotDataState;
                }

                // set new data as loaded
                data = setDataAsLoaded(data) as ITree[];

                data = enrichFolderIds(
                    data,
                    parentNodeType === TreeDataType.PROJECT
                        ? ({ pathIds: [nodeId] } as unknown as ITree)
                        : undefined,
                );

                // merge new data from server to specific place in tree
                const mergedTreeData = treeData
                    ? mergeNodesToTreeState(nodeId, data, treeData)
                    : data;

                return listenerApi.dispatch(onLoadNodeDone({ variant, tree: mergedTreeData }));
            })
            .catch(error => {
                console.log(error);
                return listenerApi.dispatch(onLoadNodeFail({ variant, error: error }));
            });
    },
});

listenerMiddleware.startListening({
    actionCreator: onLoadData,
    effect: async (action, listenerApi) => {
        console.log('redux tree middleware: onLoadData');

        const { variant, token, parentId, nodeId, rootId, depth } = action.payload;

        if (parentId && depth) {
            dataManagementApi
                .dataManagementTreeManualPost(parentId, nodeId, rootId, depth)
                .then(response => {
                    return listenerApi.dispatch(
                        onLoadDataDone({
                            variant,
                            tree: response.data?.children as ITree[],
                        }),
                    );
                })
                .catch(error => {
                    return listenerApi.dispatch(onLoadDataFail({ variant, error: error }));
                });
        } else {
            listenerApi.dispatch(onLoadDataLoadingDone({ variant }));
        }
    },
});

import { Box } from '@mui/material';
import React from 'react';

import FormattedMessage from '../../components/Translation/FormattedMessage';
import { SearchResultFile } from '../../generate/api';
import messages from './messages';
import useStyles from './styles';

interface IModalProps {
    file: SearchResultFile;
    onClick: (file: SearchResultFile) => void;
}

const FileTile: React.FC<IModalProps> = ({ file, onClick }) => {
    const classes = useStyles();
    let lastOpened = '';

    if (file.lastOpened) {
        const date = new Date(file.lastOpened);
        lastOpened = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
    }

    return (
        <>
            {file && (
                <Box className={classes.fileTileWrapper} onClick={() => onClick(file)}>
                    <Box className={classes.fileTileIconWrapper}>
                        <Box className={classes.fileTileIconVersion}>
                            <span>
                                <strong>v{file.versionNumber}</strong>
                            </span>
                        </Box>
                    </Box>
                    <Box className={classes.fileTileContent}>
                        <Box>
                            <strong>{file.fileName}</strong>
                        </Box>
                        <Box>
                            <span className="text-sm">
                                <FormattedMessage {...messages.project} />: {file.projectName}
                            </span>
                        </Box>
                        <Box>
                            <span className="text-sm">
                                <FormattedMessage {...messages.lastOpened} />:{' '}
                                {lastOpened && lastOpened.length > 0 ? lastOpened : 'never'}
                            </span>
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default FileTile;

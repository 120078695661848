import { defineMessages } from 'react-intl';

export default defineMessages({
    selectFile: {
        id: 'pages.projectSetup.title.selectFile',
        defaultMessage: 'Select file',
    },
    buttonRecentFile: {
        id: 'pages.projectSetup.button.recentFiles',
        defaultMessage: 'Recent files',
    },
    buttonSelectFile: {
        id: 'pages.projectSetup.button.selectFile',
        defaultMessage: 'Select file',
    },
    project: {
        id: 'pages.projectSetup.title.project',
        defaultMessage: 'Project',
    },
    loadingProjects: {
        id: 'pages.projectSetup.message.loadingProjects',
        defaultMessage: 'Loading projects',
    },
    recentFiles: {
        id: 'pages.projectSetup.message.recentFiles',
        defaultMessage: 'Recent files',
    },
});

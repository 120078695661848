import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { handleHttpErrors } from '../Api/errorHandler';
import { IPuppeteerTokenResponseData } from './types';

// eslint-disable-next-line @typescript-eslint/naming-convention
const _global = (window /* browser */ || global) /* node */ as any;

export const registerPuppetterTokenInfo = (
    apiClient: AxiosInstance,
    keycloakInstance: Keycloak.KeycloakInstance,
) => {
    _global.getPuppeteerKey = async (): Promise<AxiosResponse<IPuppeteerTokenResponseData>> => {
        const requestConfig: AxiosRequestConfig = {
            headers: {
                Authorization: `Bearer ${keycloakInstance.token}`,
            },
            method: 'POST',
            url: '/Puppeteer/GetTokenInfo',
        };
        return apiClient
            .request(requestConfig)
            .then(response => Promise.resolve(response.data))
            .catch((error: AxiosError) => handleHttpErrors(error));
    };
};

import { RealWorldCoordinates } from '../../components/Viewer/extensions/ToolbarTransform';
import {
    ModelAsset,
    ModelLock,
    ModelLockElement,
    ObjectMarkup,
    Sensor,
    UserInfo,
} from '../../generate/api';
import PropertyResult = Autodesk.Viewing.PropertyResult;

export interface IViewableElement {
    externalId: string;
    name: string;
}

export interface IGlobalOverlay {
    show: boolean;
    text: string;
}

export interface IAssetSettings {
    pageLimit: number;
    sortColumn: string;
    sortOrder: ISortOrder;
    disableChangeSelection: boolean;
}

export type ISelectedAsset = string | undefined;

export type ISortOrder = 'asc' | 'desc';

export interface IViewerItems {
    activeItems?: any[];
    inactiveItems?: any[];
    selectedItem?: any;
}

export type ISerialNumberStatus = 'ready' | 'notFilled' | 'inProgress' | undefined;

export interface IPoint3 {
    x: number;
    y: number;
    z: number;
}

export interface IFileElementMove {
    deltaClient: IPoint3;
    deltaWorld: IPoint3;
    deltaWorldReal: IPoint3;
    scheduled?: boolean;
    moveOperationTranslated?: IPoint3;
    targetRealWorldCoords: RealWorldCoordinates;
    targetWorldPoint: THREE.Vector3;
}

export interface IFileElementCreate {
    targetWorldPoint: THREE.Vector3;
    scheduled?: boolean;
    sourceElementTemplate: Sensor;
    moveOperationTranslated?: IPoint3; // TODO is this needed?
    targetRealWorldCoords: RealWorldCoordinates;
}

export interface IFileElementCopy {
    targetWorldPoint: THREE.Vector3;
    scheduled?: boolean;
    sourceElementId?: string;
    targetRealWorldCoords: RealWorldCoordinates;
}

export interface IFileElementRotate {
    angle?: number;
    angleRad?: number;
    scheduled?: boolean;
    rotateAxis: THREE.Vector3;
}

export interface IFileElementRemoval {
    delete?: boolean;
    scheduled?: boolean;
}

export interface IFileElementStatus extends ModelLockElement {
    serialNumberStatus?: ISerialNumberStatus; // todo deprecated
    isLocked?: boolean; // todo deprecated
    hasSerialNumber?: boolean; // todo deprecated
    move?: IFileElementMove;
    copy?: IFileElementCopy;
    rotate?: IFileElementRotate;
    create?: IFileElementCreate;
    delete?: IFileElementRemoval;
    modifications?: any;
}

export interface IFileGeometryElementStatus {
    //Source origin - the origiinal position of the element
    origin?: THREE.Vector2;
    //Target position - position to move the element to
    move?: IFileElementMove;
    //Copy position - the position to copy the element to + source id
    copy?: IFileElementCopy;
    //Rotation - the rotation of the element
    rotate?: IFileElementRotate;
    //Target position - position where element should be created
    create?: IFileElementCreate;
    //Is element deleted
    delete?: IFileElementRemoval;
}

export interface IFileElementsStatus {
    [key: string]: IFileElementStatus;
}

export interface IVersion {
    id: string;
    text: string;
    data: {
        createdTime: string;
        createdUsername: string;
        fileSize: number;
        fileType: string;
        lastModifiedTime: string;
        lastModifiedUsername: string;
        storageId: string;
        type: string;
        urn: string;
        version: number;
    };
}

export type IHubId = string;

export interface IProject {
    type: string;
    id: string;
    name: string;
    isAssetSyncSupported: boolean;
}

export type ICurrentViewable = Autodesk.Viewing.BubbleNode['data'];

export interface IFile {
    // Current version
    currentVersion?: IVersion; // in use

    // components
    viewableElements?: IViewableElement[]; // in use
    selectedAsset?: ISelectedAsset; // in use
    selectedAssetProps?: PropertyResult; // in use

    currentViewable?: ICurrentViewable;

    // for list of elements in viewable and their status ("locked" etc..)
    elementsStatus: IFileElementsStatus; // wip - in use
}

export interface IBluetoothDevice {
    name: string;
    address: string;
    id: string;
    class: number;
}

export interface ISelectedMarkup extends ObjectMarkup {
    content: string;
    fromIssue?: boolean;
}

export interface IMarkupsState {
    list: ObjectMarkup[];
    selected?: ISelectedMarkup;
    isEdited?: boolean;
    onSetActiveMarkupID?: number | null | undefined;
    loading?: boolean;
}

export interface IMenu {
    exportOpen: boolean;
    filterOpen: boolean;
    assetsOpen: boolean;
    fileBrowserOpen: boolean;
    scanningOpen: boolean;
    commissioningOpen: boolean;
    markupsOpen: boolean;
    issuesOpen: boolean;
}

export interface IProjectState {
    hubId: IHubId;
    project?: IProject;
    file: IFile;
    menu: IMenu;
    sidebar: {
        open: boolean; // todo - not implemented
    };
    lockedModelInfo?: ModelLock;
    modelAssetStatuses?: ModelAsset[];
    assetsSettings: IAssetSettings; // in use
    globalOverlay: IGlobalOverlay; // in use
    viewerItems: IViewerItems; // todo - not implemented
    bluetooth: {
        selectedDevice?: IBluetoothDevice;
    };
    markups: IMarkupsState;
    user?: IUser;
}

export interface IAssetSettings {
    pageLimit: number;
    sortColumn: string;
    sortOrder: ISortOrder;
    disableChangeSelection: boolean;
}

export interface IViewerItems {
    activeItems?: any[];
    inactiveItems?: any[];
    selectedItem?: any;
}

export interface IUser extends UserInfo {
    hasFireBimRole?: boolean;
}

export enum UserRoles {
    ProjectManagerManager = 'SI FIS Manager',
    ProjectManagerProductManager = 'SI FIS Produktmanager/Productmanager',
    ProjectManagerChefProject = 'SI FIS Projektleiter/Chef Projet',
    ProjectManagerVendeur = 'SI FIS Verkäufer/Vendeur',
    // --
    Modeller = 'SI FIS Modellierer/Modeleur',
    // --
    TechnicianTechnicien = 'SI FIS Techniker/Technicien',
    TechnicianExpert = 'SI BIM Experte/Expert',
    TechnicianResponsible = 'SI BIM Verantwortlicher/Responsible',
    // --
    Electrician = 'EXT FIS ELE Monteur',
}
export const roleElectrician: string[] = [UserRoles.Electrician];
export const roleTechnician: string[] = [
    UserRoles.TechnicianTechnicien,
    UserRoles.TechnicianExpert,
    UserRoles.TechnicianResponsible,
];
export const roleProjectManager: string[] = [
    UserRoles.ProjectManagerManager,
    UserRoles.ProjectManagerProductManager,
    UserRoles.ProjectManagerChefProject,
    UserRoles.ProjectManagerVendeur,
];
export const roleModeller: string[] = [UserRoles.Modeller];

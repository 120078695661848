/* tslint:disable */
/* eslint-disable */
/**
 * FireBIM API
 * API definitions for FireBim
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { TextFilter } from '../models';
// @ts-ignore
import { TreeNode } from '../models';
// @ts-ignore
import { ViewerFileInfo } from '../models';
/**
 * ForgeDataManagementApi - axios parameter creator
 * @export
 */
export const ForgeDataManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets newest file derivative urn for forge file.
         * @param {string} urn Forge file URN
         * @param {string} [projectId] Forge project ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataManagementDerivativeUrnNewestGet: async (urn: string, projectId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'urn' is not null or undefined
            assertParamExists('dataManagementDerivativeUrnNewestGet', 'urn', urn)
            const localVarPath = `/DataManagement/Derivative/{urn}/Newest`
                .replace(`{${"urn"}}`, encodeURIComponent(String(urn)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets file info for viewever.
         * @param {string} urn Forge file or version URN
         * @param {string} [projectId] Forge project ID
         * @param {boolean} [fetchLatest] Indicates whether to fetch latest version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataManagementDerivativeUrnViewerGet: async (urn: string, projectId?: string, fetchLatest?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'urn' is not null or undefined
            assertParamExists('dataManagementDerivativeUrnViewerGet', 'urn', urn)
            const localVarPath = `/DataManagement/Derivative/{urn}/Viewer`
                .replace(`{${"urn"}}`, encodeURIComponent(String(urn)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (fetchLatest !== undefined) {
                localVarQueryParameter['fetchLatest'] = fetchLatest;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns file specified by manual node definition.
         * @param {string} [nodeType] Tree node type
         * @param {string} [parentId] Parent node ID
         * @param {string} [nodeId] Item ID
         * @param {boolean} [inline] If true file name will be set to output file stream
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataManagementDownloadManualGet: async (nodeType?: string, parentId?: string, nodeId?: string, inline?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/DataManagement/DownloadManual`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (nodeType !== undefined) {
                localVarQueryParameter['nodeType'] = nodeType;
            }

            if (parentId !== undefined) {
                localVarQueryParameter['parentId'] = parentId;
            }

            if (nodeId !== undefined) {
                localVarQueryParameter['nodeId'] = nodeId;
            }

            if (inline !== undefined) {
                localVarQueryParameter['inline'] = inline;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets issues count.
         * @param {string} nodeId Tree node ID
         * @param {boolean} [force] Force fetch, recache
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataManagementIssuesNodeIdCountGet: async (nodeId: string, force?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('dataManagementIssuesNodeIdCountGet', 'nodeId', nodeId)
            const localVarPath = `/DataManagement/Issues/{nodeId}/Count`
                .replace(`{${"nodeId"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets thumbnail.
         * @param {string} nodeId Tree node ID
         * @param {number} [width] Thumbnail width
         * @param {number} [height] Thumbnail height
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataManagementThumbnailNodeIdGet: async (nodeId: string, width?: number, height?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('dataManagementThumbnailNodeIdGet', 'nodeId', nodeId)
            const localVarPath = `/DataManagement/Thumbnail/{nodeId}`
                .replace(`{${"nodeId"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * NodeId is Base64 encoded json of IoLabs.Forge.DataManagement.Tree.NodeDefinition and \'NodeId\' property represents ForgeID of \'Type\' item (example shows ForgeHubID, RootID is ForgeHubID if ParentID is not null).              <pre>              {\"Type\":\"hub\",\"NodeId\":\"b.58c08b5c-0cfb-47a5-8ab9-a1062de0a490\",\"ParentId\":null,\"RootId\":null}              </pre>.
         * @summary Gets tree nodes manually.
         * @param {string} [nodeType] Node type
         * @param {string} [parentId] Parent node ID
         * @param {string} [nodeId] Current node ID
         * @param {number} [depth] Nesting depth to load (Hubs &#x3D; 1)
         * @param {string} [rootId] Root node ID
         * @param {{ [key: string]: TextFilter; }} [requestBody] Filters to apply. The keys are node types where to apply the filter to. Currently only folder and item supported.&lt;br /&gt;&lt;pre&gt;  {    \&quot;folder\&quot;: {      \&quot;include\&quot;: \&quot;Project Files\&quot;,      \&quot;depth\&quot;: 1    },    \&quot;item\&quot;: {      \&quot;exclude\&quot;: \&quot;*.rvt\&quot;,      \&quot;depth\&quot;: 3    }  }  &lt;/pre&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataManagementTreeManualPost: async (nodeType?: string, parentId?: string, nodeId?: string, depth?: number, rootId?: string, requestBody?: { [key: string]: TextFilter; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/DataManagement/Tree/Manual`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (nodeType !== undefined) {
                localVarQueryParameter['nodeType'] = nodeType;
            }

            if (parentId !== undefined) {
                localVarQueryParameter['parentId'] = parentId;
            }

            if (nodeId !== undefined) {
                localVarQueryParameter['nodeId'] = nodeId;
            }

            if (depth !== undefined) {
                localVarQueryParameter['depth'] = depth;
            }

            if (rootId !== undefined) {
                localVarQueryParameter['rootId'] = rootId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * NodeId is Base64 encoded json of IoLabs.Forge.DataManagement.Tree.NodeDefinition and \'NodeId\' property represents ForgeID of \'Type\' item (example shows ForgeHubID, RootID is ForgeHubID if ParentID is not null).              <pre>              {\"Type\":\"hub\",\"NodeId\":\"b.58c08b5c-0cfb-47a5-8ab9-a1062de0a490\",\"ParentId\":null,\"RootId\":null}              </pre>.
         * @summary Gets tree nodes or branch nodes.
         * @param {string} [nodeId] Tree node ID
         * @param {number} [depth] Depth of recursion
         * @param {{ [key: string]: TextFilter; }} [requestBody] Filters to apply. The keys are node types where to apply the filter to. Currently only folder and item supported.&lt;br /&gt;&lt;pre&gt;  {    \&quot;folder\&quot;: {      \&quot;include\&quot;: \&quot;Project Files\&quot;,      \&quot;depth\&quot;: 1    },    \&quot;item\&quot;: {      \&quot;exclude\&quot;: \&quot;*.rvt\&quot;,      \&quot;depth\&quot;: 3    }  }  &lt;/pre&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataManagementTreePost: async (nodeId?: string, depth?: number, requestBody?: { [key: string]: TextFilter; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/DataManagement/Tree`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (nodeId !== undefined) {
                localVarQueryParameter['nodeId'] = nodeId;
            }

            if (depth !== undefined) {
                localVarQueryParameter['depth'] = depth;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets Token for viewer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataManagementViewerTokenGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/DataManagement/Viewer/Token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForgeDataManagementApi - functional programming interface
 * @export
 */
export const ForgeDataManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForgeDataManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets newest file derivative urn for forge file.
         * @param {string} urn Forge file URN
         * @param {string} [projectId] Forge project ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataManagementDerivativeUrnNewestGet(urn: string, projectId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataManagementDerivativeUrnNewestGet(urn, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets file info for viewever.
         * @param {string} urn Forge file or version URN
         * @param {string} [projectId] Forge project ID
         * @param {boolean} [fetchLatest] Indicates whether to fetch latest version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataManagementDerivativeUrnViewerGet(urn: string, projectId?: string, fetchLatest?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViewerFileInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataManagementDerivativeUrnViewerGet(urn, projectId, fetchLatest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns file specified by manual node definition.
         * @param {string} [nodeType] Tree node type
         * @param {string} [parentId] Parent node ID
         * @param {string} [nodeId] Item ID
         * @param {boolean} [inline] If true file name will be set to output file stream
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataManagementDownloadManualGet(nodeType?: string, parentId?: string, nodeId?: string, inline?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataManagementDownloadManualGet(nodeType, parentId, nodeId, inline, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets issues count.
         * @param {string} nodeId Tree node ID
         * @param {boolean} [force] Force fetch, recache
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataManagementIssuesNodeIdCountGet(nodeId: string, force?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataManagementIssuesNodeIdCountGet(nodeId, force, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets thumbnail.
         * @param {string} nodeId Tree node ID
         * @param {number} [width] Thumbnail width
         * @param {number} [height] Thumbnail height
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataManagementThumbnailNodeIdGet(nodeId: string, width?: number, height?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataManagementThumbnailNodeIdGet(nodeId, width, height, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * NodeId is Base64 encoded json of IoLabs.Forge.DataManagement.Tree.NodeDefinition and \'NodeId\' property represents ForgeID of \'Type\' item (example shows ForgeHubID, RootID is ForgeHubID if ParentID is not null).              <pre>              {\"Type\":\"hub\",\"NodeId\":\"b.58c08b5c-0cfb-47a5-8ab9-a1062de0a490\",\"ParentId\":null,\"RootId\":null}              </pre>.
         * @summary Gets tree nodes manually.
         * @param {string} [nodeType] Node type
         * @param {string} [parentId] Parent node ID
         * @param {string} [nodeId] Current node ID
         * @param {number} [depth] Nesting depth to load (Hubs &#x3D; 1)
         * @param {string} [rootId] Root node ID
         * @param {{ [key: string]: TextFilter; }} [requestBody] Filters to apply. The keys are node types where to apply the filter to. Currently only folder and item supported.&lt;br /&gt;&lt;pre&gt;  {    \&quot;folder\&quot;: {      \&quot;include\&quot;: \&quot;Project Files\&quot;,      \&quot;depth\&quot;: 1    },    \&quot;item\&quot;: {      \&quot;exclude\&quot;: \&quot;*.rvt\&quot;,      \&quot;depth\&quot;: 3    }  }  &lt;/pre&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataManagementTreeManualPost(nodeType?: string, parentId?: string, nodeId?: string, depth?: number, rootId?: string, requestBody?: { [key: string]: TextFilter; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TreeNode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataManagementTreeManualPost(nodeType, parentId, nodeId, depth, rootId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * NodeId is Base64 encoded json of IoLabs.Forge.DataManagement.Tree.NodeDefinition and \'NodeId\' property represents ForgeID of \'Type\' item (example shows ForgeHubID, RootID is ForgeHubID if ParentID is not null).              <pre>              {\"Type\":\"hub\",\"NodeId\":\"b.58c08b5c-0cfb-47a5-8ab9-a1062de0a490\",\"ParentId\":null,\"RootId\":null}              </pre>.
         * @summary Gets tree nodes or branch nodes.
         * @param {string} [nodeId] Tree node ID
         * @param {number} [depth] Depth of recursion
         * @param {{ [key: string]: TextFilter; }} [requestBody] Filters to apply. The keys are node types where to apply the filter to. Currently only folder and item supported.&lt;br /&gt;&lt;pre&gt;  {    \&quot;folder\&quot;: {      \&quot;include\&quot;: \&quot;Project Files\&quot;,      \&quot;depth\&quot;: 1    },    \&quot;item\&quot;: {      \&quot;exclude\&quot;: \&quot;*.rvt\&quot;,      \&quot;depth\&quot;: 3    }  }  &lt;/pre&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataManagementTreePost(nodeId?: string, depth?: number, requestBody?: { [key: string]: TextFilter; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TreeNode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataManagementTreePost(nodeId, depth, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets Token for viewer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataManagementViewerTokenGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataManagementViewerTokenGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForgeDataManagementApi - factory interface
 * @export
 */
export const ForgeDataManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForgeDataManagementApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets newest file derivative urn for forge file.
         * @param {string} urn Forge file URN
         * @param {string} [projectId] Forge project ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataManagementDerivativeUrnNewestGet(urn: string, projectId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.dataManagementDerivativeUrnNewestGet(urn, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets file info for viewever.
         * @param {string} urn Forge file or version URN
         * @param {string} [projectId] Forge project ID
         * @param {boolean} [fetchLatest] Indicates whether to fetch latest version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataManagementDerivativeUrnViewerGet(urn: string, projectId?: string, fetchLatest?: boolean, options?: any): AxiosPromise<ViewerFileInfo> {
            return localVarFp.dataManagementDerivativeUrnViewerGet(urn, projectId, fetchLatest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns file specified by manual node definition.
         * @param {string} [nodeType] Tree node type
         * @param {string} [parentId] Parent node ID
         * @param {string} [nodeId] Item ID
         * @param {boolean} [inline] If true file name will be set to output file stream
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataManagementDownloadManualGet(nodeType?: string, parentId?: string, nodeId?: string, inline?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.dataManagementDownloadManualGet(nodeType, parentId, nodeId, inline, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets issues count.
         * @param {string} nodeId Tree node ID
         * @param {boolean} [force] Force fetch, recache
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataManagementIssuesNodeIdCountGet(nodeId: string, force?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.dataManagementIssuesNodeIdCountGet(nodeId, force, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets thumbnail.
         * @param {string} nodeId Tree node ID
         * @param {number} [width] Thumbnail width
         * @param {number} [height] Thumbnail height
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataManagementThumbnailNodeIdGet(nodeId: string, width?: number, height?: number, options?: any): AxiosPromise<void> {
            return localVarFp.dataManagementThumbnailNodeIdGet(nodeId, width, height, options).then((request) => request(axios, basePath));
        },
        /**
         * NodeId is Base64 encoded json of IoLabs.Forge.DataManagement.Tree.NodeDefinition and \'NodeId\' property represents ForgeID of \'Type\' item (example shows ForgeHubID, RootID is ForgeHubID if ParentID is not null).              <pre>              {\"Type\":\"hub\",\"NodeId\":\"b.58c08b5c-0cfb-47a5-8ab9-a1062de0a490\",\"ParentId\":null,\"RootId\":null}              </pre>.
         * @summary Gets tree nodes manually.
         * @param {string} [nodeType] Node type
         * @param {string} [parentId] Parent node ID
         * @param {string} [nodeId] Current node ID
         * @param {number} [depth] Nesting depth to load (Hubs &#x3D; 1)
         * @param {string} [rootId] Root node ID
         * @param {{ [key: string]: TextFilter; }} [requestBody] Filters to apply. The keys are node types where to apply the filter to. Currently only folder and item supported.&lt;br /&gt;&lt;pre&gt;  {    \&quot;folder\&quot;: {      \&quot;include\&quot;: \&quot;Project Files\&quot;,      \&quot;depth\&quot;: 1    },    \&quot;item\&quot;: {      \&quot;exclude\&quot;: \&quot;*.rvt\&quot;,      \&quot;depth\&quot;: 3    }  }  &lt;/pre&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataManagementTreeManualPost(nodeType?: string, parentId?: string, nodeId?: string, depth?: number, rootId?: string, requestBody?: { [key: string]: TextFilter; }, options?: any): AxiosPromise<TreeNode> {
            return localVarFp.dataManagementTreeManualPost(nodeType, parentId, nodeId, depth, rootId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * NodeId is Base64 encoded json of IoLabs.Forge.DataManagement.Tree.NodeDefinition and \'NodeId\' property represents ForgeID of \'Type\' item (example shows ForgeHubID, RootID is ForgeHubID if ParentID is not null).              <pre>              {\"Type\":\"hub\",\"NodeId\":\"b.58c08b5c-0cfb-47a5-8ab9-a1062de0a490\",\"ParentId\":null,\"RootId\":null}              </pre>.
         * @summary Gets tree nodes or branch nodes.
         * @param {string} [nodeId] Tree node ID
         * @param {number} [depth] Depth of recursion
         * @param {{ [key: string]: TextFilter; }} [requestBody] Filters to apply. The keys are node types where to apply the filter to. Currently only folder and item supported.&lt;br /&gt;&lt;pre&gt;  {    \&quot;folder\&quot;: {      \&quot;include\&quot;: \&quot;Project Files\&quot;,      \&quot;depth\&quot;: 1    },    \&quot;item\&quot;: {      \&quot;exclude\&quot;: \&quot;*.rvt\&quot;,      \&quot;depth\&quot;: 3    }  }  &lt;/pre&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataManagementTreePost(nodeId?: string, depth?: number, requestBody?: { [key: string]: TextFilter; }, options?: any): AxiosPromise<TreeNode> {
            return localVarFp.dataManagementTreePost(nodeId, depth, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets Token for viewer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataManagementViewerTokenGet(options?: any): AxiosPromise<string> {
            return localVarFp.dataManagementViewerTokenGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ForgeDataManagementApi - object-oriented interface
 * @export
 * @class ForgeDataManagementApi
 * @extends {BaseAPI}
 */
export class ForgeDataManagementApi extends BaseAPI {
    /**
     * 
     * @summary Gets newest file derivative urn for forge file.
     * @param {string} urn Forge file URN
     * @param {string} [projectId] Forge project ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForgeDataManagementApi
     */
    public dataManagementDerivativeUrnNewestGet(urn: string, projectId?: string, options?: AxiosRequestConfig) {
        return ForgeDataManagementApiFp(this.configuration).dataManagementDerivativeUrnNewestGet(urn, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets file info for viewever.
     * @param {string} urn Forge file or version URN
     * @param {string} [projectId] Forge project ID
     * @param {boolean} [fetchLatest] Indicates whether to fetch latest version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForgeDataManagementApi
     */
    public dataManagementDerivativeUrnViewerGet(urn: string, projectId?: string, fetchLatest?: boolean, options?: AxiosRequestConfig) {
        return ForgeDataManagementApiFp(this.configuration).dataManagementDerivativeUrnViewerGet(urn, projectId, fetchLatest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns file specified by manual node definition.
     * @param {string} [nodeType] Tree node type
     * @param {string} [parentId] Parent node ID
     * @param {string} [nodeId] Item ID
     * @param {boolean} [inline] If true file name will be set to output file stream
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForgeDataManagementApi
     */
    public dataManagementDownloadManualGet(nodeType?: string, parentId?: string, nodeId?: string, inline?: boolean, options?: AxiosRequestConfig) {
        return ForgeDataManagementApiFp(this.configuration).dataManagementDownloadManualGet(nodeType, parentId, nodeId, inline, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets issues count.
     * @param {string} nodeId Tree node ID
     * @param {boolean} [force] Force fetch, recache
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForgeDataManagementApi
     */
    public dataManagementIssuesNodeIdCountGet(nodeId: string, force?: boolean, options?: AxiosRequestConfig) {
        return ForgeDataManagementApiFp(this.configuration).dataManagementIssuesNodeIdCountGet(nodeId, force, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets thumbnail.
     * @param {string} nodeId Tree node ID
     * @param {number} [width] Thumbnail width
     * @param {number} [height] Thumbnail height
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForgeDataManagementApi
     */
    public dataManagementThumbnailNodeIdGet(nodeId: string, width?: number, height?: number, options?: AxiosRequestConfig) {
        return ForgeDataManagementApiFp(this.configuration).dataManagementThumbnailNodeIdGet(nodeId, width, height, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * NodeId is Base64 encoded json of IoLabs.Forge.DataManagement.Tree.NodeDefinition and \'NodeId\' property represents ForgeID of \'Type\' item (example shows ForgeHubID, RootID is ForgeHubID if ParentID is not null).              <pre>              {\"Type\":\"hub\",\"NodeId\":\"b.58c08b5c-0cfb-47a5-8ab9-a1062de0a490\",\"ParentId\":null,\"RootId\":null}              </pre>.
     * @summary Gets tree nodes manually.
     * @param {string} [nodeType] Node type
     * @param {string} [parentId] Parent node ID
     * @param {string} [nodeId] Current node ID
     * @param {number} [depth] Nesting depth to load (Hubs &#x3D; 1)
     * @param {string} [rootId] Root node ID
     * @param {{ [key: string]: TextFilter; }} [requestBody] Filters to apply. The keys are node types where to apply the filter to. Currently only folder and item supported.&lt;br /&gt;&lt;pre&gt;  {    \&quot;folder\&quot;: {      \&quot;include\&quot;: \&quot;Project Files\&quot;,      \&quot;depth\&quot;: 1    },    \&quot;item\&quot;: {      \&quot;exclude\&quot;: \&quot;*.rvt\&quot;,      \&quot;depth\&quot;: 3    }  }  &lt;/pre&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForgeDataManagementApi
     */
    public dataManagementTreeManualPost(nodeType?: string, parentId?: string, nodeId?: string, depth?: number, rootId?: string, requestBody?: { [key: string]: TextFilter; }, options?: AxiosRequestConfig) {
        return ForgeDataManagementApiFp(this.configuration).dataManagementTreeManualPost(nodeType, parentId, nodeId, depth, rootId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * NodeId is Base64 encoded json of IoLabs.Forge.DataManagement.Tree.NodeDefinition and \'NodeId\' property represents ForgeID of \'Type\' item (example shows ForgeHubID, RootID is ForgeHubID if ParentID is not null).              <pre>              {\"Type\":\"hub\",\"NodeId\":\"b.58c08b5c-0cfb-47a5-8ab9-a1062de0a490\",\"ParentId\":null,\"RootId\":null}              </pre>.
     * @summary Gets tree nodes or branch nodes.
     * @param {string} [nodeId] Tree node ID
     * @param {number} [depth] Depth of recursion
     * @param {{ [key: string]: TextFilter; }} [requestBody] Filters to apply. The keys are node types where to apply the filter to. Currently only folder and item supported.&lt;br /&gt;&lt;pre&gt;  {    \&quot;folder\&quot;: {      \&quot;include\&quot;: \&quot;Project Files\&quot;,      \&quot;depth\&quot;: 1    },    \&quot;item\&quot;: {      \&quot;exclude\&quot;: \&quot;*.rvt\&quot;,      \&quot;depth\&quot;: 3    }  }  &lt;/pre&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForgeDataManagementApi
     */
    public dataManagementTreePost(nodeId?: string, depth?: number, requestBody?: { [key: string]: TextFilter; }, options?: AxiosRequestConfig) {
        return ForgeDataManagementApiFp(this.configuration).dataManagementTreePost(nodeId, depth, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets Token for viewer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForgeDataManagementApi
     */
    public dataManagementViewerTokenGet(options?: AxiosRequestConfig) {
        return ForgeDataManagementApiFp(this.configuration).dataManagementViewerTokenGet(options).then((request) => request(this.axios, this.basePath));
    }
}

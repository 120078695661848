import { createStyles, makeStyles } from '@mui/styles';

import theme from '../../theme/Theme';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            position: 'relative',
            zIndex: 200,
            display: 'flex',
        },
        menuItem: {
            backgroundColor: '#f3f4f6',
            borderRadius: '4px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '34px',
            height: '34px',
            padding: '0',
            marginRight: '8px',
            cursor: 'pointer',
            '& svg path': {
                fill: theme.palette.primary.main,
            },
            '&:hover': {
                backgroundColor: '#e1e1e5',
            },
            '&:disabled': {
                backgroundColor: '#f3f4f6',
                cursor: 'default',
                '& svg path': {
                    fill: theme.palette.grey['500'],
                },
            },
            // [theme.breakpoints.up('lg')]: {
            //     marginLeft: theme.spacing(1),
            //     marginRight: '0',
            //     borderRadius: '6px',
            //     width: '45px',
            //     height: '45px',
            // },
        },
        headerUserName: {
            color: theme.palette.grey['600'],
            fontSize: theme.typography.pxToRem(15),
            padding: '8px 18px',
            fontWeight: 500,
        },
        divider: {
            backgroundColor: theme.palette.text.secondary,
            opacity: 0.2,
            height: '1px',
            border: 0,
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5),
        },
        menuItemLanguage: {
            display: 'flex',
            padding: '5px',
        },
        menuItemRow: {
            width: '100%',
            color: '#333',
            background: theme.palette.common.white,
            alignItems: 'center',
            justifyContent: 'start',
            marginTop: '1px',
            marginBottom: '1px',
            '& svg path': {
                fill: theme.palette.primary.main,
            },
        },
        menuItemActive: {
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            '& svg path': {
                fill: theme.palette.common.white,
            },
            '&:hover': {
                backgroundColor: theme.palette.primary.dark,
            },
        },
        menuItemRowIcon: {
            margin: '7px',
        },
        verticalMenu: {
            color: '#333',
            width: '210px',
            background: theme.palette.common.white,
            borderRadius: '4px',
            position: 'absolute',
            right: '6px',
            top: '52px',
            padding: '4px',
            boxShadow: '0 0 5px #00000038',
        },
    }),
);

export default useStyles;

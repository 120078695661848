import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';

interface IModalProps {
    opened: boolean;
    onConfirmClick: () => void;
    onCloseClick: () => void;
    title?: string;
    confirmButtonText?: string;
    cancelButtonText?: string;
    children: React.ReactNode;
}

const ConfirmModal: React.FC<IModalProps> = ({
    title,
    confirmButtonText,
    cancelButtonText,
    opened,
    onConfirmClick,
    onCloseClick,
    children,
}) => {
    return (
        <Dialog open={opened} maxWidth="xl" onClose={onCloseClick}>
            <DialogTitle>{title ? title : 'Dialog'}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" size="medium" onClick={onConfirmClick}>
                    {confirmButtonText ? confirmButtonText : 'Confirm'}
                </Button>
                <Button variant="outlined" color="primary" size="medium" onClick={onCloseClick}>
                    {cancelButtonText ? cancelButtonText : 'Close'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmModal;

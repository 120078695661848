import { createStyles, makeStyles } from '@mui/styles';

import theme from '../../theme/Theme';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            width: '100%',
            height: '100%',
            position: 'absolute',
            zIndex: 10,
            pointerEvents: 'none',
        },
        viewerWrapper: {
            position: 'relative',
            zIndex: 0,
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            '& .viewcubeWrapper': {
                top: '1px',
            },
            '& .adsk-viewing-viewer .adsk-toolbar-vertical': {
                width: '40px',
            },
            '& .adsk-button': {
                width: '19px',
                height: '19px',
            },
            '& #MarkupsGuiToolbar .markups-btn .adsk-button-icon svg': {
                width: '19px',
                height: '19px',
                '&>g': {
                    stroke: 'white',
                },
                '& path.char': {
                    fill: 'white',
                },
            },
            '& .adsk-viewing-viewer .adsk-button-icon': {
                paddingTop: '0!important',
            },
            '& .adsk-button>.toolbar-vertical-group.adsk-hidden': {
                pointerEvents: 'none',
            },
            '& #hitarea': {
                display: 'none !important',
            },
        },
        viewerWrapperInner: {
            position: 'relative',
            height: '100%',
            width: '100%',
            zIndex: 1,
        },
        globalOverlay: {
            position: 'fixed',
            top: '0',
            right: '0',
            bottom: '0',
            left: '0',
            background: 'rgba(0,0,0,0.42)',
            zIndex: 10000,
        },
        viewerHorizontalWrapper: {
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
        },
        viewerHorizontalWrapperInner: {
            position: 'relative',
            height: '100%',
        },
        sensorsOverlay: {
            position: 'absolute',
            pointerEvents: 'none',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            zIndex: 1,
        },
        boxOverlay: {
            position: 'absolute',
            pointerEvents: 'all',
        },

        '@keyframes pulse': {
            '0%': {
                transform: 'scale(1)',
            },
            '70%': {
                transform: 'scale(1.1)',
            },
            '100%': {
                transform: 'scale(1)',
            },
        },
        sensorSymbolOverlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(255,255,0,0.2)',
            zIndex: 1,
        },
        operationsInfo: {
            position: 'absolute',
            left: 'calc(100% + .5em)',
            bottom: 'calc(100% + .5em)',
            zIndex: 200,
            background: '#f0f0f0',
            color: 'black',
            width: 'fit-content',
            padding: '0.2em',
        },
        operationsInfoItem: {
            fontSize: theme.typography.pxToRem(10),
            whiteSpace: 'nowrap',
            ':not(:last-child)': {
                marginRight: '1em',
            },
        },
        rotatingControls: {
            position: 'absolute',
            left: '200%',
            top: '0',
            width: '48px',
            height: '24px',
            background: 'rgba(0,0,0,0.1)',
            zIndex: 200,
            margin: 0,
            display: 'flex',
            pointerEvents: 'auto',
            '& button': {
                margin: 0,
            },
        },
        sensorSymbolOverlayImage: {
            zIndex: 1,
            width: '100%',
            height: '100%',
        },

        highlighter: {
            width: '100%',
            height: '100%',
            background: 'rgba(0,255,255,0.2)',
            position: 'relative',
            boxSizing: 'border-box',
            zIndex: 20,
        },
        badge: {
            position: 'absolute',
            top: -5,
            right: -5,
            width: '20px',
            height: '20px',
            textAlign: 'center',
            borderRadius: '50%',
            fontWeight: 'bold',
            lineHeight: '25px',
            zIndex: 20,
        },
        badgeInProgress: {
            background: '#fff700',
            color: '#ffffff',
            border: '1px solid #000',
        },
        badgeReady: {
            background: '#03f303',
            color: '#000000',
            border: '1px solid #000',
        },
        badgeNotFilled: {
            background: '#ff0000',
            color: '#ffffff',
            border: '1px solid #000',
        },
        highlighterSelected: {
            animation: '$pulse 1s linear infinite',
            transform: 'scale(1)',
            borderRadius: '4%',
            boxShadow: '0 0 0 5px #44cccd',
            background: 'rgb(68 204 205 / 43%)',
        },
        badgeSelected: {
            top: -15,
            right: -15,
            border: '8px solid #000',
        },

        svgIcon: {
            width: '70%',
            height: '70%',
            position: 'absolute',
            top: 0,
            maxWidth: '50px',
            maxHeight: '50px',
            pointerEvents: 'none',
        },
    }),
);

export default useStyles;

import { defineMessages } from 'react-intl';

export default defineMessages({
    tableLinesPerPage: {
        id: 'components.assets.table.linesPerPage',
        defaultMessage: 'Lines per page',
    },

    // Titles
    titleComment: {
        id: 'components.assets.title.comment',
        defaultMessage: 'Comment',
    },
    tableLoading: {
        id: 'components.assets.table.loading',
        defaultMessage: 'Loading',
    },
    tableNoResults: {
        id: 'components.assets.table.noResults',
        defaultMessage: 'No results',
    },

    // Buttons
    buttonEdit: {
        id: 'components.assets.button.edit',
        defaultMessage: 'Edit',
    },
    buttonClose: {
        id: 'components.assets.button.close',
        defaultMessage: 'Close',
    },
    buttonSave: {
        id: 'components.assets.button.save',
        defaultMessage: 'Save',
    },
    buttonCancel: {
        id: 'components.assets.button.cancel',
        defaultMessage: 'Cancel',
    },

    // Errors
    errorUnableToUpdateAsset: {
        id: 'components.assets.error.unableToUpdateAsset',
        defaultMessage: 'Unable to update asset',
    },
});

import { createStyles, makeStyles } from '@mui/styles';

import theme from '../../theme/Theme';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
        },

        markup: {
            margin: '20px 20px 30px',
        },

        noMarkups: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: theme.palette.grey[300],
        },

        spinnerItem: {
            width: '40px',
            height: '40px',
            color: theme.palette.primary.main,
        },

        markupHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0 0 3px',
        },

        preview: {
            width: '100%',
            borderRadius: '5px',
            overflow: 'hidden',
            border: '2px solid #000',
            cursor: 'pointer',
            boxSizing: 'border-box',
            transition: 'opacity ease 200ms',
            '&:hover': {
                borderColor: theme.palette.primary.main,
            },
            '& img': {
                aspectRatio: '16/9',
                objectFit: 'cover',
                width: '100%',
                display: 'block',
            },
        },
        previewSelected: {
            borderColor: theme.palette.primary.main,
            boxShadow: '0 0 0 3px ' + theme.palette.primary.main,
        },

        popoverMenuOpener: {
            fontSize: '30px',
            position: 'relative',
            top: '-1px',
            lineHeight: '2px',
            height: '19px',
            padding: '0 3px',
            cursor: 'pointer',
            borderRadius: '4px',
            '&:hover': {
                background: '#ddd',
            },
        },
        popoverMenuItem: {
            cursor: 'pointer',
            padding: '3px 7px',
            '&:hover': {
                background: '#ddd',
            },
        },
        popover: {
            '& .MuiPopover-paper': {
                border: '1px solid #000',
            },
        },

        title: {
            display: 'table',
            margin: '0 auto 1rem',
            fontWeight: 'normal',
            color: theme.palette.primary.main,
        },

        hideMarkupButton: {
            position: 'absolute',
            background: '#31999a',
            color: 'white',
            textAlign: 'center',
            padding: '10px 20px',
            top: '10px',
            right: '45px',
            zIndex: 10,
            cursor: 'pointer',
            '&:hover': {
                background: '#006c6c',
            },
        },
    }),
);

export default useStyles;

import { Box, Skeleton } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import { useEditingState } from '../../redux/editing/hooks';
import { useProjectState } from '../../redux/project';
import { useTranslation } from '../../redux/translations/hook';
import { createTranslationKey } from '../Translation/utils';
import PropertiesEditor from './PropertiesEditor';
import useStyles from './styles';
import { IAttributesMap, IAttributesMapItem, IAttributesNames, IAttributeTable } from './types';

const TranslatedLabel: React.FC<{ columnName?: string }> = ({ columnName }) => {
    const translation = columnName
        ? useTranslation({
              id: createTranslationKey('assets.label', columnName),
              defaultMessage: columnName,
          })
        : '';

    return <>{translation}</>;
};

export const AttributesTable: React.FC<IAttributeTable> = ({
    editMode,
    setEditedAttributes,
    externalIdMap,
    viewer,
}) => {
    const classes = useStyles();

    const [attributes, setAttributes] = useState<IAttributesMap>();
    const [loading, setLoading] = useState<boolean>(false);
    const projectState = useProjectState();
    const editingState = useEditingState();

    useEffect(() => {
        setEditedAttributes(attributes);
    }, [attributes]);

    const [attributesData, setAttributesData] = useState<IAttributesMap>();

    useEffect(() => {
        if (editingState.selectedElementAttributes) {
            setLoading(false);
            setAttributesData(editingState.selectedElementAttributes);
        } else {
            setLoading(true);
        }

        // if this asset is scheduled as create
        const selectedAssetId = projectState?.file.selectedAsset;
        const scheduledOperations = editingState?.elementsScheduleOperations.find(
            item => item.elementID === selectedAssetId,
        );

        // if creating new element - enable editing for areaAddress, sectionAddress, zoneAddress
        // if scheduledOperations contains create operation
        if (scheduledOperations?.create || scheduledOperations?.copy) {
            // debugger;
            const data = editingState.selectedElementAttributes?.map(attribute => {
                // for these fields enable additional edit
                if (
                    attribute.attributeName === IAttributesNames.areaAddress ||
                    attribute.attributeName === IAttributesNames.sectionAddress ||
                    attribute.attributeName === IAttributesNames.zoneAddress
                ) {
                    return {
                        ...attribute,
                        editable: true,
                    };
                }
                return attribute;
            });
            setAttributesData(data);
        }

        // override attributes by copy adjust geometry
        if (
            editingState.onForceRewriteAttributesByCopy &&
            editingState.selectedElementAttributesCache
        ) {
            const data = editingState.selectedElementAttributes?.map(attribute => {
                const newAttribute = editingState.selectedElementAttributesCache?.find(
                    item => item.attributeName === attribute.attributeName,
                );
                if (newAttribute) {
                    // copy values from ancestor with scheduled changes
                    if (
                        attribute.attributeName === IAttributesNames.zoneCustomerText ||
                        attribute.attributeName === IAttributesNames.zonePlanNumber
                    ) {
                        return {
                            ...attribute,
                            ...newAttribute,
                            isForceRewrite: true,
                        };
                    }

                    // copy values from ancestor without scheduled changes
                    // for these fields enable additional edit
                    if (
                        attribute.attributeName === IAttributesNames.areaAddress ||
                        attribute.attributeName === IAttributesNames.sectionAddress ||
                        attribute.attributeName === IAttributesNames.zoneAddress
                    ) {
                        return {
                            ...attribute,
                            ...newAttribute,
                            isForceRewrite: true,
                            editable: true,
                        };
                    }
                }
                return attribute;
            });
            setAttributesData(data);
        }
    }, [
        editingState.selectedElementAttributes,
        editingState.selectedElementAttributesCache,
        editingState.onForceRewriteAttributesByCopy,
    ]);

    /*
     * For copied element we need to override/add attributes by ancestor attributes
     * 1. detect if element is copied
     * 2. get attributes from ancestor
     * 3. override attributes by ancestor attributes
     * */
    useEffect(() => {
        // detect if element is copied
        const selectedAssetId = projectState?.file.selectedAsset;
        const scheduledOperations = editingState?.elementsScheduleOperations.find(
            item => item.elementID === selectedAssetId,
        );
        if (scheduledOperations?.copy) {
            // get ancestor externalId
            const sourceElementId = scheduledOperations.copy.sourceElementId;
            const externalId = externalIdMap?.get(sourceElementId);

            if (!externalId) return;

            const isStringInEnum = (str: string): str is IAttributesNames => {
                return Object.values(IAttributesNames).includes(str as IAttributesNames);
            };

            // get attributes from viewer
            viewer?.model.getProperties(externalId, propsResult => {
                const originData: {
                    attributeName: string;
                    displayName: string;
                    displayValue: string | number;
                    value: string | number;
                }[] = [];

                // go over all properties and get only attributes
                propsResult.properties.forEach(prop => {
                    if (isStringInEnum(prop.attributeName)) {
                        originData.push({
                            attributeName: prop.attributeName,
                            displayName: prop.displayName,
                            displayValue: prop.displayValue,
                            value: prop.displayValue,
                        });
                    }
                });
                // console.log('originData', originData);
                // console.log('attributesData', attributesData);

                // go over all attributesData and override values which do not have set parameter "newValue"
                let data = attributesData?.map(attribute => {
                    // check if parameter "newValue" is set in attribute
                    if (attribute.hasOwnProperty('newValue')) {
                        return attribute;
                    } else {
                        // find attribute displayValue in originData
                        const originAttribute = originData.find(
                            item => item.attributeName === attribute.attributeName,
                        );
                        if (originAttribute) {
                            return {
                                ...originAttribute,
                                displayValue: attribute.displayValue,
                                value: originAttribute.value,
                            } as IAttributesMapItem;
                        } else {
                            return attribute;
                        }
                    }
                });

                // define editable attributes for copied element in edit mode
                if (editMode) {
                    data = data?.map(attribute => {
                        // for these fields enable additional edit
                        if (
                            attribute.attributeName === IAttributesNames.areaAddress ||
                            attribute.attributeName === IAttributesNames.sectionAddress ||
                            attribute.attributeName === IAttributesNames.zoneAddress ||
                            attribute.attributeName === IAttributesNames.zoneCustomerText ||
                            attribute.attributeName === IAttributesNames.zonePlanNumber
                        ) {
                            return {
                                ...attribute,
                                editable: true,
                            };
                        }
                        if (attribute.attributeName === IAttributesNames.serialNumber) {
                            return {
                                ...attribute,
                                editable: true,
                                isForceRewrite: true,
                            };
                        }
                        return attribute;
                    });
                }

                // setAttributesData only if data are different from attributesData
                if (_.isEqual(data, attributesData)) return;

                setAttributesData(data);
            });
        }
    }, [attributesData, editMode]);

    if (editMode)
        return (
            <>
                {loading ? (
                    <>Loading...</>
                ) : (
                    <>
                        {attributesData && (
                            <PropertiesEditor
                                attributes={attributesData}
                                setAttributes={setAttributes}
                            />
                        )}
                    </>
                )}
            </>
        );

    return (
        <>
            <Box className={classes.tableWrapper}>
                {loading ? (
                    <>
                        <Box
                            p={1}
                            display="flex"
                            flexDirection="column"
                            alignItems="flex-start"
                            mt={-1}
                        >
                            <Skeleton variant="text" width="80%" height={23} />
                            <Skeleton variant="text" width="60%" height={23} />
                            <Skeleton variant="text" width="70%" height={23} />
                            <Skeleton variant="text" width="50%" height={23} />
                            <Skeleton variant="text" width="90%" height={23} />
                            <Skeleton variant="text" width="50%" height={23} />
                            <Skeleton variant="text" width="80%" height={23} />
                            <Skeleton variant="text" width="60%" height={23} />
                            <Skeleton variant="text" width="90%" height={23} />
                            <Skeleton variant="text" width="70%" height={23} />
                        </Box>
                    </>
                ) : (
                    <>
                        <table className={classes.table}>
                            <tbody>
                                {attributesData?.map((attribute, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                {attribute.hasOwnProperty('newValue') && (
                                                    <Box className={classes.isEditedIcon}></Box>
                                                )}
                                            </td>
                                            <td className="align-top">
                                                <TranslatedLabel
                                                    columnName={attribute.displayValue}
                                                />
                                            </td>
                                            <td>
                                                {attribute.hasOwnProperty('newValue')
                                                    ? attribute.newValue
                                                    : attribute.value}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </>
                )}
            </Box>
        </>
    );
};

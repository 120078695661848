import { DispatchAction } from '@iolabs/redux-utils';
import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { FilterSchemaResult } from '../../generate/api';
import {
    onFilters as onAssetsFilters,
    useAssetsFilters,
    useAssetsFiltersLoading,
} from '../../redux/assets';
import Filter from '../Filter/Filter';
import { IFilterDefinition, IFilterGroup, IFilterType } from '../Filter/types';

const AssetsFilter: React.FC<{ fileVersionUrn?: string }> = ({ fileVersionUrn }) => {
    const { keycloak, initialized: keycloakInitialized } = useKeycloak();
    const dispatch = useDispatch<DispatchAction>();

    const assetsFiltersLoading: boolean = useAssetsFiltersLoading();
    const assetsFilters: FilterSchemaResult = useAssetsFilters();

    // // Filters already loaded in Assets.tsx
    // useEffect(() => {
    //     if (keycloak && keycloak.token && fileVersionUrn) {
    //         dispatch(
    //             onAssetsFilters({ token: keycloak.token, forgeFileVersionUrn: fileVersionUrn }),
    //         );
    //     }
    // }, [keycloak, keycloakInitialized, fileVersionUrn]);

    return (
        <div>
            <Filter
                type={IFilterType.ASSETS}
                filterGroups={assetsFilters?.filterGroups as IFilterGroup[]}
                filterDefinitions={assetsFilters?.filtersDefinitons as IFilterDefinition[]}
                loading={assetsFiltersLoading}
            />
        </div>
    );
};

export default AssetsFilter;

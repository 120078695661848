import { Box } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import {
    useClickAnyWhere,
    useEventListener,
    useIsomorphicLayoutEffect,
    useWindowSize,
} from 'usehooks-ts';

import useStyles, { maxHeight, minWidth } from './styles';

export interface IItemDef {
    code: string;
    name?: string;
    visible?: boolean;
}

export interface IContextMenuPosition {
    clientX: number;
    clientY: number;
}

export interface IContextMenu {
    targetElement: React.RefObject<HTMLElement>;
    items?: IItemDef[];
    itemTemplate?: (item: any) => string;
    displayed?: boolean;
    stylesOverride?: React.CSSProperties | null;
    disableRightClick?: boolean;
    onSetItems?: (items: IItemDef[] | undefined) => void;
}

const ContextMenu: React.FC<IContextMenu> = ({
    targetElement,
    items,
    itemTemplate,
    displayed,
    stylesOverride,
    disableRightClick,
    onSetItems,
}) => {
    const classes = useStyles();
    const winSize = useWindowSize();
    const contextMenuRef = useRef<HTMLElement>(null);

    const [contextMenuDisplayed, setContextMenuDisplayed] = useState<boolean>(false);
    const [contextMenuStyle, setContextMenuStyle] = useState<React.CSSProperties>({});

    useEffect(() => {
        if (!displayed) {
            handleHideContextMenu(contextMenuDisplayed);
        } else {
            setContextMenuDisplayed(true);
            setContextMenuStyle(stylesOverride || {});
        }
    }, [displayed]);

    const handleHideContextMenu = (triggerSetItems = false) => {
        setContextMenuDisplayed(false);
        setContextMenuStyle({});
        if (onSetItems && triggerSetItems) onSetItems(items);
    };

    const handleSelectCheckbox = (item: IItemDef) => {
        item.visible = !item.visible;
    };

    const handleContextMenu = (event: MouseEvent) => {
        if (disableRightClick) return;

        event.preventDefault();

        const targetCurrent: HTMLElement = targetElement.current as unknown as HTMLElement;
        if (!targetCurrent) {
            if (contextMenuDisplayed) handleHideContextMenu();
            return;
        }

        const { y, height } = targetCurrent.getBoundingClientRect();

        const clientYReduced = event.clientY - y;

        if (event.clientY < y || event.clientY > y + height) {
            if (contextMenuDisplayed) handleHideContextMenu();
            return;
        }

        setContextMenuDisplayed(!contextMenuDisplayed);

        const posTop = event.clientY > winSize.height - maxHeight ? 'auto' : clientYReduced + 'px';
        const posBottom =
            event.clientY > winSize.height - maxHeight ? winSize.height - y - 16 + 'px' : 'auto';
        const posLeft = event.clientX > winSize.width - minWidth ? 'auto' : event.clientX + 'px';
        const posRight =
            event.clientX > winSize.width - minWidth
                ? winSize.width - event.clientX + 'px'
                : 'auto';

        if (!contextMenuDisplayed) {
            const contextMenuPosition: React.CSSProperties = {
                top: posTop,
                left: posLeft,
                bottom: posBottom,
                right: posRight,
            };

            setContextMenuStyle(contextMenuPosition);
        } else {
            if (onSetItems) onSetItems(items);
            setContextMenuStyle({});
        }
    };

    useEventListener('contextmenu', handleContextMenu, targetElement);

    useClickAnyWhere(() => {
        if (!displayed && contextMenuDisplayed) handleHideContextMenu(true);
    });

    useIsomorphicLayoutEffect(() => {
        setContextMenuDisplayed(false);
    }, []);

    return !contextMenuDisplayed ? (
        <></>
    ) : (
        <Box className={classes.contextMenu} style={contextMenuStyle} ref={contextMenuRef}>
            {items?.map(item => (
                <div key={`data-col-${item.code}`}>
                    <label htmlFor={`data-col-${item.code}-check`}>
                        <input
                            type="checkbox"
                            id={`data-col-${item.code}-check`}
                            name={item.code}
                            defaultChecked={item.visible}
                            className={classes.inputCheck}
                            onClick={() => handleSelectCheckbox(item)}
                        />{' '}
                        {itemTemplate ? itemTemplate(item) : item.code}
                    </label>
                </div>
            ))}
        </Box>
    );
};

export default ContextMenu;

import { DispatchAction } from '@iolabs/redux-utils';
import { Backdrop, Box, Modal, Theme, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { createStyles, makeStyles } from '@mui/styles';
import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useHeaderStyles } from '../../components/Header/Header';
import useHeaderContent from '../../components/HeaderProvider/useHeaderContent';
import SignalRProvider from '../../components/SignalRProvider/SignalRProvider';
import { ViewerState } from '../../components/Viewer/types';
import { encodeUrnToBase64 } from '../../components/Viewer/utils';
import Viewer from '../../components/Viewer/Viewer';
import config from '../../config/config';
import { projectApi } from '../../packages/Api/data/projects/client';
import { dataManagementApi } from '../../packages/Api/data/tree/client';
import {
    setCurrentVersion,
    setProject,
    setUser,
    useGlobalOverlay,
    useProjectState,
} from '../../redux/project';
import { IVersion, UserRoles } from '../../redux/project/types';
import { useTranslation } from '../../redux/translations/hook';
import Page from '../Page/Page';
import { Path } from '../PageRouter';
import SingleLayout from '../ProjectSetup/SingleLayout';
import messages from './messages';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dashboardBox: {
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            flex: 1,
            height: '100%',
            overflow: 'auto',
            [theme.breakpoints.up('md')]: {
                flexFlow: 'row wrap',
                justifyContent: 'center',
                alignContent: 'center',
            },
        },
        dashboardItem: {
            height: '300px',
            width: '200px',
            margin: theme.spacing(1),
            display: 'flex',
            flexFlow: 'column nowrap',
            justifyContent: 'space-between',
            '&:last-child': {
                marginBottom: theme.spacing(4),
            },
            [theme.breakpoints.up('md')]: {
                height: '400px',
                width: '300px',
                margin: theme.spacing(2),
                '&:last-child': {
                    marginBottom: theme.spacing(2),
                },
            },
        },
    }),
);

const globalOverlayStyles = makeStyles(() =>
    createStyles({
        globalOverlay: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            color: 'white',
            fontSize: '1.2rem',
            userSelect: 'none',
        },
        globalOverlayInner: {
            padding: '2rem',
            borderRadius: '0.5rem',
            background: '#282828',
        },
        globalOverlayLoader: {
            display: 'table',
            margin: '0 auto 1rem',
        },
    }),
);

const parseModelInfo = (fileInfo: string): ViewerState | undefined => {
    try {
        const modelInfo = JSON.parse(atob(fileInfo));
        return {
            urn: encodeUrnToBase64(modelInfo.VersionUrn),
            isEmea: modelInfo.IsEMEA,
        };
    } catch (e) {
        console.error(e, 'Unable to parse file info');
        return undefined;
    }
};

const ViewerPage: React.FC = () => {
    const classes = useStyles();
    const headerClasses = useHeaderStyles();
    const dispatch = useDispatch<DispatchAction>();
    const history = useHistory();
    const { setHeaderContent } = useHeaderContent();
    const { keycloak, initialized: keycloakInitialized } = useKeycloak();
    const project = useProjectState();

    const [modelInfo, setModelInfo] = useState<ViewerState>();
    const [fileID, setFileID] = useState<string>();
    const [projectID, setProjectID] = useState<string>();

    // Testing
    // ====================================================================
    const testing = false;
    const versionForTest = 0;

    const testData = [
        {
            file: {
                id: 'eyJUeXBlIjoidmVyc2lvbiIsIk5vZGVJZCI6InVybjphZHNrLndpcHByb2Q6ZnMuZmlsZTp2Zi5NdGxtc0NTOFRKSzZyQjVkSmMteG93P3ZlcnNpb249OSIsIlBhcmVudElkIjoiYi4zOTM3YzI0Ni03NTRmLTQ0YTgtOTQ2MC01NGIzMDM1M2ZlMDgiLCJSb290SWQiOm51bGx9',
                text: 'CH_ZH_XXXX_Zurich_ioLabs - FireBIM App_V78_230724_NEW.rvt',
                data: {
                    type: 'version',
                    urn: 'urn:adsk.wipprod:fs.file:vf.MtlmsCS8TJK6rB5dJc-xow?version=10',
                    fileType: 'rvt',
                    version: 9,
                    extensionType: 'items:autodesk.bim360:C4RModel',
                    extensionTypeVersion: 'items:autodesk.bim360:C4RModel',
                    modelType: 'multiuser',
                    isCompositeDesign: false,
                    extractionStatus: 'Success',
                    processingStatus: 'ProcessingComplete',
                    reviewStatus: 'NotInReview',
                    fileSize: 24104960,
                    markupsCount: 1,
                    lastModifiedTime: '2023-09-20T12:53:21Z',
                    createdTime: '2023-09-20T12:53:19Z',
                    lastModifiedUsername: 'Martin Loucka',
                    createdUsername: 'YRZ6N48RHZFC',
                },
            },
            project: {
                type: 'Bim360',
                id: 'b.3937c246-754f-44a8-9460-54b30353fe08',
                name: 'CH_ZH_XXXX_Zürich_ioLabs - FireBIM App',
                isAssetSyncSupported: true,
            },
            user: {
                userID: '5ce7c558-84ce-4845-8217-f9699d31be40',
                email: 'ml@iolabs.ch',
                fullName: 'Martin Loucka',
                logName: 'Martin Loucka [5ce7c558-84ce-4845-8217-f9699d31be40]',
                roles: [],
                // forgeRoles: ['SI FIS Techniker/Technicien'],
                forgeRoles: [UserRoles.TechnicianTechnicien],
            },
        },
    ];

    useEffect(() => {
        // For selected model which needed to be tested - copy this object into testingConfig
        console.log('DEBUG - current file', {
            file: project.file.currentVersion,
            project: project.project,
        });
    }, [project.file.currentVersion]);

    useEffect(() => {
        if (testing) {
            setFileID(testData[versionForTest].file.data.urn);
            setProjectID(testData[versionForTest].project.id);
            dispatch(setCurrentVersion(testData[versionForTest].file as unknown as IVersion)); // project slice
            dispatch(setProject(testData[versionForTest].project)); // project slice
            dispatch(setUser(testData[versionForTest].user)); // project slice
        }
    }, []);
    // ====================================================================

    useEffect(() => {
        if (project?.file?.currentVersion?.data.urn && project.project?.id) {
            setFileID(project.file.currentVersion.data.urn);
            setProjectID(project.project.id);
        } else {
            if (!testing) {
                // redirect to root page if no project is selected
                history.push(Path.ROOT);
            }
        }

        return () => {
            // setModelInfo(undefined);
        };
    }, [project]);

    // Load user data after change file urn
    useEffect(() => {
        if (!project.project?.id) {
            dispatch(setUser({}));
            return;
        }

        projectApi
            .projectUserGet(project.project?.id)
            .then(response => {
                dispatch(setUser(response.data));
            })
            .catch(error => {
                console.error('FB: Unable to load project user data + response', error);
            });
    }, [project.file.currentVersion]);

    useEffect(() => {
        if (keycloak?.token && fileID && projectID) {
            dataManagementApi
                .dataManagementDerivativeUrnViewerGet(fileID, projectID, false)
                .then(response => {
                    setModelInfo({
                        api: response.data.api ?? undefined,
                        urn: response.data.urn as string,
                        isEmea: response.data.isEmea as boolean,
                    });
                })
                .catch(error => {
                    console.error('Error viewer proxy:', error);
                });
        }
    }, [keycloak, keycloakInitialized, fileID, projectID]);

    // translations
    const transHeaderTitle = useTranslation({ ...messages.headerTitle });

    // Modal - Global overlay
    const globalOverlay = useGlobalOverlay();
    const globalOverlayCLasses = globalOverlayStyles();

    return (
        <SingleLayout>
            <Helmet>
                <title>{`${config?.template?.title} - ${transHeaderTitle}`}</title>
            </Helmet>
            <Box className={classes.dashboardBox}>
                <Viewer
                    modelInfo={modelInfo as ViewerState}
                    projectId={projectID}
                    versionUrn={fileID}
                />
            </Box>

            <Modal open={globalOverlay?.show}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: bTheme => bTheme.zIndex.drawer + 1 }}
                    open={globalOverlay?.show}
                >
                    <Box className={globalOverlayCLasses.globalOverlay}>
                        <Box className={globalOverlayCLasses.globalOverlayInner}>
                            <Box className={globalOverlayCLasses.globalOverlayLoader}>
                                <CircularProgress />
                            </Box>
                            <Box>{globalOverlay?.text}</Box>
                        </Box>
                    </Box>
                </Backdrop>
            </Modal>
        </SingleLayout>
    );
};

export default ViewerPage;

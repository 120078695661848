import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { markupThumbnail } from '../../../packages/Api/data/markups/client';
import { useCurrentIssuesDetails } from '../../../redux/issues';
import { setActiveMarkupID, setMenu } from '../../../redux/project';

interface ICurrentIssueMarkupProps {
    objectMarkupId?: number;
}

const CurrentIssueMarkup: React.FC<ICurrentIssueMarkupProps> = () => {
    const currentIssueDetails = useCurrentIssuesDetails();
    const { keycloak } = useKeycloak();
    const [thumbnail, setThumbnail] = useState<string>();
    const dispatch = useDispatch();

    const objectMarkupId = currentIssueDetails?.issue?.objectMarkupID;

    useEffect(() => {
        if (!objectMarkupId) {
            setThumbnail(undefined);
            return;
        }
        if (keycloak?.token && objectMarkupId) {
            markupThumbnail(keycloak.token, objectMarkupId, { thumbnailWidth: 300 }).then(
                response => {
                    setThumbnail(URL.createObjectURL(response));
                },
            );
        }
    }, [objectMarkupId]);

    /**
     * Handle click on markup thumbnail and open current markup
     * - Save action into redux
     * - close Issues
     * - open Markups
     * - set active markup
     */
    const handleClickMarkup = () => {
        dispatch(setActiveMarkupID(objectMarkupId));
        dispatch(setMenu({ markupsOpen: true, issuesOpen: false }));
    };

    if (!thumbnail) return <>-</>;

    return (
        <img
            src={thumbnail}
            alt="Markup"
            onClick={handleClickMarkup}
            className="object-cover aspect-video border rounded border-gray-300 block"
        />
    );
};

export default CurrentIssueMarkup;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TranslationListResponse } from '../../generate/api';
import { RootState } from '../store';
import { ITranslationCache } from './types';

const selectedLanguageStorageKey = 'selectedLanguage';

const initialState: Readonly<ITranslationCache> = {
    currentLanguage: localStorage.getItem(selectedLanguageStorageKey) || 'EN',
    languages: [],
    items: [],
    newTranslations: [],
    loaded: false,
};

export const translationsSlice = createSlice({
    name: 'translations',
    initialState: initialState,
    reducers: {
        setLanguage: (state, action: PayloadAction<string>) => {
            localStorage.setItem(selectedLanguageStorageKey, action.payload.trim().toUpperCase());
            state.currentLanguage = action.payload;
        },
        setTranslation: (state, action: PayloadAction<TranslationListResponse>) => {
            if (state.items && state.items.some(x => x.language == action.payload.language)) {
                state.items = state.items.map(item => {
                    if (item.language != action.payload.language) {
                        return item;
                    }
                    return {
                        ...item,
                        ...action.payload,
                    };
                });
            } else {
                state.items.push(action.payload);
            }
            state.loaded = true;
        },
        addNewTranslation: (state, action: PayloadAction<string>) => {
            state.newTranslations = [...state.newTranslations, action.payload];
        },
        setLoaded: (state, action: PayloadAction<boolean>) => {
            state.loaded = action.payload;
        },
        setLanguages: (state, action: PayloadAction<string[]>) => {
            state.languages = action.payload;
        },
    },
});

export const { setTranslation, setLanguage, addNewTranslation, setLoaded, setLanguages } =
    translationsSlice.actions;

export const selectTranslation = (state: RootState, key: string) => {
    const actualTranslations = state.translations?.items?.find(
        x => x.language?.toLowerCase() == state.translations.currentLanguage.toLowerCase(),
    )?.translations as { [key: string]: string };
    if (actualTranslations && key in actualTranslations) {
        return actualTranslations[key];
    }
    return undefined;
};

// check if key exists in any language and in newTranslations
export const onKeyExists = (state: RootState, key: string) => {
    const inNewTranslations = state.translations.newTranslations.includes(key);
    const existsInTranslations = state.translations?.items?.some(item => {
        return key in (item?.translations ?? {});
    });
    return !!(inNewTranslations || existsInTranslations);
};

export default translationsSlice.reducer;

// startup check
export interface IStartupCheckResponse {
    status: StartupCheckStatus;
    lastLoaded?: string; // When data were last loaded (UTC time).
}

export enum StartupCheckStatus {
    LOADED = 'Loaded',
    NOT_LOADED = 'NotLoaded',
    LOADING = 'Loading',
    RELOADING = 'Reloading',
}

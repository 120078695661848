export default class TwoWayMap<K1, K2> {
    map: Map<K1, K2>;

    reverseMap: Map<K2, K1>;

    constructor(map) {
        this.map = new Map<K1, K2>(map);
        this.reverseMap = new Map<K2, K1>();

        map.forEach(([k1, k2]) => {
            this.reverseMap.set(k2, k1);
        });
    }

    public get(key) {
        return this.map.get(key);
    }

    public revGet(key) {
        return this.reverseMap.get(key);
    }
}

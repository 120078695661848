import { Box } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setSidebar, useSidebar } from '../../redux/project';
import useStyles from './styles';

interface ISidebar {
    open?: boolean;
    children?: React.ReactNode;
}

const iconArrowRightSvg = (
    <svg xmlns="http://www.w3.org/2000/svg" width="6.798" height="11.89" viewBox="0 0 6.798 11.89">
        <path
            id="Icon_ionic-ios-arrow-down"
            data-name="Icon ionic-ios-arrow-down"
            d="M5.947,4.749l4.5-4.5a.846.846,0,0,1,1.2,0,.857.857,0,0,1,0,1.2l-5.094,5.1a.848.848,0,0,1-1.172.025L.248,1.457a.85.85,0,0,1,1.2-1.2Z"
            transform="translate(0 11.89) rotate(-90)"
            fill="#fff"
        />
    </svg>
);

const iconArrowLeftSvg = (
    <svg xmlns="http://www.w3.org/2000/svg" width="6.798" height="11.89" viewBox="0 0 6.798 11.89">
        <path
            id="Icon_ionic-ios-arrow-down"
            data-name="Icon ionic-ios-arrow-down"
            d="M5.947,4.749l4.5-4.5a.846.846,0,0,1,1.2,0,.857.857,0,0,1,0,1.2l-5.094,5.1a.848.848,0,0,1-1.172.025L.248,1.457a.85.85,0,0,1,1.2-1.2Z"
            transform="translate(6.798 0) rotate(90)"
            fill="#fff"
        />
    </svg>
);

const Sidebar: React.FC<ISidebar> = ({ open, children }) => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const sidebarOpened = useSidebar();

    useEffect(() => {
        if (sidebarOpened != isOpen) {
            setIsOpen(sidebarOpened);
        }
    }, [sidebarOpened]);

    const [isOpen, setIsOpen] = useState(true);
    const handleToggle = () => {
        setIsOpen(!isOpen);
        dispatch(setSidebar(!isOpen));
    };

    if (!open) {
        return null;
    }

    return (
        <Box className={clsx(classes.root, !isOpen && classes.rootHidden)}>
            <div className={classes.arrow} onClick={handleToggle}>
                {isOpen ? iconArrowLeftSvg : iconArrowRightSvg}
            </div>
            <div className={clsx(classes.children, !isOpen && classes.childrenHidden)}>
                {children}
            </div>
        </Box>
    );
};

export default Sidebar;

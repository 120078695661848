import { createStyles, makeStyles } from '@mui/styles';

import theme from '../../theme/Theme';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            maxWidth: '800px',
            width: '100%',
            margin: '0 auto',
            padding: '0 .5rem',

            [theme.breakpoints.up('lg')]: {},
        },

        title: {
            display: 'table',
            // margin: '70px auto 1rem',
            padding: '0 0 0 1rem',
            fontWeight: 'normal',
        },

        fileBox: {
            width: '100%',
            height: '100%',
            [theme.breakpoints.up('sm')]: {
                border: '1px solid #ddd',
            },
        },

        select: {
            backgroundColor: '#EBF7F8',
            '&.Mui-focused': {
                backgroundColor: '#EBF7F8 !important',
            },
        },

        button: {
            width: '100%',
            height: '3.5rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid #DDDDDD',
        },

        alert: {
            textAlign: 'center',
            fontSize: '1.2rem',
            backgroundColor: '#EBF7F8',
            padding: '1rem',
        },

        fileExplorerWrapper: {
            display: 'flex',
            flexDirection: 'column',
            gap: '0.6rem',
            marginTop: '0.5rem',
        },

        fileExplorerButtons: {
            display: 'flex',
            flexDirection: 'row',
            gap: '0.6rem',
        },
    }),
);

export default useStyles;

import { DispatchAction } from '@iolabs/redux-utils';
import { Checkbox, FormControlLabel, FormGroup, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { onFilter as onAssetsFilter, useAssetsActiveFilters } from '../../redux/assets';
import { AssetsFilters } from '../../redux/assets/types';
import { onFilter as onIssuesFilter, useIssuesFilters } from '../../redux/issues';
import { IIssuesFilters, IssuesFiltersKeys } from '../../redux/issues/types';
import { useTranslation } from '../../redux/translations/hook';
import messages from './messages';
import { IFilterDefinition, IFilterType } from './types';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formGroup: {
            marginTop: '-6px',
            marginBottom: '12px',
        },
        formControlLabel: {
            marginLeft: '-2px',
            '& .MuiFormControlLabel-label': {
                marginLeft: theme.spacing(0.5),
            },
            '& .MuiTypography-body1': {
                fontSize: theme.typography.pxToRem(12),
                lineHeight: '1.7em',
            },
        },
        checkbox: {
            padding: 0,
            '& svg': {
                color: theme.palette.secondary.main,
            },
        },
    }),
);

interface IFieldBooleanProps {
    type: IFilterType;
    filterDefinition: IFilterDefinition;
}

const FieldBoolean: React.FC<IFieldBooleanProps> = ({ type, filterDefinition }) => {
    const classes = useStyles();
    const dispatch = useDispatch<DispatchAction>();
    const issuesFilters: IIssuesFilters = useIssuesFilters();
    const assetsFilters = useAssetsActiveFilters();

    const [state, setState] = useState<boolean>(false);

    const handleChange = event => {
        dispatch(
            type === IFilterType.ISSUES
                ? onIssuesFilter({
                      key: filterDefinition?.parameterName as IssuesFiltersKeys,
                      value: event.target.checked,
                  })
                : onAssetsFilter({
                      key: filterDefinition?.parameterName as string,
                      value: event.target.checked,
                  }),
        );
    };

    // use redux filters state as local component state
    useEffect(() => {
        if (type === IFilterType.ISSUES) {
            if (issuesFilters[filterDefinition?.parameterName as string] !== undefined) {
                setState(issuesFilters[filterDefinition?.parameterName as string] as boolean);
            } else {
                setState(false);
            }
        } else {
            if (assetsFilters[filterDefinition?.parameterName as string] !== undefined) {
                setState(assetsFilters[filterDefinition?.parameterName as string] as boolean);
            } else {
                setState(false);
            }
        }
    }, [issuesFilters, assetsFilters]);

    // translations
    const transFieldSubLocation = useTranslation({ ...messages.fieldSubLocation });

    /**
     * Mapping DE locale for filterDefinition caption
     * @param filterDefinition
     */
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const localeMapping = (filterDefinition: IFilterDefinition): string => {
        switch (filterDefinition?.parameterName) {
            case 'includeSubLocations': {
                return transFieldSubLocation;
            }
            default: {
                return filterDefinition?.caption as string;
            }
        }
    };

    return (
        <>
            {filterDefinition?.parameterName !== 'includeSubLocations' && (
                <FormGroup row className={classes.formGroup}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                size="small"
                                checked={state}
                                onChange={handleChange}
                                name={filterDefinition?.parameterName}
                                className={classes.checkbox}
                            />
                        }
                        label={localeMapping(filterDefinition)}
                        className={classes.formControlLabel}
                    />
                </FormGroup>
            )}
        </>
    );
};

export default FieldBoolean;

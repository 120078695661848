import { DispatchAction } from '@iolabs/redux-utils';
import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../redux/store';
import { setLanguage } from '../../redux/translations/translationsSlice';

const LanguageSelector: React.FC = () => {
    const dispatch = useDispatch<DispatchAction>();
    const languages = useSelector((state: RootState) => state.translations.languages);
    const currentLanguage = useSelector((state: RootState) => state.translations.currentLanguage);

    return (
        <div>
            {languages.map(lang => (
                <button
                    className={clsx(
                        'mr-2 py-2 px-2 rounded',
                        lang === currentLanguage && 'bg-primary',
                    )}
                    key={lang}
                    onClick={() => dispatch(setLanguage(lang))}
                >
                    {lang}
                </button>
            ))}
        </div>
    );
};

export default LanguageSelector;

import { DispatchAction } from '@iolabs/redux-utils';
import { Box, Skeleton, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { TreeDataType, TreeVariant } from '../../packages/Api/data/tree/types';
import { useTranslation } from '../../redux/translations/hook';
import { onLoadNode, usePlotData, usePlotLoading } from '../../redux/tree';
import Icon from '../Icon/Icon';
import messages from './messages';
import Tree from './Tree';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            height: '100%',
            padding: '4px',
        },
        skeleton: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        skeleton10: {
            marginLeft: theme.spacing(2),
        },
        skeleton20: {
            marginLeft: theme.spacing(4),
        },
        skeleton30: {
            marginLeft: theme.spacing(6),
        },
        emptyProject: {
            height: '100%',
            display: 'flex',
            flexFlow: 'column nowrap',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            '& svg': {
                fill: theme.palette.grey['700'],
            },
            '& p': {
                marginTop: theme.spacing(0.5),
            },
        },
    }),
);

interface ITreeWrapperProps {
    variant: TreeVariant;
    // activeProject?: ProjectData;
    activeProject?: any; // todo ts
    loadNodeByEvent?: any; // todo - if active -
}

const TreeWrapper: React.FC<ITreeWrapperProps> = ({ variant, activeProject }) => {
    const classes = useStyles();
    const { keycloak } = useKeycloak();
    const dispatch = useDispatch<DispatchAction>();

    const treePlotData = usePlotData();
    const treePlotLoading = usePlotLoading();

    const treeData = treePlotData;
    const treeLoading = treePlotLoading;

    useEffect(() => {
        if (activeProject && treeData.length === 0 && !treeLoading) {
            dispatch(
                // todo - jb - default version
                // onLoadNode({
                //     token: keycloak?.token as string,
                //     variant: variant,
                //     nodeId: activeProject?.id as string,
                //     depth: 2,
                //
                //     parentNodeType: TreeDataType.PROJECT,
                // })
                onLoadNode({
                    token: keycloak?.token as string,
                    variant: TreeVariant.PLOT,
                    nodeId: activeProject.id as string,
                    depth: 1,
                    parentNodeType: TreeDataType.PROJECT,
                    filter: {
                        folder: {
                            visibleType: 'items:autodesk.bim360:File',
                            // include: "Project Files", // simple like pattern
                            depth: 1, // how deep from requested node apply filter (same as in filter param)
                        },
                    },
                }),
            );
        }
    }, [activeProject]);

    const skeleton = (
        <Box p={1} display="flex" flexDirection="column" alignItems="flex-start" mt={-1}>
            <Skeleton variant="text" width="60%" height={40} />
            <Skeleton variant="text" width="60%" height={40} className={classes.skeleton10} />
            <Skeleton variant="text" width="60%" height={40} className={classes.skeleton20} />
            <Skeleton variant="text" width="60%" height={40} className={classes.skeleton30} />
            <Skeleton variant="text" width="60%" height={40} />
            <Skeleton variant="text" width="60%" height={40} className={classes.skeleton10} />
            <Skeleton variant="text" width="60%" height={40} className={classes.skeleton10} />
            <Skeleton variant="text" width="60%" height={40} />
            <Skeleton variant="text" width="60%" height={40} />
        </Box>
    );

    // translations
    const transEmptyProject = useTranslation({ ...messages.emptyProject });

    return (
        <Box className={classes.root}>
            {activeProject && treeData && (
                <>
                    {treeData.length !== 0 ? (
                        <Tree variant={variant} treeData={treeData} treeLoading={treeLoading} />
                    ) : (
                        <>
                            {treeLoading ? (
                                <>{skeleton}</>
                            ) : (
                                <Box className={classes.emptyProject}>
                                    <Icon name="folder-open" size={60} />
                                    <Typography variant="body2">{transEmptyProject}</Typography>
                                </Box>
                            )}
                        </>
                    )}
                </>
            )}
        </Box>
    );
};

export default TreeWrapper;

import { DispatchAction } from '@iolabs/redux-utils';
import { IonLoading, IonSpinner } from '@ionic/react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Input,
    Popover,
    Typography,
} from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DialogContext } from '../../dialogs/DialogProvider/DialogProvider';
import { ObjectMarkup, UpdateMarkupRequest } from '../../generate/api';
import { markupsApi2, markupThumbnail } from '../../packages/Api/data/markups/client';
import { addNotification } from '../../redux/notifier';
import {
    setActiveMarkupID,
    setMarkupIsEdited,
    setMarkupsList,
    setSelectedMarkup,
    unselectMarkup,
    useMarkups,
    useProjectState,
} from '../../redux/project';
import { useTranslation } from '../../redux/translations/hook';
import { DF } from '../DialogFactory/DialogFactory';
import { INotificationDialogProps } from '../DialogFactory/NotificationDialog/NotificationDialog';
import FormattedMessage from '../Translation/FormattedMessage';
import { getObjectId } from './createObjectId';
import LeaveConfirmDialog from './LeaveConfirmDialog';
import messages from './messages';
import useStyles from './styles';

interface IMarkupsSidebarProps {
    viewable?: any;
    fileVersionUrn?: string;
}

interface IMarkupProps {
    markup: ObjectMarkup;
    loadMarkups: any; // todo ts
}

interface IDeleteMarkupProps {
    markup: ObjectMarkup;
    loadMarkups: any; // todo ts
    handleClosePopover: any; // todo ts
}

interface IRenameMarkupProps {
    markup: ObjectMarkup;
    loadMarkups: any; // todo ts
    handleClosePopover: any; // todo ts
}

const DeleteMarkup: React.FC<IDeleteMarkupProps> = ({
    markup,
    loadMarkups,
    handleClosePopover,
}) => {
    const dispatch = useDispatch<DispatchAction>();

    const { keycloak } = useKeycloak();

    const [openDialogBox, setOpenDialogBox] = React.useState(false);

    const { openDialog } = useContext(DialogContext);

    // Translations
    const transMessageMarkupDeleted = useTranslation({ ...messages.messageMarkupDeleted });

    const deleteMarkup = () => {
        if (keycloak?.token && markup?.objectMarkupID) {
            markupsApi2
                .markupsObjectMarkupIdDeleteDelete(markup.objectMarkupID)
                .then(() => {
                    dispatch(
                        addNotification({
                            variant: 'success',
                            message: transMessageMarkupDeleted,
                        }),
                    );
                    console.log('## Markups - Markup deleted');
                })
                .catch(error => {
                    console.error('## Deleting markup was not successful', error);
                    openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Error', {
                        content: (
                            <FormattedMessage {...messages.messageDeletingMarkupNotSuccessful} />
                        ),
                    });
                })
                .finally(() => {
                    loadMarkups();
                    handleClosePopover();
                    dispatch(unselectMarkup());
                });
        }
    };

    const handleDelete = () => {
        deleteMarkup();
        setOpenDialogBox(false);
    };

    const handleOpenDialog = () => {
        setOpenDialogBox(true);
    };

    const handleCloseDialog = () => {
        setOpenDialogBox(false);
    };

    return (
        <>
            <Box onClick={handleOpenDialog}>
                <FormattedMessage {...messages.buttonDelete} />
            </Box>
            <Dialog
                open={openDialogBox}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <FormattedMessage {...messages.messageDoYouWantDeleteThisMarkup} />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {markup.name}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} variant="outlined">
                        <FormattedMessage {...messages.buttonStorno} />
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleDelete}>
                        <FormattedMessage {...messages.buttonDelete} />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

const RenameMarkup: React.FC<IRenameMarkupProps> = ({
    markup,
    loadMarkups,
    handleClosePopover,
}) => {
    const dispatch = useDispatch<DispatchAction>();

    const { keycloak } = useKeycloak();

    const [openDialogBox, setOpenDialogBox] = React.useState(false);
    const [name, setName] = React.useState<ObjectMarkup['name']>(markup.name);

    const { openDialog } = useContext(DialogContext);

    // Translations
    const transMessageMarkupUpdated = useTranslation({ ...messages.messageMarkupUpdated });

    const handleRename = () => {
        const data: UpdateMarkupRequest = {
            markup: {
                description: '',
                name: name,
            },
        };

        if (keycloak?.token && markup?.objectMarkupID) {
            markupsApi2
                .markupsObjectMarkupIdUpdatePatch(markup.objectMarkupID, data)
                .then(() => {
                    dispatch(
                        addNotification({
                            variant: 'success',
                            message: transMessageMarkupUpdated,
                        }),
                    );
                    console.log('## Markups - Markup updated');
                })
                .catch(error => {
                    console.error('Markups - Unable to update markup', error);
                    openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Error', {
                        content: <FormattedMessage {...messages.messageUnableToUpdateMarkup} />,
                    });
                })
                .finally(() => {
                    loadMarkups();
                    handleClosePopover();
                });
        }
    };

    const handleOpenDialog = () => {
        setOpenDialogBox(true);
    };

    const handleCloseDialog = () => {
        setOpenDialogBox(false);
        handleClosePopover();
        setName(markup.name);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    return (
        <>
            <Box onClick={handleOpenDialog}>
                <FormattedMessage {...messages.buttonRename} />
            </Box>
            <Dialog
                open={openDialogBox}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{'Rename markup'}</DialogTitle>
                <DialogContent>
                    <Input value={decodeURI(name as string)} onChange={handleChange} fullWidth />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>
                        <FormattedMessage {...messages.buttonStorno} />
                    </Button>
                    <Button variant="contained" color="secondary" onClick={handleRename}>
                        <FormattedMessage {...messages.buttonOk} />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

const Markup: React.FC<IMarkupProps> = ({ markup, loadMarkups }) => {
    const classes = useStyles();

    const { keycloak } = useKeycloak();

    const [thumbnail, setThumbnail] = useState<any>();

    const dispatch = useDispatch<DispatchAction>();
    const markups = useMarkups();
    const selectedMarkup = markups.selected;
    const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

    let isSelectedFromIssue = false;
    // if markup id is set remotely from Redux, select it
    const onReduxActiveMarkupID = useMarkups().onSetActiveMarkupID;
    useEffect(() => {
        if (onReduxActiveMarkupID && onReduxActiveMarkupID === markup?.objectMarkupID) {
            console.log('Markup selected remotely');
            isSelectedFromIssue = true;
            handleClickShow();
            dispatch(setActiveMarkupID(undefined));
        }
    }, [onReduxActiveMarkupID]);

    const confirmExitCallback = callback => {
        if (callback) {
            dispatch(setMarkupIsEdited(false));
            showContent();
        }
        setOpenConfirmDialog(false);
    };

    const handleClickShow = () => {
        if (markups?.isEdited) {
            setOpenConfirmDialog(true);
            return false;
        }
        showContent();
    };

    const showContent = () => {
        if (keycloak?.token) {
            // if this selected - unselect
            if (selectedMarkup?.objectMarkupID === markup.objectMarkupID) {
                dispatch(unselectMarkup());
            } else {
                if (!markup.objectMarkupID) return;
                markupsApi2
                    .markupsObjectMarkupIdContentGet(markup.objectMarkupID)
                    .then(response => {
                        dispatch(
                            setSelectedMarkup({
                                ...markup,
                                content: response.data?.content as string,
                                fromIssue: isSelectedFromIssue,
                            }),
                        );
                    })
                    .catch(error => {
                        console.error(error);
                    })
                    .finally(() => {
                        isSelectedFromIssue = false;
                    });
            }
        }
    };

    useEffect(() => {
        if (keycloak?.token && markup.objectMarkupID) {
            markupThumbnail(keycloak.token, markup.objectMarkupID, { thumbnailWidth: 300 }).then(
                response => {
                    setThumbnail(URL.createObjectURL(response));
                },
            );
        }
    }, [markup]);

    // Popover
    const [anchorPopoverEl, setAnchorPopoverEl] = React.useState<HTMLDivElement | null>(null);
    const popoverOpen = Boolean(anchorPopoverEl);
    const popoverId = popoverOpen ? 'markup-popover-selection' : undefined;
    const handleOpenPopover = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setAnchorPopoverEl(event.currentTarget);
    };
    const handleClosePopover = () => {
        setAnchorPopoverEl(null);
    };

    return (
        <>
            <Box className={classes.markup}>
                <Box className={classes.markupHeader}>
                    <Box>{decodeURI(markup.name as string)}</Box>
                    <Box onClick={handleOpenPopover} className={classes.popoverMenuOpener}>
                        ...
                    </Box>
                </Box>
                <Box
                    className={clsx(
                        classes.preview,
                        markup.objectMarkupID === selectedMarkup?.objectMarkupID &&
                            classes.previewSelected,
                    )}
                    onClick={handleClickShow}
                >
                    <img src={thumbnail} alt={markup.name as string} />
                </Box>
            </Box>

            {openConfirmDialog && <LeaveConfirmDialog confirmCallback={confirmExitCallback} />}

            <Popover
                id={popoverId}
                open={popoverOpen}
                anchorEl={anchorPopoverEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                className={classes.popover}
            >
                <>
                    <Box className={classes.popoverMenuItem}>
                        <DeleteMarkup
                            markup={markup}
                            loadMarkups={loadMarkups}
                            handleClosePopover={handleClosePopover}
                        />
                    </Box>
                    <Box className={classes.popoverMenuItem}>
                        <RenameMarkup
                            markup={markup}
                            loadMarkups={loadMarkups}
                            handleClosePopover={handleClosePopover}
                        />
                    </Box>
                </>
            </Popover>
        </>
    );
};

const MarkupsSidebar: React.FC<IMarkupsSidebarProps> = ({ viewable, fileVersionUrn }) => {
    const { keycloak } = useKeycloak();

    const dispatch = useDispatch<DispatchAction>();

    const project = useProjectState();

    const markups = useMarkups();

    const classes = useStyles();

    const { openDialog } = useContext(DialogContext);

    const loadMarkups = () => {
        if (fileVersionUrn && viewable?.id) {
            const objectId = getObjectId(fileVersionUrn, viewable.guid);
            if (keycloak?.token) {
                markupsApi2
                    .markupsAllPost({ objectId })
                    .then(response => {
                        console.log('Markups - open');
                        dispatch(setMarkupsList(response.data.markups as ObjectMarkup[]));
                    })
                    .catch(error => {
                        console.error('Markups - Unable to load markups', error);
                        openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Error', {
                            content: (
                                <FormattedMessage {...messages.messageLoadingListNotSuccessful} />
                            ),
                        });
                    });
            }
        }
    };

    useEffect(() => {
        loadMarkups();
        dispatch(unselectMarkup());
    }, [viewable]);

    return (
        <>
            <Typography variant="h1" className={classes.title}>
                <FormattedMessage {...messages.title} />
            </Typography>

            {project.markups.loading && (
                <>
                    <div className="table mx-auto">
                        <IonSpinner className={classes.spinnerItem} />
                    </div>
                </>
            )}

            {!project.markups.loading && (
                <>
                    {markups?.list.map((markup, index) => {
                        return <Markup key={index} markup={markup} loadMarkups={loadMarkups} />;
                    })}
                    {markups?.list.length === 0 && (
                        <Typography variant="body1" className={classes.noMarkups}>
                            <FormattedMessage {...messages.messageNoMarkupsCreatedYet} />
                        </Typography>
                    )}
                </>
            )}
        </>
    );
};

export default MarkupsSidebar;

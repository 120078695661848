import ForgeViewer from '@iolabs/react-forge-viewer';
import { DispatchAction } from '@iolabs/redux-utils';
import { Box } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import _ from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DialogContext } from '../../dialogs/DialogProvider/DialogProvider';
import { Sensor } from '../../generate/api';
import { issuesApi } from '../../packages/Api/data/issues/client';
import { dataManagementApi } from '../../packages/Api/data/tree/client';
import ProjectSetup from '../../pages/ProjectSetup/ProjectSetup';
import { useAssetsState } from '../../redux/assets';
import {
    IElementScheduleOperations,
    ModeTypes,
    setElementScheduleOperations,
} from '../../redux/editing/editingSlice';
import { useEditingState, useMode } from '../../redux/editing/hooks';
import {
    setAssetsSettings,
    setCurrentViewable,
    setElementsStatus,
    setMarkupIsEdited,
    setSelectedAsset,
    setSelectedAssetProps,
    setViewableElements,
    useAssetsSettings,
    useElementsStatus,
    useMenu,
    useModelAssetStatuses,
    useProjectState,
    useSelectedAsset,
} from '../../redux/project';
import {
    ICurrentViewable,
    IFileElementCopy,
    IFileElementCreate,
    IFileElementMove,
    IFileElementRemoval,
    IFileElementRotate,
    IPoint3,
    IViewableElement,
} from '../../redux/project/types';
import { isJwtExpired } from '../../utils/Jwt';
import { sanitizeExternalId } from '../../utils/sanitizeExternalId';
import TwoWayMap from '../../utils/TwoWayMap';
import Assets from '../Assets/Assets';
import AssetsFilter from '../AssetsFilter/AssetsFilter';
import Commissioning from '../Commissioning/Commissioning';
import { DF } from '../DialogFactory/DialogFactory';
import { INotificationDialogProps } from '../DialogFactory/NotificationDialog/NotificationDialog';
import Issues from '../Issues/Issues';
import IssueSidebar from '../Issues/Sidebar';
import IssuesFilter from '../IssuesFilter/IssuesFilter';
import LockedModelInfo from '../LockedModelInfo/LockedModelInfo';
import Markups from '../Markups/Markups';
import MarkupsSidebar from '../Markups/MarkupsSidebar';
import NavigationMenu from '../NavigationMenu/NavigationMenu';
import Scanning from '../Scanning/Scanning';
import {
    getModifications,
    updateElementsStatus,
    updateLockInfo,
} from '../Scanning/updateElementsStatus';
import Sidebar from '../Sidebar/Sidebar';
import ViewSelector from '../ViewSelector/ViewSelector';
import ToolbarExtensionName, {
    type ToolbarExtensionType,
    getRealWorldCoordinates,
    getWorldCoordinatesFromReal,
    RealWorldCoordinates,
    toMetricCoords,
    TransformTool,
} from './extensions/ToolbarTransform';
import Logo from './Logo';
import SensorsWrapper, { getObjectBound2D } from './SensorsWrapper';
import useStyles from './styles';
import { Emea, ViewerRole, ViewerState } from './types';
import Viewer3D = Autodesk.Viewing.Viewer3D;
import PropertyResult = Autodesk.Viewing.PropertyResult;

import { useCurrentLanguage } from '../../redux/translations/hook';

interface IModelingProgress {
    modelInfo: ViewerState;
    viewerState?: any;
    versionUrn?: string;
    projectId?: string;
}

export type ISelection = PropertyResult;

const Viewer: React.FC<IModelingProgress> = ({ modelInfo, viewerState, projectId, versionUrn }) => {
    const classes = useStyles();

    const { keycloak } = useKeycloak();
    const project = useProjectState();
    const mode = useMode();
    const assetsSettings = useAssetsSettings();
    const editingState = useEditingState();
    const dispatch = useDispatch<DispatchAction>();
    const elementsStatus = useElementsStatus();
    const lockedModelInfo = useProjectState().lockedModelInfo;
    const reduxMenu = useMenu();
    const currentLanguage = useCurrentLanguage();

    const [modelLoaded, setModelLoaded] = useState<boolean>(false);
    const [viewer, setViewer] = useState<Viewer3D>();
    const [forgeToken, setForgeToken] = useState<string>();

    const [externalIdMap, setExternalIdMap] = useState<TwoWayMap<string, number>>();

    const [adjustGeometryOpen, setAdjustGeometryOpen] = useState<boolean>(false);
    const [adjustingActiveTool, setAdjustingActiveTool] = useState<TransformTool | undefined>();
    const [overlay2dEdit, setOverlay2dEdit] = useState<any>();

    const { openDialog } = useContext(DialogContext);

    // region - ViewableList
    // ====================================================================
    const [viewable, setViewable] = useState<IViewableElement>();
    const [viewableList, setViewableList] = useState<IViewableElement[]>([]);
    const viewableRef = useRef(viewable);
    // endregion

    // region - ForgeViewer events
    // ====================================================================

    const handleForgeScriptLoaded = () => {};

    const handleModelError = error => {
        console.error('Error loading the model: ', error);
    };

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const handleDocumentLoaded = (doc: any, viewableList: any[]) => {
        setViewable(viewableList[0]);
        setViewableList(viewableList);

        Autodesk.Viewing.Document.getAecModelData(doc.getRoot()).then(aec =>
            console.log('Aec data:', aec),
        );
    };

    const handleTokenRequested = (onAccessToken: any) => {
        console.debug('ForgeViewer - handleTokenRequested', { forgeToken });
        if (!forgeToken || isJwtExpired(forgeToken, 30)) {
            dataManagementApi.dataManagementViewerTokenGet().then(response => {
                setForgeToken(response.data);
                onAccessToken(response.data);
            });
        } else {
            onAccessToken(forgeToken);
        }
    };

    interface MeasureExt {
        getMeasurementList: () => any;
    }

    // todo - fix - eventListener set twice
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const handleViewerLoaded = (viewer: Autodesk.Viewing.Viewer3D) => {
        setViewer(viewer);

        // Check if the event listener already exists
        if (
            !viewer.hasEventListener(
                Autodesk.Viewing.SELECTION_CHANGED_EVENT,
                handleSelectionChanged,
            )
        ) {
            // If it doesn't exist, add a new event listener
            viewer.addEventListener(
                Autodesk.Viewing.SELECTION_CHANGED_EVENT,
                handleSelectionChanged,
            );
        }

        const measureEventName = 'measurement-completed';

        viewer.addEventListener(measureEventName, event => {
            // console.log('AEC | Measure finished', event);
            const measureExtension = viewer.getExtension(
                'Autodesk.Measure',
            ) as unknown as MeasureExt;
            console.log(measureExtension.getMeasurementList());
        });

        viewer.addEventListener('ADJUST_GEOMETRY_TOOL_ACTIVATED', event => {
            setAdjustingActiveTool(event.detail);
        });
        viewer.addEventListener('ADJUST_GEOMETRY_TOOL_DEACTIVATED', event => {
            setAdjustingActiveTool(undefined);
        });

        /*viewer.addEventListener(Autodesk.Viewing.OBJECT_UNDER_MOUSE_CHANGED, event => {
            console.log('Under mouse:', event);
        });*/
    };

    const handleSelectionChanged = event => {
        if (assetsSettings.disableChangeSelection) {
            return;
        }
        setMyState(event.dbIdArray);
    };

    const handleViewerError = error => {
        console.error('Error loading mobile viewer: ', error);
    };

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const handleDocumentError = (viewer: any, error: any) => {
        console.error('Error loading a document: ', error);
        const fileName = project.file.currentVersion?.text;
        const version = project.file.currentVersion?.data?.version;
        openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Error', {
            content: `Unable to load ${fileName} v${version}, most likely due to translation error`,
        });
    };

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const setViewerBackground = (viewer: Autodesk.Viewing.Viewer3D) => {
        viewer.setBackgroundColor(251, 251, 251, 251, 251, 251);
        viewer.setLightPreset(0);
    };

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const initState = (viewer: Autodesk.Viewing.Viewer3D) => {
        if (viewerState) {
            viewer.restoreState(viewerState);
        }
    };

    const handleModelLoaded = (
        // eslint-disable-next-line @typescript-eslint/no-shadow
        viewer: Autodesk.Viewing.Viewer3D,
        model: Autodesk.Viewing.Model,
    ) => {
        if (viewer) {
            setViewerBackground(viewer);
            initState(viewer);

            viewer.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, () => {
                setModelLoaded(true);
            });
        }
    };

    const onUnmount = () => {};
    // endregion

    // region - Viewable selection
    // ====================================================================
    const handleSetViewable = data => {
        viewableRef.current = data;
        setViewable(data);
    };

    const handleSelectViewable = newViewable => {
        if (viewable != newViewable) {
            handleSetViewable(newViewable);
            setModelLoaded(false);
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const getViewableName = (viewable: Autodesk.Viewing.BubbleNode): string => {
        return viewable.data.name as unknown as string;
    };
    // endregion

    // region - Assets
    // ====================================================================
    const assetsOpen = reduxMenu.assetsOpen;
    const selectedAsset = useSelectedAsset();

    // Asset select for scanning
    // ====================================================================
    const [selection, setSelection] = React.useState<number[]>([]);
    const [singleSelection, setSingleSelection] = useState<ISelection>(); // TODO - remove

    const globalDeselect = () => {
        viewer?.clearSelection();
        dispatch(setSelectedAsset(undefined));
        dispatch(setSelectedAssetProps(undefined));
        setSingleSelection(undefined);
    };

    // Set selected assets when selection changes
    useEffect(() => {
        // console.debug({ selection });
        if (viewer && selection) {
            viewer.getProperties(selection[0] as unknown as number, function (e) {
                if (
                    assetsSettings.disableChangeSelection &&
                    e.externalId === singleSelection?.externalId
                ) {
                    return;
                }
                // if (e.externalId === singleSelection?.externalId) return;

                // if disabled change selection - select only one current element
                if (assetsSettings.disableChangeSelection) {
                    const dbId = externalIdMap?.get(selectedAsset);
                    if (typeof dbId === 'number') {
                        viewer?.select([dbId]); // Apply selection with an array containing a single element
                    }
                } else {
                    setSingleSelection(e);
                }
            });

            // disable unselecting
            if (assetsSettings.disableChangeSelection) return;

            // If no selection - clear selected asset
            if (selection.length === 0) {
                globalDeselect();
            }
        }
    }, [selection, viewer]);

    // Select asset from viewer
    useEffect(() => {
        const externalIds = selection.map(dbId => externalIdMap?.revGet(dbId)) as string[];
        const scheduledElement = editingState.elementsScheduleOperations.find(
            e => e.elementID === selectedAsset,
        );

        if (singleSelection?.externalId && externalIds.includes(singleSelection?.externalId)) {
            dispatch(setSelectedAsset(singleSelection?.externalId));
        } else if (scheduledElement?.create || scheduledElement?.copy) {
            // do nothing
        } else {
            globalDeselect();
        }
    }, [singleSelection]);

    // When selected asset - get other parameters from viewer and set them into redux - project.setSelectedAssetProps
    useEffect(() => {
        if (!selectedAsset) {
            dispatch(setSelectedAssetProps(undefined));
            return;
        }
        const externalIds = selection.map(dbId => externalIdMap?.revGet(dbId)) as string[];
        if (selectedAsset && externalIds.includes(selectedAsset)) {
            const dbId = externalIdMap?.get(selectedAsset as string);
            if (!dbId) return;
            viewer?.model.getProperties(dbId, propsResult => {
                dispatch(
                    setSelectedAssetProps({
                        externalId: selectedAsset,
                        dbId: dbId,
                        name: propsResult.name,
                        properties: propsResult.properties,
                    }),
                );
            });
        }
    }, [selection]);

    // Set selection
    const myStateRef = React.useRef(selection);
    const setMyState = data => {
        myStateRef.current = data;
        setSelection(data);
    };

    // Set selected assets in viewer
    useEffect(() => {
        if (assetsSettings.disableChangeSelection) {
            return;
        }

        const dbId = externalIdMap?.get(selectedAsset);
        const scheduledElement = editingState.elementsScheduleOperations.find(
            e => e.elementID === selectedAsset,
        );

        if (typeof dbId === 'number') {
            viewer?.select([dbId]); // Apply selection with an array containing a single element
        } else if (scheduledElement?.create || scheduledElement?.copy) {
            // do nothing
        } else {
            viewer?.clearSelection();
        }

        //Set adjust geometry open for selected elements
        setAdjustGeometryOpen(selectedAsset !== undefined);
    }, [selectedAsset]);

    const activateAdjustingGeometry = () => {
        let tTool: ToolbarExtensionType = viewer?.getExtension(
            ToolbarExtensionName,
        ) as unknown as ToolbarExtensionType;

        let activateAdjustingElement = false;
        if (tTool && tTool.isAdjustToolActive()) {
            activateAdjustingElement = true;
        }

        if (!activateAdjustingElement && viewer && adjustGeometryOpen) {
            const selected = viewer.getSelection() || [];
            const dbId = selected[0];
            let externalId: string | boolean | undefined = externalIdMap?.revGet(dbId) || false;
            if (!externalId) {
                // if viewer is not selected, try to select using selectedAsset (probably temp(
                externalId = selectedAsset;
            }
            let selectedElementStatus;
            if (externalId) {
                selectedElementStatus = elementsStatus[externalId];
            }

            tTool = viewer?.getExtension(ToolbarExtensionName) as unknown as ToolbarExtensionType;
            if (tTool) {
                tTool.setActiveElement({
                    dbId: dbId,
                    externalId: externalId,
                    name: 'n/a',
                    status: selectedElementStatus,
                });
            }
        } else {
            if (!activateAdjustingElement && tTool) {
                tTool.setActiveElement(undefined);
            }
        }
    };

    useEffect(() => {
        activateAdjustingGeometry();
    }, [adjustGeometryOpen]);

    // endregion

    // region - Filter
    // ====================================================================
    const filterOpen = reduxMenu.filterOpen;
    // endregion

    // region - FileBrowser
    // ====================================================================
    const fileBrowserOpen = reduxMenu.fileBrowserOpen;
    // endregion

    // region - Scanning
    // ====================================================================
    const scanningOpen = reduxMenu.scanningOpen;

    const [viewableElementsList, setViewableElementsList] = useState<IViewableElement[]>([]);

    // hide element statuses when scanning is closed
    // enable changing selection in asset table
    useEffect(() => {
        if (!scanningOpen) {
            dispatch(setElementsStatus({}));
            dispatch(setAssetsSettings({ disableChangeSelection: false }));
            if (viewer) {
                viewer.unloadExtension(ToolbarExtensionName);
            }
        } else {
            if (viewer) {
                viewer.loadExtension(ToolbarExtensionName).then(() => {
                    activateAdjustingGeometry();
                });
            }
        }
    }, [scanningOpen]);
    // endregion

    interface AutodeskEdit2d {
        defaultContext: any;
        registerDefaultTools: () => void;
    }

    // region - Adjust geometry
    // ====================================================================
    // todo only if the current version is the latest
    const createEdit2D = async () => {
        // Load Edit2D extension
        const options = {
            // If true, PolygonTool will create Paths instead of just Polygons. This allows to change segments to arcs.
            enableArcs: true,
        };

        // es-lint-disable-next-line ts-ignore
        const edit2d: AutodeskEdit2d = (await viewer?.loadExtension(
            'Autodesk.Edit2D',
        )) as unknown as AutodeskEdit2d;

        // Register all standard tools in default configuration
        // es-lint-disable-next-line ts-ignore
        edit2d?.registerDefaultTools();
        return edit2d;
    };

    //TODO: Verify if needed
    const handleSelection2DChanged = e => {};

    //TODO: Overlay shape
    useEffect(() => {
        if (viewer && !overlay2dEdit) {
            createEdit2D().then(edit2d => {
                const ctx = edit2d.defaultContext;

                ctx.selection.addEventListener(
                    Autodesk.Edit2D.Selection.Events.SELECTION_CHANGED,
                    handleSelection2DChanged,
                );

                setOverlay2dEdit(edit2d);
            });
        }
    }, [adjustGeometryOpen]);
    // endregion

    // region - Commissioning
    // ====================================================================
    const commissioningOpen = reduxMenu.commissioningOpen;
    // endregion

    // region - Markups
    // ====================================================================
    const markupsOpen = reduxMenu.markupsOpen;
    useEffect(() => {
        if (!markupsOpen) {
            dispatch(setMarkupIsEdited(false));
        }
    }, [markupsOpen]);
    // endregion

    // region - Issues
    // ====================================================================
    const issuesOpen = reduxMenu.issuesOpen;
    useEffect(() => {
        if (keycloak?.token && projectId && versionUrn) {
            issuesApi
                .issuesCachePost({
                    forgeProjectId: projectId,
                    forgeVersionUrn: versionUrn,
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {});
        }
    }, [versionUrn, projectId, versionUrn]);
    // endregion

    // region - Sidebar
    // ====================================================================
    const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

    useEffect(() => {
        // Sidebar visibility
        if (fileBrowserOpen || filterOpen || markupsOpen || issuesOpen) {
            setSidebarOpen(true);
        } else {
            setSidebarOpen(false);
        }
    }, [fileBrowserOpen, filterOpen, markupsOpen, issuesOpen]);
    // endregion

    // Set Viewable Elements and names for filtering in assets
    // Set Viewable Elements and names for Scanning
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        function getAllDbIds(viewer) {
            const instanceTree = viewer.model.getData().instanceTree;

            if (instanceTree?.nodeAccess?.dbIdToIndex) {
                const allDbIdsStr = Object.keys(instanceTree.nodeAccess.dbIdToIndex);

                return allDbIdsStr.map(function (id) {
                    return parseInt(id);
                });
            } else return [];
        }

        function onPropsReceived(x: ISelection[]) {
            let assetsLikeElements = x.filter(a =>
                a.properties.some(b => b.attributeName === 'SI_BMA_Gruppe Farbe'),
            );
            assetsLikeElements = assetsLikeElements.filter(
                a =>
                    a.name?.match(/SI_.+\[[0-9]+\]/) &&
                    (!a.properties.some(b => b.attributeName === 'Kurzbezeichnung') ||
                        a.properties
                            .find(b => b.attributeName === 'Kurzbezeichnung')
                            ?.displayValue.toString()
                            ?.toLowerCase() !== 'ai'),
            );

            // prepare map externalId -> dbId
            const externalMap = new TwoWayMap<string, number>(
                assetsLikeElements.map(i => [i.externalId, i.dbId]),
            );
            setExternalIdMap(externalMap);

            // prepare map externalId -> dbId
            // const externalMap = new Map<string, number>(assetsLikeElements.map(i => [i.externalId, i.dbId]));
            // setExternalIdMap(externalMap);

            let newViewableElementsList = assetsLikeElements.map(o => {
                return { externalId: o.externalId?.toString(), name: o.name };
            }) as IViewableElement[];

            newViewableElementsList = newViewableElementsList.map(item => {
                item.externalId = sanitizeExternalId(item.externalId as string);
                return item;
            });

            // save viewable elements to state
            setViewableElementsList(newViewableElementsList);

            // save viewable elements to redux
            dispatch(setViewableElements(newViewableElementsList));
        }

        // get properties of all elements
        if (viewer && viewer.model && modelLoaded) {
            const activeIds = getAllDbIds(viewer);
            viewer.model.getBulkProperties(
                activeIds,
                {
                    propFilter: [
                        'externalId',
                        'CategoryId',
                        'SI_BMA_Gruppe Farbe',
                        'Kurzbezeichnung',
                        'name',
                    ],
                },
                onPropsReceived,
            );
        }
    }, [viewer, modelLoaded, versionUrn, viewable]);

    // region - elements statuses
    // ====================================================================
    const modelAssetStatuses = useModelAssetStatuses();
    const reduxAssets = useAssetsState();
    useEffect(() => {
        if (externalIdMap && viewer) {
            updateLockInfo().then(() => {
                // console.log('Lock info updated');
            });
        }
    }, [viewer, externalIdMap, reduxAssets]);

    /*
     * Update elements statuses
     */
    useEffect(() => {
        if (modelLoaded && externalIdMap && viewer) {
            // console.log('AEC | Fetching modifications');
            // temp hotfix to be sure that the extension is loaded
            viewer?.loadExtension('Autodesk.AEC.ViewportsExtension', {}).then(extension => {
                const viewableElements = viewableElementsList;
                getModifications(viewable, modifications => {
                    updateElementsStatus({
                        externalIdMap,
                        viewer,
                        viewableElements,
                        modifications,
                    });

                    /*
                     * Convert modifications from API to schedule operations and dispatch to redux
                     */
                    modifications.elements?.forEach(element => {
                        /*
                         * Move
                         */
                        const moveOperation = element.operations?.find(
                            operation => operation.operationType === 'MoveElement',
                        );
                        let moveProps: IFileElementMove | undefined;
                        if (moveOperation) {
                            const moveDeltaWorldReal = moveOperation?.parameters?.find(
                                parameter => parameter.name === 'Position',
                            )?.value;

                            // original position
                            const originElementDbId = externalIdMap.get(element.elementID);
                            const originBoundingBox: THREE.Box3 = getObjectBound2D(
                                viewer.model,
                                originElementDbId,
                            );
                            const originVectorWorld = originBoundingBox?.center();
                            const originVectorWorldReal = getRealWorldCoordinates(
                                viewer,
                                originVectorWorld,
                            );
                            const originVectorClient = viewer.worldToClient(originVectorWorld);

                            // target position
                            const worldCoordsTargetReal = originVectorWorldReal.imperial
                                .clone()
                                .add(moveDeltaWorldReal);
                            const worldCoordsTarget = getWorldCoordinatesFromReal(
                                viewer,
                                worldCoordsTargetReal,
                            );
                            const clientCoordsTarget = viewer.worldToClient(worldCoordsTarget);

                            const deltaClient = clientCoordsTarget.clone().sub(originVectorClient);
                            const deltaWorld = worldCoordsTarget.clone().sub(originVectorWorld);

                            moveProps = {
                                scheduled: true,
                                deltaWorld: deltaWorld,
                                deltaWorldReal: moveDeltaWorldReal,
                                deltaClient: deltaClient as IPoint3,
                                targetRealWorldCoords: {
                                    imperial: worldCoordsTargetReal,
                                    metric: toMetricCoords(worldCoordsTargetReal),
                                },
                                targetWorldPoint: worldCoordsTarget,
                            };
                        }

                        /*
                         * Copy
                         */
                        const copyOperation = element.operations?.find(
                            operation => operation.operationType === 'CopyElement',
                        );
                        let copyProps: IFileElementCopy | undefined;
                        if (copyOperation) {
                            const placement = copyOperation.parameters?.find(
                                parameter => parameter.name === 'Placement',
                            );

                            const worldCoordsTargetReal = new THREE.Vector3(
                                placement?.value?.position?.x ?? 0,
                                placement?.value?.position?.y ?? 0,
                                placement?.value?.position?.z ?? 0,
                            );

                            const worldCoordsTarget = getWorldCoordinatesFromReal(
                                viewer,
                                worldCoordsTargetReal,
                            );

                            copyProps = {
                                targetWorldPoint: worldCoordsTarget,
                                scheduled: true,
                                sourceElementId: placement?.value.sourceElementID,
                                targetRealWorldCoords: {
                                    imperial: worldCoordsTargetReal,
                                    metric: toMetricCoords(worldCoordsTargetReal),
                                },
                            };
                        }

                        /*
                         * Rotate
                         */
                        const rotateOperation = element.operations?.find(
                            operation => operation.operationType === 'RotateElement',
                        );
                        let rotateProps: IFileElementRotate | undefined;
                        if (rotateOperation) {
                            const rotateParamValue = rotateOperation?.parameters?.find(
                                parameter => parameter.name === 'Rotation',
                            )?.value;
                            const rotateUnitVector = rotateParamValue?.unitVector;
                            const angle = rotateParamValue?.angle;
                            rotateProps = {
                                scheduled: true,
                                rotateAxis: new THREE.Vector3(
                                    rotateUnitVector?.x,
                                    rotateUnitVector?.y,
                                    rotateUnitVector?.z,
                                ),
                                angle: angle,
                                angleRad: angle * (Math.PI / 180),
                            };
                        }

                        /*
                         * Create
                         */
                        const createOperation = element.operations?.find(
                            operation => operation.operationType === 'CreateElement',
                        );
                        let createProps: IFileElementCreate | undefined;
                        if (createOperation) {
                            const placement = createOperation.parameters?.find(
                                parameter => parameter.name === 'Placement',
                            );

                            const worldCoordsTargetReal = new THREE.Vector3(
                                placement?.value?.position?.x ?? 0,
                                placement?.value?.position?.y ?? 0,
                                placement?.value?.position?.z ?? 0,
                            );

                            const worldCoordsTarget = getWorldCoordinatesFromReal(
                                viewer,
                                worldCoordsTargetReal,
                            );

                            const sensorCode: string = placement?.value.sensorCode;

                            createProps = {
                                targetWorldPoint: worldCoordsTarget,
                                scheduled: true,
                                sourceElementTemplate: {
                                    // TODO check if this code is enough or whole object is needed,
                                    //  the data is loaded in Scanning component, see filesApi.fileSensorsGet
                                    code: sensorCode,
                                },
                                targetRealWorldCoords: {
                                    imperial: worldCoordsTargetReal,
                                    metric: toMetricCoords(worldCoordsTargetReal),
                                },
                            };
                        }

                        /*
                         * Delete
                         */
                        const deleteProps: IFileElementRemoval = {
                            scheduled: true,
                            delete: element.operations?.some(
                                e => e.operationType === 'DeleteElement',
                            ),
                        };

                        /*
                         * Schedule object
                         */
                        const scheduleOperationsData: IElementScheduleOperations = {
                            elementID: element.elementID as string,
                            elementName: element.elementName as string,
                            scheduled: true,
                            move: moveProps,
                            copy: copyProps,
                            rotate: rotateProps,
                            create: createProps,
                            delete: deleteProps.delete ? deleteProps : undefined,
                        };

                        // check if element is already scheduled
                        if (
                            _.isEqual(
                                scheduleOperationsData,
                                editingState.elementsScheduleOperations.find(
                                    e => e.elementID === scheduleOperationsData.elementID,
                                ),
                            )
                        ) {
                            return;
                        } else {
                            /*
                             * Dispatch schedule operations to redux
                             */
                            if (editingState.mode === ModeTypes.default) {
                                dispatch(setElementScheduleOperations(scheduleOperationsData));
                            }
                        }
                    });
                });
            });
        }
    }, [
        lockedModelInfo,
        // viewer,
        // viewable,
        modelLoaded,
        // externalIdMap,
        modelAssetStatuses,
        // editingState.elementsScheduleOperations,
        editingState.mode,
    ]);
    // endregion

    // const [currentViewable, setCurrentViewable] = useState<Autodesk.Viewing.BubbleNode>();
    useEffect(() => {
        const currentViewable = viewable as unknown as Autodesk.Viewing.BubbleNode;
        dispatch(setCurrentViewable(currentViewable?.data as ICurrentViewable));
    }, [viewable]);

    return (
        <Box className={classes.viewerWrapper}>
            <div className="bg-white border-b border-gray-200 h-[50px] w-full flex justify-between">
                <div className="flex">
                    <Logo />
                    <ViewSelector
                        viewables={viewableList}
                        viewable={viewable ? viewable : viewableList?.[0]} // intentionally pass first viewable as default
                        onSelectViewable={handleSelectViewable}
                        getViewableName={getViewableName}
                        role={ViewerRole['2D']}
                        disabled={scanningOpen}
                    />
                    <LockedModelInfo />
                </div>
                <div className="flex items-center">
                    <NavigationMenu modelLoaded={modelLoaded} />
                </div>
            </div>

            <div className="flex-1 flex relative">
                <Sidebar open={sidebarOpen}>
                    {fileBrowserOpen && <ProjectSetup sidebarMode={true} />}
                    {filterOpen && assetsOpen && <AssetsFilter fileVersionUrn={versionUrn} />}
                    {markupsOpen && (
                        <MarkupsSidebar viewable={viewable} fileVersionUrn={versionUrn} />
                    )}
                    {filterOpen && issuesOpen && <IssuesFilter />}
                    {issuesOpen && <IssueSidebar viewable={viewable} />}
                </Sidebar>
                {scanningOpen && (
                    <Scanning
                        viewer={viewer}
                        modelLoaded={modelLoaded}
                        viewable={viewable}
                        viewableElements={viewableElementsList}
                        singleSelection={project.file.selectedAssetProps}
                        externalIdMap={externalIdMap}
                        adjustGeometryEnabled={adjustGeometryOpen}
                    />
                )}

                <Box className={classes.viewerWrapperInner}>
                    <Box className={classes.viewerHorizontalWrapper}>
                        <Box className={classes.viewerHorizontalWrapperInner}>
                            {viewer && commissioningOpen && <Commissioning viewer={viewer} />}

                            {modelInfo?.urn && (
                                <ForgeViewer
                                    version="7.93"
                                    urn={modelInfo?.urn}
                                    api={modelInfo?.isEmea ? Emea.eu : Emea.default}
                                    view={viewable}
                                    query={{ type: 'geometry' }}
                                    onViewerError={handleViewerError}
                                    onTokenRequest={handleTokenRequested}
                                    onDocumentLoad={handleDocumentLoaded}
                                    onDocumentError={handleDocumentError}
                                    onModelLoad={handleModelLoaded}
                                    onModelError={handleModelError}
                                    onViewerLoad={handleViewerLoaded}
                                    onScriptLoaded={handleForgeScriptLoaded}
                                    onUnmount={onUnmount}
                                    forgeScriptAlreadyLoaded
                                    config={{
                                        memory: {
                                            limit: 1000,
                                        },
                                    }}
                                    options={{
                                        language: currentLanguage.toLowerCase(),
                                    }}
                                />
                            )}
                            {viewer && modelLoaded && externalIdMap && !markupsOpen && (
                                <SensorsWrapper
                                    viewer={viewer}
                                    externalIDMap={externalIdMap}
                                    adjustingActiveTool={adjustingActiveTool?.toolCode}
                                />
                            )}
                            {viewer && modelLoaded && markupsOpen && (
                                <Markups
                                    viewer={viewer}
                                    viewable={viewable}
                                    fileVersionUrn={versionUrn}
                                />
                            )}
                        </Box>

                        {assetsOpen && viewer && (
                            <Assets
                                modelLoaded={modelLoaded}
                                viewable={viewable}
                                scanningOpen={scanningOpen}
                            />
                        )}

                        {issuesOpen && viewer && <Issues viewable={viewable} viewer={viewer} />}

                        {/*<SensorTesting viewer={viewer} externalIdMap={externalIdMap} />*/}
                    </Box>
                </Box>
            </div>
        </Box>
    );
};

export default Viewer;

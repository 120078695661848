import { DispatchAction } from '@iolabs/redux-utils';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { InputAdornment, TextField, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DateRange, DateRangePicker } from '../../packages/CustomDateRange';
import { onFilter as onAssetsFilter, useAssetsActiveFilters } from '../../redux/assets';
import { AssetsFilters } from '../../redux/assets/types';
import { onFilter as onIssuesFilter, useIssuesFilters } from '../../redux/issues';
import { IIssuesFilters, IssuesFiltersKeys } from '../../redux/issues/types';
import { useTranslation } from '../../redux/translations/hook';
import messages from './messages';
import { IFilterDefinition, IFilterType } from './types';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dateRangePicker: {
            position: 'fixed',
            zIndex: 100000,
            "& [class*='materialui-daterange-picker-makeStyles-filled']": {
                '& p': {
                    color: theme.palette.common.white,
                },
                backgroundColor: theme.palette.primary.dark,
            },
            "& [class*='materialui-daterange-picker-makeStyles-highlighted']": {
                '& p': {
                    color: theme.palette.common.black,
                },
                backgroundColor: theme.palette.primary.light,
            },
            // selected date ranges
            "& [class*='materialui-daterange-picker-makeStyles-headerItem-']": {
                '&:nth-of-type(1), &:nth-of-type(3)': {
                    flex: 8,
                },
                '& h6': {
                    fontSize: theme.typography.pxToRem(12),
                },
            },
            // today
            "& [class*='materialui-daterange-picker-makeStyles-outlined']": {
                border: `2px solid ${theme.palette.primary.dark}`,
            },
        },
        inputAdornment: {
            cursor: 'pointer',
            '& svg': {
                color: theme.palette.text.secondary,
            },
        },
    }),
);

interface IFieldDateRangeProps {
    type: IFilterType;
    filterDefinition: IFilterDefinition;
}

const FieldDateRange: React.FC<IFieldDateRangeProps> = ({ type, filterDefinition }) => {
    const classes = useStyles();
    const dispatch = useDispatch<DispatchAction>();
    const issuesFilters: IIssuesFilters = useIssuesFilters();
    const assetsFilters = useAssetsActiveFilters();

    const [open, setOpen] = useState<boolean>(false);
    const [dateRange, setDateRange] = React.useState<DateRange>();
    const toggle = () => setOpen(!open);

    const handleChange = (newRange: DateRange) => {
        if (newRange?.startDate && newRange?.endDate) {
            setDateRange(newRange);
            dispatch(
                type === IFilterType.ISSUES
                    ? onIssuesFilter({
                          key: filterDefinition?.parameterName as IssuesFiltersKeys,
                          value: { startDate: newRange?.startDate, endDate: newRange?.endDate },
                      })
                    : onAssetsFilter({
                          key: filterDefinition?.parameterName as string,
                          value: { startDate: newRange?.startDate, endDate: newRange?.endDate },
                      }),
            );
        }

        // close picker on date range end date selected
        if (newRange?.endDate) {
            toggle();
        }
    };

    // use redux filters state as local component state
    useEffect(() => {
        if (type === IFilterType.ISSUES) {
            if (issuesFilters[filterDefinition?.parameterName as string] !== undefined) {
                setDateRange(issuesFilters[filterDefinition?.parameterName as string] as DateRange);
            } else {
                setDateRange(undefined);
            }
        } else {
            if (assetsFilters[filterDefinition?.parameterName as string] !== undefined) {
                setDateRange(assetsFilters[filterDefinition?.parameterName as string] as DateRange);
            } else {
                setDateRange(undefined);
            }
        }
    }, [issuesFilters, assetsFilters]);

    // translations
    const transPlaceholder = useTranslation({ ...messages.dateRangePlaceholder });
    const transFieldDueDate = useTranslation({ ...messages.fieldDueDate });
    const transFieldDueDatePlaceholder = useTranslation({
        ...messages.fieldDueDatePlaceholder,
    });
    const transFieldCreatedOn = useTranslation({ ...messages.fieldCreatedOn });
    const transFieldCreatedOnPlaceholder = useTranslation({
        ...messages.fieldCreatedOnPlaceholder,
    });

    /**
     * Mapping DE locale for filterDefinition caption
     * @param filterDefinition
     */
    const localeMapping = (
        // eslint-disable-next-line @typescript-eslint/no-shadow
        filterDefinition: IFilterDefinition,
    ): { label: string; placeholder: string } => {
        switch (filterDefinition?.parameterName) {
            case 'dueDateRange': {
                return { label: transFieldDueDate, placeholder: transFieldDueDatePlaceholder };
            }
            case 'createdOnRange': {
                return { label: transFieldCreatedOn, placeholder: transFieldCreatedOnPlaceholder };
            }
            default: {
                return {
                    label: filterDefinition?.caption as string,
                    placeholder: transPlaceholder,
                };
            }
        }
    };

    const { label, placeholder } = localeMapping(filterDefinition);

    return (
        <div>
            <TextField
                id={filterDefinition?.parameterName}
                required
                fullWidth
                label={label}
                placeholder={placeholder}
                type="text"
                variant="outlined"
                margin="dense"
                value={`${
                    dateRange?.startDate
                        ? format(dateRange?.startDate, 'MMM. d, yyyy', { locale: de })
                        : ''
                }${
                    dateRange?.endDate
                        ? ` - ${format(dateRange?.endDate, 'MMM. d, yyyy', { locale: de })}`
                        : ''
                }`}
                onFocus={() => toggle()}
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment
                            position="start"
                            onClick={() => toggle()}
                            className={classes.inputAdornment}
                        >
                            <DateRangeIcon fontSize="small" />
                        </InputAdornment>
                    ),
                }}
            />
            <DateRangePicker
                open={open}
                reset={dateRange ? Object.entries(dateRange).length === 0 : false}
                toggle={() => toggle()}
                onChange={handleChange}
                closeOnClickOutside
                wrapperClassName={classes.dateRangePicker}
                locale={de}
                initialDateRange={dateRange}
            />
        </div>
    );
};

export default FieldDateRange;

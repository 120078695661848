import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import React from 'react';

export interface ILeaveConfirmDialogProps {
    confirmCallback: any;
}

const LeaveConfirmDialog: React.FC<ILeaveConfirmDialogProps> = ({ confirmCallback }) => {
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
        confirmCallback(false);
    };
    const handleOk = () => {
        setOpen(false);
        confirmCallback(true);
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {'Do you wish to exit Markup editor without saving?'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description"></DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleOk} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default LeaveConfirmDialog;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Issue, IssueDetailsResponse, IssueSearchResponse } from '../../generate/api';
import { IStartupCheckResponse, StartupCheckStatus } from '../../packages/Api/data/issues/types';
import { ISortOrder } from '../project/types';
import { RootState } from '../store';
import {
    ICustomAttribute,
    IIssuesFilters,
    OnFilterCustomAttributesClearOptions,
    OnFilterCustomAttributesOptions,
    OnFilterMultipleOptions,
    OnFilterOptions,
    OnFiltersOptions,
    OnForceRefreshOptions,
    OnSearchOptions,
    OnSelectOptions,
    OnStartupCheckDoneOptions,
    OnStartupCheckFailOptions,
    OnStartupCheckOptions,
} from './types';

export interface IIssuesSettings {
    pageLimit: number;
    sortColumn: string;
    sortDirection: ISortOrder;
    offset: number;
}
export interface IIssueCurrentPushpin {
    objectId?: number;
    coordinates?: {
        x?: number;
        y?: number;
        z?: number;
    };
    externalId?: string | null;
}

export type IMode = 'edit' | 'view' | 'create';

export interface IIssuesState {
    searchText: string;
    filters: IIssuesFilters | Record<string, never>;
    forceRefresh: boolean;
    startupCheck: {
        loading: boolean;
        startupCheck?: IStartupCheckResponse;
    };
    selected: any[]; // todo: IIssueRow[];
    list?: IssueSearchResponse;

    // new
    settings: IIssuesSettings;
    currentIssue?: Issue;
    currentIssueDetails?: IssueDetailsResponse;
    currentPushpin?: IIssueCurrentPushpin; // todo - not implemented
    currentPushpinExternalId?: string; // todo - not implemented
    onRefresh?: boolean;
    mode?: IMode;
    pushpin?: {
        insertMode?: boolean;
    };
}

export const initialState: Readonly<IIssuesState> = {
    searchText: '',
    filters: {
        limit: 10,
        offset: 0,
        sortColumn: 'identifier',
        sortDirection: 'asc',
        searchText: '',
    },
    forceRefresh: false,
    startupCheck: {
        loading: false,
        startupCheck: {
            status: StartupCheckStatus.NOT_LOADED,
        },
    },
    selected: [],
    settings: {
        pageLimit: 50,
        offset: 0,
        sortColumn: 'typeName',
        sortDirection: 'asc',
    },
};

export const issuesSlice = createSlice({
    name: 'issues',
    initialState: initialState,

    reducers: {
        onSearch: (state, action: PayloadAction<OnSearchOptions>) => {
            state.searchText = action.payload.searchText;
        },

        onFilter: (state, action: PayloadAction<OnFilterOptions>) => {
            state.filters = {
                ...state.filters,
                [action.payload.key]: action.payload.value,
            };
        },

        onSelect: (state, action: PayloadAction<OnSelectOptions>) => {
            state.selected = action.payload.issues;
        },

        onFilterMultiple: (state, action: PayloadAction<OnFilterMultipleOptions>) => {
            state.filters = {
                ...state.filters,
                ...action.payload.filters,
            };
        },

        onFilters: (state, action: PayloadAction<OnFiltersOptions>) => {
            state.filters = action.payload.filters;
        },

        onFilterCustomAttributes: (
            state,
            action: PayloadAction<OnFilterCustomAttributesOptions>,
        ) => {
            state.filters = {
                ...state.filters,
                [action.payload.key]: [action.payload.value],
            };
        },

        onFilterCustomAttributesClear: (
            state,
            action: PayloadAction<OnFilterCustomAttributesClearOptions>,
        ) => {
            const { key, value } = action.payload;

            const customAttributes = state.filters[key] as ICustomAttribute[];
            const filteredAttributes = customAttributes
                ? customAttributes.filter(attr => attr.parameterName !== value?.parameterName)
                : [];

            state.filters = {
                ...state.filters,
                [action.payload.key]: [...filteredAttributes],
            };
        },

        onFilterReset: state => {
            state.filters = initialState.filters;
        },

        onForceRefresh: (state, action: PayloadAction<OnForceRefreshOptions>) => {
            state.forceRefresh = action.payload.status;
        },

        onStartupCheck: (state, action: PayloadAction<OnStartupCheckOptions>) => {
            state.startupCheck = {
                ...state.startupCheck,
                loading: true,
            };
        },

        onStartupCheckDone: (state, action: PayloadAction<OnStartupCheckDoneOptions>) => {
            state.startupCheck = {
                ...state.startupCheck,
                loading: false,
                startupCheck: action.payload.startupCheck,
            };
        },

        onStartupCheckFail: (state, action: PayloadAction<OnStartupCheckFailOptions>) => {
            state.startupCheck = {
                ...state.startupCheck,
                loading: false,
            };
        },

        // New
        onSetIssues: (state, action: PayloadAction<IssueSearchResponse>) => {
            state.list = action.payload;
        },
        setIssuesSettings: (state, action: PayloadAction<Partial<IIssuesSettings>>) => {
            state.settings = {
                ...state.settings,
                ...action.payload,
            };
        },
        setCurrentIssue: (state, action: PayloadAction<Issue | undefined>) => {
            state.currentIssue = action.payload;
        },
        setCurrentIssueDetails: (
            state,
            action: PayloadAction<IssueDetailsResponse | undefined>,
        ) => {
            state.currentIssueDetails = action.payload;
        },
        setCurrenIssuePushpin: (state, action: PayloadAction<IIssueCurrentPushpin | undefined>) => {
            state.currentPushpin = action.payload;
        },
        setCurrentIssuePushpinExternalId: (state, action: PayloadAction<string | null>) => {
            state.currentPushpin = {
                ...state.currentPushpin,
                externalId: action.payload,
            };
        },
        setIssuesOnRefresh: (state, action: PayloadAction<boolean>) => {
            state.onRefresh = action.payload;
        },

        setPushpinInsertMode: (state, action: PayloadAction<boolean>) => {
            state.pushpin = {
                insertMode: action.payload,
            };
        },

        setMode: (state, action: PayloadAction<IMode>) => {
            state.mode = action.payload;
        },
    },
});

export default issuesSlice.reducer;

export const {
    onSearch,
    onFilter,
    onSelect,
    onFilterMultiple,
    onFilters,
    onFilterCustomAttributes,
    onFilterCustomAttributesClear,
    onFilterReset,
    onForceRefresh,
    onStartupCheck,
    onStartupCheckDone,
    onStartupCheckFail,
    onSetIssues,
    setIssuesSettings,
    setCurrentIssue,
    setCurrentIssueDetails,
    setCurrenIssuePushpin,
    setCurrentIssuePushpinExternalId,
    setIssuesOnRefresh,
    setPushpinInsertMode,
    setMode,
} = issuesSlice.actions;

// Selectors
export const selectIssuesState = (state: RootState): IIssuesState => state.issues;
export const selectSearchText = (state: RootState): IIssuesState['searchText'] =>
    state.issues.searchText;
export const selectFilters = (state: RootState): IIssuesState['filters'] => state.issues.filters;
export const selectIssuesSelected = (state: RootState): IIssuesState['selected'] =>
    state.issues.selected;
export const selectIssuesSelectedCount = (state: RootState): number =>
    state.issues.selected?.length ? state.issues.selected?.length : 0;
export const selectFilterIsActive = (state: RootState): boolean =>
    Object.keys(state.issues.filters)?.filter(
        fk =>
            fk !== 'limit' &&
            fk !== 'offset' &&
            fk !== 'sortColumn' &&
            fk !== 'sortDirection' &&
            fk !== 'searchText',
    ).length !== 0;
export const selectForceRefresh = (state: RootState): IIssuesState['forceRefresh'] =>
    state.issues.forceRefresh;
export const selectIssuesSettings = (state: RootState) => state.issues.settings;
export const selectCurrentIssueDetails = (state: RootState) => state.issues.currentIssueDetails;

// startup check
export const selectStartupCheckLoading = (
    state: RootState,
): IIssuesState['startupCheck']['loading'] => state.issues.startupCheck.loading;
export const selectStartupCheck = (
    state: RootState,
): IIssuesState['startupCheck']['startupCheck'] => state.issues.startupCheck.startupCheck;

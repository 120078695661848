import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

export enum IExportRunningState {
    initializing = 'initializing', // initLockElements => fetch all elements from whole model from api
    initializingKeepOverlay = 'initializingKeepOverlay', // new DA activity of type Export is triggered and overlay is kept
    initializingDone = 'initializingDone', // show FSM modal - can sort elements in tree
    exportStart = 'exportStart', // start export
}

export interface IExportState {
    runningState?: IExportRunningState;
}

const initialState: Readonly<IExportState> = {
    runningState: undefined,
};

const exportSlice = createSlice({
    name: 'export',
    initialState,
    reducers: {
        onExportState: (state, action: PayloadAction<IExportRunningState | undefined>) => {
            state.runningState = action.payload;
        },
    },
});

export const { onExportState } = exportSlice.actions;

export const selectExportState = (state: RootState) => state.export;

export default exportSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IFilterView } from '../../components/Filter/types';
import { AssetListResponse, FilterSchemaResult } from '../../generate/api';
import { ICustomAttribute } from '../issues/types';
import { RootState } from '../store';
import {
    OnAssetsListDoneOptions,
    OnAssetsListOptions,
    OnFilterAssetClearAllOptions,
    OnFilterAssetsClearOptions,
    OnFilterAssetsOptions,
    OnFilterOptions,
    OnFiltersDoneOptions,
    OnFiltersFailOptions,
    OnFiltersOptions,
    OnFilterViewOptions,
    OnReloadCacheDoneOptions,
    OnReloadCacheFailOptions,
    OnReloadCacheOptions,
    OnSearchOptions,
    OnUpdateAssetOptions,
} from './types';
import { AssetsFilters, IAssetsListResponseData } from './types';

export interface IAssetsState {
    searchText: string;
    filters: {
        view: IFilterView;
        loading: boolean;
        filters?: FilterSchemaResult;
        active: {
            [key: string]: AssetsFilters | ICustomAttribute;
        };
    };
    assets: {
        loading: boolean;
        assetsList?: AssetListResponse;
    };
    reloadCache: {
        loading: boolean;
        reloadCache: boolean;
    };
}

const initialState: Readonly<IAssetsState> = {
    searchText: '',
    filters: {
        view: IFilterView.FILTER,
        loading: false,
        active: {},
    },
    assets: {
        loading: false,
    },
    reloadCache: {
        loading: false,
        reloadCache: false,
    },
};

export const assetsSlice = createSlice({
    name: 'assets',
    initialState: initialState,

    reducers: {
        // Filter
        onSearch: (state, action: PayloadAction<OnSearchOptions>) => {
            const { searchText } = action.payload;
            state.searchText = searchText;
        },
        onFilter: (state, action: PayloadAction<OnFilterOptions>) => {
            const { key, value } = action.payload;
            state.filters = {
                ...state.filters,
                active: {
                    ...state.filters.active,
                    [key]: value,
                },
            };
        },
        onFilterAssets: (state, action: PayloadAction<OnFilterAssetsOptions>) => {
            const { key, value } = action.payload;
            state.filters = {
                ...state.filters,
                active: {
                    ...state.filters.active,
                    [key]: value,
                },
            };
        },
        onFilterAssetsClear: (state, action: PayloadAction<OnFilterAssetsClearOptions>) => {
            const { key, value } = action.payload;

            const customAttributes = state.filters[key] as ICustomAttribute[];
            const filteredAttributes = customAttributes
                ? customAttributes?.filter(attr => attr?.parameterName !== value?.parameterName)
                : [];

            state.filters = {
                ...state.filters,
                active: {
                    [key]: [...filteredAttributes],
                },
            };
        },
        onFilterAssetClearAll: (state, action: PayloadAction<OnFilterAssetClearAllOptions>) => {
            const key = action.payload.key;
            const activeFilters = state.filters.active;
            delete activeFilters[key];

            state.filters = {
                ...state.filters,
                active: {
                    ...activeFilters,
                },
            };
        },
        onFilterReset: (state, action: PayloadAction) => {
            state.filters = {
                ...state.filters,
                active: initialState.filters.active,
            };
        },
        onFilterView: (state, action: PayloadAction<OnFilterViewOptions>) => {
            const { view } = action.payload;
            state.filters = {
                ...state.filters,
                view,
            };
        },

        // filters
        onFilters: (state, action: PayloadAction<OnFiltersOptions>) => {
            state.filters = {
                ...state.filters,
                loading: true,
            };
        },
        onFiltersDone: (state, action: PayloadAction<OnFiltersDoneOptions>) => {
            const { filters } = action.payload;
            state.filters = {
                ...state.filters,
                loading: false,
                filters,
            };
        },
        onFiltersFail: (state, action: PayloadAction<OnFiltersFailOptions>) => {
            state.filters = {
                ...state.filters,
                loading: false,
            };
        },

        // attribute list
        onAssetsList: (state, action: PayloadAction<OnAssetsListOptions>) => {
            state.assets = {
                ...state.assets,
                loading: true,
            };
        },
        onUpdateAssetsListRow: (state, action: PayloadAction<OnUpdateAssetOptions>) => {
            const { target, value, elementId } = action.payload;

            // find row key in list by elementId
            const rowKey = state.assets.assetsList?.assets?.findIndex(
                x => x.elementID === elementId,
            );
            if (rowKey === undefined || rowKey === -1) return;

            if (!state.assets.assetsList?.assets) return;

            // update rows
            if (target === 'status') {
                state.assets.assetsList.assets[rowKey].statusName = value;
                state.assets.assetsList.assets[rowKey].isStatusModified = true;
            } else if (target === 'comment') {
                const customPropertyCommentKey = state.assets.assetsList.assets[
                    rowKey
                ].customAttributes?.findIndex(x => x.attributeCode === 'comment');

                // check if comment not exists - create
                if (!customPropertyCommentKey) {
                    state.assets.assetsList.assets[rowKey].customAttributes?.push({
                        attributeCode: 'comment',
                        value: value,
                    });
                } else {
                    // update comment
                    const asset = state.assets.assetsList.assets[rowKey];
                    const customAttributes = asset?.customAttributes;

                    if (customAttributes) {
                        const comment = customAttributes[customPropertyCommentKey];
                        if (comment) {
                            comment.value = value;
                        }
                    }
                }
            }
        },
        onAssetsListDone: (state, action: PayloadAction<OnAssetsListDoneOptions>) => {
            const { attributeList } = action.payload;
            state.assets = {
                ...state.assets,
                loading: false,
                assetsList: attributeList,
            };
        },
        onAssetsListFail: (state, action: PayloadAction<OnFiltersFailOptions>) => {
            state.assets = {
                ...state.assets,
                loading: false,
            };
        },

        // reload cache
        onReloadCache: (state, action: PayloadAction<OnReloadCacheOptions>) => {
            state.reloadCache = {
                ...state.reloadCache,
                loading: true,
            };
        },
        onReloadCacheDone: (state, action: PayloadAction<OnReloadCacheDoneOptions>) => {
            const { reloadCache } = action.payload;
            state.reloadCache = {
                ...state.reloadCache,
                loading: false,
                reloadCache,
            };
        },
        onReloadCacheFail: (state, action: PayloadAction<OnReloadCacheFailOptions>) => {
            state.reloadCache = {
                ...state.reloadCache,
                loading: false,
            };
        },
    },
});

export const {
    onSearch,
    onFilter,
    onFilterAssets,
    onFilterAssetsClear,
    onFilterAssetClearAll,
    onFilterReset,
    onFilterView,
    onFilters,
    onFiltersDone,
    onFiltersFail,
    onAssetsList,
    onUpdateAssetsListRow,
    onAssetsListDone,
    onAssetsListFail,
    onReloadCache,
    onReloadCacheDone,
    onReloadCacheFail,
} = assetsSlice.actions;

export default assetsSlice.reducer;

// Selectors
// --------------------------------------------------

// All
export const selectAssetsState = (state: RootState): IAssetsState => state.assets;

// custom
export const selectSearchText = (state: RootState): IAssetsState['searchText'] =>
    state.assets.searchText;
export const selectActiveFilters = (state: RootState): IAssetsState['filters']['active'] =>
    state.assets.filters.active;
export const selectIsActiveFilter = (state: RootState) =>
    Object.keys(state.assets.filters.active)?.length !== 0;
export const selectFilterView = (state: RootState): IFilterView => state.assets.filters.view;

// filters
export const selectFiltersLoading = (state: RootState): boolean => state.assets.filters.loading;
export const selectFilters = (state: RootState): FilterSchemaResult =>
    state.assets.filters?.filters as FilterSchemaResult;

// attribute list
export const selectAttributeListLoading = (state: RootState): boolean =>
    state.assets.assets.loading;
export const selectAttributeList = (state: RootState): AssetListResponse | undefined =>
    state.assets.assets?.assetsList;

// reload cache
export const selectReloadCacheLoading = (state: RootState): boolean =>
    state.assets.reloadCache.loading;
export const selectReloadCache = (state: RootState): boolean =>
    state.assets.reloadCache.reloadCache;

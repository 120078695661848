/* tslint:disable */
/* eslint-disable */
/**
 * FireBIM API
 * API definitions for FireBim
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Statuses of revit cloud model lock.
 * @export
 * @enum {string}
 */

export const CloudModelLockStatus = {
    LockRequested: 'LockRequested',
    Locked: 'Locked',
    ReleaseRequested: 'ReleaseRequested',
    Released: 'Released',
    Busy: 'Busy'
} as const;

export type CloudModelLockStatus = typeof CloudModelLockStatus[keyof typeof CloudModelLockStatus];




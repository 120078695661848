import React from 'react';

import CommissioningButton from './CommissioningButton';
import CommissioningSettings from './CommissioningSettings';

interface ICommissioningProps {
    viewer: any;
}

const Commissioning: React.FC<ICommissioningProps> = ({ viewer }) => {
    return (
        <>
            <CommissioningSettings />
            <CommissioningButton viewer={viewer} />
        </>
    );
};

export default Commissioning;

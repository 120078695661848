import { defineMessages } from 'react-intl';
export default defineMessages({
    // Title
    titleIssue: {
        id: 'components.issues.title.issue',
        defaultMessage: 'Issue',
    },
    titleCreateIssue: {
        id: 'components.issues.editor.title.create',
        defaultMessage: 'Create Issue',
    },
    titleUpdateIssue: {
        id: 'components.issues.editor.title.update',
        defaultMessage: 'Update Issue',
    },

    // Labels
    labelTitle: {
        id: 'components.issues.detail.label.title',
        defaultMessage: 'Title',
    },
    labelAssignTo: {
        id: 'components.issues.detail.label.assignTo',
        defaultMessage: 'Assign To',
    },
    labelDueDate: {
        id: 'components.issues.detail.label.dueDate',
        defaultMessage: 'Due Date',
    },
    labelDescription: {
        id: 'components.issues.detail.label.description',
        defaultMessage: 'Description',
    },
    labelMarkup: {
        id: 'components.issues.detail.label.markup',
        defaultMessage: 'Markup',
    },
    labelAttachments: {
        id: 'components.issues.detail.label.attachments',
        defaultMessage: 'Attachments',
    },
    labelPushpin: {
        id: 'components.issues.detail.label.pushpin',
        defaultMessage: 'Pushpin',
    },

    // Buttons
    buttonEdit: {
        id: 'components.issues.button.edit',
        defaultMessage: 'Edit',
    },
    buttonCreate: {
        id: 'components.issues.editor.button.create',
        defaultMessage: 'Create',
    },
    buttonUpdate: {
        id: 'components.issues.editor.button.update',
        defaultMessage: 'Update',
    },
    buttonCancel: {
        id: 'components.issues.editor.button.cancel',
        defaultMessage: 'Cancel',
    },
    buttonPlacePushpin: {
        id: 'components.issues.editor.button.placePushpin',
        defaultMessage: 'Place Pushpin',
    },
    buttonUploadNewFile: {
        id: 'components.issues.editor.button.uploadNewFile',
        defaultMessage: 'Upload New File',
    },
    buttonSelectMarkup: {
        id: 'components.issues.editor.button.selectMarkup',
        defaultMessage: 'Select Markup',
    },
    buttonUnlinkMarkup: {
        id: 'components.issues.editor.button.unlinkMarkup',
        defaultMessage: 'Unlink Markup',
    },

    // Messages
    messagePushpinPlacedCorrectly: {
        id: 'components.issues.editor.message.pushpinPlacedCorrectly',
        defaultMessage: 'Pushpin placed correctly',
    },
    messagePushpinPlacedIncorrectly: {
        id: 'components.issues.editor.message.pushpinPlacedIncorrectly',
        defaultMessage: 'Pushpin placed incorrectly',
    },
    messageUploading: {
        id: 'components.issues.editor.message.uploading',
        defaultMessage: 'Uploading',
    },
    messageNoMarkups: {
        id: 'components.issues.editor.message.noMarkups',
        defaultMessage: 'No markups',
    },
    messageNoIssueSelected: {
        id: 'components.issues.message.noIssueSelected',
        defaultMessage: 'No issue selected',
    },
    messageIssueUpdated: {
        id: 'components.issues.editor.message.issueUpdated',
        defaultMessage: 'Issue updated',
    },
    messageIssueUpdateFailed: {
        id: 'components.issues.editor.message.issueUpdateFailed',
        defaultMessage: 'Issue update failed',
    },
    messageIssueCreated: {
        id: 'components.issues.editor.message.issueCreated',
        defaultMessage: 'Issue created',
    },
    // Issue creation failed
    messageIssueCreationFailed: {
        id: 'components.issues.editor.message.issueCreationFailed',
        defaultMessage: 'Issue creation failed',
    },
});

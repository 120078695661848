import { Button, DialogActions, DialogContent, Typography } from '@mui/material';
import React, { useContext } from 'react';

import { DialogContext } from '../../../dialogs/DialogProvider/DialogProvider';
import { useTranslation } from '../../../redux/translations/hook';
import { DF } from '../DialogFactory';
import messages from './messages';
import useStyles from './styles';

const WIP: React.FC = () => {
    const classes = useStyles();
    const { toggleDialog } = useContext(DialogContext);

    // translations
    const transContent = useTranslation({ ...messages.content });
    const transBtnClose = useTranslation({ ...messages.btnClose });

    return (
        <>
            <DialogContent dividers className={classes.dialogContent}>
                <Typography variant="body1">{transContent}</Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => toggleDialog(DF.WIP)}
                >
                    {transBtnClose}
                </Button>
            </DialogActions>
        </>
    );
};

export default WIP;

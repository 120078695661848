import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

export type NotificationVariant = 'default' | 'success' | 'error' | 'info' | undefined;

export interface INotification {
    key?: number;
    message: string;
    variant?: NotificationVariant;
    persist?: boolean;
}

export interface INotifierState {
    nextNotification: number;
    notifications: INotification[];
}

const initialState: Readonly<INotifierState> = {
    nextNotification: -1, // used for notifications keys
    notifications: [],
};

export const notifierSlice = createSlice({
    name: 'notifier',
    initialState: initialState,
    reducers: {
        addNotification: (state, action: PayloadAction<INotification>) => {
            const key = state.nextNotification + 1;
            state.nextNotification = key;
            // add notification with incremented key at the start of the list
            state.notifications = [
                {
                    ...action.payload,
                    key: key,
                },
                ...state.notifications,
            ];
        },

        removeNotification: (state, action: PayloadAction<number>) => {
            // remove notification from the list for given key
            state.notifications = state.notifications.filter(
                notification => notification.key !== action.payload,
            );
        },
    },
});

export const { addNotification, removeNotification } = notifierSlice.actions;

export const selectNotifications = (state: RootState) => state.notifier.notifications;

export default notifierSlice.reducer;

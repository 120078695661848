import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        listItem: {
            borderBottom: '1px solid #efefef',
            padding: '5px 10px',
            cursor: 'pointer',
            '&:hover': {
                background: '#efefef',
            },
        },
        listItemActive: {
            background: '#efefef',
        },
        title: {
            textAlign: 'center',
            fontSize: '1.2rem',
        },
        modal: {
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '10px',
        },
    }),
);

export default useStyles;

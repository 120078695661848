import config from '../../../../config/config';
import { Configuration, IssuesModuleApi } from '../../../../generate/api';
import { store } from '../../../../redux/store';
import ApiClient from '../../ApiClient';

const issueApiOld = new ApiClient('/Issues', config.api.baseUrl);

export const attachmentDownload = async ({
    token,
    forgeProjectId,
    forgeIssueID,
    forgeAttachmentID,
}: {
    token: string;
    forgeProjectId: string;
    forgeIssueID: string;
    forgeAttachmentID: string;
}) => {
    ApiClient.setAuthorizationToken(token);

    const data = {
        forgeProjectId: forgeProjectId,
        forgeIssueID: forgeIssueID,
        forgeAttachmentID: forgeAttachmentID,
    };

    try {
        const response = await issueApiOld.post('/Attachments/Download', data, {
            responseType: 'blob',
        });
        return await Promise.resolve(response.data);
    } catch (error) {
        return await Promise.reject(error);
    }
};

export const issuesApi = new IssuesModuleApi(
    new Configuration({
        basePath: config.api.baseUrl,
        accessToken: () => {
            return store.getState().keycloak.token as string;
        },
    }),
);

import { MenuItem, TextField, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import { useAssetsActiveFilters } from '../../redux/assets';
import { AssetsFilters } from '../../redux/assets/types';
import { useIssuesFilters } from '../../redux/issues';
import { IIssuesFilters } from '../../redux/issues/types';
import { useTranslation } from '../../redux/translations/hook';
import FieldMultiTypeDate from './FieldMultiTypeDate';
import FieldMultiTypeList from './FieldMultiTypeList';
import FieldMultiTypeNumeric from './FieldMultiTypeNumeric';
import FieldMultiTypeText from './FieldMultiTypeText';
import messages from './messages';
import { IFilterDefinition, IFilterType, IMultiTypeValue } from './types';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        listSubheader: {
            paddingLeft: 0,
            paddingRight: 0,
            height: '27px',
            '& span': {
                color: theme.palette.text.primary,
                fontSize: theme.typography.pxToRem(12),
                fontWeight: 600,
                backgroundColor: theme.palette.grey[50],
                lineHeight: '30px',
                width: '100%',
            },
            '&.Mui-disabled': {
                opacity: 1,
            },
        },
        placeholder: {
            color: theme.palette.grey[300],
            '& .MuiSelect-root': {
                color: `${theme.palette.text.disabled} !important`,
            },
        },
        textField: {
            '& .MuiOutlinedInput-root, & .MuiSelect-root': {
                minWidth: 'auto',
                color: theme.palette.grey[300],
            },
        },
        checkbox: {
            padding: theme.spacing(0, 1, 0, 0),
            '& svg': {
                color: theme.palette.secondary.main,
            },
        },
    }),
);

interface IFieldMultiTypeProps {
    type: IFilterType;
    filterDefinition: IFilterDefinition;
}

const FieldMultiType: React.FC<IFieldMultiTypeProps> = ({ type, filterDefinition }) => {
    const classes = useStyles();
    const issuesFilters: IIssuesFilters = useIssuesFilters();
    const assetsFilters = useAssetsActiveFilters();

    const [attributeParameterName, setAttributeParameterName] = useState<string>('');
    const [attributeMultiTypeValue, setAttributeMultiTypeValue] = useState<IMultiTypeValue>();

    const handleChange = event => {
        setAttributeParameterName(event.target.value);
    };

    const handleClearAttribute = () => {
        setAttributeParameterName('');
        setAttributeMultiTypeValue(undefined);
    };

    const handleClick = (multiTypeValue: IMultiTypeValue) => {
        setAttributeMultiTypeValue(multiTypeValue);
    };

    // set initial component state on clear all filter
    useEffect(() => {
        if (type === IFilterType.ISSUES) {
            if (issuesFilters[filterDefinition?.parameterName as string] === undefined) {
                setAttributeParameterName('');
                setAttributeMultiTypeValue(undefined);
            } else {
                const multipleTypeValue = filterDefinition?.multiTypeValues?.find(
                    fdmtv =>
                        fdmtv?.parameterName ===
                        issuesFilters[filterDefinition?.parameterName as string]?.[0]
                            ?.parameterName,
                );

                if (multipleTypeValue) {
                    setAttributeParameterName(
                        issuesFilters[filterDefinition?.parameterName as string]?.[0]
                            ?.parameterName,
                    );
                    setAttributeMultiTypeValue(multipleTypeValue);
                }
            }
        } else {
            if (assetsFilters[filterDefinition?.parameterName as string] === undefined) {
                setAttributeParameterName('');
                setAttributeMultiTypeValue(undefined);
            }
        }
    }, [issuesFilters, assetsFilters]);

    const renderMultiTypeField = (
        // eslint-disable-next-line @typescript-eslint/no-shadow
        filterDefinition: IFilterDefinition,
        multiTypeValue: IMultiTypeValue,
    ) => {
        switch (multiTypeValue?.type) {
            // select
            case 'array':
            case 'list': {
                return (
                    <FieldMultiTypeList
                        type={type}
                        filterDefinition={filterDefinition}
                        multiTypeValue={multiTypeValue}
                        handleClearAttribute={handleClearAttribute}
                        localeMappingOperand={localeMappingOperand}
                        defaultOperand={
                            issuesFilters[filterDefinition?.parameterName as string]?.[0]?.operand
                        }
                        defaultValue={
                            issuesFilters[filterDefinition?.parameterName as string]?.[0]?.value
                        }
                    />
                );
            }
            // text field
            case 'string':
            case 'text':
            case 'paragraph': {
                return (
                    <FieldMultiTypeText
                        type={type}
                        filterDefinition={filterDefinition}
                        multiTypeValue={multiTypeValue}
                        handleClearAttribute={handleClearAttribute}
                        localeMappingOperand={localeMappingOperand}
                        defaultOperand={
                            issuesFilters[filterDefinition?.parameterName as string]?.[0]?.operand
                        }
                        defaultValue={
                            issuesFilters[filterDefinition?.parameterName as string]?.[0]?.value
                        }
                    />
                );
            }
            // number field
            case 'numeric': {
                return (
                    <FieldMultiTypeNumeric
                        type={type}
                        filterDefinition={filterDefinition}
                        multiTypeValue={multiTypeValue}
                        handleClearAttribute={handleClearAttribute}
                        localeMappingOperand={localeMappingOperand}
                        defaultOperand={
                            issuesFilters[filterDefinition?.parameterName as string]?.[0]?.operand
                        }
                        defaultValue={
                            issuesFilters[filterDefinition?.parameterName as string]?.[0]?.value
                        }
                    />
                );
            }
            // date
            case 'date': {
                return (
                    <FieldMultiTypeDate
                        type={type}
                        filterDefinition={filterDefinition}
                        multiTypeValue={multiTypeValue}
                        handleClearAttribute={handleClearAttribute}
                        localeMappingOperand={localeMappingOperand}
                        defaultOperand={
                            issuesFilters[filterDefinition?.parameterName as string]?.[0]?.operand
                        }
                        defaultValue={
                            issuesFilters[filterDefinition?.parameterName as string]?.[0]?.value
                        }
                    />
                );
            }
            default: {
                break;
            }
        }
    };

    // translations
    const transFieldCustomAttribute = useTranslation({ ...messages.fieldCustomAttribute });
    const transFieldCustomAttributePlaceholder = useTranslation({
        ...messages.fieldCustomAttributePlaceholder,
    });
    const transOperandUndefined = useTranslation({ ...messages.operandUndefined });
    const transOperandDefined = useTranslation({ ...messages.operandDefined });
    const transOperandEmpty = useTranslation({ ...messages.operandEmpty });
    const transOperandNotEmpty = useTranslation({ ...messages.operandNotEmpty });
    const transOperandEquals = useTranslation({ ...messages.operandEquals });
    const transOperandNotEquals = useTranslation({ ...messages.operandNotEquals });
    const transOperandGt = useTranslation({ ...messages.operandGt });
    const transOperandEgt = useTranslation({ ...messages.operandEgt });
    const transOperandLt = useTranslation({ ...messages.operandLt });
    const transOperandElt = useTranslation({ ...messages.operandElt });
    const transOperandStartsWith = useTranslation({ ...messages.operandStartsWith });
    const transOperandEndsWith = useTranslation({ ...messages.operandEndsWith });
    const transOperandContains = useTranslation({ ...messages.operandContains });

    /**
     * Mapping DE locale for operands
     * @param operand
     */
    const localeMappingOperand = (operand: string): string => {
        switch (operand) {
            case 'Is not defined': {
                return transOperandUndefined;
            }
            case 'Is defined': {
                return transOperandDefined;
            }
            case 'Is empty': {
                return transOperandEmpty;
            }
            case 'Is not empty': {
                return transOperandNotEmpty;
            }
            case 'Equals': {
                return transOperandEquals;
            }
            case 'Not equals': {
                return transOperandNotEquals;
            }
            case 'Greater than': {
                return transOperandGt;
            }
            case 'Equal or grather than': {
                return transOperandEgt;
            }
            case 'Lower than': {
                return transOperandLt;
            }
            case 'Equal or lower than': {
                return transOperandElt;
            }
            case 'Starts with': {
                return transOperandStartsWith;
            }
            case 'Ends with': {
                return transOperandEndsWith;
            }
            case 'Contains': {
                return transOperandContains;
            }
            default: {
                return operand;
            }
        }
    };

    return (
        <>
            <TextField
                id={filterDefinition?.parameterName}
                required
                fullWidth
                select
                label={transFieldCustomAttribute}
                variant="outlined"
                margin="dense"
                className={clsx(classes.textField, {
                    [classes.placeholder]: attributeParameterName === '',
                })}
                InputLabelProps={{
                    shrink: true,
                }}
                SelectProps={{
                    value: attributeParameterName,
                    displayEmpty: true,
                    onChange: handleChange,
                    MenuProps: {
                        // getContentAnchorEl: null,
                    },
                }}
            >
                <MenuItem value="" className={classes.placeholder}>
                    {transFieldCustomAttributePlaceholder}
                </MenuItem>
                {filterDefinition?.multiTypeValues?.map((multiTypeValue, index) => (
                    <MenuItem
                        key={index}
                        value={multiTypeValue?.parameterName}
                        onClick={() => handleClick(multiTypeValue)}
                    >
                        {multiTypeValue?.displayName}
                    </MenuItem>
                ))}
            </TextField>
            {attributeMultiTypeValue &&
                renderMultiTypeField(filterDefinition, attributeMultiTypeValue)}
        </>
    );
};

export default FieldMultiType;

import { onStart } from '@iolabs/app';
import { createListenerMiddleware } from '@reduxjs/toolkit';

import ApiClient from '../../packages/Api/ApiClient';
import { setEvent, setToken } from './keycloakSlice';

let authorizationToken: string | null = null;

export const setAuthorizationToken = (token: string): void => {
    authorizationToken = token;
};
export const getAuthorizationToken = (): string | null => {
    return authorizationToken;
};

export const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
    actionCreator: setEvent,
    effect: async (action, listenerApi) => {
        listenerApi.dispatch(onStart());
    },
});

listenerMiddleware.startListening({
    actionCreator: setToken,
    effect: async (action, listenerApi) => {
        const token = action.payload.token as string;
        ApiClient.setAuthorizationToken(token);
        setAuthorizationToken(token);
    },
});

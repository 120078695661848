import { useKeycloak } from '@react-keycloak/web';
import React, { useContext, useEffect, useState } from 'react';

import { DialogContext } from '../../dialogs/DialogProvider/DialogProvider';
import { FilterSchemaResult, IssueFilterRequest } from '../../generate/api';
import { issuesApi } from '../../packages/Api/data/issues/client';
import { useProjectState } from '../../redux/project';
import { DF } from '../DialogFactory/DialogFactory';
import { INotificationDialogProps } from '../DialogFactory/NotificationDialog/NotificationDialog';
import Filter from '../Filter/Filter';
import { IFilterDefinition, IFilterGroup, IFilterType } from '../Filter/types';

const IssuesFilter: React.FC = () => {
    const { keycloak } = useKeycloak();

    const project = useProjectState();
    const forgeProjectId = project?.project?.id;
    const fileVersionUrn = project?.file?.currentVersion?.data?.urn;

    const [issuesFilters, setIssuesFilters] = useState<FilterSchemaResult>();

    const { openDialog } = useContext(DialogContext);

    useEffect(() => {
        if (keycloak?.token && forgeProjectId && fileVersionUrn) {
            const data: IssueFilterRequest = {
                forgeProjectId: forgeProjectId,
                forgeVersionUrn: fileVersionUrn,
            };

            issuesApi
                .issuesFiltersPost(data)
                .then(issuesFiltersResponseData => {
                    setIssuesFilters(issuesFiltersResponseData.data);
                    console.log('## Issues - Open filters');
                })
                .catch(error => {
                    openDialog<INotificationDialogProps>(DF.NOTIFICATION_DIALOG, 'Error', {
                        content: 'loading filters was not successful',
                    });
                    console.error('## Issues - Unable to load filters', error);
                });
        }
    }, [forgeProjectId, fileVersionUrn]);

    return (
        <div>
            <Filter
                type={IFilterType.ISSUES}
                filterGroups={issuesFilters?.filterGroups as IFilterGroup[]}
                filterDefinitions={issuesFilters?.filtersDefinitons as IFilterDefinition[]}
            />
        </div>
    );
};

export default IssuesFilter;

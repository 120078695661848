import { DispatchAction } from '@iolabs/redux-utils';
import { OptionsObject, useSnackbar } from 'notistack';
import React, { PropsWithChildren, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { removeNotification, selectNotifications } from './notifierSlice';

interface IProps {
    autoHideDuration?: number;
}

const Notifier: React.FC<PropsWithChildren<IProps>> = (props: IProps) => {
    const dispatch = useDispatch<DispatchAction>();
    const { enqueueSnackbar } = useSnackbar();
    const notifications = useSelector(selectNotifications);
    const { autoHideDuration } = props;

    const [displayed, setDisplayed] = useState<number[]>([]);

    React.useEffect(() => {
        notifications.map(notification => {
            // if notification already displayed, abort
            if (displayed.filter(key => key === notification.key).length > 0) {
                return;
            }

            const opts: OptionsObject = { variant: notification.variant };
            if (autoHideDuration) {
                opts.autoHideDuration = autoHideDuration;
            }
            // display notification using Snackbar
            enqueueSnackbar(notification.message, opts);

            // add notification's key to the local state
            setDisplayed([...displayed, notification.key as number]);

            // dispatch action to remove the notification from the redux store
            dispatch(removeNotification(notification.key as number));
        });
    }, [notifications]);

    return <></>;
};

export default Notifier;

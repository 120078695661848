import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'components.notificationDialog.title',
        defaultMessage: 'Notification title',
    },
    content: {
        id: 'components.notificationDialog.content',
        defaultMessage: 'Notification content.',
    },
    btnClose: {
        id: 'components.notificationDialog.btnClose',
        defaultMessage: 'Close',
    },
});

import {
    addDays,
    addMonths,
    addWeeks,
    endOfMonth,
    endOfWeek,
    Locale,
    startOfMonth,
    startOfWeek,
} from 'date-fns';

// eslint-disable-next-line no-unused-vars
import { DefinedRange } from './types';

const getDefaultRanges = (date: Date, locale: Locale): DefinedRange[] => [
    {
        label: 'Today', // Heute
        startDate: date,
        endDate: date,
    },
    {
        label: 'Yesterday', // Gestern
        startDate: addDays(date, -1),
        endDate: addDays(date, -1),
    },
    {
        label: 'This Week', // Diese Woche
        startDate: startOfWeek(date, { locale }),
        endDate: endOfWeek(date, { locale }),
    },
    {
        label: 'Last Week', // Letzte Woche
        startDate: startOfWeek(addWeeks(date, -1), { locale }),
        endDate: endOfWeek(addWeeks(date, -1), { locale }),
    },
    {
        label: 'Last 7 Days', // Letzte 7 Tage
        startDate: addWeeks(date, -1),
        endDate: date,
    },
    {
        label: 'This Month', // Dieser Monat
        startDate: startOfMonth(date),
        endDate: endOfMonth(date),
    },
    {
        label: 'Last Month', // Letzter Monat
        startDate: startOfMonth(addMonths(date, -1)),
        endDate: endOfMonth(addMonths(date, -1)),
    },
];

export function defaultRanges(locale: Locale) {
    return getDefaultRanges(new Date(), locale);
}
